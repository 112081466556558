import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DisplayAPIsException from "../../BasicComponents/DisplayAPIsExceptionAlert";
import { logEvent, logException } from "../../helper/AppInsightsHelper";
import { CustomShimmer } from "../../helper/CustomShimmer";
import { partnerChartType } from "../../interfaces/partner";
import { useGetPartnerDataQuery } from "../../slices/Customer.slice";
import { groupBy } from "../../Utils/js-common";
import PartnerChart from "./PartnerChart";
import PartnerTable from "./PartnerTable";

const PartnerContainer = (props: any) => {
    const [searchParams] = useSearchParams();
    const tenantId = searchParams.get("id");
    const { data, error, isFetching } = useGetPartnerDataQuery(tenantId);
    const [items, setitems] = useState([]);
    const [isChartLoading, setIsChartLoading] = useState(true);
    const [filteredItems, setFilteredItems] = useState([]);
    const [filteredChartData, setFilteredChartData] = React.useState<partnerChartType>();
    const [chartKeys, setChartKeys] = React.useState<string[]>([]);
    const [chartFilter, setChartFilter] = React.useState({
        filterKey: "",
        value: ""
    });
    const [filterChData, setFilterChData] = React.useState<any>({
        partnerType: "",
        partnerOneName: ""
    });
    const HandleAPIExcpetion = () => (error && <DisplayAPIsException height={250} />) || "";

    useEffect(() => {
        logEvent("Customer Partner", "Customer Page", "PartnerContainer");
    }, []);

    useEffect(() => {
        if (data) {
            setitems(data);
            setFilteredItems(data);
            const groupByPartnerType = groupBy(data, "partnerType");
            const dataByStatus = Object.keys(groupByPartnerType).map((item) => {
                return {
                    partnerType: item,
                    partnerTypeCount: groupByPartnerType[item].length
                };
            });
            const groupByPartnerOneName = groupBy(data, "partnerOneName");
            const dataByType = Object.keys(groupByPartnerOneName).map((item) => {
                return {
                    partnerOneName: item,
                    partnerOneNameCount: groupByPartnerOneName[item].length
                };
            });
            setFilteredChartData({
                partnerType: dataByStatus,
                partnerOneName: dataByType
            });
            setFilterChData({
                partnerType: "",
                partnerOneName: ""
            });
            setIsChartLoading(false);
            setChartKeys(["partnerType", "partnerOneName"]);
        }

        if (error) {
            setIsChartLoading(false);
            logException("Customer View", "PartnerContainer", "useGetPartnerDataQuery", error);
        }
    }, [data, error]);

    React.useEffect(() => {
        switch (chartFilter.filterKey) {
            case "partnerType":
                {
                    let filteredData: any = [];
                    filteredData = items.filter((item: any) => item.partnerType === chartFilter.value);
                    setFilterChData({
                        ...filterChData,
                        partnerType: chartFilter.value
                    });
                    if (filterChData.partnerOneName !== "") {
                        filteredData = filteredData.filter((item: any) => item.partnerOneName === filterChData.partnerOneName);
                    }

                    setFilteredItems(filteredData);
                    const partnerOneName = groupBy(filteredData, "partnerOneName");
                    const filteredPartnerOneName: any[] = [];
                    Object.keys(partnerOneName).forEach((item) => {
                        filteredPartnerOneName.push({
                            partnerOneName: item,
                            partnerOneNameCount: partnerOneName[item].length
                        });
                    });

                    if (filteredPartnerOneName && filteredChartData?.partnerOneName) {
                        setFilteredChartData({
                            ...filteredChartData,
                            partnerOneName: filteredPartnerOneName
                        });
                    }
                }
                break;
            case "partnerOneName":
                {
                    let filteredData: any = [];
                    filteredData = items.filter((item: any) => item.partnerOneName === chartFilter.value);
                    setFilterChData({
                        ...filterChData,
                        partnerOneName: chartFilter.value
                    });
                    if (filterChData.partnerType !== "") {
                        filteredData = filteredData.filter((item: any) => item.partnerType === filterChData.partnerType);
                    }

                    setFilteredItems(filteredData);
                    const partnerType = groupBy(filteredData, "partnerType");
                    const partnerTypeFiltered: any[] = [];
                    Object.keys(partnerType).forEach((item) => {
                        partnerTypeFiltered.push({
                            partnerType: item,
                            partnerTypeCount: partnerType[item].length
                        });
                    });

                    if (partnerTypeFiltered && filteredChartData?.partnerType) {
                        setFilteredChartData({
                            ...filteredChartData,
                            partnerType: partnerTypeFiltered
                        });
                    }
                }
                break;

            default: {
                setFilteredItems(data);
                if (data) {
                    const groupBypartnerType = groupBy(data, "partnerType");
                    const dataByPartnerType = Object.keys(groupBypartnerType).map((item) => {
                        return {
                            partnerType: item,
                            partnerTypeCount: groupBypartnerType[item].length
                        };
                    });
                    const groupByPartnerOneName = groupBy(data, "partnerOneName");
                    const dataByPartnerOneName = Object.keys(groupByPartnerOneName).map((item) => {
                        return {
                            partnerOneName: item,
                            partnerOneNameCount: groupByPartnerOneName[item].length
                        };
                    });
                    setFilteredChartData({
                        partnerType: dataByPartnerType,
                        partnerOneName: dataByPartnerOneName
                    });
                    setFilterChData({
                        partnerType: "",
                        partnerOneName: ""
                    });
                }
            }
        }
    }, [chartFilter]);

    const showNoData = () => {
        const rootStyle = {
          alignItems: "center",
          display: "flex",
          fontSize: 18,
          fontWeight: 600,
          justifyContent: "center",
          padding: 200
        };
        return <div style={rootStyle}>{`Partner details are not available for ${props.tenantName}.`}</div>;
    };

    return (
        <>
            <PartnerChart
                chartData={filteredChartData}
                isChartLoading={isChartLoading}
                keys={chartKeys}
                setChartFilter={setChartFilter}
                tenantName={props.tenantName}
            />
            {isFetching ? (
                <CustomShimmer columnCount={11} />
            ) : (
                (items.length && (
                    <div style={{ marginLeft: 3, marginRight: 3 }}>
                        <PartnerTable tableData={filteredItems} tenantName={props.tenantName} />
                    </div>
                )) ||
                showNoData()
            )}
            <HandleAPIExcpetion />
        </>
    );
};

export default PartnerContainer;
