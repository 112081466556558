import { Breadcrumb, IBreadcrumbItem } from "@coherence-design-system/breadcrumb";
import { useNavigate } from "react-router";
export default function CustomerBreadCrumb(props: { name: string }) {
  const navigate = useNavigate();

  const items: IBreadcrumbItem[] = [
    {
      text: "Home",
      key: "Home",
      href: "",
      onClick: () => navigate("/")
    } as IBreadcrumbItem,
    {
      text: "Customers",
      key: "customersearch",
      href: "",
      onClick: () => navigate("/customersearch")
    } as IBreadcrumbItem,

    { text: props.name, key: "product", isCurrentItem: true }
  ];

  return (
    <Breadcrumb
      items={items}
      ariaLabel=""
      // OnClick={() => window.location.reload()}
      overflowAriaLabel="More links"
      styles={{
        itemLink: {
          selectors: { ":hover": { textDecoration: "underline" } }
        }
      }}
    />
  );
}
