// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store/store";
import { serviceApi } from "../Services/ServiceConfig";

// Define a service using a base URL and expected endpoints

export const CRMCustomerProductApi = createApi({
  reducerPath: "CRMCustomerProductApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serviceApi.security360 + "customer/",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).msal.token?.accessToken;
      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    }
  }),

  endpoints: (builder) => ({
    getCRMCustomerProductForTenant: builder.query({
      query: (id) => `getCRMCustomerProductForTenant?TenantId=${id}`
    })
  })
});

export const { useGetCRMCustomerProductForTenantQuery } = CRMCustomerProductApi;
