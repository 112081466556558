import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IProductFamily } from "../interfaces/customer-product-v2.interfaces";
import { ISelectedTenant } from "../interfaces/Customer.interfaces";
import { serviceApi } from "../Services/ServiceConfig";
import { RootState } from "../store/store";

// Define a service using a base URL and expected endpoints
export const CustomerAPI = createApi({
  reducerPath: "customerApi",
  tagTypes: ["FavoritesCustomer"],
  baseQuery: fetchBaseQuery({
    baseUrl: serviceApi.security360,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).msal.token?.accessToken;
      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getProductKPIs: builder.query<IProductFamily[], string>({
      query: (id) => `customer/getFactProductKPIByTenantId?TenantId=${id}`
    }),
    getEngagements: builder.query({
      query: (id) => `customer/getEngagementProgramData?TenantId=${id}`
    }),
    getSupportDataOfTenant: builder.query({
      query: (id) => `customer/getSupportDataOfTenant?TenantId=${id}`
    }),
    getPartnerData: builder.query({
      query: (id) => `customer/getPartnerData?TenantId=${id}`
    }),
    getCustomerEngagements: builder.query({
      query: (id) => `customer/getCustomerEngagements?TenantId=${id}`
    }),
    GetTenantData: builder.query<ISelectedTenant[], {id: string}>({
      query: ({ id }) => `customer/getTenantData?TenantId=${id}`
    }),
    getFavorableCustomerByLogin: builder.query({
      query: ({ email, env }) => `customer/getFavCustomersByLogin?LoginEmail=${email}&Enviroment=${env}`,
      providesTags: ["FavoritesCustomer"]
    }),
    getTenantList: builder.mutation({
      query(body) {
        return {
          url: "customer/getTenantsList",
          method: "POST",
          body
        };
      }
    }),
    getFilters: builder.query({
      query: () => "common/getFilterData"
    }),
    insertUpdateFavCustomers: builder.mutation({
      query(customer) {
        return {
          url: "customer/insertUpdateFavCustomers",
          method: "POST",
          body: customer
        };
      },
      invalidatesTags: ["FavoritesCustomer"]
    })
  })
});

// Export hooks for usage in functional components, which are
// Auto-generated based on the defined endpoints
export const {
  useGetProductKPIsQuery,
  useGetEngagementsQuery,
  useGetTenantListMutation,
  useGetTenantDataQuery,
  useInsertUpdateFavCustomersMutation,
  useGetFavorableCustomerByLoginQuery,
  useGetSupportDataOfTenantQuery,
  useGetPartnerDataQuery,
  useGetCustomerEngagementsQuery,
  useGetFiltersQuery
} = CustomerAPI;
