import React, { useEffect, useState } from "react";
import { useGetCountryCodeAndKpisQuery } from "../slices/CountryCodesKPI.slice";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import { CustomShimmer } from "../helper/CustomShimmer";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import GlossaryBreadCrumb from "../MuiComponents/GlossaryBreadCrumb";
import { PaperHeaderText } from "../helper/PaperHeaderText";
import { logPageView } from "../helper/AppInsightsHelper";

export default function Glossary() {
  const kpisList = useGetCountryCodeAndKpisQuery(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (kpisList.isSuccess) {
      setLoading(false);
    }

    logPageView("Glossary Page", window.location.href);
  }, [kpisList.isSuccess]);

  const columns: GridColDef[] = [
    {
      headerName: "Metric Name",
      field: "name",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            fontSize: 10,
            whiteSpace: "normal",
            wordWrap: "break-word"
          }}
          title={params.row.name || "N/A"}
        >
          {params.row.name || "N/A"}
        </span>
      ),

      sortable: true
    },
    {
      headerName: "Metric Definition",
      field: "definition",
      width: 1500,
      renderCell: (params) => (
        <span
          style={{
            fontSize: 12,
            whiteSpace: "normal",
            wordWrap: "break-word"
          }}
          title={params.row.definition}
        >
          {params.row.definition}
        </span>
      ),
      sortable: true
    }
  ];

  return (
    <div
      style={{
        paddingLeft: "10px"
      }}
    >
      <GlossaryBreadCrumb />

      <Card>
        <Paper>
          <PaperHeaderText text={"Glossary"} />
          {isLoading ? (
            <CustomShimmer columnCount={columns.length} />
          ) : (
            kpisList?.data?.productKpis && (
              <DataGrid
                rows={kpisList?.data?.productKpis}
                columns={columns}
                pageSizeOptions={[10]}
                getRowId={(row) => row.name}
                columnHeaderHeight={40}
                disableColumnMenu
                disableColumnFilter
                disableRowSelectionOnClick
                disableColumnSelector
                hideFooterPagination
                hideFooter
                style={{ paddingTop: 10 }}
              />
            )
          )}
        </Paper>
      </Card>
    </div>
    // </div>
  );
}
