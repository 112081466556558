import { IStackProps, Panel, PanelType, PrimaryButton, Spinner } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DisplayAPIsException from "../../../BasicComponents/DisplayAPIsExceptionAlert";
import { logEvent, logException } from "../../../helper/AppInsightsHelper";
import InsightsComponent from "../../../helper/InsightsComponent";
import { IArrayKey } from "../../../interfaces/carousel-props.interfaces";
import { IProductFamily } from "../../../interfaces/customer-product-v2.interfaces";
import { getChatInsightCompletion } from "../../../Services/openAIServices";
import { Loader } from "../../../SharedComponents/Loader";
import { useGetProductKPIsQuery } from "../../../slices/Customer.slice";
import { arrSortDescByKeyLength } from "../../../Utils/js-common";
import { CustomerProductCarousel } from "./CustomerProductCarousel";

export default function CustomerProductInfo(props: any) {
  const [apiData, setData] = useState<IProductFamily[]>([]);
  const [searchParams] = useSearchParams();
  const tenantId = searchParams.get("id");
  const { data, isFetching, error } = useGetProductKPIsQuery(tenantId || "");
  const HandleAPIExcpetion = () => (error && <DisplayAPIsException height={250} />) || "";

  useEffect(() => {
    if (data) {
      setData(
        arrSortDescByKeyLength({
          key: "data",
          data: JSON.parse(JSON.stringify(data))
        } as IArrayKey)
      );
      logEvent("Customer Product Info", "Customer Page", "CustomerProductInfoV2");
    }
    if (error) {
      logException("Customer Page", "CustomerProductInfoV2", "useGetProductKPIsQuery", error);
    }
  }, [data, isFetching, error]);

  const rowProps: IStackProps = { horizontal: true, verticalAlign: "center" };

  const tokens = {
    sectionStack: {
      childrenGap: 10
    },
    spinnerStack: {
      childrenGap: 20
    }
  };

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [insightData, setInsightData] = useState("");

  const getInsights = async () => {
    await getChatInsightCompletion("product", tenantId, props.tenantName).then((response) => {
      if (response !== "") {
        setInsightData(response);
      } else {
        setInsightData("No data found for given prompt");
      }
    });
  };
  useEffect(() => {
    getInsights();
  }, []);

  return (
    <>
      <Loader rowProps={rowProps} spinnerStack={tokens.spinnerStack} loading={!!isFetching} />
      {!isFetching && !error && apiData.length === 0 && (
        <span
          style={{
            marginLeft: "45%",
            marginRight: 5,
            marginTop: 50,

            background: "#FFF",
            lineHeight: 2
          }}
        >
          No Product Data found for the given Tenant Id
        </span>
      )}

      {(!isFetching && apiData.length && (
        <div className="CustomerProductgrid" style={{ padding: "0 8px" }}>
          <PrimaryButton
            style={{ position: "relative", left: "90%" }}
            text="AI Insights"
            onClick={() => {
              openPanel();
            }}
          />

          <Panel
            onRenderHeader={() => (
              <div style={{ fontSize: 26, fontWeight: 700, paddingLeft: 32 }}>Product Insights</div>
            )}
            isLightDismiss
            isOpen={isOpen}
            onDismiss={dismissPanel}
            type={PanelType.medium}
            closeButtonAriaLabel="Close"
            styles={{
              navigation: {
                justifyContent: "space-between",
                alignItems: "center"
              }
            }}
          >
            {insightData?.length === 0 && (
              <Spinner style={{ position: "absolute", left: "40%", top: "40%" }} label="Loading" />
            )}
            {insightData?.length > 0 && <InsightsComponent insightDataInfo={insightData} />}
          </Panel>
          <Loader rowProps={rowProps} spinnerStack={tokens.spinnerStack} loading={!!isFetching} />
          {apiData.map(
            ({ name: pName, data: pData }) =>
              (!isFetching && (
                <Accordion key={`customer-product-${pName}`} defaultExpanded style={{ margin: 0 }}>
                  <AccordionSummary id={pName} expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      variant="h2"
                      sx={{
                        marginTop: 2,
                        marginBottom: 2
                      }}
                    >
                      {pName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomerProductCarousel
                      key={pName}
                      carouseldata={{
                        name: "",
                        items: pData.map(({ name: kpiName, data: kpiData }) => ({
                          name: kpiName,
                          data: kpiData
                        }))
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              )) ||
              ""
          )}
        </div>
      )) ||
        ""}
      <HandleAPIExcpetion />
    </>
  );
}
