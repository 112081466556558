import { Stack } from "@fluentui/react";
import { getNextColor } from "@fluentui/react-charting";
import { Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import DisplayAPIsException from "../../../../BasicComponents/DisplayAPIsExceptionAlert";
import { LineChartComponent } from "../../../../ChartComponents/LineChartFluent";
import CustomCard from "../../../../SharedComponents/CustomCard";
import { arrSortAscByKeyValue, getLastDateUTC } from "../../../../Utils/js-common";
import { CustomShimmer } from "../../../../helper/CustomShimmer";
import { getProductMetricsCardsGraphsSelector } from "../../../../slices/Product.slice";
import { useAppSelector } from "../../../../store/hooks";
import { PRODUCT_VIEW_DATA } from "../../Common/ProductView.constants";
import { ProductViewSummaryTabCardProps } from "../../Common/ProductView.interfaces";
import { PRODUCT_VIEW_STYLES } from "../../Common/ProductView.styles";

export const SummaryTab = () => {
  const [width, setWidth] = useState(500);
  const widthCalc = useRef<HTMLDivElement>(null);
  const resizedWidth = useAppSelector(({ resize }) => resize.offsetWidth);
  const { data, isError, isLoading, isSuccess } = useAppSelector(getProductMetricsCardsGraphsSelector);

  const getMetricsData = (data: any[], isTenantMetrics: 0 | 1 = 0) => {
    if (data.length === 0) return {};

    const reduceInitialValue = { data: [] };
    const metricsData = arrSortAscByKeyValue({
      key: "name",
      data: data.reduce((a, b) => ({ ...a, data: a.data.concat(b.data) }), reduceInitialValue).data
    });

    return {
      chartTitle: "Line Chart",
      lineChartData: data
        .filter((x) => x.isTenantMetrics === String(isTenantMetrics))[0]
        ?.data.map((m: any) => ({
          legend: m.name,
          data: m.data.map((a: any) => ({
            x: getLastDateUTC(a.x.split(" ")[0], a.x.split(" ")[1]),
            y: Number(a.y)
          })),
          color: getNextColor(metricsData.findIndex(({ name }) => name === m.name)),
          lineOptions: {
            lineBorderWidth: "4"
          }
        }))
    };
  };

  const displayLineChart = (isTenantMetrics: 0 | 1 = 0) =>
    isSuccess && (
      <LineChartComponent
        data={getMetricsData(data?.metricsData || [], isTenantMetrics)}
        height={400}
        width={width - 10}
        allowResize={true}
      />
    );

  const displayAPIsException = isError && <DisplayAPIsException height={widthCalc.current?.offsetHeight} />;

  const CustomCardDetails = ({ isTenantMetrics }: Partial<ProductViewSummaryTabCardProps>) =>
    isLoading ? <CustomShimmer /> : displayLineChart(isTenantMetrics) || displayAPIsException;

  const renderCards = PRODUCT_VIEW_DATA.TABS.TAB.SUMMARY.DATA.map(({ key, header, isTenantMetrics }) => (
    <div key={key} ref={widthCalc} style={PRODUCT_VIEW_STYLES.TAB.SUMMARY.cardContainer}>
      <CustomCard cardHeaderTitle={header}>
        <CustomCardDetails isTenantMetrics={isTenantMetrics} />
      </CustomCard>
    </div>
  ));

  const handleResize = useCallback((width: number) => {
    setWidth(width);
  }, []);

  useEffect(() => {
    if (widthCalc.current) {
      const _width = widthCalc.current?.offsetWidth;
      handleResize(_width);
    }
  }, [handleResize, resizedWidth]);

  useLayoutEffect(() => {
    if (widthCalc.current) {
      const _width = widthCalc.current?.offsetWidth;

      handleResize(_width);
    }
  }, [width, handleResize]);

  return (
    <Stack horizontal tokens={PRODUCT_VIEW_STYLES.TAB.SUMMARY.stackContainer}>
      {renderCards}
    </Stack>
  );
};

export const tenantCountMetricsTooltipMessage = (
  <Fragment>
    See number of tenants are trending over time for security products. The graph below shows 6 month trend.
    <br />
    <b>
      <i>Note: Filters are not supported for this graph.</i>
    </b>
  </Fragment>
);
