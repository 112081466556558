import { Box, Card, CardContent, CardHeader } from "@mui/material";
import { ChartsAxisContentProps } from "@mui/x-charts";
import { LineChart } from "@mui/x-charts/LineChart";
import { format } from "d3-format";

interface ILineData {
  chartData: { value: number; month: string }[];
  name: string;
}

const styles = {
  colors: ["rgb(0, 120, 212)"],
  LineChart: {
    ".MuiLineElement-root": {
      strokeWidth: 2.5
    }
  },
  titleTypographyProps: {
    fontSize: 12,
    fontWeight: 600
  },
  CardHeaderSx: { borderBottom: "1px solid #e1e1e1", padding: "8px" },
  CardContentFlex: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
    padding: "8px"
  },
  CardContentFlexItem: { color: "rgb(0, 108, 190)" }
};

const CustomAxisContent = (props: ChartsAxisContentProps) => {
  const { series, dataIndex } = props;
  const uv: number = Number(series[0]?.data.at(dataIndex || 0));
  return (
    <Box sx={{ minWidth: 140, ml: 2 }}>
      <Card variant="outlined">
        <CardHeader
          titleTypographyProps={styles.titleTypographyProps}
          title={props.axisValue}
          sx={{ ...styles.CardHeaderSx }}
        />
        <CardContent sx={{ fontSize: 12, fontWeight: 600 }}>
          <div style={styles.CardContentFlex}>
            <div style={{ ...styles.CardContentFlexItem }}>{props.axis.dataKey}:&nbsp;</div>
            <div>{format(",")(uv)}</div>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
};

export default function HomeSparkLineChart({ chartData, name }: ILineData) {
  const values = chartData.map((m) => m.value);
  const labels = chartData.map((m) => m.month);
  return (
    <LineChart
      width={75}
      height={35}
      series={[{ curve: "linear", data: values, showMark: false }]}
      xAxis={[{ scaleType: "point", data: labels, dataKey: name }]}
      sx={styles.LineChart}
      leftAxis={null}
      bottomAxis={null}
      slotProps={{ legend: { hidden: true } }}
      axisHighlight={{ x: "none" }}
      margin={{ top: 5, right: 8, bottom: 2, left: 8 }}
      colors={styles.colors}
      slots={{ axisContent: CustomAxisContent }}
    />
  );
}
