import React from "react";
import { Link } from "react-router-dom";

interface PageNotFoundProps {}

interface PageNotFoundState {}

class PageNotFound extends React.Component<PageNotFoundProps, PageNotFoundState> {
  constructor(props: PageNotFoundProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <h2>404 Error</h2>
        <h2>Page Not Found</h2>
        <p>
          <Link to="/">Go to Home </Link>
        </p>
      </>
    );
  }
}

export default PageNotFound;
