import { IconButton } from "@fluentui/react";
import { HorizontalBarChart, HorizontalBarChartVariant, IChartProps } from "@fluentui/react-charting";
import React, { useState } from "react";
import DisplayEmptyDataMessage from "../SharedComponents/DisplayEmptyDataMessage";

const CustomHorizontalBarChart: React.FC<{
  data: IChartProps[];
}> = ({ data: apiResponse }) => {
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState<number>(0);

  // Sort and filter data to remove items with empty chartData
  const sortedData = [...apiResponse]
    .filter((item) => item.chartData && item.chartData.length > 0)
    .sort(
      (a, b) =>
        (b?.chartData?.[0]?.horizontalBarChartdata?.x || 0) - (a?.chartData?.[0]?.horizontalBarChartdata?.x || 0)
    );

  const paginatedData = sortedData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  // Ensure maxXValue calculation is safe
  const maxXValue = Math.max(...paginatedData.map((item) => item?.chartData?.[0]?.horizontalBarChartdata?.x || 0));

  // Adjust data safely
  const adjustedData = paginatedData.map((item) => ({
    ...item,
    chartData: item?.chartData?.map((data) =>
      data?.horizontalBarChartdata
        ? {
            ...data,
            horizontalBarChartdata: {
              ...data.horizontalBarChartdata,
              y: maxXValue || 1 // Ensure y is defined, default to 1
            }
          }
        : data
    )
  }));

  return (
    <div style={{ minHeight: 400, display: "flex", flexDirection: "column" }}>
      {/* Ensure a consistent height for the chart and button container */}
      {(adjustedData.length && (
        <>
          <div style={{ flexGrow: 1 }}>
            <HorizontalBarChart
              data={adjustedData as IChartProps[]}
              barHeight={20}
              variant={HorizontalBarChartVariant.PartToWhole}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              iconProps={{ iconName: "ChevronUp" }}
              onClick={() => setCurrentPage(Math.max(0, currentPage - 1))}
              disabled={currentPage === 0}
            />
            <IconButton
              iconProps={{ iconName: "ChevronDown" }}
              onClick={() => setCurrentPage(Math.min(Math.ceil(sortedData.length / itemsPerPage) - 1, currentPage + 1))}
              disabled={currentPage === Math.ceil(sortedData.length / itemsPerPage) - 1}
            />
          </div>
        </>
      )) || <DisplayEmptyDataMessage />}
    </div>
  );
};

export default CustomHorizontalBarChart;
