export const DATA_GRID_CONFIG = {
  rows: {
    count: 0,
    values: [],
    size: 0,
    skip: 0
  },
  pagination: {
    pageSize: 25,
    page: 0
  }
};

export const COPY_TENANT_ID = "Copy Tenant Id";

export const TENANT_ID_COPIED = "Copied";
