/* eslint-disable no-undef */
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paper } from "@mui/material";
import { useGetFavorableCustomerByLoginQuery } from "../../slices/Customer.slice";
import { logEvent, logException } from "../../helper/AppInsightsHelper";
import { DATA_GRID_SLOTS } from "../../BasicComponents/DataGridSlots";

export default function FavoriteCustomersGridComponent() {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const enviornment = process.env.REACT_APP_ENVIRONMENT;
  const loginEmail = sessionStorage.getItem("loginUserName")?.toString();
  const { data, error } = useGetFavorableCustomerByLoginQuery({
    email: loginEmail,
    env: enviornment
  });

  useEffect(() => {
    setLoading(true);
    if (data) {
      setRows(data);
      setLoading(false);

      logEvent(
        "useGetFavorableCustomerByLoginQuery API call success",
        "Favorite Customers",
        "FavoriteCustomersGridComponent"
      );
    }
    if (error) {
      setLoading(false);
      logException("Favorite Customers", "FavoriteCustomersGridComponent", "useGetFavorableCustomerByLoginQuery", error);
    }
  }, [data, error]);

  const columns: GridColDef[] = [
    {
      headerName: "Tenant Id",
      field: "tenantId",
      width: 270,
      sortable: false,
      renderCell: (params) => (
        <a
          style={{
            color: "rgb(0, 120, 212)",
            textDecoration: "none",
            fontSize: 12,
            cursor: "pointer"
          }}
          tabIndex={0}
          onClick={() => {
            navigate("/customer?id=" + params.row.tenantId);
          }}
        >
          {params.row.tenantId}
        </a>
      )
    },
    {
      headerName: "Tenant Name",
      field: "tenanatName",
      width: 220,
      sortable: false,
      renderCell: (params) => (
        <a
          style={{
            color: "rgb(0, 120, 212)",
            textDecoration: "none",
            fontSize: 12,
            cursor: "pointer",
            overflow: "hidden",
            textOverflow: "ellipsis",
            minWidth: 170
          }}
          tabIndex={0}
          onClick={() => {
            navigate("/customer?id=" + params.row.tenantId);
          }}
          title={params.row.tenantName}
        >
          {params.row.tenantName}
        </a>
      )
    },
    {
      headerName: "Top Parent Id",
      field: "tpid",
      width: 120,
      sortable: false
    },

    {
      headerName: "Segment",
      field: "segmentName",
      width: 170,
      sortable: false
    },
    {
      headerName: "Sub Segment",
      field: "subSegmentName",
      width: 190,
      sortable: false
    },
    {
      headerName: "Region",
      field: "regionName",
      width: 130,
      sortable: false
    },
    {
      headerName: "Industry",
      field: "industryName",
      width: 160,
      sortable: false
    },
    {
      headerName: "VerticalName",
      field: "verticalName",
      width: 160,
      sortable: false
    },
    {
      headerName: "IsFaviourite",
      field: "isFaviourite",
      width: 160,
      sortable: false
    },
    {
      headerName: "IsPartner",
      field: "isPartner",
      width: 160,
      sortable: false
    },

    // {
    //   headerName: "EntraID Logins",
    //   field: "currentValue",
    //   width: 160,
    //   sortable: false
    // }
  ];

  return (
    <Paper>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        getRowId={(row) => row.tenantId}
        loading={loading}
        keepNonExistentRowsSelected
        hideFooterPagination
        hideFooter
        disableColumnMenu
        disableColumnFilter
        disableRowSelectionOnClick
        disableColumnSelector
        slots={{
          loadingOverlay: DATA_GRID_SLOTS.loadingOverlay,
          noRowsOverlay: DATA_GRID_SLOTS.noRowsOverlay(!!error)
        }}
      />
    </Paper>
  );
}
