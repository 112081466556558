import React, { useState } from "react";
import Card from "@mui/material/Card";
import { makeStyles } from "@fluentui/react-components";
import {
  DrinkCoffee20Regular
  // DocumentSearch16Regular,
  // Person20Regular,
  // DrawerArrowDownload20Regular,
  // Doctor16Regular
} from "@fluentui/react-icons";
import { CustomerInsights } from "./CustomerInsights";
import { SupportInsights } from "./SupportInsights";
import { EngagementInsights } from "./EngagementInsights";
import { FeedbackInsights } from "./FeedbackInsights";
import { TechnicalAssistance } from "./TechnicalAssistance";

// const customerInsights = "AI assistant for customer or tenant insights";
// const supportInsights = "AI assistant for support data insights";
const engagementInsights = "AI assistant for CRM engagement insights";
// const feedbackInsights = "AI assistant for feedback insights";
// const technicalAssistance = "AI assistant for technical assistance insights";

const useStyles = makeStyles({
  cardActive: {
    backgroundColor: "lightgray"
  },
  cardInactive: {
    backgroundColor: "white"
  },
  content: {
    paddingTop: "15px",
    textAlign: "center",
    justifyContent: "space-evenly",
    cursor: "pointer",
    height: "80px",
    width: "200px"
  },
  textStyle: {
    paddingLeft: "5px",
    fontWeight: "bold",
    fontSize: "14px"
  },
  descriptionStyle: {
    fontSize: "10px"
  },
  mainContainerStyle: {
    display: "flex",
    // alignItems: "center",
    alignItems: "flex-start",
    // justifyContent: "space-evenly",
    marginTop: "20px",
    marginBottom: "20px",
    marginLeft :"50px",
    width: "100%"
  },
  imageStyle: {
    width: "15px",
    height: "15px",
    paddingTop: "2px"
  }
});

export default function AIPlaygroundContainer() {
  const styles = useStyles();

  const [selectedAICard, setSelectedAICard] = useState<string>("customerTenantInsights");
  // const [customerInsightsCardClicked, setCustomerInsightsCardClicked] = useState<boolean>(true);
  // const [supportInsightsCardClicked, setSupportInsightsCardClicked] = useState<boolean>(false);
  const [engagementInsightsCardClicked, setEngagementInsightsCardClicked] = useState<boolean>(false);
  // const [feedbackInsightsCardClicked, setFeedbackInsightsCardClicked] = useState<boolean>(false);
  // const [technicalAssistanceCardClicked, setTechnicalAssistanceCardClicked] = useState<boolean>(false);

  const handleCardClick = (card: any) => {
    setSelectedAICard(card.selectedCard);
    switch (card.selectedCard) {
      // case "customerInsights":
      //   setCustomerInsightsCardClicked(true);
      //   setSupportInsightsCardClicked(false);
      //   setEngagementInsightsCardClicked(false);
      //   setFeedbackInsightsCardClicked(false);
      //   setTechnicalAssistanceCardClicked(false);
      //   break;
      // case "supportInsights":
      //   setSupportInsightsCardClicked(true);
      //   setCustomerInsightsCardClicked(false);
      //   setEngagementInsightsCardClicked(false);
      //   setFeedbackInsightsCardClicked(false);
      //   setTechnicalAssistanceCardClicked(false);
      //   break;
      case "engagementInsights":
        setEngagementInsightsCardClicked(true);
        // setSupportInsightsCardClicked(false);
        // setCustomerInsightsCardClicked(false);
        // setFeedbackInsightsCardClicked(false);
        // setTechnicalAssistanceCardClicked(false);
        break;
      // case "feedbackInsights":
      //   setFeedbackInsightsCardClicked(true);
      //   setEngagementInsightsCardClicked(false);
      //   setSupportInsightsCardClicked(false);
      //   setCustomerInsightsCardClicked(false);
      //   setTechnicalAssistanceCardClicked(false);
      //   break;
      // case "technicalAssistance":
      //   setTechnicalAssistanceCardClicked(true);
      //   setFeedbackInsightsCardClicked(false);
      //   setEngagementInsightsCardClicked(false);
      //   setSupportInsightsCardClicked(false);
      //   setCustomerInsightsCardClicked(false);
      //   break;
      // Default to first case
      default:
        // setCustomerInsightsCardClicked(true);
        // setSupportInsightsCardClicked(false);
        setEngagementInsightsCardClicked(false);
        // setFeedbackInsightsCardClicked(false);
        // setTechnicalAssistanceCardClicked(false);
        break;
    }
  };

  const cardData = [
    // {
    //   title: customerInsights,
    //   selectedCard: "customerInsights",
    //   icon: <Person20Regular className={styles.imageStyle} />,
    //   text: "Customer Insights",
    //   description: customerInsights,
    //   styles: customerInsightsCardClicked ? styles.cardActive : styles.cardInactive
    // },
    // {
    //   title: supportInsights,
    //   selectedCard: "supportInsights",
    //   icon: <DocumentSearch16Regular className={styles.imageStyle} />,
    //   text: "Support Insights",
    //   description: supportInsights,
    //   styles: supportInsightsCardClicked ? styles.cardActive : styles.cardInactive
    // },
    {
      title: engagementInsights,
      selectedCard: "engagementInsights",
      icon: <DrinkCoffee20Regular className={styles.imageStyle} />,
      text: "Engagement Insights",
      description: engagementInsights,
      styles: engagementInsightsCardClicked ? styles.cardActive : styles.cardInactive
    }
    // ,{
    //   title: feedbackInsights,
    //   selectedCard: "feedbackInsights",
    //   icon: <DrawerArrowDownload20Regular className={styles.imageStyle} />,
    //   text: "Feedback Insights",
    //   description: feedbackInsights,
    //   styles: feedbackInsightsCardClicked ? styles.cardActive : styles.cardInactive
    // },
    // {
    //   title: technicalAssistance,
    //   selectedCard: "technicalAssistance",
    //   icon: <Doctor16Regular className={styles.imageStyle} />,
    //   text: "Technical Assistance",
    //   description: technicalAssistance,
    //   styles: technicalAssistanceCardClicked ? styles.cardActive : styles.cardInactive
    // }
  ];

  return (
    <>
      <div className={styles.mainContainerStyle}>
        {cardData.map((card, index) => (
          <Card key={index} className={card.styles}>
            <div className={styles.content} title={card.title} tabIndex={0} onClick={() => handleCardClick(card)}>
              <div>
                <div className="row">
                  {card.icon}
                  <span className={styles.textStyle}>{card.text}</span>
                </div>
                <span className={styles.descriptionStyle}>{card.description}</span>
              </div>
            </div>
          </Card>
        ))}
      </div>

      <div>
        {(() => {
          switch (selectedAICard) {
            case "customerInsights":
              return <CustomerInsights />;
            case "supportInsights":
              return <SupportInsights />;
            case "engagementInsights":
              return <EngagementInsights />;
            case "feedbackInsights":
              return <FeedbackInsights />;
            case "technicalAssistance":
              return <TechnicalAssistance />;
            default:
              // return <CustomerInsights />;
              return <EngagementInsights />;
          }
        })()}
      </div>
    </>
  );
}
