import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import DisplayAPIsException from "../../BasicComponents/DisplayAPIsExceptionAlert";
import { CustomShimmer } from "../../helper/CustomShimmer";
import { groupBy } from "../../Utils/js-common";
import { programChartType } from "../../interfaces/program-engagement";
import { useGetEngagementsQuery } from "../../slices/Customer.slice";
import EngagementTable from "./EngagementTable";
import EngagementsAnnotations from "./EngagementsAnnotations";
import EngagementsChart from "./EngagementsChart";
import { logEvent } from "../../helper/AppInsightsHelper";

const ProgramsContainer = (props: any) => {
  const [searchParams] = useSearchParams();
  const tenantId = searchParams.get("id");
  const [items, setitems] = React.useState([]);
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [filteredChartData, setFilteredChartData] = React.useState<programChartType>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [chartKeys, setChartKeys] = React.useState<string[]>([]);
  const [chartFilter, setChartFilter] = React.useState({
    filterKey: "",
    value: ""
  });
  const [filterChData, setFilterChData] = React.useState<any>({
    programState: "",
    productFamily: ""
  });
  const { data, error } = useGetEngagementsQuery(tenantId);
  const HandleAPIExcpetion = () => (error && <DisplayAPIsException height={250} />) || "";

  useEffect(() => {
    logEvent("Customer Programs", "Customer Page", "ProgramsContainer");
  }, []);

  useEffect(() => {
    if (data) {
      setitems(data);
      setFilteredItems(data);
      const groupBySatus = groupBy(data, "programState");
      const dataByStatus = Object.keys(groupBySatus).map((item) => {
        return {
          programState: item,
          programStateCount: groupBySatus[item].length
        };
      });
      const groupByProduct = groupBy(data, "programProduct");
      const dataByProduct = Object.keys(groupByProduct).map((item) => {
        return {
          productFamily: item,
          productFamilyCount: groupByProduct[item].length
        };
      });
      setFilteredChartData({
        programState: dataByStatus,
        productFamily: dataByProduct
      });
      setFilterChData({
        programState: "",
        productFamily: ""
      });
      setChartKeys(["programState", "programProduct"]);
    }
    if (data || error) {
      setIsLoading(false);
    }
  }, [data, error]);

  useEffect(() => {
    switch (chartFilter.filterKey) {
      case "programState":
        {
          let filteredData: any = [];
          filteredData = items.filter((item: any) => item.programState === chartFilter.value);
          setFilterChData({
            ...filterChData,
            programState: chartFilter.value
          });
          if (filterChData.ProductFamily !== "" && filterChData.ProductFamily !== undefined) {
            filteredData = filteredData.filter((item: any) => item.programProduct === filterChData.productFamily);
          }

          setFilteredItems(filteredData);
          const ProductFamily = groupBy(filteredData, "programProduct");
          const filteredFamily: any[] = [];
          Object.keys(ProductFamily).forEach((item) => {
            filteredFamily.push({
              productFamily: item,
              productFamilyCount: ProductFamily[item].length
            });
          });

          if (filteredFamily && filteredChartData?.productFamily) {
            setFilteredChartData({
              ...filteredChartData,
              productFamily: filteredFamily
            });
          }
        }
        break;
      case "programProduct":
        {
          let filteredData: any = [];
          filteredData = items.filter((item: any) => item.programProduct === chartFilter.value);
          setFilterChData({
            ...filterChData,
            productFamily: chartFilter.value
          });
          if (filterChData.programState !== "" && filterChData.programState !== undefined) {
            filteredData = filteredData.filter((item: any) => item.programState === filterChData.programState);
          }

          setFilteredItems(filteredData);
          const programState = groupBy(filteredData, "programState");
          const stateFiltered: any[] = [];
          Object.keys(programState).forEach((item) => {
            stateFiltered.push({
              programState: item,
              programStateCount: programState[item].length
            });
          });

          if (stateFiltered && filteredChartData?.programState) {
            setFilteredChartData({
              ...filteredChartData,
              programState: stateFiltered
            });
          }
        }
        break;

      default: {
        setFilteredItems(data);
        if (data) {
          const groupBySatus = groupBy(data, "programState");
          const dataByStatus = Object.keys(groupBySatus).map((item) => {
            return {
              programState: item,
              programStateCount: groupBySatus[item].length
            };
          });
          const groupByProduct = groupBy(data, "programProduct");
          const dataByProduct = Object.keys(groupByProduct).map((item) => {
            return {
              productFamily: item,
              productFamilyCount: groupByProduct[item].length
            };
          });
          setFilteredChartData({
            programState: dataByStatus,
            productFamily: dataByProduct
          });
        }

        setFilterChData({
          programState: "",
          productFamily: ""
        });
      }
    }
  }, [chartFilter]);

  return (
    <>
      <div style={{ display: "flex" }}>
        {(filteredItems?.length && <EngagementsAnnotations annotationData={filteredItems} />) ||
          (isLoading && (
            <div style={{ width: "30%" }}>
              <CustomShimmer columnCount={2} rowCount={9} />
            </div>
          ))}
        <div style={{ width: "70%" }}>
          <EngagementsChart
            chartData={filteredChartData}
            isChartLoading={isLoading}
            keys={chartKeys}
            setChartFilter={setChartFilter}
          />
        </div>
      </div>
      {isLoading ? (
        <CustomShimmer columnCount={8} />
      ) : (
        (!error && (
          <>
            <div style={{ marginLeft: 3, marginRight: 3 }}>
              <EngagementTable tableData={filteredItems} tenantName={props.tenantName} />
            </div>
          </>
        )) ||
        ""
      )}
      <HandleAPIExcpetion />
    </>
  );
};

export default ProgramsContainer;
