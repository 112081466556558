import axios from "axios";
import { IAzSearchInput } from "../interfaces/Customer.interfaces";
import { serviceApi } from "./ServiceConfig";
import {
  ISupportInsightsRequest,
  ICustomerInsightsRequest,
  IEngagementInsightsRequest,
  IFeedbackInsightsRequest,
  ITechnicalAssistanceRequest
} from "../ServiceModels/CustomerService-Models";
import { logEvent, logException } from "../helper/AppInsightsHelper";

const controller = new AbortController();

axios.interceptors.request.use(
  (config: any) => {
    config.headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")?.toString()}`;
    config.headers["Content-Type"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Cache-Control"] = "no-cache";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error;
  }
);

export async function getTenantsList(
  pageNo: any,
  rowCount: any,
  searchText: string = "",
  region: string = "",
  segment: string = "",
  subsegment: string = "",
  industry: string = "",
  isSST: string = "",
  hasPartner: string = "",
  isFastTrackStrategic: string = "",
  isCAT: string = ""
) {
  const obj = {
    pageno: pageNo,
    rowcnt: rowCount,
    searchtext: searchText,
    region: region,
    segment: segment,
    subsegment: subsegment,
    industry: industry,
    isSST: isSST,
    hasPartner: hasPartner,
    isFastTrackStrategic: isFastTrackStrategic,
    isCAT: isCAT
  };
  return axios({
    method: "post",
    url: serviceApi.security360 + "customer/getTenantsList",
    data: JSON.stringify(obj)
  })
    .then((response) => {
      logEvent("getTenantsList API call success", "", "customer service");
      return response?.data;
    })
    .catch((error) => {
      logException("", "customer service", "getTenantsList");
      return error;
    });
}
export async function getRegions() {
  return axios({
    method: "get",
    url: serviceApi.security360 + "customer/getRegions"
  })
    .then((response) => {
      logEvent("getRegions API call success", "", "customer service");
      return response?.data;
    })
    .catch((error) => {
      logException("", "customer service", "getRegions");
      return error;
    });
}
export async function getSegments() {
  return axios({
    method: "get",
    url: serviceApi.security360 + "customer/getSegments"
  })
    .then((response) => {
      logEvent("getSegments API call success", "", "customer service");
      return response?.data;
    })
    .catch((error) => {
      logException("", "customer service", "getSegments");
      return error;
    });
}
export async function getSubSegments(segmentNames: string = "All") {
  return axios({
    method: "get",
    url: serviceApi.security360 + `customer/getSubSegments?segmentName=${segmentNames}`
  })
    .then((response) => {
      logEvent("getSubSegments API call success", "", "customer service");
      return response?.data;
    })
    .catch((error) => {
      logException("", "customer service", "getSubSegments");
      return error;
    });
}
export async function getIndustries() {
  return axios({
    method: "get",
    url: serviceApi.security360 + "customer/getIndustry"
  })
    .then((response) => {
      logEvent("getIndustry API call success", "", "customer service");
      return response?.data;
    })
    .catch((error) => {
      logException("", "customer service", "getIndustry");
      return error;
    });
}
export async function getCustomerDetails(tenantId: string) {
  return axios({
    method: "get",

    url:
      serviceApi.security360 + `customer/getTenantData?TenantId=${tenantId}`
  })
    .then((response) => {
      logEvent("getTenantData API call success", "", "customer service");
      return response?.data;
    })
    .catch((error) => {
      logException("", "customer service", "getTenantData");
      return error;
    });
}

export async function getSubscriptionByTenantId(tenantId: any) {
  return axios({
    method: "get",
    url: serviceApi.security360 + "customer/getSubscriptionByTenant?TenantId=" + tenantId
  })
    .then((response) => {
      logEvent("getSubscriptionByTenant API call success", "", "customer service");
      return response?.data;
    })
    .catch((error) => {
      logException("", "customer service", "getSubscriptionByTenant");
      return error;
    });
}

export async function getSubscriptionGraphByTenantId(tenantId: any) {
  return axios({
    method: "get",
    url: serviceApi.security360 + "customer/getSubscriptionByTenantGraph?TenantId=" + tenantId
  })
    .then((response) => {
      logEvent("getSubscriptionByTenantGraph API call success", "", "customer service");
      return response?.data;
    })
    .catch((error) => {
      logException("", "customer service", "getSubscriptionByTenantGraph");
      return error;
    });
}

export async function getProducts() {
  return axios({
    method: "get",
    url: serviceApi.security360 + "product/getAllProductsNames"
  })
    .then((response) => {
      logEvent("getAllProductsNames API call success", "", "customer service");
      return response?.data;
    })
    .catch((error) => {
      logException("", "customer service", "getAllProductsNames");
      return error;
    });
}

export async function getCRMRequestAssistanceData(tenantId: any) {
  return axios({
    method: "get",
    url: serviceApi.security360 + "customer/getCRMRequestAssistanceData?TenantId=" + tenantId
  })
    .then((response) => {
      logEvent("getCRMRequestAssistanceData API call success", "", "customer service");
      return response?.data;
    })
    .catch((error) => {
      logException("", "customer service", "getCRMRequestAssistanceData");
      return error;
    });
}

export async function getCRMFeatureRequestData(tenantId: any) {
  return axios({
    method: "get",
    url: serviceApi.security360 + "customer/getCRMFeatureRequestData?TenantId=" + tenantId
  })
    .then((response) => {
      logEvent("getCRMFeatureRequestData API call success", "", "customer service");
      return response?.data;
    })
    .catch((error) => {
      logException("", "customer service", "getCRMFeatureRequestData");
      return error;
    });
}

export const getCustomers = async (data: IAzSearchInput) => {
  return axios
    .post(`${serviceApi.security360}customer/getCustomers`, data, {
      signal: controller.signal
    })
    .then((response) => {
      logEvent("getCustomers API call success", "", "customer service");
      return response?.data;
    })
    .catch((error) => {
      logException("", "customer service", "getCustomers");
      return error;
    });
};

export async function getCustomerContactList(tenantId: any) {
  return axios({
    method: "get",
    url: serviceApi.security360 + "customer/getCustomerContactList?TenantId=" + tenantId
  })
    .then((response) => {
      logEvent("getCustomerContactList API call success", "", "customer service");
      return response?.data;
    })
    .catch((error) => {
      logException("", "customer service", "getCustomerContactList");
      return error;
    });
}

async function postInsightsDataRequest(url: string, payload: any, scenario: string) {
  return axios({
    method: "post",
    url: serviceApi.security360 + url,
    data: JSON.stringify(payload)
  })
    .then((response) => {
      logEvent(url + " API call success", scenario, "customer service");
      return response?.data;
    })
    .catch((error) => {
      logException(scenario, "customer service", url);
      return error;
    });
}

async function postInsightsScheduleMetadataRequest(url: string, payload: any, scenario: string) {
  return axios({
    method: "post",
    url: serviceApi.security360 + url,
    data: JSON.stringify(payload)
  })
    .then((response) => {
      logEvent(url + " API call success", scenario, "customer service");
      return response?.data;
    })
    .catch((error) => {
      logException(scenario, "customer service", url);
      return error;
    });
}

// Fetch Insights data
export async function customerInsightsData(customerInsightsDataRequestPayload: ICustomerInsightsRequest) {
  return postInsightsDataRequest(
    "AIPlayground/CallCustomerQueryNLAzFunc",
    customerInsightsDataRequestPayload,
    "Customer Insights"
  );
}

export async function supportInsightsData(supportInsightsDataRequestPayload: ISupportInsightsRequest) {
  return postInsightsDataRequest(
    "AIPlayground/CallCustomerSupportDataNLAzFunc",
    supportInsightsDataRequestPayload,
    "Support Insights"
  );
}

export async function engagementInsightsData(engagementInsightsDataRequestPayload: IEngagementInsightsRequest) {
  return postInsightsDataRequest(
    "AIPlayground/CallCRMEngagementNLAzFunc",
    engagementInsightsDataRequestPayload,
    "Engagement Insights"
  );
}

export async function feedbackInsightsData(feedbackInsightsDataRequestPayload: IFeedbackInsightsRequest) {
  return postInsightsDataRequest(
    "AIPlayground/CallFeedbackNLAzFunc",
    feedbackInsightsDataRequestPayload,
    "Feedback Insights"
  );
}

export async function technicalAssistanceInsightsData(
  technicalAssistanceInsightsRequestPayload: ITechnicalAssistanceRequest
) {
  return postInsightsDataRequest(
    "AIPlayground/CallTechnicalAssistanceNLAzFunc",
    technicalAssistanceInsightsRequestPayload,
    "Technical Assistance"
  );
}

// Save Insights schedule metadata
export async function customerInsightsScheduleMetadata(customerInsightsDataRequestPayload: ICustomerInsightsRequest) {
  return postInsightsScheduleMetadataRequest(
    "AIPlayground/SaveCustomerTenantScheduleMetaData",
    customerInsightsDataRequestPayload,
    "Customer Insights"
  );
}

export async function supportInsightsScheduleMetaData(supportInsightsDataRequestPayload: ISupportInsightsRequest) {
  return postInsightsScheduleMetadataRequest(
    "AIPlayground/SaveCustomerSupportScheduleMetaData",
    supportInsightsDataRequestPayload,
    "Support Insights"
  );
}

export async function engagementInsightsScheduleMetaData(
  engagementInsightsDataRequestPayload: IEngagementInsightsRequest
) {
  return postInsightsScheduleMetadataRequest(
    "AIPlayground/SaveCRMEnagagementMetaData",
    engagementInsightsDataRequestPayload,
    "Engagement Insights"
  );
}

export async function feedbackInsightsScheduleMetaData(feedbackInsightsDataRequestPayload: IFeedbackInsightsRequest) {
  return postInsightsScheduleMetadataRequest(
    "AIPlayground/SaveFeedbackMetaData",
    feedbackInsightsDataRequestPayload,
    "Feedback Insights"
  );
}

export async function technicalAssistanceInsightsScheduleMetadata(
  technicalAssistanceInsightsRequestPayload: ITechnicalAssistanceRequest
) {
  return postInsightsScheduleMetadataRequest(
    "AIPlayground/SaveTechnicalAssistanceMetaData",
    technicalAssistanceInsightsRequestPayload,
    "Technical Assistance"
  );
}
