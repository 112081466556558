import { Breadcrumb, IBreadcrumbItem } from "@coherence-design-system/breadcrumb";
import { useNavigate } from "react-router";
export default function GlossaryBreadCrumb() {
  const navigate = useNavigate();
  const items: IBreadcrumbItem[] = [
    {
      text: "Home",
      key: "",
      href: "",
      onClick: () => navigate("/")
    } as IBreadcrumbItem,
    { text: "Glossary", key: "glossary", isCurrentItem: true }
  ];

  return (
    <Breadcrumb
      items={items}
      ariaLabel=""
      overflowAriaLabel="More links"
      styles={{
        itemLink: {
          selectors: { ":hover": { textDecoration: "underline" } }
        }
      }}
    />
  );
}
