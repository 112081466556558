import {
  ChoiceGroup,
  ComboBox,
  DefaultButton,
  Dropdown,
  FontIcon,
  IComboBoxOption,
  IDropdownStyles,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
  TooltipHost,
  mergeStyleSets
} from "@fluentui/react";
import React, { useEffect, useState, useRef } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CUSTOM_CUSTOMER_LIST_STYLES } from "../../SharedComponents/Styles/CustomCustomerList.styles";
import { IEngagementInsightsRequest } from "../../ServiceModels/CustomerService-Models";
import {
  engagementInsightsData,
  engagementInsightsScheduleMetaData,
  getProducts
} from "../../Services/customerServices";
import { commonAIPlaygroundStyles } from "./AIPlayground-Common.Styles";
import { logEvent, logPageView } from "../../helper/AppInsightsHelper";

const styles = mergeStyleSets({
  engagementInsightsContainer: {
    width: 1500,
    border: "1px solid black",
    margin: "30px 30px 30px 30px"
  }
});

export const EngagementInsights = () => {
  const [promptValue, setPromptValue] = useState("");
  const [responseTextValue, setResponseTextValue] = useState("");
  const [copyButtonCaption, setCopyButtonCaption] = useState(" Copy ");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState<boolean>(false);
  const [scheduleSuccess, setscheduleSuccess] = useState<boolean>(false);

  const [showEmojiButtons, setShowEmojiButtons] = useState<boolean>(false);
  const [showLikeButton, setShowLikeButton] = useState<boolean>(true);
  const [showDislikeButton, setShowDislikeButton] = useState<boolean>(true);
  const [showLikeSolidButton, setShowLikeSolidButton] = useState<boolean>(false);
  const [showDislikeSolidButton, setShowDislikeSolidButton] = useState<boolean>(false);

  const [selectedCustomerName, setSelectedCustomerName] = useState<string>("");
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const [selectedSales, setSelectedSales] = useState<string>("");

  const customerComboboxOptions: IComboBoxOption[] = [
    { key: "none", text: "None" },
    { key: "walmart", text: "WALMART INC." },
    { key: "dell", text: "DELL COMPUTER CORPORATION" },
    { key: "novartis", text: "Novartis AG" },
    { key: "shell", text: "Shell plc (Global Parent)" },
    { key: "alaskaAir", text: "ALASKA AIR GROUP" },
    { key: "emerson", text: "EMERSON" },
    { key: "globalEnglish", text: "GLOBALENGLISH CORPORATION" },
    { key: "virginMoney", text: "Virgin Money" },
    { key: "delta", text: "DELTA AIR LINES INC" },
    { key: "hdfc", text: "HDFC Bank" },
    { key: "bayer", text: "Bayer AG" },
    { key: "krones", text: "Krones AG" },
    { key: "deutsche", text: "Deutsche Post DHL" },
    { key: "zionsBank", text: "ZIONS BANK CORPORATION" },
    { key: "veradigm", text: "Veradigm" },
    { key: "chubb", text: "CHUBB" },
    { key: "icertis", text: "Icertis Solution Pvt.Ltd" },
    { key: "edeka", text: "EDEKA AG" },
    { key: "fHoffmann", text: "F. Hoffmann-La Roche AG" },
    { key: "ferguson", text: "FERGUSON ENTERPRISES" },
    { key: "carollCounty", text: "Carroll County Public Schools" },
    { key: "commonSpiritHealth", text: "CommonSpirit Health" },
    { key: "bankOfNewZealand", text: "Bank Of New Zealand" },
    { key: "datavant", text: "Datavant" },
    { key: "sena", text: "Servicio Nacional de Aprendizaje - SENA" },
    { key: "cityOfAustin", text: "TX-CITY OF AUSTIN" },
    { key: "wellStar", text: "WELLSTAR HEALTH SYSTEM" },
    { key: "workSafe", text: "WORKSAFE BC" },
    { key: "networkInternational", text: "Network International L.L.C" },
    { key: "howardCounty", text: "HOWARD COUNTY SCHOOL DISTRICT" },
    { key: "meec", text: "MEEC-MARYLAND EDUCATION ENTERPRISE CONSORTIUM" },
    { key: "energisa", text: "ENERGISA MINAS GERAIS - DISTRIBUIDORA DE ENERGIA SA" },
    { key: "honorHealth", text: "HONORHEALTH" },
    { key: "paCounty", text: "PA-COUNTY OF LUZERNE" },
    { key: "flCity", text: "FL-CITY OF TALLAHASSEE" },
    { key: "govtOfQuebec", text: "Government Of Quebec" },
    { key: "sscict", text: "Ssc-Ict" },
    { key: "migliore", text: "Migliore Web Community Pvt Limited" },
    { key: "deutscheLufthansa", text: "Deutsche Lufthansa AG" },
    { key: "dashenBank", text: "Dashen Bank" },
    { key: "uhg", text: "Unitedhealth Group" },
    { key: "tecnatom", text: "TECNATOM S.A." }
  ];

  const salesComboboxOptions: IComboBoxOption[] = [
    { key: "1-qualify 10%", text: "1-Qualify 10%" },
    { key: "2-develop 20%", text: "2-Develop 20%" },
    { key: "3-present 40%", text: "3-Present 40%" },
    { key: "4-prove 60%", text: "4-Prove 60%" },
    { key: "5-negotiate 80%", text: "5-Negotiate 80%" },
    { key: "6-complete 100%", text: "6-Complete 100%" },
    { key: "7-lost", text: "7-Lost" },
    { key: "8-disengaged", text: "8-Disengaged" },
    { key: "unknown", text: "Unknown" },
    { key: "none", text: "None" }
  ];
  const [productComboboxOptions, setProductComboboxOptions] = useState<any>();

  const onCustomerNameComboboxChange = (event: any, option?: any): void => {
    setSelectedCustomerName(option.text);
  };

  const handleStartDate = (e: any) => {
    setStartDate(e.target?.value);
  };

  const handleEndDate = (e: any) => {
    setEndDate(e.target?.value);
  };

  const onProductComboboxChange = (event: any, option?: any): void => {
    if (option.key === "none") {
      setSelectedProduct("");
    } else {
      setSelectedProduct(option.key);
    }
  };

  const onSalesComboboxChange = (event: any, option?: any): void => {
    setSelectedSales(option?.text);
    setPromptValue("");
  };

  const toolTipMessage =
    "Schedule prompt-based report deliveries according to your selected frequency, reports will be sent at 1 AM UTC";

  const winReport = "Generate Aggregated Win Report.";
  const lossReport = "Generate Aggregated Loss Report.";
  const prospectAggregatedReport = "Generate Aggregated Prospect Report.";
  const qualifiedOpportunityAggregatedReport = "Generate Aggregated Qualified Opportunity Report.";
  const [selectedPrompt, setSelectedPrompt] = useState<string>();
  const promptOptions: IComboBoxOption[] = [
    { key: "winReport", text: winReport },
    { key: "lossReport", text: lossReport },
    { key: "prospectAggregatedReport", text: prospectAggregatedReport },
    { key: "qualifiedOpportunityAggregatedReport", text: qualifiedOpportunityAggregatedReport }
  ];

  const onPromptSelectionChange = (event: any, option?: any): void => {
    setSelectedPrompt(option.key);
    switch (option.key) {
      case "winReport":
        setPromptValue(winReport);
        setSelectedSales("6-Complete 100%");
        break;
      case "lossReport":
        setPromptValue(lossReport);
        setSelectedSales("7-Lost");
        break;
      case "prospectAggregatedReport":
        setPromptValue(prospectAggregatedReport);
        break;
      case "qualifiedOpportunityAggregatedReport":
        setPromptValue(qualifiedOpportunityAggregatedReport);
        break;
      default:
        setSelectedPrompt("");
        setPromptValue("");
    }
  };

  const dropDownStyles: Partial<IDropdownStyles> = {
    root: {
      marginTop: "40px",
      marginLeft: "50px",
      marginRight: "30px",
      width: 1000
    },
    dropdownOptionText: {
      overflow: "visible",
      whiteSpace: "normal"
    },
    dropdownItem: { height: "auto" }
  };

  // Scheduled run dropdown settings and handlers
  const [selectedScheduledRunItem, setSelectedScheduledRunItem] = useState<string>("daily");
  const scheduledRunDropdownOptions = [
    { key: "daily", text: "Daily" },
    { key: "weekly", text: "Weekly" },
    { key: "monthly", text: "Monthly" }
  ];
  const onScheduledRunDropdownChange = (event: any, option?: any): void => {
    setSelectedScheduledRunItem(option.key);
  };

  const onLikeButtonClicked = () => {
    setShowLikeButton(false);
    setShowLikeSolidButton(true);

    if (showDislikeSolidButton) {
      setShowDislikeButton(true);
      setShowDislikeSolidButton(false);
    }
  };

  const onDislikeButtonClicked = () => {
    setShowDislikeButton(false);
    setShowDislikeSolidButton(true);

    if (showLikeSolidButton) {
      setShowLikeButton(true);
      setShowLikeSolidButton(false);
    }
  };

  const onLikeSolidButtonClicked = () => {
    setShowLikeButton(true);
    setShowLikeSolidButton(false);
  };

  const onDislikeSolidButtonClicked = () => {
    setShowDislikeButton(true);
    setShowDislikeSolidButton(false);
  };

  const responseDiv = useRef<HTMLDivElement>(null);

  const copyClicked = async () => {
    if (responseDiv.current) {
      const htmlContent = responseDiv.current.innerHTML;
      const blob = new Blob([htmlContent], { type: "text/html" });
      const data = [new ClipboardItem({ "text/html": blob })];
      const oldCaption = copyButtonCaption;
      try {
        await navigator.clipboard.write(data);
        setCopyButtonCaption("Copied");
      } catch (err) {
        setCopyButtonCaption("Failed");
      } finally {
        setTimeout(() => setCopyButtonCaption(oldCaption), 2000);
      }
    }
  };

  const submitButtonClicked = () => {
    setLoading(true);
    setResponseTextValue("");
    setShowEmojiButtons(false);

    const loginName = sessionStorage.getItem("loginUserName")
      ? sessionStorage.getItem("loginUserName")?.split("@")[0]
      : undefined;

    const engagementInsightsRequestPayload: IEngagementInsightsRequest = {
      logDate: new Date(),
      category: "crmEngagement",
      schedule: "",
      alias: loginName,
      question: promptValue,
      customerName: selectedCustomerName,
      productName: selectedProduct,
      salesStage: selectedSales,
      startDate: startDate,
      endDate: endDate
    };
    logEvent(
      "AI Playground - Engagement Insights Submit Button Clicked",
      "AI Playground - Engagement Insights Page",
      "EngagementInsights"
    );
    (async () => {
      const engagementInsightsResponse = await engagementInsightsData(engagementInsightsRequestPayload);
      setLoading(false);
      setResponseTextValue(engagementInsightsResponse);

      // Reset like/dislike buttons
      setShowEmojiButtons(true);
      setShowLikeButton(true);
      setShowDislikeButton(true);
      setShowLikeSolidButton(false);
      setShowDislikeSolidButton(false);

      // Send API request again to save the request payload
      // Logging call is done here to save the turn around time on previous API request
      // Fire and forget - Do nothing on UI side. Backend will work on logging the request payload
      await engagementInsightsScheduleMetaData(engagementInsightsRequestPayload);
    })();
  };

  const scheduleButtonCLicked = () => {
    const loginName = sessionStorage.getItem("loginUserName")
      ? sessionStorage.getItem("loginUserName")?.split("@")[0]
      : undefined;

    const engagementInsightsRequestPayload: IEngagementInsightsRequest = {
      logDate: new Date(),
      category: "crmEngagement",
      schedule: selectedScheduledRunItem,
      alias: loginName,
      question: promptValue,
      customerName: selectedCustomerName,
      productName: selectedProduct,
      salesStage: selectedSales
    };

    logEvent(
      "AI Playground - Engagement Insights Schedule Button Clicked",
      "AI Playground - Engagement Insights Page",
      "EngagementInsights"
    );
    (async () => {
      await engagementInsightsScheduleMetaData(engagementInsightsRequestPayload);
      setscheduleSuccess(true);
      setTimeout(() => {
        setscheduleSuccess(false);
      }, 3000);
    })();
  };

  const loadAllProducts = async () => {
    const allProducts = await getProducts();
    const options: any = [];
    options.push({
      key: "none",
      text: "None"
    });
    options.push({
      key: "Microsoft Entra External ID",
      text: "Microsoft Entra External ID"
    });
    if (allProducts.length !== 0) {
      allProducts.Result.map((ele: any) => {
        options.push({
          key: ele.ProductName,
          text: ele.ProductName
        });
      });
    }
    setProductComboboxOptions(options);
  };

  useEffect(() => {
    logPageView("AI Playground - Engagement Insights Page", window.location.href);
    loadAllProducts();
  }, []);

  return (
    <>
      <div className={styles.engagementInsightsContainer}>
        <div className={commonAIPlaygroundStyles.dropDownContainerStyles}>
          <ComboBox
            label="Customer"
            options={customerComboboxOptions}
            onChange={onCustomerNameComboboxChange}
            className={commonAIPlaygroundStyles.combobox}
            placeholder="Select a customer"
            allowFreeInput
            autoComplete="on"
            disabled={true}
          />
          <ComboBox
            label="Product"
            options={productComboboxOptions}
            onChange={onProductComboboxChange}
            className={commonAIPlaygroundStyles.combobox}
            placeholder="Select a product"
            allowFreeInput
            autoComplete="on"
          />
          <ComboBox
            label="Sales Stage"
            options={salesComboboxOptions}
            onChange={onSalesComboboxChange}
            className={commonAIPlaygroundStyles.combobox}
            placeholder="Select a Sales Stage"
            allowFreeInput
            autoComplete="on"
          />
        </div>

        <div className={commonAIPlaygroundStyles.dropDownContainerStyles}>
          <TextField
            type="date"
            label="Start Date"
            name="Start Date"
            placeholder="Start Date"
            className={commonAIPlaygroundStyles.startDatePicker}
            onChange={handleStartDate}
          />
          <TextField
            type="date"
            label="End Date"
            name="End Date"
            placeholder="End Date"
            className={commonAIPlaygroundStyles.endDatePicker}
            onChange={handleEndDate}
          />
        </div>

        <div className={commonAIPlaygroundStyles.searchLabelAndBox}>
          <Dropdown
            label="Suggested Prompts"
            selectedKey={selectedPrompt ? selectedPrompt : undefined}
            onChange={onPromptSelectionChange}
            placeholder="Select an option"
            options={promptOptions}
            styles={dropDownStyles}
          />
        </div>

        <div className={commonAIPlaygroundStyles.labelStyles}>
          <div className={commonAIPlaygroundStyles.promptAndButtonGroup}>
            <TextField
              label="Custom Prompt"
              multiline
              rows={2}
              className={commonAIPlaygroundStyles.promptTextBoxStyles}
              placeholder="Please enter your question here"
              value={promptValue}
              onChange={(event: any) => {
                setSelectedPrompt("Custom Prompt");
                setPromptValue(event.target.value);
              }}
            />
            <PrimaryButton
              text="Submit"
              className={commonAIPlaygroundStyles.submitButtonStyles}
              onClick={submitButtonClicked}
            />
          </div>

          <div className={commonAIPlaygroundStyles.responseAndScheduleRunGroup}>
            {responseTextValue.length > 0 && (
              <div>
                <div className={commonAIPlaygroundStyles.responseHeader}>
                  <DefaultButton className={commonAIPlaygroundStyles.copyClipboardButton} onClick={copyClicked}>
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
                      <ContentCopyIcon
                        sx={CUSTOM_CUSTOMER_LIST_STYLES.customDataGridColumns.tenantIdTooltipCopyIconBtn}
                        titleAccess="Copy"
                      />
                      <span>{copyButtonCaption}</span>
                    </Stack>
                  </DefaultButton>
                </div>
                <div
                  ref={responseDiv}
                  className={commonAIPlaygroundStyles.responseContainer}
                  dangerouslySetInnerHTML={{ __html: responseTextValue }}
                ></div>
              </div>
            )}
            {responseTextValue.length == 0 && <div className={commonAIPlaygroundStyles.responseContainer}></div>}
            <div className={commonAIPlaygroundStyles.spinnerStyles}>
              {loading && <Spinner label="Hang tight! Something awesome is getting cooked..." />}
            </div>
            <div className={commonAIPlaygroundStyles.scheduleItemsStyles}>
              {showEmojiButtons && (
                <Label className={commonAIPlaygroundStyles.likeButtonLabel}>Do you like the response?</Label>
              )}
              {showEmojiButtons && (
                <div className={commonAIPlaygroundStyles.likeDislikeButtonContainerStyles}>
                  {showLikeButton && (
                    <DefaultButton
                      title="Like"
                      ariaLabel="likeEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onLikeButtonClicked}
                    >
                      <FontIcon
                        aria-label="LikeButton"
                        iconName="Like"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                  {showLikeSolidButton && (
                    <DefaultButton
                      title="LikeSolid"
                      ariaLabel="likeSolidEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onLikeSolidButtonClicked}
                    >
                      <FontIcon
                        aria-label="LikeSolidButton"
                        iconName="LikeSolid"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                  {showDislikeButton && (
                    <DefaultButton
                      title="Dislike"
                      ariaLabel="dislikeEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onDislikeButtonClicked}
                    >
                      <FontIcon
                        aria-label="DislikeButton"
                        iconName="Dislike"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                  {showDislikeSolidButton && (
                    <DefaultButton
                      title="DislikeSolid"
                      ariaLabel="dislikeSolidEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onDislikeSolidButtonClicked}
                    >
                      <FontIcon
                        aria-label="DislikeSolidButton"
                        iconName="DislikeSolid"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                </div>
              )}
              <Label className={commonAIPlaygroundStyles.scheduleChoiceGroupLabel}>
                <Stack horizontal verticalAlign="center">
                  <span>Schedule this prompt?&nbsp;&nbsp;</span>
                  <TooltipHost content={toolTipMessage}>
                    <FontIcon className={commonAIPlaygroundStyles.tooltipStyles} aria-label="tooltip" iconName="Info" />
                  </TooltipHost>
                </Stack>
              </Label>
              <ChoiceGroup
                defaultSelectedKey="daily"
                className={commonAIPlaygroundStyles.scheduledRun}
                options={scheduledRunDropdownOptions}
                onChange={onScheduledRunDropdownChange}
              />
              <PrimaryButton
                text="Schedule (Coming Soon...)"
                className={commonAIPlaygroundStyles.scheduleButtonStyles}
                onClick={scheduleButtonCLicked}
                disabled={true}
              />
              {scheduleSuccess && (
                <MessageBar
                  className={commonAIPlaygroundStyles.scheduleMessageStyles}
                  messageBarType={MessageBarType.success}
                  isMultiline={false}
                >
                  Successfully scheduled.
                </MessageBar>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
