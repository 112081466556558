import { Shimmer, ShimmerElementType, Stack } from "@fluentui/react";
import { IChartProps } from "@fluentui/react-charting";
import { OptionOnSelectData } from "@fluentui/react-components";
import { useCallback, useContext, useEffect, useState } from "react";
import CustomHorizontalBarChart from "../../../../BasicComponents/CustomHorizontalBarChart";
import DisplayAPIsException from "../../../../BasicComponents/DisplayAPIsExceptionAlert";
import { CustomShimmer } from "../../../../helper/CustomShimmer";
import { ProductViewFiltersContext } from "../../../../pages/ProductView";
import CustomCard from "../../../../SharedComponents/CustomCard";
import { getFeaturesSelector, useGetFeatureProductDeatailsMutation } from "../../../../slices/FeatureProduct.slice";
import { useAppSelector } from "../../../../store/hooks";
import CustomDropdown from "../../Common/CustomDropdown";
import { FeaturesByCommonNameProps } from "../../Common/ProductView.interfaces";
import { PRODUCT_VIEW_STYLES } from "../../Common/ProductView.styles";

const INITIAL_FEATURE: OptionOnSelectData = { optionText: "", optionValue: "", selectedOptions: [] };
const formatDataForChart = (data: FeaturesByCommonNameProps[]): IChartProps[] => {
  return data.map(({ name, allUp }) => {
    return {
      chartTitle: name,
      chartData: [
        {
          legend: name,
          horizontalBarChartdata: {
            x: allUp,
            y: 10000
          },
          color: "#0078D4"
        }
      ]
    };
  });
};

const RenderCustomDropdown = ({ onSelectedOption }: { onSelectedOption?: (data: OptionOnSelectData) => void }) => {
  const [selectedFeature, setSelectedFeature] = useState<OptionOnSelectData>(INITIAL_FEATURE);
  const { data, isLoading, isSuccess } = useAppSelector((state) => getFeaturesSelector(state));
  const filters = useContext(ProductViewFiltersContext);

  const onSelectedFeature = useCallback((option: OptionOnSelectData) => {
    setSelectedFeature(option);
    if (onSelectedOption) {
      onSelectedOption(option);
    }
  }, []);

  useEffect(() => {
    setSelectedFeature(INITIAL_FEATURE);
  }, [
    filters.hasPartner,
    filters.industry,
    filters.isCAT,
    filters.isFastTrackStrategic,
    filters.isSST,
    filters.productName,
    filters.region,
    filters.segmentGroup,
    filters.segment,
    filters.subSegment
  ]);

  useEffect(() => {
    if (isSuccess) {
      const option = data?.featuresByName.at(0);
      if (option && !selectedFeature.optionValue) {
        setSelectedFeature({
          optionText: option.featureName,
          optionValue: option.featureName,
          selectedOptions: [option.featureId]
        });
      }
    }
  }, [isSuccess]);

  return (
    (isLoading && (
      <div style={{ flexBasis: "100%" }}>
        <Shimmer
          shimmerColors={{ shimmer: "var(--shimmer-color)" }}
          styles={{
            root: { paddingTop: 10, paddingBottom: 10, width: "25%" },
            shimmerWrapper: { ".ms-ShimmerGap-root": { background: "var(--colorBodyBackground1)" } }
          }}
          shimmerElements={[
            { type: ShimmerElementType.line, width: "15%" },
            { type: ShimmerElementType.gap, width: 10 },
            { type: ShimmerElementType.line, width: "15%" }
          ]}
        />
      </div>
    )) ||
    (isSuccess && (
      <CustomDropdown
        onSelectedOption={onSelectedFeature}
        defaultOption={selectedFeature}
        data={(data?.featuresByName || [])
          .filter(
            ({ featureId }, index, currentArray) => currentArray.findIndex((f) => f.featureId === featureId) === index
          )
          .map(({ featureId, featureName }) => ({ value: featureId, text: featureName }))}
        styles={{ root: { flexBasis: "100%", paddingBottom: 10 } }}
      />
    ))
  );
};

export const SegmentationTab = () => {
  const [selectedFeature, setSelectedFeature] = useState<OptionOnSelectData>();
  const { data, isError, isLoading, isSuccess } = useAppSelector((state) => getFeaturesSelector(state));
  const [getData, { isLoading: isLoadingByFeature }] = useGetFeatureProductDeatailsMutation({
    fixedCacheKey: "getFeatures"
  });
  const filters = useContext(ProductViewFiltersContext);

  const onSelectedFeature = useCallback((option: OptionOnSelectData) => setSelectedFeature(option), []);
  const renderHorizontalBarHeader = useCallback(({ title }: { title: string }) => {
    return isLoading ? <Shimmer styles={PRODUCT_VIEW_STYLES.TAB.SEGMENTATION_TAB.CSS_STYLES.shimmerStyles} /> : title;
  }, []);
  const displayAPIsException = isError && <DisplayAPIsException height={345} />;
  const displayBarChart = useCallback(
    (dataForChart?: FeaturesByCommonNameProps[]) =>
      isSuccess && <CustomHorizontalBarChart data={formatDataForChart(dataForChart || [])} />,
    [data]
  );

  useEffect(() => {
    if (selectedFeature && filters.productName) {
      const requestData = {
        featureId: selectedFeature.optionValue,
        hasPartner: filters.hasPartner,
        industry: filters.industry,
        isCAT: filters.isCAT,
        isFastTrackStrategic: filters.isFastTrackStrategic,
        isSST: filters.isSST,
        productName: filters.productName,
        region: filters.region,
        segmentGroup:filters.segmentGroup,
        segment: filters.segment,
        subSegment: filters.subSegment
      };
      getData(requestData);
    }
  }, [selectedFeature]);

  return (
    <Stack tokens={{ padding: "10px 13px 13px 13px", childrenGap: 13 }}>
      <Stack>
        <RenderCustomDropdown onSelectedOption={onSelectedFeature} />
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 13 }}>
        <CustomCard
          cardHeaderTitle={renderHorizontalBarHeader({ title: "AllUp by Region" })}
          styles={{
            root: { flex: 1 },
            cardDetailsContent: { padding: 13 },
            cardHeaderStart: { flex: 1 },
            cardHeaderTitleContainer: { flexBasis: "40%" }
          }}
        >
          {isLoading || isLoadingByFeature ? (
            <div style={{ paddingBottom: "15px" }}>
              <CustomShimmer columnCount={4} />
            </div>
          ) : (
            displayBarChart(data?.featuresByRegion.map(({ allUp, region: name }) => ({ allUp, name }))) ||
            displayAPIsException
          )}
        </CustomCard>
        <CustomCard
          cardHeaderTitle={renderHorizontalBarHeader({ title: "AllUp by Industry" })}
          styles={{
            root: { flex: 1 },
            cardDetailsContent: { padding: 13 },
            cardHeaderStart: { flex: 1 },
            cardHeaderTitleContainer: { flexBasis: "40%" }
          }}
        >
          {isLoading || isLoadingByFeature ? (
            <div style={{ paddingBottom: "15px" }}>
              <CustomShimmer columnCount={4} />
            </div>
          ) : (
            displayBarChart(data?.featuresByIndustry.map(({ allUp, industry: name }) => ({ allUp, name }))) ||
            displayAPIsException
          )}
        </CustomCard>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 13 }}>
        <CustomCard
          cardHeaderTitle={renderHorizontalBarHeader({ title: "AllUp by Segment" })}
          styles={{
            root: { flex: 1 },
            cardDetailsContent: { padding: 13 },
            cardHeaderStart: { flex: 1 },
            cardHeaderTitleContainer: { flexBasis: "40%" }
          }}
        >
          {isLoading || isLoadingByFeature ? (
            <div style={{ paddingBottom: "15px" }}>
              <CustomShimmer columnCount={4} />
            </div>
          ) : (
            displayBarChart(data?.featuresBySegment.map(({ allUp, segment: name }) => ({ allUp, name }))) ||
            displayAPIsException
          )}
        </CustomCard>
        <CustomCard
          cardHeaderTitle={renderHorizontalBarHeader({ title: "AllUp by Sub Segment" })}
          styles={{
            root: { flex: 1 },
            cardDetailsContent: { padding: 13 },
            cardHeaderStart: { flex: 1 },
            cardHeaderTitleContainer: { flexBasis: "40%" }
          }}
        >
          {isLoading || isLoadingByFeature ? (
            <div style={{ paddingBottom: "15px" }}>
              <CustomShimmer columnCount={4} />
            </div>
          ) : (
            displayBarChart(data?.featuresBySubSegment.map(({ allUp, subSegment: name }) => ({ allUp, name }))) ||
            displayAPIsException
          )}
        </CustomCard>
      </Stack>
    </Stack>
  );
};
