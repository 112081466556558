import React from "react";
import { Mail12Regular, Info12Filled } from "@fluentui/react-icons";
import { useLocation, useNavigate } from "react-router-dom";

const email = "MSECCxEInsightsSG@microsoft.com";
export default function MailTo() {
  const navigate = useNavigate();
  const onNavigateGlossary = (e) => {
    e.preventDefault();
    navigate("/glossary");
  };
  return (
    <>
      <br />
      <div style={{ display: "flex", position: "relative" }}>
        <span>
          <Info12Filled style={{ position: "absolute", width: "22px", height: "17px" }} /> &nbsp;
          <a
            href="javascipt:void(0)"
            onClick={onNavigateGlossary}
            style={{
              position: "absolute",
              left: "10%",
              color: "blue",
              fontSize: "13px"
            }}
          >
            Glossary
          </a>
        </span>
      </div>
      <div style={{ display: "flex", position: "relative" }}>
        <span>
          {" "}
          <Mail12Regular style={{ position: "absolute", width: "22px", height: "17px" }} />
          &nbsp;&nbsp;
          <a href={`mailto:${email}`} style={{ position: "absolute", left: "10%", fontSize: "13px" }}>
            Contact Us
          </a>
        </span>
      </div>
      <div style={{ display: "flex", position: "relative" }}>
        <span>
          <Info12Filled style={{ position: "absolute", width: "22px", height: "17px" }} /> &nbsp;
          <a
            href="https://privacy.microsoft.com/en-US/data-privacy-notice"
            target="_blank"
            style={{
              position: "absolute",
              left: "10%",
              color: "blue",
              fontSize: "13px"
            }}
          >
            Data Privacy Notice
          </a>
        </span>
      </div>
    </>
  );
}
