import {
  IBreadcrumbStyles,
  IMessageBarStyles,
  IPivotStyles,
  IRawStyle,
  IStackTokens,
  IStyle,
  mergeStyleSets
} from "@fluentui/react";
import React from "react";

export const PRODUCT_VIEW_STYLES = {
  PRODUCT_VIEW: {
    container: { paddingBottom: 10, width: "100%" } as IStyle
  },
  PRODUCT_PAGE: {
    CLASS_NAMES: mergeStyleSets({
      productPageContainer: {
        margin: 12,
        backgroundColor: "#fff"
      }
    }),
    CSS_STYLES: {
      productViewCustomBreadcrumb: {
        root: {
          fontSize: 14,
          margin: 0,
          paddingLeft: 8,
          "--itemLink-color": "rgb(0, 120, 212)"
        },
        item: {
          fontSize: "inherit"
        },
        itemLink: {
          fontSize: "inherit",
          color: "var(--itemLink-color)",
          ":hover": {
            background: "none",
            textDecoration: "underline",
            color: "var(--itemLink-color)"
          }
        }
      } as Partial<IBreadcrumbStyles>
    }
  },
  OVERVIEW_SECTOIN: {
    cardContent: { padding: 0, paddingLeft: 12 } as React.CSSProperties,
    cardRoot: { maxWidth: "" } as IStyle,
    tabsCardRoot: { maxWidth: "" ,padding:10,boxShadow:"var(--customShimmerShadow4)" } as IStyle,
    overviewContainer: { paddingTop: 18, paddingBottom: 18 } as React.CSSProperties,
    selectedMetricCard: { backgroundColor: "lightcyan" } as IStyle,
    shimmerStyles: {
      root: {
        boxShadow: "var(--customShimmerShadow4)",
        gap: 13,
        height: 61,
        margin: 0,
        paddingLeft: 12,
        paddingRight: 12
      },
      articleContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        padding: 0
      },
      lineContainer: {
        backgroundColor: "inherit !important",
        margin: "0 !important"
      }
    },
    NoDataStyles: {
      display: "flex",
      alignItems: "center",
      height: 70,
      justifyContent: "center",
    } as React.CSSProperties
  },
  TABS_SECTION: {
    pivotStyles: {
      itemContainer: { paddingTop: 18, backgroundColor: "white" },
      root: { height: 44, backgroundColor: "white" }
    } as Partial<IPivotStyles>,
    funnelContainer: {
      flexBasis: "40%",
      width: "40%"
    } as React.CSSProperties,
    funnelCard: {
      height: 400
    },
    funnelPaper: {
      borderRadius: 1,
      color: "black",
      textAlign: "center",
      fontWeight: "bold",
      fontsize: 12,
      backgroundColor: "white"
    } as React.CSSProperties
  },
  TAB: {
    SUMMARY: {
      stackContainer: { childrenGap: 20 } as IStackTokens,
      cardContainer: { width: "50%" } as React.CSSProperties
    },
    OPPORTUNITY_TENANTS: {
      CLASS_NAMES: mergeStyleSets({
        cardsContainer: { gap: "0 20px", display: "flex", boxSizing: "border-box", flexWrap: "wrap" } as IStyle,
        cardsContainerItem: {
          flex: 1,
          paddingBottom: 10,
          width: "calc(50% - 13px)"
        } as IStyle
      }),
      CSS_STYLES: {
        cardStyles: {
          cardDetailsContent: { padding: 13 },
          cardHeaderStart: { flex: 1 },
          cardHeaderTitleContainer: { flexBasis: "100%" },
          root: { flex: 1 }
        },
        cardHeaderShimmerStyles: {
          root: { width: "50%" },
          shimmerWrapper: {
            backgroundColor: "#deecf9",
            borderTopLeftRadius: 6,
            height: 20
          },
          shimmerGradient: {
            backgroundColor: "#deecf9",
            backgroundImage: "linear-gradient(to right, #fff0 0%, #c7e0f4 50%, #fff0 100%)"
          }
        }
      },
      messageBarStyles: { icon: { color: "var(--colorMessageBarWarningIcon)" } } as IMessageBarStyles
    },
    COHORTS: {
      CLASS_NAMES: mergeStyleSets({
        emptyDataMessageForLeftMetricDetails: {
          alignItems: "center",
          display: "flex",
          height: "calc(100% - 26px)",
          justifyContent: "center"
        },
        viewByMetricSelectContainer: {
          alignItems: "center",
          columnGap: "10px",
          display: "flex",
          flexBasis: "100%"
        } as IStyle
      })
    },
    FEATURE_VIEW: {
      CLASS_NAMES: mergeStyleSets({
        noDataForProductDisplayMessage: {
          alignItems: "center",
          display: "flex",
          fontSize: 18,
          fontWeight: 600,
          justifyContent: "center",
          minHeight: 545
        } as IStyle
      })
    },
    FEATURE_VIEW_SUMMARY_TAB: {
      CSS_STYLES: {
        customCardStyles: {
          root: { flex: 1 } as IRawStyle | undefined,
          cardDetailsContent: { padding: 13, height: 400 } as React.CSSProperties
        }
      },
      CLASS_NAMES: mergeStyleSets({
        featureViewSummaryTabContainer: {
          minHeight: 426,
          padding: 13,
          paddingTop: 10,
          width: "calc(50% - 26px)"
        } as IStyle
      })
    },
    DETAILED_VIEW: {
      CSS_STYLES: {
        customShimmerStyles: { root: { height: 400 } as IStyle },
        customCardStyles: {
          root: { marginBottom: 13 } as IRawStyle | undefined,
          cardDetailsContent: { padding: "0 13px" } as React.CSSProperties
        }
      },
      CLASS_NAMES: mergeStyleSets({
        carouselContainer: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          minHeight: 426,
          padding: 13,
          paddingBottom: 0,
          paddingTop: 0
        } as IStyle
      })
    },
    SEGMENTATION_TAB: {
      CSS_STYLES: {
        shimmerStyles: {
          shimmerWrapper: {
            backgroundColor: "var(--shimmer-color)",
            borderTopLeftRadius: 6,
            height: 20
          },
          shimmerGradient: {
            backgroundColor: "var(--shimmer-color)",
            backgroundImage:
              "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #c7e0f4 50%, rgba(255, 255, 255, 0) 100%)"
          }
        }
      }
    }
  }
};
