import { IBreadcrumbItem } from "@fluentui/react";
import { Divider, Paper } from "@mui/material";
import { createContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ProductFilterPill } from "../components/ProductView/Common/ProductFilterPill";
import { PRODUCT_VIEW_DATA } from "../components/ProductView/Common/ProductView.constants";
import { ProductViewFiltersProps } from "../components/ProductView/Common/ProductView.interfaces";
import { PRODUCT_VIEW_STYLES } from "../components/ProductView/Common/ProductView.styles";
import ProductView from "../components/ProductView/ProductView";
import { logPageView } from "../helper/AppInsightsHelper";
import { CustomBreadcrumb } from "../SharedComponents/CustomBreadcrumb";

export const ProductViewFiltersContext = createContext<ProductViewFiltersProps>(
  PRODUCT_VIEW_DATA.PRODUCT_VIEW.INITIAL_PRODUCT_VIEW_FILTERS
);

export default function ProductPage() {
  const navigate = useNavigate();
  const [product] = useSearchParams();
  const productName: any = product?.get("productname") ?? PRODUCT_VIEW_DATA.PRODUCT_VIEW.DEFAULT_PRODUCT_NAME;
  const [regionValue, setRegionValue] = useState("");
  const [segmentGroupValue, setSegmentGroupValue] = useState("");
  const [segmentValue, setSegmentValue] = useState("");
  const [subSegmentValue, setSubSegmentValue] = useState("");
  const [industryValue, setIndustryValue] = useState("");
  const [hasPartners, setHasPartner] = useState("");
  const [isFastTracks, setIsFastTrack] = useState("");
  const [isCats, setIsCat] = useState("");
  const [isSsts, setIsSst] = useState("");
  const breadcrumbProps = {
    items: [
      { text: "Home", key: "explore-products-to-home", onClick: () => navigate("/") },
      { text: "Products", key: "explore-products-list", onClick: () => navigate("/products") },
      { text: productName, key: "explore-products-with-product-name", isCurrentItem: true }
    ] as IBreadcrumbItem[],
    styles: PRODUCT_VIEW_STYLES.PRODUCT_PAGE.CSS_STYLES.productViewCustomBreadcrumb
  };
  const handleProviderValue = useMemo(
    () => ({
      hasPartner: hasPartners,
      industry: industryValue,
      isCAT: isCats,
      isFastTrackStrategic: isFastTracks,
      isSST: isSsts,
      productName: productName,
      region: regionValue,
      segment: segmentValue,
      subSegment: subSegmentValue,
      segmentGroup: segmentGroupValue
    }),
    [
      hasPartners,
      industryValue,
      isCats,
      isFastTracks,
      isSsts,
      productName,
      regionValue,
      segmentGroupValue,
      segmentValue,
      subSegmentValue
    ]
  );

  useEffect(() => {
    const customPropsForLogging = {
      ProductName: productName
    };

    logPageView("Product View Page", window.location.href, customPropsForLogging);
  }, []);

  return (
    <div className={PRODUCT_VIEW_STYLES.PRODUCT_PAGE.CLASS_NAMES.productPageContainer}>
      <ProductViewFiltersContext.Provider value={handleProviderValue}>
        <Paper>
          <CustomBreadcrumb {...breadcrumbProps} />
          <Divider />
          <ProductFilterPill
            productName={productName}
            onRegionChange={setRegionValue}
            onSegmentChange={setSegmentValue}
            onSubSegmentChange={setSubSegmentValue}
            onIndustryChange={setIndustryValue}
            onPartnerChange={setHasPartner}
            onFastTrackChange={setIsFastTrack}
            onIsCatChange={setIsCat}
            onIsSstChange={setIsSst}
            onSegmentGroupChange={setSegmentGroupValue}
          />
        </Paper>
        <ProductView />
      </ProductViewFiltersContext.Provider>
    </div>
  );
}
