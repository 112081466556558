import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DisplayAPIsException from "../../BasicComponents/DisplayAPIsExceptionAlert";
import { logEvent, logException } from "../../helper/AppInsightsHelper";
import { CustomShimmer } from "../../helper/CustomShimmer";
import { groupBy } from "../../Utils/js-common";
import {
  crmCustomerProductChartType,
  deploymentStatusType,
  productNameType,
  stateType
} from "../../interfaces/crm-customer-product-types";
import { useGetCRMCustomerProductForTenantQuery } from "../../slices/CRMCustomerProduct.slice";
import CRMCustomerProductCharts from "./CRMCustomerProductCharts";
import CRMCustomerProductTable from "./CRMCustomerProductTable";

const CRMCustomerProductContainer = (props: any) => {
  const [searchParams] = useSearchParams();
  const [tableItems, setTableItems] = useState([]);
  const [filteredTableItems, setFilteredTableItems] = useState([]);
  const [filteredChartItems, setFilteredChartItems] = useState<crmCustomerProductChartType>();

  const [isTableLoading, setIsTableLoading] = useState(true);
  const [isChartLoading, setIsChartLoading] = useState(true);

  const [chartKeys, setChartKeys] = useState<string[]>([]);
  const [chartFilter, setChartFilter] = React.useState({
    filterKey: "",
    value: ""
  });

  const tenantId = searchParams.get("id");
  const [filterChartData, setFilterChartData] = useState<any>({
    productName: "",
    deploymentStatus: "",
    state: ""
  });

  const crmCustomerProductData = useGetCRMCustomerProductForTenantQuery(tenantId);

  const HandleAPIException = () => {
    const enableAPIException = crmCustomerProductData.error;
    return (enableAPIException && <DisplayAPIsException height={250} />) || "";
  };

  React.useEffect(() => {
    logEvent("Customer CRM Customer Product", "Customer Page", "CRMCustomerProductContainer");
  });

  useEffect(() => {
    if (crmCustomerProductData && crmCustomerProductData.data) {
      if (crmCustomerProductData?.data?.table) {
        setTableItems(
          (crmCustomerProductData?.data?.table ?? []).map(({ deploymentStatus, ...rest }: any) => ({
            ...rest,
            deploymentStatus: deploymentStatus ?? "unknown"
          }))
        );
        setFilteredTableItems(crmCustomerProductData?.data?.table);
        setIsTableLoading(false);
      }

      if (crmCustomerProductData?.data?.graphs) {
        setFilterChartData({
          productName: "",
          deploymentStatus: "",
          state: ""
        });
        setFilteredChartItems(crmCustomerProductData?.data?.graphs);
        setIsChartLoading(false);

        const keys: string[] = Object.keys(crmCustomerProductData?.data?.graphs);
        setChartKeys(keys);
      }

      if (crmCustomerProductData.error) {
        setIsTableLoading(false);
        setIsChartLoading(false);
        logException(
          "Customer Page",
          "CRMCustomerProductContainer",
          "useGetCRMCustomerProductForTenantQuery",
          crmCustomerProductData.error
        );
      }
    }
  }, [crmCustomerProductData?.data, crmCustomerProductData.error, crmCustomerProductData]);

  const showNoData = () => {
    const rootStyle = {
      alignItems: "center",
      display: "flex",
      fontSize: 18,
      fontWeight: 600,
      justifyContent: "center",
      padding: 200
    };
    return <div style={rootStyle}>{`Customer product details are not available for ${props.tenantName}.`}</div>;
  };

  React.useEffect(() => {
    switch (chartFilter.filterKey) {
      case "productName":
        {
          let filteredData: any = [];
          filteredData = tableItems.filter((item: any) => item.productName === chartFilter.value);
          setFilterChartData({
            ...filterChartData,
            productName: chartFilter.value
          });
          if (filterChartData.deploymentStatus !== "") {
            filteredData = filteredData.filter(
              (item: any) => item.deploymentStatus === filterChartData.deploymentStatus
            );
          }
          if (filterChartData.state !== "") {
            filteredData = filteredData.filter((item: any) => item.customerProductState === filterChartData.state);
          }
          setFilteredTableItems(filteredData);
          const deploymentStatus = groupBy(filteredData, "deploymentStatus");
          const filteredDeploymentStatus: deploymentStatusType[] = [];
          Object.keys(deploymentStatus).forEach((item) => {
            filteredDeploymentStatus.push({
              name: item,
              count: deploymentStatus[item].length
            });
          });

          const state = groupBy(filteredData, "customerProductState");
          const filteredState: stateType[] = [];
          Object.keys(state).forEach((item) => {
            filteredState.push({
              name: item,
              count: state[item].length
            });
          });

          if (filteredDeploymentStatus && filteredState && filteredChartItems?.productName) {
            setFilteredChartItems({
              ...filteredChartItems,
              deploymentStatus: filteredDeploymentStatus,
              state: filteredState
            });
          }
        }
        break;

      case "deploymentStatus":
        {
          let filteredData: any = [];
          filteredData = tableItems.filter((item: any) => item.deploymentStatus === chartFilter.value);
          setFilterChartData({
            ...filterChartData,
            deploymentStatus: chartFilter.value
          });
          if (filterChartData.productName !== "") {
            filteredData = filteredData.filter((item: any) => item.productName === filterChartData.productName);
          }
          if (filterChartData.state !== "") {
            filteredData = filteredData.filter((item: any) => item.customerProductState === filterChartData.state);
          }
          setFilteredTableItems(filteredData);
          const productName = groupBy(filteredData, "productName");
          const filteredProductName: productNameType[] = [];
          Object.keys(productName).forEach((item) => {
            filteredProductName.push({
              name: item,
              count: productName[item].length
            });
          });

          const state = groupBy(filteredData, "customerProductState");
          const filteredState: stateType[] = [];
          Object.keys(state).forEach((item) => {
            filteredState.push({
              name: item,
              count: state[item].length
            });
          });

          if (filteredProductName && filteredState && filteredChartItems?.deploymentStatus) {
            setFilteredChartItems({
              ...filteredChartItems,
              productName: filteredProductName,
              state: filteredState
            });
          }
        }
        break;

      case "state":
        {
          let filteredData: any = [];
          filteredData = tableItems.filter((item: any) => item.customerProductState === chartFilter.value);
          setFilterChartData({
            ...filterChartData,
            state: chartFilter.value
          });
          if (filterChartData.productName !== "") {
            filteredData = filteredData.filter((item: any) => item.productName === filterChartData.productName);
          }
          if (filterChartData.deploymentStatus !== "") {
            filteredData = filteredData.filter(
              (item: any) => item.deploymentStatus === filterChartData.deploymentStatus
            );
          }
          setFilteredTableItems(filteredData);
          const productName = groupBy(filteredData, "productName");
          const filteredProductName: productNameType[] = [];
          Object.keys(productName).forEach((item) => {
            filteredProductName.push({
              name: item,
              count: productName[item].length
            });
          });

          const deploymentStatus = groupBy(filteredData, "deploymentStatus");
          const filteredDeploymentStatus: deploymentStatusType[] = [];
          Object.keys(deploymentStatus).forEach((item) => {
            filteredDeploymentStatus.push({
              name: item,
              count: deploymentStatus[item].length
            });
          });

          if (filteredProductName && filteredDeploymentStatus && filteredChartItems?.state) {
            setFilteredChartItems({
              ...filteredChartItems,
              productName: filteredProductName,
              deploymentStatus: filteredDeploymentStatus
            });
          }
        }
        break;

      default: {
        setFilteredTableItems(crmCustomerProductData?.data?.table);
        setFilteredChartItems(crmCustomerProductData?.data?.graphs);
        setFilterChartData({
          productName: "",
          deploymentStatus: "",
          state: ""
        });
      }
    }
  }, [chartFilter]);

  return (
    <>
      <CRMCustomerProductCharts chartData={filteredChartItems} isChartLoading={isChartLoading} keys={chartKeys} setChartFilter={setChartFilter} />
      {isTableLoading ? (
        <CustomShimmer columnCount={11} />
      ) : (
        (!crmCustomerProductData.error && tableItems.length > 0 && (
          <CRMCustomerProductTable tableData={filteredTableItems} tenantName={props.tenantName} />
        )) ||
        showNoData()
      )}
      <HandleAPIException />
    </>
  );
};

export default CRMCustomerProductContainer;
