import {
  Label,
  SearchBox,
  TextField,
  IStyle,
  Spinner,
  SpinnerSize,
  mergeStyleSets,
  PrimaryButton,
  ChoiceGroup,
  MessageBar,
  MessageBarType,
  DefaultButton,
  FontIcon,
  Stack,
  TooltipHost
} from "@fluentui/react";
import { CustomerFilterPill } from "../CustomerSearch/CustomerFilterPill";
import React, { useState, useEffect } from "react";
import { customerInsightsData, customerInsightsScheduleMetadata, getCustomers } from "../../Services/customerServices";
import { IAzSearchInput } from "../../interfaces/Customer.interfaces";
import { useGetTenantListMutation } from "../../slices/Customer.slice";
import GroupsIcon from "@mui/icons-material/Groups";
import { Dropdown, DropdownMenuItemType, IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import { ICustomerInsightsRequest } from "../../ServiceModels/CustomerService-Models";
import { commonAIPlaygroundStyles } from "./AIPlayground-Common.Styles";
import { logEvent, logPageView } from "../../helper/AppInsightsHelper";

const styles = mergeStyleSets({
  searchBar: {
    color: "white",
    display: "flex",
    justifyContent: "space-around",
    fontSize: 20,
    fontWeight: 700,
    position: "absolute",
    left: "60px",
    marginTop: "5px",
    cursor: "pointer",
    "@media (min-width: 1900px)": {
      fontSize: 9,
      // Left: "-2%",
      fontWeight: 400
    },
    "@media (max-width: 600px)": {
      fontSize: 9,
      left: 10,
      fontWeight: 400
    },
    "@media (max-width: 400px)": {
      fontSize: 9,
      left: -30,
      fontWeight: 400
    }
  },
  ul: {
    color: " hsl(206, 100%, 42%)",
    borderBottom: "1px solid #ececec",
    margin: 0,
    padding: "5px 10px",
    cursor: "pointer",
    fontSize: 14,
    listStyleType: "none"
  },
  li: {
    marginTop: "2px",
    paddingRight: "10px",
    color: "gray",
    fontSize: "12px",
    fontWeight: 600
  },
  label: {
    padding: "10px",
    display: "block",
    borderBottom: "1px solid #dfdfdf",
    background: "#f4f5f5",
    color: "#808080",
    "& > b": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        color: "black"
      }
    }
  },
  searchDiv: {
    marginTop: "40px",
    marginLeft: "50px",
    right: 420,
    top: 8,
    bottom: 8,
    backgroundColor: "transparent !important",
    display: "inline-block"
  } as IStyle,
  searchItemDiv: {
    backgroundColor: "#fff",
    maxHeight: "450px",
    marginBottom: "10px",
    marginTop: "40px",
    display: "-ms-flexbox",
    border: "solid 0.7px #ececec",
    borderRadius: 4,
    zIndex: 999999,
    position: "relative",
    overflowY: "auto",
    overflowX: "hidden",
    boxShadow: "rgb(161, 161, 161) 0px 0px"
  } as IStyle,
  searchBoxCSS: {
    width: 650,
    display: "flex",
    backgroundColor: "rgb(208, 231, 248)",
    border: "none"
  } as IStyle,
  searchLoader: {
    backgroundColor: "#fff",
    maxWidth: "450px",
    maxHeight: "50px",
    marginBottom: "10px",
    marginTop: "7px",
    border: "solid 0.7px #ececec",
    borderRadius: 4,
    zIndex: 999999,
    position: "relative",
    boxShadow: "rgb(161, 161, 161) 0px 0px"
  } as IStyle,
  choiceGroup: {
    marginTop: "40px",
    marginLeft: "50px"
  },
  filterPillStyles: {
    marginLeft: "50px",
    marginTop: "10px"
  },
  customerAIPlaygroundContainer: {
    width: 1500,
    border: "1px solid black",
    margin: "30px 30px 30px 30px"
  }
});

export const CustomerInsights = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [placeholder, setPlaceholder] = useState<string>("Search");
  const [showSearchList, setShowSearchList] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>("");
  const [rows, setRows] = useState<{ category: string; items: [] }[]>([]);
  const [, setAllResultsVisible] = useState(false);
  const [getTenantsList] = useGetTenantListMutation();

  const [selectedCustomerName, setSelectedCustomerName] = useState("");
  const [, setSelectedTPID] = useState("");
  const [selectedTenantId, setSelectedTenantId] = useState("");

  const [loading, setLoading] = useState<boolean>(false);
  const [scheduleSuccess, setscheduleSuccess] = useState<boolean>(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false);

  const [showEmojiButtons, setShowEmojiButtons] = useState<boolean>(false);
  const [showLikeButton, setShowLikeButton] = useState<boolean>(true);
  const [showDislikeButton, setShowDislikeButton] = useState<boolean>(true);
  const [showLikeSolidButton, setShowLikeSolidButton] = useState<boolean>(false);
  const [showDislikeSolidButton, setShowDislikeSolidButton] = useState<boolean>(false);

  // Scheduled run dropdown settings and handlers
  const [selectedScheduledRunItem, setSelectedScheduledRunItem] = useState<string>("daily");
  const scheduledRunDropdownOptions = [
    { key: "daily", text: "Daily" },
    { key: "weekly", text: "Weekly" },
    { key: "monthly", text: "Monthly" }
  ];
  const onScheduledRunDropdownChange = (event: any, option?: any): void => {
    setSelectedScheduledRunItem(option.key);
  };

  const toolTipMessage =
    "Schedule prompt-based report deliveries according to your selected frequency, reports will be sent at 1 AM UTC";

  // Prompt dropdown settings and handlers
  const [promptValue, setPromptValue] = useState("");
  const [responseValue, setResponseValue] = useState("");
  const [selectedPrompt, setSelectedPrompt] = useState<string>();
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [promptErrorMessage, setPromptErrorMessage] = useState<string>("");
  const [showHtmlBox, setShowHtmlBox] = useState<boolean>(false);

  const [suggestedPromptPlaceholderText] = useState<string>("Select a suggested prompt");

  const promptPlaceholderText = "Please enter your question here";
  const promptErrorMessageText = "Please select a customer from the search bar above to proceed";

  // Tenant profile suggested prompts
  const customerProfile = "Give me the basic profile of the selected customer.";
  const isTenantCAT = "Tell me if the tenant is CAT, Partner, Fasttrack and SST.";
  const isInS500 =
    "Tell me if the customer corresponding to the selected tenant is in S500, Global 500, Fortune 500 and 1000 list?";

  // Tenant Usage suggested prompts
  const e5Mpu =
    "What is the latest E5 MPU for Defender for Endpoint for the tenant? Also include year and month in the response.";
  const purviewInfoProtection = "What is Microsoft Purview Information protection MPU for the month of April 2024?";
  const pauMau =
    "Tell me PAU and MPU for 'Microsoft Defender for Office' in last 3 months. Pivot by Product KPI Name. Also include month and year detail.";
  const productsAndKpisForTenant = "What all products and KPIs you can help me with?";

  // Generic suggested prompts
  const top10DspCat = "Provide me a list of 10 DSP CAT customers.";
  const catTenantAssociation = "How many Tenants have CAT association?";
  const cspPartnerTenantAssociation = "How many Tenants have CSP Partner association?";
  const productsAndKpis = "What all products and KPIs you can help me with?";
  const topFiveTenantsbyEntraId = "List top 5 Tenants by Entra ID MAU for the latest month.";
  const countOfTenantSplitByRegion = "Show me the count of tenant split by region.";
  const countOfTenantSplitByVertical = "Show me the count of tenant split by vertical.";
  const countOfTenantSplitBySegment = "Show me the count of tenants split by Segment and Sub-Segment.";
  const tenantBuckets =
    "Consider a combination of Segment and SubSegment as a bucket. Tell me a couple of tenants that belong to each of these buckets.";

  const promptOptions = [
    {
      key: "customerProfile",
      text: "Tenant Profile",
      itemType: DropdownMenuItemType.Header
    },
    { key: "basicProfile", text: customerProfile },
    { key: "isTenantCAT", text: isTenantCAT },
    { key: "isInS500", text: isInS500 },
    {
      key: "customerUsage",
      text: "Tenant Usage",
      itemType: DropdownMenuItemType.Header
    },
    { key: "e5Mpu", text: e5Mpu },
    { key: "purviewInfoProtection", text: purviewInfoProtection },
    { key: "pauMau", text: pauMau },
    { key: "productsAndKpisForTenant", text: productsAndKpisForTenant },
    {
      key: "genericProfile",
      text: "Generic",
      itemType: DropdownMenuItemType.Header
    },
    { key: "top10DspCat", text: top10DspCat },
    { key: "catTenantAssociation", text: catTenantAssociation },
    {
      key: "cspPartnerTenantAssociation",
      text: cspPartnerTenantAssociation
    },
    { key: "productsAndKpis", text: productsAndKpis },
    { key: "topFiveTenantsbyEntraId", text: topFiveTenantsbyEntraId },
    { key: "countOfTenantSplitByRegion", text: countOfTenantSplitByRegion },
    {
      key: "countOfTenantSplitByVertical",
      text: countOfTenantSplitByVertical
    },
    {
      key: "countOfTenantSplitBySegment",
      text: countOfTenantSplitBySegment
    },
    { key: "tenantBuckets", text: tenantBuckets }
  ];
  const onPromptSelectionChange = (event: any, option?: any): void => {
    setSelectedPrompt(option.key);
    switch (option.key) {
      case "top10DspCat":
        setPromptValue(top10DspCat);
        setSelectedCategory("general");
        setPromptErrorMessage("");
        setSubmitButtonDisabled(false);
        break;
      case "catTenantAssociation":
        setPromptValue(catTenantAssociation);
        setSelectedCategory("general");
        setPromptErrorMessage("");
        setSubmitButtonDisabled(false);
        break;
      case "cspPartnerTenantAssociation":
        setPromptValue(cspPartnerTenantAssociation);
        setSelectedCategory("general");
        setPromptErrorMessage("");
        setSubmitButtonDisabled(false);
        break;
      case "topFiveTenantsbyEntraId":
        setPromptValue(topFiveTenantsbyEntraId);
        setSelectedCategory("general");
        setPromptErrorMessage("");
        setSubmitButtonDisabled(false);
        break;
      case "productsAndKpis":
        setPromptValue(productsAndKpis);
        setSelectedCategory("general");
        setPromptErrorMessage("");
        setSubmitButtonDisabled(false);
        break;
      case "countOfTenantSplitByRegion":
        setPromptValue(countOfTenantSplitByRegion);
        setSelectedCategory("general");
        setPromptErrorMessage("");
        setSubmitButtonDisabled(false);
        break;
      case "countOfTenantSplitByVertical":
        setPromptValue(countOfTenantSplitByVertical);
        setSelectedCategory("general");
        setPromptErrorMessage("");
        setSubmitButtonDisabled(false);
        break;
      case "countOfTenantSplitBySegment":
        setPromptValue(countOfTenantSplitBySegment);
        setSelectedCategory("general");
        setPromptErrorMessage("");
        setSubmitButtonDisabled(false);
        break;
      case "tenantBuckets":
        setPromptValue(tenantBuckets);
        setSelectedCategory("general");
        setPromptErrorMessage("");
        setSubmitButtonDisabled(false);
        break;
      case "basicProfile":
        if (selectedCustomerName === "") {
          setPromptErrorMessage(promptErrorMessageText);
          setSubmitButtonDisabled(true);
        }
        setPromptValue(customerProfile);
        setSelectedCategory("basicProfile");
        break;
      case "isTenantCAT":
        if (selectedCustomerName === "") {
          setPromptErrorMessage(promptErrorMessageText);
          setSubmitButtonDisabled(true);
        }
        setPromptValue(isTenantCAT);
        setSelectedCategory("customer");
        break;
      case "isInS500":
        if (selectedCustomerName === "") {
          setPromptErrorMessage(promptErrorMessageText);
          setSubmitButtonDisabled(true);
        }
        setPromptValue(isInS500);
        setSelectedCategory("customer");
        break;
      case "e5Mpu":
        if (selectedCustomerName === "") {
          setPromptErrorMessage(promptErrorMessageText);
          setSubmitButtonDisabled(true);
        }
        setPromptValue(e5Mpu);
        setSelectedCategory("customer");
        break;
      case "purviewInfoProtection":
        if (selectedCustomerName === "") {
          setPromptErrorMessage(promptErrorMessageText);
          setSubmitButtonDisabled(true);
        }
        setPromptValue(purviewInfoProtection);
        setSelectedCategory("customer");
        break;
      case "pauMau":
        if (selectedCustomerName === "") {
          setPromptErrorMessage(promptErrorMessageText);
          setSubmitButtonDisabled(true);
        }
        setPromptValue(pauMau);
        setSelectedCategory("customer");
        break;
      case "productsAndKpisForTenant":
        if (selectedCustomerName === "") {
          setPromptErrorMessage(promptErrorMessageText);
          setSubmitButtonDisabled(true);
        }
        setPromptValue(productsAndKpisForTenant);
        setSelectedCategory("customer");
        break;
      default:
        setSelectedCategory("general");
        setPromptValue("");
        setSelectedPrompt("");
    }
  };

  // Filter Pill settings and event handlers
  const [regionFilterValue, setRegionFilterValue] = useState("");
  const [segmentFilterValue, setSegmentFilterValue] = useState("");
  const [subSegmentFilterValue, setSubSegmentFilterValue] = useState("");
  const [industryFilterValue, setIndustryFilterValue] = useState("");
  const [hasPartnerFilterValue, setHasPartnerFilterValue] = React.useState("");
  const [isSstFilterValue, setIsSstFilterValue] = useState("");
  const [isFastTrackFilterValue, setisFastTrackFilterValue] = useState("");
  const [isCatFilterValue, setIsCatFilterValue] = useState("");
  const [segmentGroupFilterValue, setSegmentGroupFilterValue] = useState("");

  const onRegionChange = (value: any) => {
    setRegionFilterValue(value);
  };

  const onSegmentChange = (value: any) => {
    setSegmentFilterValue(value);
  };

  const onSubSegmentChange = (value: any) => {
    setSubSegmentFilterValue(value);
  };

  const onIndustryChange = (value: any) => {
    setIndustryFilterValue(value);
  };

  const onFastTrackChange = (value: any) => {
    setisFastTrackFilterValue(value);
  };

  const onHasPartnerChange = (value: any) => {
    setHasPartnerFilterValue(value);
  };

  const onIsCatChange = (value: any) => {
    setIsCatFilterValue(value);
  };

  const onIsSstChange = (value: any) => {
    setIsSstFilterValue(value);
  };

  const onSegmentGroupChange = (value: any) => {
    setSegmentGroupFilterValue(value);
  };

  useEffect(() => {
    let active = true;
    const apiBody = {
      rowcnt: 10,
      region: regionFilterValue,
      segment: segmentFilterValue,
      subsegment: subSegmentFilterValue,
      segmentgroup: segmentGroupFilterValue,
      industry: industryFilterValue,
      isSST: isSstFilterValue,
      isFastTrackStrategic: isFastTrackFilterValue,
      isCAT: isCatFilterValue
    };
    getTenantsList(apiBody);
    if (!active) {
      return;
    }
    return () => {
      active = false;
    };
  }, [
    regionFilterValue,
    segmentFilterValue,
    subSegmentFilterValue,
    segmentGroupFilterValue,
    hasPartnerFilterValue,
    industryFilterValue,
    isSstFilterValue,
    isFastTrackFilterValue,
    isCatFilterValue,
    getTenantsList
  ]);

  const handleClick = ({ row }: { row: any }) => {
    setShowSearchList(false);

    setSearchText(row.tenantName);

    setSelectedCustomerName(row.tenantName);
    setSelectedTenantId(row.tenantId);
    setSelectedTPID(row.topParentId);

    if (promptErrorMessage) {
      setPromptErrorMessage("");
      setSubmitButtonDisabled(false);
    }
  };

  const _onChange = (_event?: React.ChangeEvent<HTMLInputElement>, newSearchText?: string) => {
    setSearchText(newSearchText || "");
  };

  const _onClear = () => {
    setPlaceholder("Search");
    setSearchText("");
    setSelectedCustomerName("");
    setSelectedTenantId("");

    if (selectedCategory === "basicInfo" || selectedCategory === "customer") {
      if (promptErrorMessage === "") {
        setPromptErrorMessage(promptErrorMessageText);
        setSubmitButtonDisabled(true);
      }
    }
  };

  const _onFocusHandler = () => {
    const input: HTMLInputElement | null = document.querySelector("#searchbox");

    if (!input?.value.trim()) setPlaceholder("Look up customers and tenants");
    setShowSearchList(Boolean(rows.length));
  };

  const LoadSpinner = () => <Spinner styles={{ root: { height: 40 } }} size={SpinnerSize.small} label="Searching" />;

  const onLikeButtonClicked = () => {
    setShowLikeButton(false);
    setShowLikeSolidButton(true);

    if (showDislikeSolidButton) {
      setShowDislikeButton(true);
      setShowDislikeSolidButton(false);
    }
  };

  const onDislikeButtonClicked = () => {
    setShowDislikeButton(false);
    setShowDislikeSolidButton(true);

    if (showLikeSolidButton) {
      setShowLikeButton(true);
      setShowLikeSolidButton(false);
    }
  };

  const onLikeSolidButtonClicked = () => {
    setShowLikeButton(true);
    setShowLikeSolidButton(false);
  };

  const onDislikeSolidButtonClicked = () => {
    setShowDislikeButton(true);
    setShowDislikeSolidButton(false);
  };

  const onCustomRender = () => {
    const RenderCustomers = (gIndx: number, cIndx: number, row: any) => (
      <>
        {cIndx === 0 ? (
          <label className={styles.label}>
            <b>
              <GroupsIcon />
              &nbsp; Customers
            </b>
          </label>
        ) : (
          ""
        )}
        <ul
          key={`result-items-ul-customers-${gIndx + cIndx}`}
          className={`link-hover ${styles.ul}`}
          onClick={() => handleClick({ row })}
        >
          <b>{row.tenantName}</b>
          <li key={`result-items-li-tpid-${gIndx + cIndx}`} className={styles.li}>
            TPID : {row.topParentId}
          </li>
          <li key={`result-items-li-tenantid-${gIndx + cIndx}`} className={styles.li}>
            Tenant ID : {row.tenantId}
          </li>
        </ul>
      </>
    );

    const RenderList = () => {
      return rows?.map((group, gIndx) => {
        return (
          <>
            {group.items.map((row: any, cIndx) => {
              return RenderCustomers(gIndx, cIndx, row);
            })}
          </>
        );
      });
    };

    return (
      <>
        <br />
        <div className={styles.searchItemDiv}>{searchText.length > 0 && showSearchList ? <RenderList /> : ""}</div>
      </>
    );
  };

  useEffect(() => {
    logPageView("AI Playground - Customer Insights Page", window.location.href);

    if (!selectedCustomerName) {
      setRows([]);
      setAllResultsVisible(false);
      setShowSearchList(false);

      if (searchText.trim().length < 2) {
        setAllResultsVisible(true);
        return;
      }

      const timeout = setTimeout(() => {
        const data: IAzSearchInput = {
          searchText: `/.*${searchText}.*/`,
          size: 3
        };
        const getPromises = () => [getCustomers({ ...data })];

        Promise.allSettled(getPromises())
          .then((values) => {
            const _rows = [];
            for (let idx = 0; idx < values.length; idx++) {
              const { status } = values[idx];
              if (status === "fulfilled") {
                const d = (values[idx] as PromiseFulfilledResult<any>).value;
                switch (idx) {
                  case 0:
                    if (d.values.length)
                      _rows.push({
                        category: "Customers",
                        items: d.values
                      });
                    break;
                  default:
                    break;
                }
              }
            }
            setAllResultsVisible(true);
            setShowSearchList(true);
            if (_rows.length) {
              setRows(_rows);
            }
          })
          .catch((error) => {
            console.error(error);
            setAllResultsVisible(true);
            setShowSearchList(false);
          });
      }, 1500);
      return () => clearTimeout(timeout);
    }
  }, [
    searchText,
    selectedPrompt,
    promptValue,
    suggestedPromptPlaceholderText,
    promptErrorMessage,
    submitButtonDisabled,
    showLikeButton,
    showDislikeButton,
    showLikeSolidButton,
    showDislikeSolidButton,
    showHtmlBox
  ]);

  const dropDownStyles: Partial<IDropdownStyles> = {
    root: {
      marginTop: "40px",
      marginLeft: "50px",
      marginRight: "30px",
      width: 1000
    },
    dropdownOptionText: {
      overflow: "visible",
      whiteSpace: "normal"
    },
    dropdownItem: { height: "auto" }
  };

  const submitButtonClicked = () => {
    setResponseValue("");
    setLoading(true);
    setShowHtmlBox(false);

    setShowEmojiButtons(false);
    const loginName = sessionStorage.getItem("loginUserName")
      ? sessionStorage.getItem("loginUserName")?.split("@")[0]
      : undefined;

    const customerInsightsRequestPayload: ICustomerInsightsRequest = {
      logDate: new Date(),
      category: selectedCategory,
      schedule: "",
      alias: loginName,
      question: promptValue,
      customerId: selectedCustomerName,
      tenantId: selectedTenantId,
      segmentName: segmentFilterValue,
      subSegmentName: subSegmentFilterValue,
      segmentGroupName: segmentGroupFilterValue,
      regionName: regionFilterValue,
      isSST: isSstFilterValue,
      hasPartner: hasPartnerFilterValue,
      isFastTrackStrategic: isFastTrackFilterValue,
      isCAT: isCatFilterValue
    };

    logEvent(
      "AI Playground - Customer Insights Submit Button Clicked",
      "AI Playground - Customer Insights Page",
      "CustomerInsights"
    );
    (async () => {
      const customerInsightsResponse = await customerInsightsData(customerInsightsRequestPayload);
      // Reset like/dislike buttons
      setShowEmojiButtons(true);
      setShowLikeButton(true);
      setShowDislikeButton(true);
      setShowLikeSolidButton(false);
      setShowDislikeSolidButton(false);
      setLoading(false);
      setResponseValue(customerInsightsResponse);

      setShowHtmlBox(true);

      // Send API request again to save the request payload
      // Logging call is done here to save the turn around time on previous API request
      // Fire and forget - Do nothing on UI side. Backend will work on logging the request payload
      await customerInsightsScheduleMetadata(customerInsightsRequestPayload);
    })();
  };

  const scheduleButtonCLicked = () => {
    const loginName = sessionStorage.getItem("loginUserName")
      ? sessionStorage.getItem("loginUserName")?.split("@")[0]
      : undefined;

    const customerInsightsRequestPayload: ICustomerInsightsRequest = {
      logDate: new Date(),
      category: selectedCategory,
      schedule: selectedScheduledRunItem,
      alias: loginName,
      question: promptValue,
      customerId: selectedCustomerName,
      tenantId: selectedTenantId,
      segmentName: segmentFilterValue,
      subSegmentName: subSegmentFilterValue,
      segmentGroupName: segmentGroupFilterValue,
      regionName: regionFilterValue,
      isSST: isSstFilterValue,
      hasPartner: hasPartnerFilterValue,
      isFastTrackStrategic: isFastTrackFilterValue,
      isCAT: isCatFilterValue
    };

    logEvent(
      "AI Playground - Customer Insights Schedule Button Clicked",
      "AI Playground - Customer Insights Page",
      "CustomerInsights"
    );
    (async () => {
      await customerInsightsScheduleMetadata(customerInsightsRequestPayload);
      setscheduleSuccess(true);
      setTimeout(() => {
        setscheduleSuccess(false);
      }, 3000);
    })();
  };

  return (
    <>
      <div className={styles.customerAIPlaygroundContainer}>
        <div className={commonAIPlaygroundStyles.searchLabelAndBox}>
          <Label className={styles.searchDiv}>Customer: </Label>
          <div className={styles.searchDiv}>
            <SearchBox
              width={460}
              onChange={_onChange}
              onClear={_onClear}
              onFocus={_onFocusHandler}
              value={searchText}
              placeholder={placeholder}
              className={styles.searchBoxCSS}
            />
            {searchText.length > 0 && !showSearchList && !selectedCustomerName ? (
              <div className={styles.searchLoader}>
                {" "}
                <LoadSpinner />{" "}
              </div>
            ) : (
              ""
            )}
            {searchText.length > 0 && showSearchList ? onCustomRender() : ""}
          </div>
        </div>
        <br />
        <br />
        <div className={styles.filterPillStyles}>
          <CustomerFilterPill
            onRegionChange={onRegionChange}
            onSegmentChange={onSegmentChange}
            onSubSegmentChange={onSubSegmentChange}
            onIndustryChange={onIndustryChange}
            onFastTrackChange={onFastTrackChange}
            onHasPartnerChange={onHasPartnerChange}
            onIsCatChange={onIsCatChange}
            onIsSstChange={onIsSstChange}
            onSegmentGroupChange={onSegmentGroupChange}
          />
        </div>
        <div className={commonAIPlaygroundStyles.searchLabelAndBox}>
          <Dropdown
            label="Suggested Prompts"
            selectedKey={selectedPrompt ? selectedPrompt : undefined}
            onChange={onPromptSelectionChange}
            placeholder={suggestedPromptPlaceholderText}
            options={promptOptions}
            styles={dropDownStyles}
          />
        </div>
        <div className={commonAIPlaygroundStyles.labelStyles}>
          <div className={commonAIPlaygroundStyles.promptAndButtonGroup}>
            <TextField
              label="Custom Prompt"
              multiline
              rows={2}
              className={commonAIPlaygroundStyles.promptTextBoxStyles}
              placeholder={promptPlaceholderText}
              value={promptValue}
              errorMessage={promptErrorMessage}
              onChange={(event: any) => {
                setSelectedPrompt("Custom Prompt");
                setSelectedCategory("general");
                setPromptErrorMessage("");
                setSubmitButtonDisabled(false);
                setPromptValue(event.target.value);
              }}
            />
            <PrimaryButton
              text="Submit"
              className={commonAIPlaygroundStyles.submitButtonStyles}
              onClick={submitButtonClicked}
              disabled={submitButtonDisabled}
            />
          </div>
          <div className={commonAIPlaygroundStyles.responseAndScheduleRunGroup}>
            {showHtmlBox && (
              <TextField
                label="Response"
                multiline
                rows={15}
                autoAdjustHeight
                scrollContainerRef={containerRef}
                className={commonAIPlaygroundStyles.textBoxStyles}
                onRenderPrefix={() => (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: responseValue
                    }}
                    style={{
                      width: "978px",
                      height: "300px",
                      overflow: "scroll",
                      alignContent: "center"
                    }}
                  />
                )}
              />
            )}
            {!showHtmlBox && (
              <TextField
                label="Response"
                multiline
                rows={15}
                autoAdjustHeight
                scrollContainerRef={containerRef}
                className={commonAIPlaygroundStyles.textBoxStyles}
                value={responseValue}
              />
            )}

            <div className={commonAIPlaygroundStyles.spinnerStyles}>
              {loading && <Spinner label="Hang tight! Something awesome is getting cooked..." />}
            </div>
            <div className={commonAIPlaygroundStyles.scheduleItemsStyles}>
              {showEmojiButtons && (
                <Label className={commonAIPlaygroundStyles.likeButtonLabel}>Do you like the response?</Label>
              )}
              {showEmojiButtons && (
                <div className={commonAIPlaygroundStyles.likeDislikeButtonContainerStyles}>
                  {showLikeButton && (
                    <DefaultButton
                      title="Like"
                      ariaLabel="likeEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onLikeButtonClicked}
                    >
                      <FontIcon
                        aria-label="LikeButton"
                        iconName="Like"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                  {showLikeSolidButton && (
                    <DefaultButton
                      title="LikeSolid"
                      ariaLabel="likeSolidEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onLikeSolidButtonClicked}
                    >
                      <FontIcon
                        aria-label="LikeSolidButton"
                        iconName="LikeSolid"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                  {showDislikeButton && (
                    <DefaultButton
                      title="Dislike"
                      ariaLabel="dislikeEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onDislikeButtonClicked}
                    >
                      <FontIcon
                        aria-label="DislikeButton"
                        iconName="Dislike"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                  {showDislikeSolidButton && (
                    <DefaultButton
                      title="DislikeSolid"
                      ariaLabel="dislikeSolidEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onDislikeSolidButtonClicked}
                    >
                      <FontIcon
                        aria-label="DislikeSolidButton"
                        iconName="DislikeSolid"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                </div>
              )}
              <Label className={commonAIPlaygroundStyles.scheduleChoiceGroupLabel}>
                <Stack horizontal verticalAlign="center">
                  <span>Schedule this prompt?&nbsp;&nbsp;</span>
                  <TooltipHost content={toolTipMessage}>
                    <FontIcon className={commonAIPlaygroundStyles.tooltipStyles} aria-label="tooltip" iconName="Info" />
                  </TooltipHost>
                </Stack>
              </Label>
              <ChoiceGroup
                defaultSelectedKey="daily"
                className={commonAIPlaygroundStyles.scheduledRun}
                options={scheduledRunDropdownOptions}
                onChange={onScheduledRunDropdownChange}
              />
              <PrimaryButton
                text="Schedule"
                className={commonAIPlaygroundStyles.scheduleButtonStyles}
                onClick={scheduleButtonCLicked}
              />
              {scheduleSuccess && (
                <MessageBar
                  className={commonAIPlaygroundStyles.scheduleMessageStyles}
                  messageBarType={MessageBarType.success}
                  isMultiline={false}
                >
                  Successfully scheduled.
                </MessageBar>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
