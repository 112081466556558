import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Defender from "../images/defender.png";
import CrossProduct from "../images/crossproduct.jpg";
import Entraa from "../images/entraa.png";
import Copilot365 from "../images/copilot365.jpg";
import Intune from "../images/Intune.jpg";
import Purview from "../images/purview.png";
import Sentinel from "../images/sentinel.png";
import { useNavigate } from "react-router-dom";

const cards = [
  { name: "Defender", path: "/reportcatalog?id=Defender", image: Defender },
  { name: "Sentinel", path: "/reportcatalog?id=Sentinel", image: Sentinel },
  { name: "Purview", path: "/reportcatalog?id=Purview", image: Purview },
  { name: "Entra", path: "/reportcatalog?id=Entra", image: Entraa },
  { name: "Intune", path: "/reportcatalog?id=Intune", image: Intune },
  {
    name: "Cross Product",
    path: "/reportcatalog?id=Cross Product",
    image: CrossProduct
  },
  {
    name: "Copilot for Security",
    path: "/reportcatalog?id=Copilot for Security",
    image: Copilot365
  }
];
const cardContainerStyle = {
  display: "flex",
  width: "100%",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid transparent"
};

export default function MediaCard() {
  const navigate = useNavigate();

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {cards.map((m, idx) => (
        <div key={idx} style={{ flex: 1, margin: "1em", cursor: "pointer" }} onClick={() => navigate(m.path)} tabIndex={idx}>
          <Card sx={cardContainerStyle} className="media-card">
            <CardMedia sx={{ height: 50, width: 50 }} image={m.image} title={m.name} />
            <CardContent>
              <Typography gutterBottom className="media-card-text" component="div" style={{ fontWeight: "bold" }}>
                {m.name}
              </Typography>
            </CardContent>
          </Card>
        </div>
      ))}
    </div>
  );
}
