import React, { useState } from "react";
import { Panel, PanelType, Pivot, PivotItem } from "@fluentui/react";
import ContactList from "./ContactList";
import CxeContactList from "./CxeContactList";

const ContactsPanel = ({
  isOpen,
  onDismiss,
  tenantName
}: {
  isOpen: boolean;
  onDismiss: () => void;
  tenantName: string;
}) => {
  const [selectedKey, setSelectedKey] = useState<string>("manualContacts");

  const onLinkClick = (item: any) => {
    setSelectedKey(item.props.itemKey);
  };

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={onDismiss}
      headerText={tenantName}
      closeButtonAriaLabel="Close"
      isLightDismiss
      type={PanelType.custom}
      customWidth="400px"
    >
      <Pivot selectedKey={selectedKey} onLinkClick={onLinkClick} headersOnly={true} aria-label="Contacts tabs">
        <PivotItem headerText="CxE Contacts" itemKey="manualContacts" />
        <PivotItem headerText="Automated Contacts" itemKey="automatedContacts" />
      </Pivot>
      <div style={{ marginTop: "20px" }}>
        {selectedKey === "manualContacts" && <CxeContactList />}
        {selectedKey === "automatedContacts" && <ContactList />}
      </div>
    </Panel>
  );
};

export default ContactsPanel;
