import { Divider } from "@mui/material";

export const PaperHeaderText = (props: any) => {
  return (
    <>
      <div style={{ padding: "10px" }}>
        <h2 style={{ margin: 0 }}>{props.text}</h2>
      </div>
      <Divider />
    </>
  );
};
