import FavoriteCustomersGridComponent from "../components/FavoriteCustomers/FavoriteCustomersGridComponent";
import { Paper } from "@mui/material";
import { PaperHeaderText } from "../helper/PaperHeaderText";
import { useEffect } from "react";
import { logPageView } from "../helper/AppInsightsHelper";

export default function FavoriteCustomers() {
  useEffect(() => {
    logPageView("Favorite Customers Page", window.location.href);
  }, []);

  return (
    <>
      <div className="mainCard" style={{ margin: 10, minWidth: 600 }}>
        <Paper>
          <PaperHeaderText text="Favorite Customers" />
          <br />
          <div style={{ padding: "10px 10px 10px 10px" }}>
            <FavoriteCustomersGridComponent />
          </div>
        </Paper>
      </div>
    </>
  );
}
