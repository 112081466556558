import { IStackTokens, Stack } from "@fluentui/react";
import { OnboardReport } from "../OnboardReports/OnboardReport";
import { TOP_REPORTS_TITLE } from "../ReportCatalog.common";
import { CardsProps } from "../ReportCatalog.interfaces";
import { TopReportsCards } from "./Cards/TopReportsCards";

const style = {
  font: { fontFamily: "var(--font-family)" },
  header: { justifyContent: "space-between", alignItems: "center" },
  link: { fontWeight: "bold" }
};

// Tokens definition
const stackTokens: IStackTokens = { childrenGap: 10 };

const ReportCatalogTopReports = (props: CardsProps) => {
  return (
    <Stack tokens={stackTokens}>
      <Stack horizontal styles={{ root: { ...style.header, ...style.font } }}>
        <b>{TOP_REPORTS_TITLE}</b>
        <OnboardReport {...props} />
      </Stack>
      <Stack horizontal disableShrink={false} tokens={stackTokens}>
        <TopReportsCards {...props} />
      </Stack>
    </Stack>
  );
};

export default ReportCatalogTopReports;
