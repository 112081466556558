import { Divider } from "@mui/material";
import { Shimmer } from "@fluentui/react";
import { SHIMMER_LINE_ELEMENT, SHIMMER_LINE_ELEMENT_PROPS, SHIMMER_DETAILS_DATA } from "./Announcement.constants";
import { ANNOUNCEMENT_STYLES } from "./Announcement.styles";

const renderShimmerForDetailsContainer = SHIMMER_DETAILS_DATA.map(({ width }, index) => (
  <div key={`shimmerForAnnouncementDetails${index + 1}`} className={ANNOUNCEMENT_STYLES.shimmerForAnnouncementDetails}>
    <Shimmer styles={{ shimmerWrapper: { width: width } }} {...SHIMMER_LINE_ELEMENT_PROPS}></Shimmer>
  </div>
));

export function AnnouncementShimmer() {
  return Array(4)
    .fill(1)
    .map(() => (
      <>
        <div className={ANNOUNCEMENT_STYLES.announcementShimmerConatiner}>
          <div className={ANNOUNCEMENT_STYLES.announcementShimmerForDate}>
            <Shimmer
              styles={{ shimmerWrapper: ANNOUNCEMENT_STYLES.shimmerWrapperForDate }}
              {...SHIMMER_LINE_ELEMENT_PROPS}
              shimmerElements={[{ ...SHIMMER_LINE_ELEMENT, height: 70 }]}
            />
          </div>
          <div className={ANNOUNCEMENT_STYLES.shimmerTypeAsLine}>{renderShimmerForDetailsContainer}</div>
        </div>
        <br />
        <Divider className={ANNOUNCEMENT_STYLES.dividerStyle} />
      </>
    ));
}
