import { Box } from "@mui/material";
import { DataGrid, GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { useCallback } from "react";
import { DATA_GRID_SLOTS } from "../BasicComponents/DataGridSlots";
import { CustomDataGridProps } from "./Interfaces/CustomDataGrid.interfaces";

export function CustomDataGrid({
  columns,
  isError,
  filterMode,
  loading,
  paginationMode,
  paginationOptions,
  rows,
  setFilterOptions,
  setPaginationOptions,
  setSortOptions,
  sortingMode,
  styles
}: CustomDataGridProps) {
  const onFilterChange = useCallback(
    (filterModel: GridFilterModel) => setFilterOptions({ ...filterModel }),
    [setFilterOptions]
  );
  const onSortModelChange = useCallback((sortModel: GridSortModel) => setSortOptions([...sortModel]), [setSortOptions]);
  const getRowId = (row: any) => {
    const key = Object.keys(row).at(0);
    return key ? row[key] : key;
  };

  return (
    <Box
      sx={{
        width: "calc(100% - 10px)",
        height: "calc(100% - 10px)",
        overflow: "auto"
      }}
    >
      <DataGrid
        columns={columns}
        columnHeaderHeight={80}
        density="compact"
        disableRowSelectionOnClick
        filterDebounceMs={500}
        filterMode={filterMode}
        getRowId={getRowId}
        keepNonExistentRowsSelected
        loading={loading}
        onFilterModelChange={onFilterChange}
        onPaginationModelChange={setPaginationOptions}
        onSortModelChange={onSortModelChange}
        pageSizeOptions={[10, 25, 50, 75, 100]}
        paginationMode={paginationMode}
        paginationModel={paginationOptions}
        rowCount={rows.count}
        rows={rows.values}
        rowHeight={75}
        slots={{
          noRowsOverlay: DATA_GRID_SLOTS.noRowsOverlay(Boolean(isError))
        }}
        sortingMode={sortingMode}
        sx={{ ...styles }}
      />
    </Box>
  );
}
