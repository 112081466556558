import { IconButton, Link, Stack } from "@fluentui/react";
import { LinkRegular } from "@fluentui/react-icons";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useState } from "react";
import { formatDate } from "../../helper/DateFormat";
import { ICRMCustomerProductTableProps } from "../../interfaces/crm-customer-product";
import { CustomModal } from "../../SharedComponents/CustomModal";
import { getDaysBetweenDates } from "../../Utils/js-common";

export default function CRMCustomerProductTable(props: ICRMCustomerProductTableProps) {
  const aiSummaryDefaultMessage =
    "This engagement record has not been updated in the last 6 months. If you want to see the details of this engagements, please refer M365CRM";
  const [customModalProps, setCustomModalProps] = useState<{ showModal: boolean; data?: any }>({
    showModal: false
  });
  const columns: GridColDef[] = [
    {
      headerName: "CRM Link",
      field: "crmLink",
      width: 100,
      sortable: true,
      renderCell: (params) => (
        <Link
          style={{
            color: "rgb(0, 120, 212)",
            textDecoration: "none",
            fontSize: 26,
            cursor: "pointer"
          }}
          href={params.row.crmLink}
          title={params.row.crmLink}
          target="_blank"
          rel="noopener noreferrer"
          tabIndex={params.row.tabIndex}
        >
          <LinkRegular />
        </Link>
      )
    },
    {
      headerName: "Product Name",
      field: "productName",
      width: 290,
      sortable: true,
      renderCell: ({ row: { productName, aiSummary } }: any) => (
        <Stack horizontal styles={{ root: { alignItems: "center", justifyContent: "space-between" } }}>
          <IconButton
            aria-label="Info"
            iconProps={{ iconName: "Info" }}
            onClick={() => handleToggleDialog(aiSummary || aiSummaryDefaultMessage)}
          />
          <div style={{ width: 250 }}>{productName}</div>
        </Stack>
      )
    },
    {
      headerName: "Owner",
      field: "owner",
      width: 220,
      sortable: true
    },
    {
      headerName: "PM List",
      field: "pmList",
      width: 220,
      sortable: true
    },
    {
      type: "number",
      headerName: "Idle Days",
      field: "idleDays",
      width: 100,
      sortable: true,
      valueGetter: (value, row) => getIdleDays(row.lastNotesModifiedOn)
    },
    {
      headerName: "Sales Stage",
      field: "salesStage",
      width: 120,
      sortable: true
    },
    {
      headerName: "Next Steps",
      field: "nextSteps",
      width: 220,
      sortable: true
    },
    {
      headerName: "Modified Notes On",
      type: "date",
      field: "lastNotesModifiedOn",
      width: 180,
      sortable: true,
      valueGetter: (value, row) => new Date(row.lastNotesModifiedOn),
      renderCell: (params) => formatDate(params.row.lastNotesModifiedOn)
    },
    {
      headerName: "Notes",
      field: "notes",
      width: 300,
      sortable: true
    },
    {
      headerName: "Created On",
      type: "date",
      field: "notesCreatedOn",
      width: 120,
      sortable: true,
      valueGetter: (value, row) => new Date(row.notesCreatedOn),
      renderCell: (params) => formatDate(params.row.notesCreatedOn)
    },
    {
      headerName: "Modified On",
      type: "date",
      field: "notesModifiedOn",
      width: 120,
      sortable: true,
      valueGetter: (value, row) => new Date(row.notesModifiedOn),
      renderCell: (params) => formatDate(params.row.notesModifiedOn)
    },
    {
      headerName: "Subject",
      field: "subject",
      width: 270,
      sortable: true
    },
    {
      headerName: "State",
      field: "customerProductState",
      width: 100,
      sortable: true
    },
    {
      headerName: "Owner Type",
      field: "ownerType",
      width: 100,
      sortable: true
    },
    {
      headerName: "Eng Owner",
      field: "engOwner",
      width: 220,
      sortable: true
    },
    {
      headerName: "Engagement Owner",
      field: "engagementOwner",
      width: 220,
      sortable: true
    },
    {
      headerName: "Pre-Sales Engaged",
      field: "preSalesEngaged",
      width: 150,
      sortable: true
    },
    {
      headerName: "CAT Engaged",
      field: "catEngaged",
      width: 120,
      sortable: true
    },
    {
      headerName: "partner Engaged",
      field: "partnerEngaged",
      width: 120,
      sortable: true
    },
    {
      headerName: "Weekly Tweet",
      field: "weeklyTweet",
      width: 270,
      sortable: true
    },
    {
      headerName: "Next Contact Date",
      type: "date",
      field: "nextContactDate",
      width: 130,
      sortable: true,
      valueGetter: (value, row) => new Date(row.nextContactDate),
      renderCell: (params) => formatDate(params.row.nextContactDate)
    },
    {
      headerName: "Deployment Type",
      field: "deploymentType",
      width: 270,
      sortable: true
    },
    {
      headerName: "Deployment",
      field: "deployment",
      width: 120,
      sortable: true
    },
    {
      headerName: "Deployment Status",
      field: "deploymentStatus",
      width: 270,
      sortable: true
    }
  ];

  const handleToggleDialog = (data: any) => {
    setCustomModalProps({ data: data, showModal: true });
  };

  const showNoData = () => {
    const rootStyle = {
      display: "flex",
      justifyContent: "center",
      alightItems: "center",
      padding: 200
    };
    return <div style={rootStyle}>{`CRM Customer Product details are not available for ${props.tenantName}`}</div>;
  };

  const getIdleDays = (lastNotesModifiedOn: any) => {
    const currentDate = new Date();
    const modifiedDate = new Date(lastNotesModifiedOn ?? currentDate);
    modifiedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    return getDaysBetweenDates(currentDate, modifiedDate);
  };

  return props?.tableData && props?.tableData.length > 0 ? (
    <div style={{ height: 600, marginLeft: 8, marginRight: 8 }}>
      <DataGrid
        columns={columns}
        density="compact"
        disableDensitySelector
        disableRowSelectionOnClick
        getRowId={(row) => row.rowId}
        rows={props.tableData.map((row, index) => ({
          ...row,
          rowId: row.customerProductId + "-" + (index + 1)
        }))}
        showCellVerticalBorder
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true
          }
        }}
      />
      {customModalProps.showModal && (
        <CustomModal
          draggable={false}
          headerTitle="AI Summary"
          showModal={customModalProps.showModal}
          onToggleHideDialog={() => setCustomModalProps((prev) => ({ ...prev, showModal: false }))}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: customModalProps.data
            }}
          />
        </CustomModal>
      )}
    </div>
  ) : (
    showNoData()
  );
}
