import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store/store";
import { serviceApi } from "../Services/ServiceConfig";

export const FeedbackApi = createApi({
  reducerPath: "feedbackApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serviceApi.security360 + "feedback/",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).msal.token?.accessToken;
      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    }
  }),

  endpoints: (builder) => ({
    getFeedbackQuestions: builder.query({
      query: () => "getFeedbackQuestions"
    }),
    getAnnouncements: builder.query({
      query: () => "getAnnouncements"
    }),
    saveFeedback: builder.mutation({
      query(body) {
        return {
          url: "saveFeedback",
          method: "POST",
          body
        };
      }
    })
  })
});

export const { useGetFeedbackQuestionsQuery, useGetAnnouncementsQuery, useSaveFeedbackMutation } = FeedbackApi;
