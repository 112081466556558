import { IShimmerColors, IShimmerElement, Shimmer, ShimmerElementType } from "@fluentui/react";
import { DonutChart, IDonutChartProps } from "@fluentui/react-charting";
import * as React from "react";

interface IFluentDonutChartProps extends IDonutChartProps {
  isChartLoading?: boolean;
}

const FluentDonutChart = (props: IFluentDonutChartProps) => {
  const shimmerElementsForChart: IShimmerElement[] = [
    { type: ShimmerElementType.gap, width: "100%" },
    { type: ShimmerElementType.circle, height: 200, width: "100%" },
    { type: ShimmerElementType.gap, width: "100%" },
  ];
  const shimmerElementsForLegends: IShimmerElement[] = [
    { type: ShimmerElementType.gap, width: "50%" },
    { type: ShimmerElementType.line, height: 12, width: "20%" },
    { type: ShimmerElementType.gap, width: "15%" },
    { type: ShimmerElementType.line, height: 12, width: "60%" },
    { type: ShimmerElementType.gap, width: "50%" },
    { type: ShimmerElementType.line, height: 12, width: "20%" },
    { type: ShimmerElementType.gap, width: "15%" },
    { type: ShimmerElementType.line, height: 12, width: "60%" },
    { type: ShimmerElementType.gap, width: "50%" },
    { type: ShimmerElementType.line, height: 12, width: "20%" },
    { type: ShimmerElementType.gap, width: "15%" },
    { type: ShimmerElementType.line, height: 12, width: "60%" },
    { type: ShimmerElementType.gap, width: "50%" },
  ];
  const fadedShimmerColors: IShimmerColors = {
    shimmer: "var(--shimmer-color)",
    background: "var(--colorCardBodyBackground1)"
  };

  React.useEffect(() => {
    setTimeout(() => {
      const rowgrpElements = document.querySelectorAll("[role=\"listbox\"]");
      rowgrpElements.forEach(function (element) {
        element.setAttribute("aria-busy", "true");
      });
      const grigrpElements = document.querySelectorAll("[role=\"grid\"]");
      grigrpElements.forEach(function (element) {
        element.setAttribute("aria-busy", "true");
      });
    }, 1000);
  }, []);

  return (
    props?.isChartLoading ? (
      <div>
        <Shimmer shimmerElements={shimmerElementsForChart} styles={{
          ...props?.styles,
          root: {
            ...(props?.styles || {}),
            margin: 20,
          },
        }} shimmerColors={fadedShimmerColors} />
        <Shimmer shimmerElements={shimmerElementsForLegends} styles={{ root: { marginBottom: 10 } }} shimmerColors={fadedShimmerColors} />
      </div>
    ) : (
      props.data && (
        <DonutChart
          innerRadius={props.innerRadius || 65}
          legendProps={{
            allowFocusOnLegends: true,
            ...props.legendProps
          }}
          {...props}
        />
      ))
  );
};

export default FluentDonutChart;
