import { DataGrid, GridColDef, GridRowSelectionModel, GridToolbar } from "@mui/x-data-grid";
import * as React from "react";
import { formatDate } from "../../helper/DateFormat";
import { IsubscriptionTableProps } from "../../interfaces/subscription-table";
import { handleToggleDataGridRowSelection } from "../../Utils/js-common";

export default function SubscriptionTable(props: IsubscriptionTableProps) {
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const handleSelection = handleToggleDataGridRowSelection(selectionModel, setSelectionModel);
  const showNoData = () => {
    const rootStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "200px"
    };
    return (
      <div style={rootStyle}>{`License and Subscription details are not available for ${props.tenantName} .`} </div>
    );
  };

  const columns: GridColDef[] = [
    {
      headerName: "Subscription Id",
      field: "subscriptionId",
      width: 270,
      sortable: true
    },
    {
      headerName: "SKU Name",
      field: "sku",
      width: 240,
      sortable: true
    },
    {
      headerName: "Included Quantity",
      type: "number",
      field: "includedQuantity",
      width: 190,
      sortable: true,
      align: "left",
      headerAlign: "left"
    },

    {
      headerName: "SKU Category",
      field: "skuCategory",
      width: 170,
      sortable: true
    },
    {
      headerName: "SKU Family",
      field: "skuFamily",
      width: 130,
      sortable: true
    },
    {
      headerName: "SKU Assigned",
      field: "skuAssigned",
      width: 130,
      sortable: true
    },
    {
      headerName: "Subscription Status",
      field: "subscriptionStatus",
      width: 150,
      sortable: true
    },
    {
      headerName: "Subscription Name",
      field: "friendlySubscriptionName",
      width: 200,
      sortable: true
    },
    {
      headerName: "Subscription Created Date",
      type: "date",
      field: "subscriptionCreatedDate",
      width: 190,
      sortable: true,
      valueGetter: (value, row) => new Date(row.subscriptionCreatedDate),
      renderCell: (params) => formatDate(params.row.subscriptionCreatedDate)
    },
    {
      headerName: "Subscription Start Date",
      type: "date",
      field: "subscriptionStartDate",
      width: 190,
      sortable: true,
      valueGetter: (value, row) => new Date(row.subscriptionStartDate),
      renderCell: (params) => formatDate(params.row.subscriptionStartDate)
    },
    {
      headerName: "Subscription End Date",
      type: "date",
      field: "subscriptionEndDate",
      minWidth: 190,
      sortable: true,
      valueGetter: (value, row) => new Date(row.subscriptionEndDate),
      renderCell: (params) => formatDate(params.row.subscriptionEndDate)
    },
    {
      headerName: "Partner OneId",
      field: "partnerOneId",
      minWidth: 120,
      sortable: true
    },
    {
      headerName: "Partner Country",
      field: "partnerCountry",
      minWidth: 120,
      sortable: true
    },
    {
      headerName: "Tier1 Partner MPNID",
      field: "tier1PartnerMPNID",
      minWidth: 180,
      sortable: true
    },
    {
      headerName: "Tier2 Partner MPNID",
      field: "tier2PartnerMPNID",
      minWidth: 180,
      sortable: true
    },
    {
      headerName: "Tier2 Partner MPNName",
      field: "tier1PartnerMPNName",
      minWidth: 180,
      sortable: true
    },
    {
      headerName: "Tier2 Partner MPNName",
      field: "tier2PartnerMPNName",
      minWidth: 180,
      flex: 1,
      sortable: true
    }
  ];

  React.useEffect(() => {
    setTimeout(() => {
      const elements = document.getElementsByClassName("MuiDataGrid-virtualScroller");
      Array.from(elements).forEach(function (element) {
        element.setAttribute("tabIndex", "0");
      });
    }, 1000);
  }, []);
  return (
    <>
      {props?.tableData && props?.tableData.length > 0 ? (
        <div style={{ height: 600, width: "100%" }} tabIndex={0}>
          <DataGrid
            rows={props.tableData}
            columns={columns}
            getRowId={(row) => row.subscriptionId}
            density="compact"
            disableDensitySelector
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true
              }
            }}
            onRowSelectionModelChange={handleSelection}
            rowSelectionModel={selectionModel}
          />
        </div>
      ) : (
        showNoData()
      )}
    </>
  );
}
