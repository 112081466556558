import { getNextColor } from "@fluentui/react-charting";
import { useContext } from "react";
import DisplayAPIsException from "../../../../BasicComponents/DisplayAPIsExceptionAlert";
import FunnelChart from "../../../../ChartComponents/FunnelChart";
import { CustomShimmer } from "../../../../helper/CustomShimmer";
import CustomCard from "../../../../SharedComponents/CustomCard";
import { OverviewAndSummaryMutationContext } from "../../ProductView";
import { PRODUCT_VIEW_DATA } from "../../Common/ProductView.constants";
import { arrSortAscByKeyValue } from "../../../../Utils/js-common";

export const KeyMetricsFunnelView = () => {
  const { data, isLoading, isError } = useContext(OverviewAndSummaryMutationContext);

  const getTenantsMetricsFunnelData = (): any[] => {
    const sortedMappedData = arrSortAscByKeyValue({
      key: "productKPIName",
      data: data?.overviewData || []
    }).map((mmetric, index) => ({
      ...mmetric,
      color: getNextColor(index)
    }));
    const keyMetricsList =
      data?.summaryData?.filter((x) => x.isTenantMetrics != "0")[0]?.data?.map((x: any) => x.name) || [];
    const filteredMetrics = sortedMappedData.filter(
      ({ productKPIName }) => keyMetricsList.indexOf(productKPIName) > -1
    );

    return filteredMetrics
      ?.map((card) => ({
        x: card.productKPIName,
        y: Number(card.currentValue.replaceAll(",", "")),
        z: card.order,
        abbValue: card.abbrevatedValue,
        color: card.color
      }))
      .sort((a: any, b: any) => a.z - b.z);
  };

  const getKeyMetricsFunnelData = (): any[] => {
    const sortedMappedData = arrSortAscByKeyValue({
      key: "productKPIName",
      data: data?.overviewData || []
    }).map((mmetric, index) => ({
      ...mmetric,
      color: getNextColor(index)
    }));
    const keyMetricsList =
      data?.summaryData?.filter((x) => x.isTenantMetrics == "0")[0]?.data?.map((x: any) => x.name) || [];
    const filteredMetrics = sortedMappedData.filter(
      ({ productKPIName }) => keyMetricsList.indexOf(productKPIName) > -1
    );

    return filteredMetrics
      ?.map((card) => ({
        x: card.productKPIName,
        y: Number(card.currentValue.replaceAll(",", "")),
        z: card.order,
        abbValue: card.abbrevatedValue,
        color: card.color
      }))
      .sort((a: any, b: any) => a.z - b.z);
  };

  const displayTenantsFunnel = !isError && <FunnelChart width="100%" data={getTenantsMetricsFunnelData()} />;
  const displayKeysFunnel = !isError && <FunnelChart width="100%" data={getKeyMetricsFunnelData()} />;
  const displayAPIsException = (
    <div style={{ paddingTop: 35 }}>
      <DisplayAPIsException height={345} />
    </div>
  );

  return (
    <div style={{ display: "flex", justifyContent: "space-around", flexDirection: "row" }}>
      <div style={{ display: "contents" }}>
        <CustomCard
          cardHeaderTitle={PRODUCT_VIEW_DATA.TABS.TAB.TENANTS_METRICS_FUNNEL_VIEW.CARD_HEADER}
          styles={{ root: { width: "40%" } }}
        >
          {isLoading ? <CustomShimmer columnCount={2} /> : displayTenantsFunnel || displayAPIsException}
        </CustomCard>
      </div>
      <div style={{ display: "contents" }}>
        <CustomCard
          cardHeaderTitle={PRODUCT_VIEW_DATA.TABS.TAB.KEY_METRICS_FUNNEL_VIEW.CARD_HEADER}
          styles={{ root: { width: "40%" } }}
        >
          {isLoading ? <CustomShimmer columnCount={2} /> : displayKeysFunnel || displayAPIsException}
        </CustomCard>
      </div>
    </div>
  );
};
