import { Card } from "@coherence-design-system/controls";
import { IBreadcrumbItem, IBreadcrumbProps, Stack } from "@fluentui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { CustomBreadcrumb } from "../SharedComponents/CustomBreadcrumb";
import AllReports from "../components/ReportCatalog/AllReports/AllReports";
import { isReportExistsPredicate, mapReportsDataPredicate } from "../components/ReportCatalog/ReportCatalog.common";
import { CardData } from "../components/ReportCatalog/ReportCatalog.interfaces";
import { useGetReportCatalogDataQuery } from "../components/ReportCatalog/ReportCatalog.slice";
import TopReports from "../components/ReportCatalog/TopReports/TopReports";
import { TopReportsViewModal } from "../components/ReportCatalog/TopReports/TopReportsViewModal";

const style = {
  "--font-family": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
  margin: 10
};

const breadcrumbStyles = {
  root: {
    fontSize: "14px",
    margin: 0,
    paddingLeft: "8px",
    "--itemLink-color": "rgb(0, 120, 212)"
  },
  item: {
    fontSize: "inherit"
  },
  itemLink: {
    fontSize: "inherit",
    color: "var(--itemLink-color)",
    ":hover": {
      background: "none",
      textDecoration: "underline",
      color: "var(--itemLink-color)"
    }
  }
};
const key = "report-catalog-bc";

export default function ReportCatalog() {
  const navigate = useNavigate();
  const [data, setData] = React.useState<CardData[]>([]);
  const [card, setCard] = React.useState<CardData | undefined>();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const { data: queryData, error, isLoading } = useGetReportCatalogDataQuery(null);
  const items: IBreadcrumbItem[] = [
    { text: "Home", key: "home", onClick: () => navigate("/") },
    { text: "Explore Reports", key: "reports", isCurrentItem: true }
  ];
  const bcrops: IBreadcrumbProps = {
    items: items,
    styles: breadcrumbStyles
  };
  const onCloseModel = React.useCallback(() => {
    setIsModalOpen(false);
    setCard(undefined);
  }, []);
  const onCardSelected = React.useCallback((card: CardData) => {
    setCard(card);
    setIsModalOpen(true);
  }, []);
  const onDataLoaded = React.useCallback((d?: any[]) => {
    setData(
      (d || [])
        .map(mapReportsDataPredicate)
        .filter(isReportExistsPredicate)
        .sort((a, b) => (a.description > b.description ? -1 : a.description < b.description ? 1 : 0))
    );
  }, []);
  const onDataError = React.useCallback(() => {
    setData([]);
  }, []);

  React.useEffect(() => {
    if (queryData) {
      onDataLoaded(queryData);
    }
    if (error) {
      onDataError();
    }
  }, [error, onDataError, onDataLoaded, queryData]);

  return (
    <div className="mainCard" style={style}>
      <Card header={<CustomBreadcrumb {...bcrops} key={key} />}>
        <Stack>
          <FluentProvider theme={webLightTheme}>
            <TopReports cards={data} onCardSelected={onCardSelected} loading={isLoading} />
          </FluentProvider>
          <AllReports />
        </Stack>
      </Card>
      <TopReportsViewModal isModelOpen={isModalOpen} card={card} onCloseModel={onCloseModel} />
    </div>
  );
}
