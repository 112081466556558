import { mergeStyleSets } from "@fluentui/react";
import { IDonutChartStyles } from "@fluentui/react-charting";
import { CSSProperties } from "react";

export const CUSTOMER_VIEW_STYLES = {
  COMMON_STYLES: {
    CLASS_NAMES: mergeStyleSets({
      customCardContainer: {
        columnGap: 10,
        display: "flex",
        margin: 8
      } as CSSProperties
    }),
    CSS_STYLES: {
      donutChart: {
        legendContainer: { "& div": { marginLeft: 0 } },
        root: { marginBottom: 24, marginTop: 32 }
      } as IDonutChartStyles
    }
  }
};
