import { PowerBIEmbed } from "powerbi-client-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { PrimaryButton } from "@fluentui/react";

export default function ReportView() {
  const token = useAppSelector((state) => state.msal.powerbiToken);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const reportId = searchParams.get("reportid")?.toString();
  const reportLink = "https://msit.powerbi.com/reportEmbed?reportId=" + reportId;
  const handleClose = () => {
    navigate("/reportcatalog");
  };

  return (
    <>
      <div>
        <div>
          <PrimaryButton
            style={{
              position: "absolute",
              right: 130,
              marginRight: "10px",
              top: 8
            }}
            text="Open In New Window"
            type="a"
            href={reportLink}
            // Href={props.selectedItem.row.ReportLink}
            target="_blank"
          ></PrimaryButton>
          <PrimaryButton
            style={{ position: "absolute", right: 50, top: 8 }}
            text="Close"
            onClick={() => {
              handleClose();
            }}
          />
        </div>

        <div
          style={{
            overflow: "none",
            height: 500,
            paddingTop: 45,
            paddingLeft: 10,
            paddingRight: 10
          }}
        >
          <PowerBIEmbed
            cssClassName="powerBiCSS"
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
              id: reportId,
              embedUrl: `https://msit.powerbi.com/reportEmbed?reportId=${reportId}`,
              accessToken: token,
              tokenType: 0
              // Use models.TokenType.Aad for SaaS embed
            }}
          />
        </div>
      </div>
    </>
  );
}
