import { CoherenceTheme } from "@coherence-design-system/styles";
import { loadTheme } from "@fluentui/react";
import { createRoot } from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { initializeIcons } from "@fluentui/react/lib/Icons";
import App from "./App";

initializeIcons(/* Optional base url */);
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */

loadTheme(CoherenceTheme);

const rootElement = document.getElementById("root");
if (rootElement) {
  createRoot(rootElement).render(App());
} else {
  console.error("Failed to find the root element");
}

// If you want to start measuring performance in your app, pass a function
// To log results (for example: reportWebVitals(console.log))
// Or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
