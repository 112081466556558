import { logEvent, logException } from "../helper/AppInsightsHelper";
import { configAxiosToken } from "../Utils/js-common";
import { serviceApi } from "./ServiceConfig";

export async function updateUserLog(graphToken: string, loginToken: string) {
  return configAxiosToken(graphToken)
    .get("https://graph.microsoft.com/v1.0/me")
    .then((resp) => configAxiosToken(loginToken).post(serviceApi.security360 + "User/UpdateUserLog", { ...resp.data }))
    .then((response) => {
      logEvent("updateUserLog API call success", "Login Page", "User Logging Service Slicer");
      return response?.data;
    })
    .catch((error) => {
      logException("Login Page", "User Logging Service Slicer", "updateUserLog", error);
      return error;
    });
}
