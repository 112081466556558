import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DisplayAPIsException from "../../BasicComponents/DisplayAPIsExceptionAlert";
import { logEvent, logException } from "../../helper/AppInsightsHelper";
import { CustomShimmer } from "../../helper/CustomShimmer";
import { customerEngagementType } from "../../interfaces/customer-engagement";
import { useGetCustomerEngagementsQuery } from "../../slices/Customer.slice";
import { groupBy } from "../../Utils/js-common";
import CustomerEngagementChart from "./CustomerEngagementChart";
import CustomerEngagementTable from "./CustomerEngagementTable";

const CustomerEngagementContainer = (props: any) => {
  const [searchParams] = useSearchParams();
  const tenantId = searchParams.get("id");
  const { data, error, isFetching } = useGetCustomerEngagementsQuery(tenantId);
  const [items, setitems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredChartData, setFilteredChartData] = React.useState<customerEngagementType>();
  const [chartKeys, setChartKeys] = React.useState<string[]>([]);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [chartFilter, setChartFilter] = React.useState({
    filterKey: "",
    value: ""
  });
  const [filterChData, setFilterChData] = React.useState<any>({
    engagementStatus: "",
    meetingType: ""
  });
  const HandleAPIExcpetion = () => (error && <DisplayAPIsException height={250} />) || "";

  useEffect(() => {
    logEvent("Customer Engagements", "Customer Page", "CustomerEngagementsContainer");
  }, []);

  useEffect(() => {
    if (data) {
      setitems(data);
      setFilteredItems(data);
      const groupByStatus = groupBy(data, "engagementStatus");
      const dataByStatus = Object.keys(groupByStatus).map((item) => {
        return {
          engagementStatus: item,
          engagementStatusCount: groupByStatus[item].length
        };
      });
      const groupByType = groupBy(data, "meetingType");
      const dataByType = Object.keys(groupByType).map((item) => {
        return {
          meetingType: item,
          meetingTypeCount: groupByType[item].length
        };
      });
      setFilteredChartData({
        engagementStatus: dataByStatus,
        meetingType: dataByType
      });
      setFilterChData({
        engagementStatus: "",
        meetingType: ""
      });
      setIsChartLoading(false);
      setChartKeys(["engagementStatus", "meetingType"]);
    }

    if (error) {
      setIsChartLoading(false);
      logException("Product View", "CustomerEngagementContainer", "useGetCustomerEngagementsQuery", error);
    }
  }, [data, error]);

  React.useEffect(() => {
    switch (chartFilter.filterKey) {
      case "engagementStatus":
        {
          let filteredData: any = [];
          filteredData = items.filter((item: any) => item.engagementStatus === chartFilter.value);
          setFilterChData({
            ...filterChData,
            engagementStatus: chartFilter.value
          });
          if (filterChData.meetingType !== "") {
            filteredData = filteredData.filter((item: any) => item.meetingType === filterChData.meetingType);
          }

          setFilteredItems(filteredData);
          const meetingType = groupBy(filteredData, "meetingType");
          const filteredMeetingType: any[] = [];
          Object.keys(meetingType).forEach((item) => {
            filteredMeetingType.push({
              meetingType: item,
              meetingTypeCount: meetingType[item].length
            });
          });

          if (filteredMeetingType && filteredChartData?.meetingType) {
            setFilteredChartData({
              ...filteredChartData,
              meetingType: filteredMeetingType
            });
          }
        }
        break;
      case "meetingType":
        {
          let filteredData: any = [];
          filteredData = items.filter((item: any) => item.meetingType === chartFilter.value);
          setFilterChData({
            ...filterChData,
            meetingType: chartFilter.value
          });
          if (filterChData.engagementStatus !== "") {
            filteredData = filteredData.filter((item: any) => item.engagementStatus === filterChData.engagementStatus);
          }

          setFilteredItems(filteredData);
          const engagementStatus = groupBy(filteredData, "engagementStatus");
          const engagementStatusFiltered: any[] = [];
          Object.keys(engagementStatus).forEach((item) => {
            engagementStatusFiltered.push({
              engagementStatus: item,
              engagementStatusCount: engagementStatus[item].length
            });
          });

          if (engagementStatusFiltered && filteredChartData?.engagementStatus) {
            setFilteredChartData({
              ...filteredChartData,
              engagementStatus: engagementStatusFiltered
            });
          }
        }
        break;

      default: {
        setFilteredItems(data);
        if (data) {
          const groupByStatus = groupBy(data, "engagementStatus");
          const dataByStatus = Object.keys(groupByStatus).map((item) => {
            return {
              engagementStatus: item,
              engagementStatusCount: groupByStatus[item].length
            };
          });
          const groupByType = groupBy(data, "meetingType");
          const dataByType = Object.keys(groupByType).map((item) => {
            return {
              meetingType: item,
              meetingTypeCount: groupByType[item].length
            };
          });
          setFilteredChartData({
            engagementStatus: dataByStatus,
            meetingType: dataByType
          });
          setFilterChData({
            engagementStatus: "",
            meetingType: ""
          });
        }
      }
    }
  }, [chartFilter]);

  const showNoData = () => {
    const rootStyle = {
      alignItems: "center",
      display: "flex",
      fontSize: 18,
      fontWeight: 600,
      justifyContent: "center",
      padding: 200
    };
    return <div style={rootStyle}>{`Engagements details are not available for ${props.tenantName}.`}</div>;
  };

  return (
    <>
      <CustomerEngagementChart
        chartData={filteredChartData}
        isChartLoading={isChartLoading}
        keys={chartKeys}
        setChartFilter={setChartFilter}
        tenantName={props.tenantName}
      />
      {isFetching ? (
        <CustomShimmer columnCount={11} />
      ) : (
        (items.length && (
          <div style={{ marginLeft: 3, marginRight: 3 }}>
            <CustomerEngagementTable tableData={filteredItems} tenantName={props.tenantName} />
          </div>
        )) ||
        showNoData()
      )}
      <HandleAPIExcpetion />
    </>
  );
};

export default CustomerEngagementContainer;
