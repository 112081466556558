import {
  ColumnActionsMode,
  DetailsListLayoutMode,
  FontWeights,
  IGroup,
  Link,
  SelectionMode,
  Text,
  mergeStyleSets
} from "@fluentui/react";
import type { ICompositeListColumn, ICompositeListDetailsList } from "@m365-admin/composite-list";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { CustomCompositeList } from "../../SharedComponents/CustomCompositeList";
import HomeSparkLineChart from "../../ChartComponents/HomeSparkLineChart";
import { logEvent, logException } from "../../helper/AppInsightsHelper";
import { useGetHomePageDataQuery } from "../../slices/Home.slice";

const styles = mergeStyleSets({
  detailsListsWrapper: {
    ".ms-DetailsList-headerWrapper": {
      padding: 0,
      fontSize: "14px",
      fontWeight: 600,
      "& .ms-DetailsHeader-cellName": {
        fontSize: "14px !important"
      }
    },
    ".ms-DetailsRow-cell": {
      display: "flex",
      alignItems: "center",
      fontSize: "12px",
      fontWeight: 600,
      color: "black",
      borderRight: "1px solid #ececec",
      selectors: {
        svg: {
          width: "inherit"
        }
      },
      "& [data-automation-key='mpuTrend'], & [data-automation-key='pauTrend'], & [data-automation-key='MAUTrend'], & [data-automation-key='MDPUTrend']":
        {
          padding: "0 12px"
        }
    }
  }
});

const columns = [{ name: "MPU" }, { name: "PAU" }, { name: "MAU" }, { name: "MDPU" }];

interface IProduct {
  family: string;
  name: string;
  data: IProductMetricVaraince[];
}

interface IProductMetricVaraince extends IProductVariance {
  name: string;
  data: IAxisValue[];
}

interface IAxisValue {
  value: number;
  month: string;
}

interface IProductVariance {
  abbrCurrentValue: string;
  abbrMOMVariance: string;
  currentValue: number;
  momVariance: number;
  readableCurrentValue: string;
  readableMOMVariance: string;
}

const groupHeaderColumns: any[] = [
  {
    key: "productName",
    name: "",
    isResizable: true,
    minWidth: 270,
    maxWidth: 320,
    onRender: (d: { name: string; total: number }) => {
      return (
        <Text styles={{ root: { fontWeight: FontWeights.semibold } }}>
          {d.name}
          {d.total > 0 ? <>&nbsp;({d.total})</> : <></>}
        </Text>
      );
    }
  }
];
export default function HomeComponent() {
  const navigate = useNavigate();
  const { data, error } = useGetHomePageDataQuery(null);
  const [showEmptyState, setShowEmptyState] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (data) {
      logEvent("useGetHomePageDataQuery API call success", "Home Page", "HomeComponent");
    }
    if (error) {
      logException("Home Page", "HomeComponent", "useGetHomePageDataQuery", error);
    }
  }, [data, error]);
  const listColumns: ICompositeListColumn[] = [
    {
      key: "productName",
      name: "Product",
      columnActionsMode: ColumnActionsMode.clickable,
      isResizable: true,
      isRowHeader: true,
      minWidth: 270,
      maxWidth: 320,
      ariaLabel: "Product",
      onRender: (data: IProduct) => (
        <>
          <Link
            title={data.name}
            styles={{
              root: {
                padding: 0,
                fontWeight: 600
              }
            }}
            onClick={() => navigate("/productview?productname=" + encodeURIComponent(data.name))}
          >
            {data.name}
          </Link>
        </>
      )
    },
    {
      key: "mpuCurrent",
      name: "MPU Current",
      isResizable: true,
      isMultiline: true,
      isRowHeader: true,
      minWidth: 90,
      maxWidth: 100,
      ariaLabel: "MPU Current",
      onRender: ({ data }: IProduct) => {
        const [m] = data.filter(({ name }) => name === "MPU");
        return (
          <div
            title={m.readableCurrentValue || "-"}
            style={{
              textAlign: "right",
              flexBasis: "100%"
            }}
          >
            {m.abbrCurrentValue || "-"}
          </div>
        );
      }
    },
    {
      key: "mpuMoM",
      name: "MPU MoM",
      isResizable: true,
      isMultiline: true,
      isRowHeader: true,
      minWidth: 70,
      maxWidth: 100,
      ariaLabel: "MPU MoM",
      onRender: ({ data }: IProduct) => {
        const [m] = data.filter(({ name }) => name === "MPU");
        return (
          <div
            style={{
              textAlign: "right",
              color: m.abbrMOMVariance?.includes("-") ? "red" : "green",
              flexBasis: "100%"
            }}
            title={m.readableMOMVariance || "-"}
          >
            {m.abbrMOMVariance || "-"}
          </div>
        );
      }
    },
    {
      key: "mpuTrend",
      name: "MPU Trend",
      isResizable: true,
      isMultiline: true,
      isRowHeader: true,
      minWidth: 70,
      maxWidth: 100,
      ariaLabel: "MPU Trend",
      onRender: ({ data }: IProduct) => {
        const [m] = data.filter(({ name }) => name === "MPU");
        return <HomeSparkLineChart chartData={m.data} name="MPU" />;
      }
    },
    {
      key: "pauCurrent",
      name: "PAU Current",
      isResizable: true,
      isMultiline: true,
      isRowHeader: true,
      minWidth: 90,
      maxWidth: 100,
      ariaLabel: "PAU Current",
      onRender: ({ data }: IProduct) => {
        const [m] = data.filter(({ name }) => name === "PAU");
        return (
          <div
            title={m.readableCurrentValue || "-"}
            style={{
              textAlign: "right",
              flexBasis: "100%"
            }}
          >
            {m.abbrCurrentValue || "-"}
          </div>
        );
      }
    },
    {
      key: "pauMoM",
      name: "PAU MoM",
      isResizable: true,
      isMultiline: true,
      isRowHeader: true,
      minWidth: 70,
      maxWidth: 100,
      ariaLabel: "PAU MoM",
      onRender: ({ data }: IProduct) => {
        const [m] = data.filter(({ name }) => name === "PAU");
        return (
          <div
            style={{
              textAlign: "right",
              color: m.abbrMOMVariance?.includes("-") ? "red" : "green",
              flexBasis: "100%"
            }}
            title={m.readableMOMVariance || "-"}
          >
            {m.abbrMOMVariance || "-"}
          </div>
        );
      }
    },
    {
      key: "pauTrend",
      name: "PAU Trend",
      isResizable: true,
      isMultiline: true,
      isRowHeader: true,
      minWidth: 70,
      maxWidth: 100,
      ariaLabel: "PAU Trend",
      onRender: ({ data }: IProduct) => {
        const [m] = data.filter(({ name }) => name === "PAU");
        return <HomeSparkLineChart chartData={m.data} name="PAU" />;
      }
    },
    {
      key: "MAUCurrent",
      name: "MAU Current",
      isResizable: true,
      isMultiline: true,
      isRowHeader: true,
      minWidth: 90,
      maxWidth: 100,
      ariaLabel: "MAU Current",
      onRender: ({ data }: IProduct) => {
        const [m] = data.filter(({ name }) => name === "MAU");
        return (
          <div
            title={m.readableCurrentValue || "-"}
            style={{
              textAlign: "right",
              flexBasis: "100%"
            }}
          >
            {m.abbrCurrentValue || "-"}
          </div>
        );
      }
    },
    {
      key: "MAUMoM",
      name: "MAU MoM",
      isResizable: true,
      isMultiline: true,
      isRowHeader: true,
      minWidth: 70,
      maxWidth: 100,
      ariaLabel: "MAU MoM",

      onRender: ({ data }: IProduct) => {
        const [m] = data.filter(({ name }) => name === "MAU");
        return (
          <div
            style={{
              textAlign: "right",
              color: m.abbrMOMVariance?.includes("-") ? "red" : "green",
              flexBasis: "100%"
            }}
            title={m.readableMOMVariance || "-"}
          >
            {m.abbrMOMVariance || "-"}
          </div>
        );
      }
    },
    {
      key: "MAUTrend",
      name: "MAU Trend",
      isResizable: true,
      isMultiline: true,
      isRowHeader: true,
      minWidth: 70,
      maxWidth: 100,
      ariaLabel: "MAU Trend",
      onRender: ({ data }: IProduct) => {
        const [m] = data.filter(({ name }) => name === "MAU");
        return <HomeSparkLineChart chartData={m.data} name="MAU" />;
      }
    },
    {
      key: "MDPUCurrent",
      name: "MDPU Current",
      isResizable: true,
      isMultiline: true,
      isRowHeader: true,
      minWidth: 90,
      maxWidth: 120,
      ariaLabel: "MDPU Current",
      onRender: ({ data }: IProduct) => {
        const [m] = data.filter(({ name }) => name === "MDPU");
        return (
          <div
            title={m.readableCurrentValue || "-"}
            style={{
              textAlign: "right",
              flexBasis: "100%"
            }}
          >
            {m.abbrCurrentValue || "-"}
          </div>
        );
      }
    },
    {
      key: "MDPUMoM",
      name: "MDPU MoM",
      isResizable: true,
      isMultiline: true,
      isRowHeader: true,
      minWidth: 80,
      maxWidth: 100,
      ariaLabel: "MDPU MoM",
      onRender: ({ data }: IProduct) => {
        const [m] = data.filter(({ name }) => name === "MDPU");
        return (
          <div
            style={{
              textAlign: "right",
              color: m.abbrMOMVariance?.includes("-") ? "red" : "green",
              flexBasis: "100%"
            }}
            title={m.readableMOMVariance || "-"}
          >
            {m.abbrMOMVariance || "-"}
          </div>
        );
      }
    },
    {
      key: "MDPUTrend",
      name: "MDPU Trend",
      isResizable: true,
      isMultiline: true,
      isRowHeader: true,
      minWidth: 90,
      maxWidth: 100,
      ariaLabel: "MDPU Trend",
      onRender: ({ data }: IProduct) => {
        const [m] = data.filter(({ name }) => name === "MDPU");
        return <HomeSparkLineChart chartData={m.data} name="MDPU" />;
      }
    }
  ];
  const [list, setList] = React.useState<ICompositeListDetailsList>({
    listProps: {
      ariaLabelForSelectAllCheckbox: "Select all items",
      items: [],
      groupProps: {
        headerProps: {
          selectAllButtonProps: {
            "aria-label": "Select all items in the group"
          }
        },
        showEmptyGroups: true
      },
      columns: listColumns,
      selectionMode: SelectionMode.none,
      layoutMode: DetailsListLayoutMode.justified
    },
    key: "product-metrics"
  });
  const _getGroups = (products: IProduct[], families: string[]) => {
    const productGroups: IGroup[] = [];
    let groupsStartIndex = 0;

    families.forEach((fn: string) => {
      const _products = products.filter((p) => p.family.toLowerCase() === fn.toLowerCase());
      const headerItem = {
        name: fn,
        total: _products.length
      };
      const group: IGroup = {
        key: fn,
        name: fn,
        startIndex: groupsStartIndex,
        count: _products.length,
        data: headerItem
      };

      groupsStartIndex += _products.length;
      productGroups.push(group);
    });

    return productGroups.length ? productGroups : undefined;
  };

  React.useEffect(() => {
    if (data && data.length) {
      const tableData = JSON.parse(JSON.stringify(data));
      tableData?.map((item: any) => {
        const result: any = [];
        columns.map((k: any, i: number) => {
          if (item.metricData.filter((f: any) => (f && f.productKpiName) === k.name).length) {
            result.splice(i, 0, item.metricData.filter((f: any) => (f && f.productKpiName) === k.name)[0]);
          } else {
            result.splice(i, 0, {
              productKpiName: k.name,
              momVariance: "",
              currentValue: "",
              chartData: []
            });
          }
          return k;
        });
        item.metricData = result;
        return item;
      });

      const families: string[] = Array.from(new Set(tableData.map((m: any) => m.productFamily)));
      const items: IProduct[] = (tableData || [])
        .map(
          (p: any) =>
            ({
              family: p.productFamily,
              name: p.productName,
              data: p.metricData.map(
                (m: any) =>
                  ({
                    ...m,
                    name: m.productKpiName,
                    data: m.chartData
                  }) as IProductMetricVaraince
              )
            }) as IProduct
        )
        .sort((a: IProduct, b: IProduct) => families.indexOf(a.family) - families.indexOf(b.family));

      setList((prev) => ({
        ...prev,
        listProps: {
          ...prev.listProps,
          items: items,
          groups: _getGroups(items, families),
          groupHeaderColumns: groupHeaderColumns
        }
      }));
    }
    if (error) {
      setShowEmptyState(!!error);
    }
  }, [data, error]);

  return (
    <div
      style={{
        overflow: "auto",
        margin: "10px 0 0 10px"
      }}
      data-is-scrollable="true"
    >
      <CustomCompositeList
        data={list}
        hideCommandBar={true}
        isCompactMode={true}
        isEmpty={showEmptyState}
        styles={styles}
      />
    </div>
  );
}
