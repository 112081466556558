import { PropsWithChildren } from "react";
import { VISUAL_NO_DATA_MESSAGE } from "./Constants/DisplayEmptyDataMessage.constants";
import { DisplayEmptyDataMessageProps } from "./Interfaces/DisplayEmptyDataMessage.interfaces";
import { DISPLAY_EMPTY_DATA_MESSAGE_STYLES } from "./Styles/DisplayEmptyDataMessage.styles";

const DisplayEmptyDataMessage = ({
  children,
  className,
  disableDefaultMessage,
  message
}: PropsWithChildren<DisplayEmptyDataMessageProps>) => (
  <div className={`${DISPLAY_EMPTY_DATA_MESSAGE_STYLES.emptyDataMessage} ${className || ""}`}>
    <div>{message || (!disableDefaultMessage && VISUAL_NO_DATA_MESSAGE)}</div>
    {children}
  </div>
);

export default DisplayEmptyDataMessage;
