import {
  DefaultButton,
  Icon,
  IContextualMenuItem,
  IContextualMenuListProps,
  IContextualMenuProps,
  IRenderFunction,
  KeyCodes,
  PrimaryButton,
  SearchBox,
  Stack
} from "@fluentui/react";
import { useFilterOptions } from "@m365-admin/hooks";
import { FilterPill } from "@m365-admin/in-page-filter";
import { ChangeEvent, KeyboardEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CustomFilterPillProps } from "./Interfaces/CustomFilterPill.interfaces";
import { CUSTOM_FILTER_PILL_STYLES as STYLES } from "./Styles/CustomFilterPill.styles";

export const CustomFilterPill = (props: CustomFilterPillProps) => {
  const menuItems = props.menuProps?.items || [];
  const containerRef = useRef<HTMLDivElement>(null);
  const [items, setItems] = useState<IContextualMenuItem[]>([]);
  const appliedFiltersRef = useRef<string[] | undefined>(undefined);
  const [appliedFilters, setAppliedFilters] = useState<string[] | undefined>(undefined);
  const [contextualMenuItem, setSelection, selectedKeys] = useFilterOptions(items.map(({ text }) => text || ""));
  const applyButtonDisabled =
    appliedFilters?.length === selectedKeys?.length &&
    appliedFilters?.every((value, index) => value === (selectedKeys || [])[index]);

  const onDismissVerifyResetMenuItems = () => {
    const { current } = appliedFiltersRef;
    const isUnchangedState =
      current?.length === selectedKeys?.length &&
      current?.every((value, index) => value === (selectedKeys || [])[index]);

    if (!(isUnchangedState === undefined || Boolean(isUnchangedState))) {
      const oldStateData = current?.reduce((previousValue: any, currentValue) => {
        previousValue[currentValue] = true;
        return previousValue;
      }, {});

      setSelection(oldStateData === undefined ? {} : oldStateData);
    }
  };

  const updateItemsHandler = useCallback(
    (event?: Event | MouseEvent | KeyboardEvent, dismissAll?: boolean) => {
      setItems(menuItems);
      props.menuProps?.onDismiss && props.menuProps?.onDismiss(event, dismissAll);
    },
    [props.menuProps?.items]
  );

  const onEmptyDataRender = useCallback(
    () => (
      <Stack
        horizontal
        tokens={STYLES.EMPTY_DATA_STYLES.emptyDataTokens}
        style={STYLES.EMPTY_DATA_STYLES.emptyDataContainer}
      >
        <Icon iconName="SearchIssue" title="No data found" />
        <span>No data found</span>
      </Stack>
    ),
    []
  );

  const onSearchInputChange = useCallback(
    (_event?: ChangeEvent<HTMLInputElement>, newValue?: string) => {
      const filteredItems = menuItems.filter(
        (item) => item.text?.toLowerCase().indexOf((newValue || "").toLowerCase()) !== -1
      );

      if (!filteredItems || !filteredItems.length) {
        filteredItems.push({
          key: "no_results"
        });
      }

      setItems(filteredItems);
    },
    [props.menuProps?.items]
  );

  const onSearchInputKeyDown = useCallback((event: any) => {
    /* Key Up, but we are not at the beginning of the text: stop event propagation to prevent ContextualMenu to focus */
    if (event.target.selectionStart > 0 && event.which === KeyCodes.up) {
      event.stopPropagation();
    }
    /* Key Down, but we are not at the end of the text: stop event propagation to prevent ContextualMenu to focus */
    if (event.target.selectionStart !== event.target.value.length && event.which === KeyCodes.down) {
      event.stopPropagation();
    }
  }, []);

  const renderMenuList = useCallback(
    (menuListProps?: IContextualMenuListProps, defaultRender?: IRenderFunction<IContextualMenuListProps>) => {
      const modifiedMenuListProps = contextualMenuItem.filter(({ key }) => !key).length
        ? ({
            ...menuListProps,
            hasCheckmarks: false,
            items: [
              {
                key: "no_results",
                onRender: onEmptyDataRender
              }
            ]
          } as IContextualMenuListProps)
        : menuListProps;

      props.menuProps?.onRenderMenuList && props.menuProps?.onRenderMenuList(menuListProps, defaultRender);

      return (
        <>
          <div className={STYLES.SEARCH_BOX.searchBoxContainer}>
            <label className={STYLES.SEARCH_BOX.searchBoxLabel}>{props.name}</label>
            <SearchBox
              ariaLabel="Filter actions by text"
              placeholder="Search"
              onClear={updateItemsHandler}
              onChange={onSearchInputChange}
              onKeyDown={onSearchInputKeyDown}
              showIcon
            />
          </div>
          {defaultRender && defaultRender(modifiedMenuListProps)}
          <Stack
            className={STYLES.ACTION_BUTTONS.CLASS_NAMES.actionButtonsContainer}
            horizontal
            tokens={STYLES.ACTION_BUTTONS.CSS_STYLES.actionButtonsContainerTokens}
          >
            <PrimaryButton
              disabled={applyButtonDisabled === undefined || Boolean(applyButtonDisabled)}
              text="Apply"
              onClick={() => {
                appliedFiltersRef.current = selectedKeys;
                setAppliedFilters(selectedKeys);
                containerRef.current?.click();
                props.onApply && props.onApply(selectedKeys);
              }}
            />
            <DefaultButton
              text="Cancel"
              onClick={() => {
                containerRef.current?.click();
                const oldStateData = appliedFilters?.reduce((previousValue: any, currentValue) => {
                  previousValue[currentValue] = true;
                  return previousValue;
                }, {});

                setSelection(oldStateData === undefined ? {} : oldStateData);
                props.onCancel && props.onCancel(appliedFilters);
              }}
            />
          </Stack>
        </>
      );
    },
    [contextualMenuItem, updateItemsHandler, onSearchInputChange, onSearchInputKeyDown]
  );

  const menuProps = useMemo(
    () =>
      ({
        ...props.menuProps,
        items: contextualMenuItem,
        onDismiss: updateItemsHandler,
        onRenderMenuList: renderMenuList
      }) as IContextualMenuProps,
    [contextualMenuItem]
  );

  useEffect(() => {
    setItems(menuItems);
  }, [props.menuProps?.items]);

  return (
    <div ref={containerRef}>
      <FilterPill
        {...props}
        menuProps={{
          ...menuProps,
          onDismiss: onDismissVerifyResetMenuItems
        }}
        resetProps={{
          ...props.resetProps,
          onClick: (event: any) => {
            setSelection({});
            appliedFiltersRef.current = undefined;
            setAppliedFilters(undefined);
            props.resetProps?.onClick && props.resetProps?.onClick(event);
            props.onResetFilters && props.onResetFilters(event);
          },
          "aria-label": props.resetProps?.["aria-label"] || `Clear ${props.name} filters`
        }}
        value={selectedKeys}
      />
    </div>
  );
};
