import { IStyle, mergeStyleSets } from "@fluentui/react";
import { DataGrid, GridColDef, GridRowSelectionModel, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { formatDate } from "../../helper/DateFormat";
import { handleToggleDataGridRowSelection } from "../../Utils/js-common";

const styles = mergeStyleSets({
  customerEngagement: {
    height: 600
  } as IStyle
});
const CustomerEngagementTable = (props: any) => {
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const handleSelection = handleToggleDataGridRowSelection(selectionModel, setSelectionModel);
  useEffect(() => {
    setTimeout(() => {
      const elements = document.getElementsByClassName("MuiDataGrid-virtualScroller");
      Array.from(elements).forEach(function (element) {
        element.setAttribute("tabIndex", "0");
      });
    }, 1000);
  }, []);

  const columns: GridColDef[] = [
    {
      headerName: "Title",
      field: "title",
      width: 240,
      sortable: true
    },
    {
      headerName: "Description",
      field: "description",
      width: 210,
      sortable: true
    },
    {
      headerName: "Association",
      field: "engagementAssociation",
      width: 120,
      sortable: true
    },
    {
      headerName: "Engagement Status",
      field: "engagementStatus",
      width: 220,
      sortable: true
    },
    {
      headerName: "Owner Name",
      field: "ownerName",
      width: 130,
      sortable: true
    },
    {
      headerName: "Meeting Type",
      field: "meetingType",
      width: 110,
      sortable: true
    },
    {
      headerName: "Engagement Type",
      field: "engagementType",
      width: 110,
      sortable: true
    },
    {
      headerName: "Detail Notes",
      field: "detailNotes",
      width: 120,
      sortable: true
    },
    {
      headerName: "Customer Meeting Request Notes",
      field: "customerMeetingRequestNotes",
      width: 220,
      sortable: true
    },
    {
      headerName: "CRM Product Name",
      field: "crmProductName",
      width: 220,
      sortable: true
    },
    {
      headerName: "created Date",
      field: "createdOn",
      type: "date",
      minWidth: 180,
      flex: 1,
      sortable: true,
      valueGetter: (value, row) => new Date(row.createdOn),
      renderCell: (params) => formatDate(params.row.createdOn)
    }
  ];

  return (
    <div className={styles.customerEngagement}>
      <DataGrid
        rows={props.tableData}
        columns={columns}
        getRowId={(row) => row.engagementId}
        density="compact"
        disableDensitySelector
        disableRowSelectionOnClick
        showCellVerticalBorder
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true
          }
        }}
        onRowSelectionModelChange={handleSelection}
        rowSelectionModel={selectionModel}
      />
    </div>
  );
};

export default CustomerEngagementTable;
