import { Pivot, PivotItem, IPivotStyles } from "@fluentui/react";
import { PropsWithChildren, useCallback, useState } from "react";
import { logEvent } from "../helper/AppInsightsHelper";

export const TabsContainer = ({
  ariaLabel,
  children,
  handleSelectedTab,
  styles
}: PropsWithChildren<{
  ariaLabel?: string;
  handleSelectedTab?: (item?: PivotItem) => void;
  styles?: Partial<IPivotStyles>;
}>) => {
  const [selectedTab, setSelectedTab] = useState("");
  const handleLinkClick = useCallback((item?: PivotItem) => {
    const selectedTab = item?.props.itemKey as string;
    if (handleSelectedTab) {
      handleSelectedTab(item);
    }
    setSelectedTab(selectedTab);

    const customPropsForLogging = {
      "SelectedTab": selectedTab
    };
    logEvent("ProductView tab selected", "ProductView", "TabsContainer", customPropsForLogging);
  }, []);

  return (
    <Pivot aria-label={ariaLabel} selectedKey={selectedTab} styles={styles} onLinkClick={handleLinkClick}>
      {children}
    </Pivot>
  );
};
