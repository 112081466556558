import {
  ChoiceGroup,
  ComboBox,
  DefaultButton,
  Dropdown,
  FontIcon,
  IComboBoxOption,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
  TooltipHost,
  mergeStyleSets
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { IFeedbackInsightsRequest } from "../../ServiceModels/CustomerService-Models";
import { feedbackInsightsData, feedbackInsightsScheduleMetaData, getProducts } from "../../Services/customerServices";
import { commonAIPlaygroundStyles } from "./AIPlayground-Common.Styles";
import { logEvent, logPageView } from "../../helper/AppInsightsHelper";

const styles = mergeStyleSets({
  feedbackInsightsContainer: {
    width: 1500,
    border: "1px solid black",
    margin: "30px 30px 30px 30px"
  }
});

export const FeedbackInsights = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [showEmojiButtons, setShowEmojiButtons] = useState<boolean>(false);
  const [showLikeButton, setShowLikeButton] = useState<boolean>(true);
  const [showDislikeButton, setShowDislikeButton] = useState<boolean>(true);
  const [showLikeSolidButton, setShowLikeSolidButton] = useState<boolean>(false);
  const [showDislikeSolidButton, setShowDislikeSolidButton] = useState<boolean>(false);

  const [promptValue, setPromptValue] = useState("");
  const [responseTextValue, setResponseTextValue] = useState("");

  const [loading, setLoading] = useState<boolean>(false);
  const [scheduleSuccess, setscheduleSuccess] = useState<boolean>(false);

  const [selectedCustomerName, setSelectedCustomerName] = useState<string>("");
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const customerComboboxOptions: IComboBoxOption[] = [
    { key: "none", text: "None" },
    { key: "walmart", text: "WALMART INC." },
    { key: "dell", text: "DELL COMPUTER CORPORATION" },
    { key: "novartis", text: "Novartis AG" },
    { key: "shell", text: "Shell plc (Global Parent)" },
    { key: "alaskaAir", text: "ALASKA AIR GROUP" },
    { key: "emerson", text: "EMERSON" },
    { key: "globalEnglish", text: "GLOBALENGLISH CORPORATION" },
    { key: "virginMoney", text: "Virgin Money" },
    { key: "delta", text: "DELTA AIR LINES INC" },
    { key: "hdfc", text: "HDFC Bank" },
    { key: "bayer", text: "Bayer AG" },
    { key: "krones", text: "Krones AG" },
    { key: "deutsche", text: "Deutsche Post DHL" },
    { key: "zionsBank", text: "ZIONS BANK CORPORATION" },
    { key: "veradigm", text: "Veradigm" },
    { key: "chubb", text: "CHUBB" },
    { key: "icertis", text: "Icertis Solution Pvt.Ltd" },
    { key: "edeka", text: "EDEKA AG" },
    { key: "fHoffmann", text: "F. Hoffmann-La Roche AG" },
    { key: "ferguson", text: "FERGUSON ENTERPRISES" },
    { key: "carollCounty", text: "Carroll County Public Schools" },
    { key: "commonSpiritHealth", text: "CommonSpirit Health" },
    { key: "bankOfNewZealand", text: "Bank Of New Zealand" },
    { key: "datavant", text: "Datavant" },
    { key: "sena", text: "Servicio Nacional de Aprendizaje - SENA" },
    { key: "cityOfAustin", text: "TX-CITY OF AUSTIN" },
    { key: "wellStar", text: "WELLSTAR HEALTH SYSTEM" },
    { key: "workSafe", text: "WORKSAFE BC" },
    { key: "networkInternational", text: "Network International L.L.C" },
    { key: "howardCounty", text: "HOWARD COUNTY SCHOOL DISTRICT" },
    { key: "meec", text: "MEEC-MARYLAND EDUCATION ENTERPRISE CONSORTIUM" },
    {
      key: "energisa",
      text: "ENERGISA MINAS GERAIS - DISTRIBUIDORA DE ENERGIA SA"
    },
    { key: "honorHealth", text: "HONORHEALTH" },
    { key: "paCounty", text: "PA-COUNTY OF LUZERNE" },
    { key: "flCity", text: "FL-CITY OF TALLAHASSEE" },
    { key: "govtOfQuebec", text: "Government Of Quebec" },
    { key: "sscict", text: "Ssc-Ict" },
    { key: "migliore", text: "Migliore Web Community Pvt Limited" },
    { key: "deutscheLufthansa", text: "Deutsche Lufthansa AG" },
    { key: "dashenBank", text: "Dashen Bank" },
    { key: "uhg", text: "Unitedhealth Group" },
    { key: "tecnatom", text: "TECNATOM S.A." }
  ];

  const toolTipMessage =
    "Schedule prompt-based report deliveries according to your selected frequency, reports will be sent at 1 AM UTC";

  const featureRequests =
    "Classify all the feature requests for Security Copilot. Provide the top 5 categories with a short description for each category";
  const capgeminiFeatureRequests =
    "Summarize all the feature requests we received from Capgemini across all the products";
  const rbacFeatureRequests = "List all the feature requests received around RBAC";
  const intuneFeatureRequests = "For Microsoft Intune, summarize all the feature requests received on MacOS or iOS";
  const splunkFeatureRequests = "For Copilot for Security, have we received any feature requests for Splunk?";

  const [selectedPrompt, setSelectedPrompt] = useState<string>();
  const promptOptions = [
    { key: "featureRequests", text: featureRequests },
    { key: "capgeminiFeatureRequests", text: capgeminiFeatureRequests },
    { key: "rbacFeatureRequests", text: rbacFeatureRequests },
    { key: "intuneFeatureRequests", text: intuneFeatureRequests },
    { key: "splunkFeatureRequests", text: splunkFeatureRequests }
  ];

  const onPromptSelectionChange = (event: any, option?: any): void => {
    setSelectedPrompt(option.key);
    switch (option.key) {
      case "featureRequests":
        setPromptValue(featureRequests);
        break;
      case "capgeminiFeatureRequests":
        setPromptValue(capgeminiFeatureRequests);
        break;
      case "rbacFeatureRequests":
        setPromptValue(rbacFeatureRequests);
        break;
      case "intuneFeatureRequests":
        setPromptValue(intuneFeatureRequests);
        break;
      case "splunkFeatureRequests":
        setPromptValue(splunkFeatureRequests);
        break;
      default:
        setSelectedPrompt("");
        setPromptValue("");
    }
  };

  const [productComboboxOptions, setProductComboboxOptions] = useState<any>();

  const onCustomerNameComboboxChange = (event: any, option?: any): void => {
    setSelectedCustomerName(option.text);
  };

  const onProductComboboxChange = (event: any, option?: any): void => {
    if (option.key === "none") {
      setSelectedProduct("");
    } else {
      setSelectedProduct(option.key);
    }
  };

  // Scheduled run dropdown settings and handlers
  const [selectedScheduledRunItem, setSelectedScheduledRunItem] = useState<string>("daily");
  const scheduledRunDropdownOptions = [
    { key: "daily", text: "Daily" },
    { key: "weekly", text: "Weekly" },
    { key: "monthly", text: "Monthly" }
  ];
  const onScheduledRunDropdownChange = (event: any, option?: any): void => {
    setSelectedScheduledRunItem(option.key);
  };

  const onLikeButtonClicked = () => {
    setShowLikeButton(false);
    setShowLikeSolidButton(true);

    if (showDislikeSolidButton) {
      setShowDislikeButton(true);
      setShowDislikeSolidButton(false);
    }
  };

  const onDislikeButtonClicked = () => {
    setShowDislikeButton(false);
    setShowDislikeSolidButton(true);

    if (showLikeSolidButton) {
      setShowLikeButton(true);
      setShowLikeSolidButton(false);
    }
  };

  const onLikeSolidButtonClicked = () => {
    setShowLikeButton(true);
    setShowLikeSolidButton(false);
  };

  const onDislikeSolidButtonClicked = () => {
    setShowDislikeButton(true);
    setShowDislikeSolidButton(false);
  };

  const submitButtonClicked = () => {
    setLoading(true);
    setResponseTextValue("");
    setShowEmojiButtons(false);

    const loginName = sessionStorage.getItem("loginUserName")
      ? sessionStorage.getItem("loginUserName")?.split("@")[0]
      : undefined;

    const feedbackInsightsRequestPayload: IFeedbackInsightsRequest = {
      logDate: new Date(),
      category: "feedback",
      schedule: "",
      alias: loginName,
      question: promptValue,
      customerName: selectedCustomerName,
      productName: selectedProduct
    };

    logEvent(
      "AI Playground - Feedback Insights Submit Button CLicked",
      "AI Playground - Feedback Insights Page",
      "FeedbackInsights"
    );
    (async () => {
      const feedbackInsightsResponse = await feedbackInsightsData(feedbackInsightsRequestPayload);
      setLoading(false);
      setResponseTextValue(feedbackInsightsResponse);

      // Reset like/dislike buttons
      setShowEmojiButtons(true);
      setShowLikeButton(true);
      setShowDislikeButton(true);
      setShowLikeSolidButton(false);
      setShowDislikeSolidButton(false);

      // Send API request again to save the request payload
      // Logging call is done here to save the turn around time on previous API request
      // Fire and forget - Do nothing on UI side. Backend will work on logging the request payload
      await feedbackInsightsScheduleMetaData(feedbackInsightsRequestPayload);
    })();
  };

  const scheduleButtonCLicked = () => {
    const loginName = sessionStorage.getItem("loginUserName")
      ? sessionStorage.getItem("loginUserName")?.split("@")[0]
      : undefined;

    const feedbackInsightsRequestPayload: IFeedbackInsightsRequest = {
      logDate: new Date(),
      category: "feedback",
      schedule: selectedScheduledRunItem,
      alias: loginName,
      question: promptValue,
      customerName: selectedCustomerName,
      productName: selectedProduct
    };

    logEvent(
      "AI Playground - Feedback Insights Schedule Button CLicked",
      "AI Playground - Feedback Insights Page",
      "FeedbackInsights"
    );
    (async () => {
      await feedbackInsightsScheduleMetaData(feedbackInsightsRequestPayload);
      setscheduleSuccess(true);
      setTimeout(() => {
        setscheduleSuccess(false);
      }, 3000);
    })();
  };

  const loadAllProducts = async () => {
    const allProducts = await getProducts();
    const options: any = [];
    options.push({
      key: "none",
      text: "None"
    });
    if (allProducts.length !== 0) {
      allProducts.Result.map((ele: any) => {
        options.push({
          key: ele.ProductName,
          text: ele.ProductName
        });
      });
    }
    setProductComboboxOptions(options);
  };

  useEffect(() => {
    logPageView("AI Playground - Feedback Insights Page", window.location.href);
    loadAllProducts();
  }, []);

  return (
    <>
      <div className={styles.feedbackInsightsContainer}>
        <div className={commonAIPlaygroundStyles.dropDownContainerStyles}>
          <ComboBox
            label="Product"
            options={productComboboxOptions}
            onChange={onProductComboboxChange}
            className={commonAIPlaygroundStyles.combobox}
            placeholder="Select a product"
            allowFreeInput
            autoComplete="on"
          />
          <ComboBox
            label="Customer"
            options={customerComboboxOptions}
            onChange={onCustomerNameComboboxChange}
            className={commonAIPlaygroundStyles.combobox}
            placeholder="Select a customer"
            allowFreeInput
            autoComplete="on"
          />
        </div>

        <div className={commonAIPlaygroundStyles.searchLabelAndBox}>
          <Dropdown
            label="Suggested Prompts"
            selectedKey={selectedPrompt ? selectedPrompt : undefined}
            onChange={onPromptSelectionChange}
            placeholder="Select an option"
            options={promptOptions}
            className={commonAIPlaygroundStyles.dropDown}
          />
        </div>

        <div className={commonAIPlaygroundStyles.labelStyles}>
          <div className={commonAIPlaygroundStyles.promptAndButtonGroup}>
            <TextField
              label="Custom Prompt"
              multiline
              rows={2}
              className={commonAIPlaygroundStyles.promptTextBoxStyles}
              placeholder="Please enter your question here"
              value={promptValue}
              onChange={(event: any) => {
                setPromptValue(event.target.value);
              }}
            />
            <PrimaryButton
              text="Submit"
              className={commonAIPlaygroundStyles.submitButtonStyles}
              onClick={submitButtonClicked}
            />
          </div>
          <div className={commonAIPlaygroundStyles.responseAndScheduleRunGroup}>
            <TextField
              label="Response"
              multiline
              rows={15}
              autoAdjustHeight
              scrollContainerRef={containerRef}
              className={commonAIPlaygroundStyles.textBoxStyles}
              value={responseTextValue}
            />
            <div className={commonAIPlaygroundStyles.spinnerStyles}>
              {loading && <Spinner label="Hang tight! Something awesome is getting cooked..." />}
            </div>
            <div className={commonAIPlaygroundStyles.scheduleItemsStyles}>
              {showEmojiButtons && (
                <Label className={commonAIPlaygroundStyles.likeButtonLabel}>Do you like the response?</Label>
              )}
              {showEmojiButtons && (
                <div className={commonAIPlaygroundStyles.likeDislikeButtonContainerStyles}>
                  {showLikeButton && (
                    <DefaultButton
                      title="Like"
                      ariaLabel="likeEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onLikeButtonClicked}
                    >
                      <FontIcon
                        aria-label="LikeButton"
                        iconName="Like"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                  {showLikeSolidButton && (
                    <DefaultButton
                      title="LikeSolid"
                      ariaLabel="likeSolidEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onLikeSolidButtonClicked}
                    >
                      <FontIcon
                        aria-label="LikeSolidButton"
                        iconName="LikeSolid"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                  {showDislikeButton && (
                    <DefaultButton
                      title="Dislike"
                      ariaLabel="dislikeEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onDislikeButtonClicked}
                    >
                      <FontIcon
                        aria-label="DislikeButton"
                        iconName="Dislike"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                  {showDislikeSolidButton && (
                    <DefaultButton
                      title="DislikeSolid"
                      ariaLabel="dislikeSolidEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onDislikeSolidButtonClicked}
                    >
                      <FontIcon
                        aria-label="DislikeSolidButton"
                        iconName="DislikeSolid"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                </div>
              )}
              <Label className={commonAIPlaygroundStyles.scheduleChoiceGroupLabel}>
                <Stack horizontal verticalAlign="center">
                  <span>Schedule this prompt?&nbsp;&nbsp;</span>
                  <TooltipHost content={toolTipMessage}>
                    <FontIcon className={commonAIPlaygroundStyles.tooltipStyles} aria-label="tooltip" iconName="Info" />
                  </TooltipHost>
                </Stack>
              </Label>
              <ChoiceGroup
                defaultSelectedKey="daily"
                className={commonAIPlaygroundStyles.scheduledRun}
                options={scheduledRunDropdownOptions}
                onChange={onScheduledRunDropdownChange}
              />
              <PrimaryButton
                text="Schedule"
                className={commonAIPlaygroundStyles.scheduleButtonStyles}
                onClick={scheduleButtonCLicked}
              />
              {scheduleSuccess && (
                <MessageBar
                  className={commonAIPlaygroundStyles.scheduleMessageStyles}
                  messageBarType={MessageBarType.success}
                  isMultiline={false}
                >
                  Successfully scheduled.
                </MessageBar>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
