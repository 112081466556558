import { IShimmerStyles, IStyle, Shimmer, mergeStyleSets, mergeStyles } from "@fluentui/react";
import React from "react";

interface CustomShimmerProps {
  columnCount?: number;
  rowCount?: number;
  shimmerStyles?: IShimmerStyles;
  styles?: {
    root?: IStyle,
    articleContainer?: IStyle;
    lineContainer?: IStyle;
    lineContainerWrapper?: IStyle;
    articleWrapper?: IStyle;
  };
}

export const CustomShimmer = (props: CustomShimmerProps) => {
  const columnsCount = props.columnCount ?? 3;
  const rowCount = props.rowCount ?? 10;
  const classNames = mergeStyleSets({
    wrapper: {
      selectors: {
        "& > .ms-Shimmer-container": {
          margin: "10px 0"
        }
      }
    }
  });
  return (
    <div className={`container flex ${mergeStyles(props.styles?.root)}`} style={{ display: "flex" }}>
      {[...Array(columnsCount)].map((element, columnIndex) => (
        <article className={mergeStyles(props.styles?.articleContainer)} key={columnIndex}>
          {[...Array(rowCount)].map((element, index) => (
            <div className={`line ${mergeStyles(props.styles?.lineContainer)}`} key={index}>
              <div className={`${classNames.wrapper} ${mergeStyles(props.styles?.lineContainer)}`}>
                <Shimmer styles={getShimmerStyles(props.shimmerStyles)} />
              </div>
            </div>
          ))}
          <div className={`${classNames.wrapper} ${mergeStyles(props.styles?.lineContainer)}`}>
            <Shimmer styles={getShimmerStyles(props.shimmerStyles)} />
          </div>
        </article>
      ))}
    </div>
  );
};

const getShimmerStyles = (styles?: IShimmerStyles): IShimmerStyles => {
  return {
    shimmerWrapper: [
      {
        backgroundColor: "#deecf9",
        borderRadius: "10px",
        height: "12px"
      }
    ],
    shimmerGradient: [
      {
        backgroundColor: "#deecf9",
        backgroundImage:
          "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #c7e0f4 50%, rgba(255, 255, 255, 0) 100%)"
      }
    ],
    ...styles
  };
};
