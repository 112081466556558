import { Panel, PanelType } from "@fluentui/react";
import React from "react";
import DomainDataGrid from "./DomainDataGrid";

const DomainPanel = (props: any) => {
  return (
    <Panel isOpen={props.isOpen} onDismiss={() => props.dismissPanel(false)} type={PanelType.large} isLightDismiss>
      <DomainDataGrid tenantName={props.tenantName} />
    </Panel>
  );
};

export default DomainPanel;
