import { IBreadcrumbItem } from "@fluentui/react";
import { Divider, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeComponent from "../../Home/HomeComponent";
import { PRODUCT_VIEW_STYLES } from "./ProductView.styles";
import { CustomBreadcrumb } from "../../../SharedComponents/CustomBreadcrumb";


export const ProductList = () => {
  const navigate = useNavigate();
  const breadcrumbProps = {
    items: [
      { text: "Home", key: "explore-products-to-home", onClick: () => navigate("/") },
      { text: "Products", key: "explore-products-list" }
    ] as IBreadcrumbItem[],
    styles: PRODUCT_VIEW_STYLES.PRODUCT_PAGE.CSS_STYLES.productViewCustomBreadcrumb
  };

  return (
    <Paper className={PRODUCT_VIEW_STYLES.PRODUCT_PAGE.CLASS_NAMES.productPageContainer}>
      <CustomBreadcrumb {...breadcrumbProps} />
      <Divider />
      <HomeComponent />
    </Paper>
  );
};
