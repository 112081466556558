import { AuthenticationResult } from "@azure/msal-browser";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IMsalAuthState {
  token: AuthenticationResult | null;
  graphToken?: string;
  powerbiToken?: string;
  error: string | null;
  loaded: boolean;
}

const initialState: IMsalAuthState = {
  token: null,
  error: null,
  loaded: false
};

const msalSlice = createSlice({
  name: "msal",
  initialState,
  reducers: {
    updateAuthResult(state, { payload }: PayloadAction<AuthenticationResult>) {
      state.token = payload;
      state.loaded = true;
    },
    updateGraphToken(state, { payload }: PayloadAction<string>) {
      state.graphToken = payload;
    },
    updatePbiToken(state, { payload }: PayloadAction<string>) {
      state.powerbiToken = payload;
    }
  }
});

export const { updateAuthResult, updateGraphToken, updatePbiToken } = msalSlice.actions;
export default msalSlice.reducer;
