import { INavLinkGroup, Nav } from "@m365-admin/nav";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function AdminNav(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const decideColor = (paths: string[]) => ({
    selected: paths.includes(location.pathname),
    color: paths.includes(location.pathname) ? "lightcyan" : ""
  });

  const navLinkGroups: INavLinkGroup[] = [
    {
      key: "HomeGroup",
      links: [
        {
          name: "Home",

          icon: "Home",
          key: "HomeLink1",
          ariaLabel: "home",

          isSelected: decideColor(["/"]).selected,
          styles: {
            icon: {
              width: 15,
              height: 15,
              fontSize: 13
            },
            root: {
              "&:hover": {
                backgroundColor: "#F2F2F2"
              }
            }
          },
          style: {
            fontSize: 13,
            fontWeight: 400,
            backgroundColor: decideColor(["/"]).color
          },

          "data-id": "automation_id_22230",
          onClick: () => navigate("/")
        },
        {
          name: "Customer Search",
          icon: "SecurityGroup",
          key: "Customer360",

          ariaLabel: "Customer Search",
          isSelected: decideColor(["/customersearch", "/customer"]).selected,
          //  "data-id": "automation_id_22230",
          styles: {
            icon: {
              width: 15,
              height: 15,
              fontSize: 13
            },
            root: {
              "&:hover": {
                backgroundColor: "#F2F2F2"
              }
            }
          },
          style: {
            fontSize: 13,
            fontWeight: 400,
            backgroundColor: decideColor(["/customersearch", "/customer"]).color
          },
          onClick: () => navigate("/customersearch")
        },
        {
          name: "Product View",
          icon: "DocumentSearch",
          key: "Product360",
          ariaLabel: "Product view",
          isSelected: decideColor(["/productview"]).selected,
          "data-id": "automation_id_22230",
          styles: {
            icon: {
              width: 15,
              height: 15,
              fontSize: 13
            },
            root: {
              "&:hover": {
                backgroundColor: "#F2F2F2"
              }
            }
          },
          style: {
            fontSize: 13,
            fontWeight: 400,
            backgroundColor: decideColor(["/productview"]).color
          },
          onClick: () => navigate("/productview")
        },
        {
          name: "Favorite Customers",
          icon: "Favoritelist",
          key: "MyCustomers",
          ariaLabel: "MyCustomers ",
          styles: {
            icon: {
              width: 15,
              height: 15,
              fontSize: 13
            },
            root: {
              "&:hover": {
                backgroundColor: "#F2F2F2"
              }
            }
          },
          isSelected: decideColor(["/favoritecustomers"]).selected,
          style: {
            fontSize: 13,
            fontWeight: 400,
            backgroundColor: decideColor(["/favoritecustomers"]).color
          },

          onClick: () => navigate("/favoritecustomers")
        },
        {
          name: "Report Catalog",
          icon: "GlobalNavButton",
          key: "ReportCatalog",
          ariaLabel: "ReportCatalog",
          id: "ReportCatalog",
          isSelected: decideColor(["/reportcatalog"]).selected,
          styles: {
            icon: {
              width: 15,
              height: 15,
              fontSize: 13
            },
            root: {
              "&:hover": {
                backgroundColor: "#F2F2F2"
              }
            }
          },
          style: {
            fontSize: 13,
            fontWeight: 400,
            backgroundColor: decideColor(["/reportcatalog"]).color
          },
          onClick: () => navigate("/reportcatalog")
        },
        {
          name: "Raise a Request",
          icon: "FeedbackRequestSolid",
          key: "RaiseRequest",
          ariaLabel: "Raise a Request",
          styles: {
            icon: {
              width: 15,
              height: 15,
              fontSize: 13
            },
            root: {
              "&:hover": {
                backgroundColor: "#F2F2F2"
              }
            }
          },
          isSelected: decideColor(["/Raiserequest"]).selected,
          style: {
            fontSize: 13,
            fontWeight: 400,
            backgroundColor: decideColor(["/Raiserequest"]).color
          },
          onClick: () => window.open("https://aka.ms/askcxeds", "_blank")
        }
      ]
    }
  ];
  return (
    <div>
      {props.isNavCollapsed && (
        <Nav
          groups={navLinkGroups}
          onNavCollapsed={() => {
            return props.onNavCollapsed;
          }}
          defaultIsNavCollapsed={props.isNavCollapsed}
          isNavCollapsed={props.onNavCollapsed}
          enableCustomization={false}
          collapseNavLinkProps={{
            "data-hint": "collapse test",
            title: props.isNavCollapsed ? "Expand Navigation" : "Collapse Navigation",
            primaryIconName: "GlobalNavButton",
            "aria-label": props.isNavCollapsed ? "Expand Navigation" : "Collapse Navigation"
          }}
          styles={{
            root: {
              position: "absolute",
              borderRight: "solid 1px #e1dfdd",
              //  Height: "calc(91.4vw - 100%)",
              height: "calc(100% - 50px)"
            },
            navContainer: {
              backgroundColor: "white",
              borderRight: "solid 1px #e1dfdd"
            }
          }}
          hideCollapseLink
          showMore={false}
        />
      )}
      {!props.isNavCollapsed && (
        <Nav
          groups={navLinkGroups}
          onNavCollapsed={() => {
            return props.onNavCollapsed;
          }}
          collapseNavLinkProps={{
            "data-hint": "collapse test",
            title: props.isNavCollapsed ? "Expand Navigation" : "Collapse Navigation",
            primaryIconName: "GlobalNavButton",
            "aria-label": props.isNavCollapsed ? "Expand Navigation" : "Collapse Navigation"
          }}
          styles={{
            root: {
              position: "absolute",
              borderRight: "solid 1px #e1dfdd",
              //    Height: "calc(91.4vw - 100%)",
              height: "calc(100% - 50px)"
            },
            navContainer: {
              backgroundColor: "white",
              borderRight: "solid 1px #e1dfdd",
              maxWidth: "240px"
            }
          }}
          defaultIsNavCollapsed={props.isNavCollapsed}
          isNavCollapsed={props.onNavCollapsed}
          hideCollapseLink
          showMore={false}
          enableCustomization={false}
        />
      )}
    </div>
  );
}
