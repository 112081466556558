import { CSSProperties } from "react";

export const CUSTOM_LINK_NAVIGATE_STYLES = {
  linkStyle: {
    color: "rgb(0, 120, 212)",
    cursor: "pointer",
    fontSize: 12,
    overflow: "hidden",
    textDecoration: "none",
    textOverflow: "ellipsis"
  } as CSSProperties
};
