import { mergeStyles } from "@fluentui/react";
import { Dispatch, useEffect, useReducer, useState } from "react";
import { logEvent, logException } from "../../helper/AppInsightsHelper";
import { AllFilters } from "../../interfaces/allFilterData";
import { CustomFilterPill } from "../../SharedComponents/CustomFilterPill";
import { useGetFiltersQuery } from "../../slices/Customer.slice";
import { customerFilterPillReducer, mapFilterPillMenuItemsPredicate } from "./CustomerSearch.common";
import { FILTER_PILL } from "./CustomerSearch.constants";
import { filterPillDispatchProps, ReducerType } from "./CustomSearch.interfaces";

export const CustomerFilterPill = (props: any) => {
  const [regionState, regionDispatch] = useReducer(customerFilterPillReducer, FILTER_PILL.INITIAL_STATE);
  const [segmentGroupState, segmentGroupDispatch] = useReducer(customerFilterPillReducer, FILTER_PILL.INITIAL_STATE);
  const [segmentState, segmentDispatch] = useReducer(customerFilterPillReducer, FILTER_PILL.INITIAL_STATE);
  const [subSegmentState, subSegmentDispatch] = useReducer(customerFilterPillReducer, FILTER_PILL.INITIAL_STATE);
  const [industryState, industryDispatch] = useReducer(customerFilterPillReducer, FILTER_PILL.INITIAL_STATE);
  const [hasPartnerState, hasPartnerDispatch] = useReducer(customerFilterPillReducer, FILTER_PILL.INITIAL_STATE);
  const [isFastTrackState, isFastTrackDispatch] = useReducer(customerFilterPillReducer, FILTER_PILL.INITIAL_STATE);
  const [isCATState, isCATDispatch] = useReducer(customerFilterPillReducer, FILTER_PILL.INITIAL_STATE);
  const [isSSTState, isSSTDispatch] = useReducer(customerFilterPillReducer, FILTER_PILL.INITIAL_STATE);
  const [allDataInfo, setAllData] = useState<AllFilters>();
  const { data, error } = useGetFiltersQuery(null);

  const createUpdateHandler = (dispatch: Dispatch<filterPillDispatchProps>) => (items?: string[]) => {
    dispatch({ type: ReducerType.UpdateSelectedMenuItems, data: items || [] });
  };

  const onRegionsUpdateHandler = createUpdateHandler(regionDispatch);
  const onSegmentGroupsUpdateHandler = createUpdateHandler(segmentGroupDispatch);
  const onSegmentsUpdateHandler = createUpdateHandler(segmentDispatch);
  const onSubSegmentsUpdateHandler = createUpdateHandler(subSegmentDispatch);
  const onIndustriesUpdateHandler = createUpdateHandler(industryDispatch);
  const onHasPartnerUpdateHandler = createUpdateHandler(hasPartnerDispatch);
  const onIsFastTracksUpdateHandler = createUpdateHandler(isFastTrackDispatch);
  const onIsCATsUpdateHandler = createUpdateHandler(isCATDispatch);
  const onIsSSTsUpdateHandler = createUpdateHandler(isSSTDispatch);

  useEffect(() => {
    if (data) {
      const segmentValue: Set<string> = new Set();
      const subSegmentValue: Set<string> = new Set();
      const segmentGroupValue: Set<string> = new Set();
      data?.segmentGroups.forEach((group: any) => {
        segmentGroupValue.add(group.segmentGroupName);
        group.segments.forEach((segment: any) => {
          segmentValue.add(segment.segmentName);
          segment.subSegments.forEach((subSegment: string) => {
            subSegmentValue.add(subSegment);
          });
        });
      });
      setAllData(data);
      regionDispatch({
        type: ReducerType.LoadMenuItems,
        data: ((data?.regionNames as string[]) || []).map(mapFilterPillMenuItemsPredicate)
      });
      segmentDispatch({
        type: ReducerType.LoadMenuItems,
        data: Array.from(segmentValue).map(mapFilterPillMenuItemsPredicate)
      });
      subSegmentDispatch({
        type: ReducerType.LoadMenuItems,
        data: Array.from(subSegmentValue).map(mapFilterPillMenuItemsPredicate)
      });
      segmentGroupDispatch({
        type: ReducerType.LoadMenuItems,
        data: Array.from(segmentGroupValue).map(mapFilterPillMenuItemsPredicate)
      });
      industryDispatch({
        type: ReducerType.LoadMenuItems,
        data: ((data?.industryNames as string[]) || []).map(mapFilterPillMenuItemsPredicate)
      });
      hasPartnerDispatch({
        type: ReducerType.LoadMenuItems,
        data: FILTER_PILL.INITIAL_FILTER_VALUES
      });
      isFastTrackDispatch({ type: ReducerType.LoadMenuItems, data: FILTER_PILL.INITIAL_FILTER_VALUES });
      isCATDispatch({ type: ReducerType.LoadMenuItems, data: FILTER_PILL.INITIAL_FILTER_VALUES });
      isSSTDispatch({ type: ReducerType.LoadMenuItems, data: FILTER_PILL.INITIAL_FILTER_VALUES });
      logEvent("useGetFiltersQuery API call success", "Product View", "FilterPillProduct");
    }
    if (error) {
      logException("Customer Search", "FilterPillComponent", "useGetFiltersQuery", error);
    }
  }, [data]);

  useEffect(() => {
    let newRegionValue: string = "";
    regionState.selectedMenuItems.forEach((element: string) => {
      newRegionValue = element + ";" + newRegionValue;
    });
    let newSegmentValue: string = "";
    segmentState.selectedMenuItems.forEach((element: string) => {
      newSegmentValue = element + ";" + newSegmentValue;
    });
    let newSegmentGroupValue: string = "";
    segmentGroupState.selectedMenuItems.forEach((element: string) => {
      newSegmentGroupValue = element + ";" + newSegmentGroupValue;
    });
    if (
      (!segmentGroupState.selectedMenuItems || segmentGroupState.selectedMenuItems?.length === 0) &&
      (!segmentState.selectedMenuItems || segmentState.selectedMenuItems?.length === 0)
    ) {
      const segmentValue: Set<string> = new Set();
      const subSegmentValue: Set<string> = new Set();
      const segmentGroupValue: Set<string> = new Set();
      data?.segmentGroups?.forEach((group: any) => {
        segmentGroupValue.add(group.segmentGroupName);
        group.segments.forEach((segment: any) => {
          segmentValue.add(segment.segmentName);
          segment.subSegments.forEach((subSegment: string) => {
            subSegmentValue.add(subSegment);
          });
        });
      });
      segmentDispatch({
        type: ReducerType.LoadMenuItems,
        data: Array.from(segmentValue).map(mapFilterPillMenuItemsPredicate)
      });
      subSegmentDispatch({
        type: ReducerType.LoadMenuItems,
        data: Array.from(subSegmentValue).map(mapFilterPillMenuItemsPredicate)
      });
      segmentGroupDispatch({
        type: ReducerType.LoadMenuItems,
        data: Array.from(segmentGroupValue).map(mapFilterPillMenuItemsPredicate)
      });
    } else {
      const filteredSegments: Set<string> = new Set();
      const filteredSubSegments: Set<string> = new Set();
      if (segmentGroupState.selectedMenuItems && segmentGroupState.selectedMenuItems.length > 0) {
        allDataInfo?.segmentGroups.forEach((group: any) => {
          if (segmentGroupState.selectedMenuItems.includes(group.segmentGroupName)) {
            group.segments.forEach((seg: any) => {
              filteredSegments.add(seg.segmentName);
              if (segmentState.selectedMenuItems && segmentState.selectedMenuItems.length > 0) {
                if (segmentState.selectedMenuItems.includes(seg.segmentName)) {
                  seg.subSegments.forEach((subSeg: string) => {
                    filteredSubSegments.add(subSeg);
                  });
                }
              } else {
                seg.subSegments.forEach((subSeg: string) => {
                  filteredSubSegments.add(subSeg);
                });
              }
            });
          }
        });
      }

      if (
        (!segmentGroupState.selectedMenuItems || segmentGroupState.selectedMenuItems.length === 0) &&
        segmentState.selectedMenuItems &&
        segmentState.selectedMenuItems.length > 0
      ) {
        allDataInfo?.segmentGroups.forEach((group: any) => {
          group.segments.forEach((seg: any) => {
            filteredSegments.add(seg.segmentName);
            if (segmentState.selectedMenuItems.includes(seg.segmentName)) {
              seg.subSegments.forEach((subSeg: string) => {
                filteredSubSegments.add(subSeg);
              });
            }
          });
        });
      }
      segmentDispatch({
        type: ReducerType.LoadMenuItems,
        data: Array.from(filteredSegments).map(mapFilterPillMenuItemsPredicate)
      });
      subSegmentDispatch({
        type: ReducerType.LoadMenuItems,
        data: Array.from(filteredSubSegments).map(mapFilterPillMenuItemsPredicate)
      });
    }
    let newSubSegmentValue: string = "";
    subSegmentState.selectedMenuItems.forEach((element) => {
      newSubSegmentValue = element + ";" + newSubSegmentValue;
    });
    let newIndustryValue: string = "";
    industryState.selectedMenuItems.forEach((element) => {
      newIndustryValue = element + ";" + newIndustryValue;
    });
    let newPartnerValue: string = "";
    hasPartnerState.selectedMenuItems.forEach((element: string | string[]) => {
      newPartnerValue = hasPartnerState.selectedMenuItems.length > 1 ? "" : element.includes("Yes") ? "1" : "0";
    });
    let newIsSstValue: string = "";
    isSSTState.selectedMenuItems.forEach((element: string | string[]) => {
      newIsSstValue = isSSTState.selectedMenuItems.length > 1 ? "" : element.includes("Yes") ? "1" : "0";
    });
    let newIsCatValue: string = "";
    isCATState.selectedMenuItems.forEach((element: string | string[]) => {
      newIsCatValue = isCATState.selectedMenuItems.length > 1 ? "" : element.includes("Yes") ? "1" : "0";
    });
    let newFastTrackValue: string = "";
    isFastTrackState.selectedMenuItems.forEach((element: string | string[]) => {
      newFastTrackValue = isFastTrackState.selectedMenuItems.length > 1 ? "" : element.includes("Yes") ? "1" : "0";
    });
    props.onRegionChange(newRegionValue);
    props.onSegmentGroupChange(newSegmentGroupValue);
    props.onSegmentChange(newSegmentValue);
    props.onSubSegmentChange(newSubSegmentValue);
    props.onIndustryChange(newIndustryValue);
    props.onFastTrackChange(newFastTrackValue);
    props.onHasPartnerChange(newPartnerValue);
    props.onIsCatChange(newIsCatValue);
    props.onIsSstChange(newIsSstValue);
  }, [
    regionState.selectedMenuItems,
    segmentGroupState.selectedMenuItems,
    segmentState.selectedMenuItems,
    subSegmentState.selectedMenuItems,
    industryState.selectedMenuItems,
    hasPartnerState.selectedMenuItems,
    isFastTrackState.selectedMenuItems,
    isCATState.selectedMenuItems,
    isSSTState.selectedMenuItems
  ]);

  return (
    <div
      className={mergeStyles({
        margin: "-4px",
        display: "flex",
        flexWrap: "wrap"
      })}
    >
      {FILTER_PILL.INITIAL_DATA.map((filterPillProps) => {
        const { key } = filterPillProps;
        const stateMapping: any = {
          regions: { state: regionState, handler: onRegionsUpdateHandler },
          segmentGroup: { state: segmentGroupState, handler: onSegmentGroupsUpdateHandler },
          segments: { state: segmentState, handler: onSegmentsUpdateHandler },
          subSegments: { state: subSegmentState, handler: onSubSegmentsUpdateHandler },
          industry: { state: industryState, handler: onIndustriesUpdateHandler },
          hasPartner: { state: hasPartnerState, handler: onHasPartnerUpdateHandler },
          isFastTrackStrategic: { state: isFastTrackState, handler: onIsFastTracksUpdateHandler },
          isCAT: { state: isCATState, handler: onIsCATsUpdateHandler },
          isSST: { state: isSSTState, handler: onIsSSTsUpdateHandler }
        };

        if (key !== undefined) {
          const mapping = stateMapping[key];
          filterPillProps.menuProps = {
            focusZoneProps: {
              /* Allow up and down arrows to move focus out of the SearchBox */
              shouldInputLoseFocusOnArrowKey: () => true
            },
            items: mapping.state.menuItems
          };
          filterPillProps.onApply = mapping.handler;
          filterPillProps.onCancel = mapping.handler;
          filterPillProps.onResetFilters = () => mapping.handler([]);
        }

        return <CustomFilterPill {...filterPillProps} key={filterPillProps.key} />;
      })}
    </div>
  );
};
