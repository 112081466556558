import {
  ChoiceGroup,
  DefaultButton,
  FontIcon,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
  TooltipHost,
  mergeStyleSets
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { Dropdown, DropdownMenuItemType } from "@fluentui/react/lib/Dropdown";
import { ISupportInsightsRequest } from "../../ServiceModels/CustomerService-Models";
import { supportInsightsData, supportInsightsScheduleMetaData } from "../../Services/customerServices";
import { commonAIPlaygroundStyles } from "./AIPlayground-Common.Styles";
import { logEvent, logPageView } from "../../helper/AppInsightsHelper";

const styles = mergeStyleSets({
  supportDataAIPlaygroundContainer: {
    width: 1500,
    border: "1px solid black",
    margin: "30px 30px 30px 30px"
  }
});

export const SupportInsights = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [promptValue, setPromptValue] = useState("");
  const [responseTextValue, setResponseTextValue] = useState("");
  const [responseMetricsValue, setResponseMetricsValue] = useState("");
  const [isTextualResponse, setIsTextualResponse] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);
  const [scheduleSuccess, setscheduleSuccess] = useState<boolean>(false);

  const [showEmojiButtons, setShowEmojiButtons] = useState<boolean>(false);
  const [showLikeButton, setShowLikeButton] = useState<boolean>(true);
  const [showDislikeButton, setShowDislikeButton] = useState<boolean>(true);
  const [showLikeSolidButton, setShowLikeSolidButton] = useState<boolean>(false);
  const [showDislikeSolidButton, setShowDislikeSolidButton] = useState<boolean>(false);

  const toolTipMessage =
    "Schedule prompt-based report deliveries according to your selected frequency, reports will be sent at 1 AM UTC";

  // Metrics selected prompts
  const totalCases = "Split total cases with rating = 1 or 2 by fiscal week, fiscal year and max severity";
  const percentageCases = "Give me % cases split by source system for fiscal week 41 2024";
  const totalCasesForSev1 = "Total cases for Sev = 1 or A based on created fiscal week";
  const weeklyTIC =
    "Show me the weekly TIC 75th percentile based on fiscal week only for case where max severity is 1 or A and enteprise support offering";
  const fiscalWeekInsights = "Give me some useful insights for fiscal week 41 of 2024";
  const totalDsatCases = "Tell me the total dsat cases by fiscal week";
  const avgCostCsatCases =
    "What is the average csat for cases with severity = 1 and 'A' for fiscal week 32, 33, 34 and 35 2024?";

  // Textual selected prompts
  const linuxRhel = "What is the problem with Linux RHEL support cases? Also, suggest what can be a solution for this";
  const summarizeCases = "Summarize cases";
  const mdeSupportCases = "Classify the MDE support cases based on the problem into different categories.";
  const categorizeClusters = "Categorize the cases into clusters L1 and L2 based on the specific problem areas.";
  const autopilotProblem = "Tell me the problem with Autopilot and what could be the resolution for it?";

  const [selectedPrompt, setSelectedPrompt] = useState<string>();
  const promptOptions = [
    {
      key: "textual",
      text: "Textual",
      itemType: DropdownMenuItemType.Header
    },
    { key: "linuxRhel", text: linuxRhel },
    { key: "summarizeCases", text: summarizeCases },
    { key: "mdeSupportCases", text: mdeSupportCases },
    { key: "categorizeClusters", text: categorizeClusters },
    { key: "autopilotProblem", text: autopilotProblem },
    {
      key: "metrics",
      text: "Metrics",
      itemType: DropdownMenuItemType.Header
    },
    { key: "totalCases", text: totalCases },
    { key: "percentageCases", text: percentageCases },
    { key: "totalCasesForSev1", text: totalCasesForSev1 },
    { key: "weeklyTIC", text: weeklyTIC },
    { key: "fiscalWeekInsights", text: fiscalWeekInsights },
    { key: "totalDsatCases", text: totalDsatCases },
    { key: "avgCostCsatCases", text: avgCostCsatCases }
  ];

  const onPromptSelectionChange = (event: any, option?: any): void => {
    setSelectedPrompt(option.key);
    switch (option.key) {
      case "linuxRhel":
        setPromptValue(linuxRhel);
        setSelectedAnalyticsTypeItem("textual");
        break;
      case "summarizeCases":
        setPromptValue(summarizeCases);
        setSelectedAnalyticsTypeItem("textual");
        break;
      case "mdeSupportCases":
        setPromptValue(mdeSupportCases);
        setSelectedAnalyticsTypeItem("textual");
        break;
      case "categorizeClusters":
        setPromptValue(categorizeClusters);
        setSelectedAnalyticsTypeItem("textual");
        break;
      case "autopilotProblem":
        setPromptValue(autopilotProblem);
        setSelectedAnalyticsTypeItem("textual");
        break;
      case "totalCases":
        setPromptValue(totalCases);
        setSelectedAnalyticsTypeItem("metrics");
        break;
      case "percentageCases":
        setPromptValue(percentageCases);
        setSelectedAnalyticsTypeItem("metrics");
        break;
      case "totalCasesForSev1":
        setPromptValue(totalCasesForSev1);
        setSelectedAnalyticsTypeItem("metrics");
        break;
      case "weeklyTIC":
        setPromptValue(weeklyTIC);
        setSelectedAnalyticsTypeItem("metrics");
        break;
      case "fiscalWeekInsights":
        setPromptValue(fiscalWeekInsights);
        setSelectedAnalyticsTypeItem("metrics");
        break;
      case "totalDsatCases":
        setPromptValue(totalDsatCases);
        setSelectedAnalyticsTypeItem("metrics");
        break;
      case "avgCostCsatCases":
        setPromptValue(avgCostCsatCases);
        setSelectedAnalyticsTypeItem("metrics");
        break;
      default:
        setSelectedPrompt("");
        setPromptValue("");
    }
  };

  // Analytics type dropdown settings and handlers
  const [selectedAnalyticsTypeItem, setSelectedAnalyticsTypeItem] = useState<string>();
  const analyticsTypeDropdownOptions = [
    { key: "textual", text: "Textual" },
    { key: "metrics", text: "Metrics" }
  ];
  const onAnalyticsTypeDropdownChange = (event: any, option?: any): void => {
    setSelectedAnalyticsTypeItem(option.key);
  };

  // Product dropdown settings and handlers
  const [selectedProductItem, setSelectedProductItem] = useState<string>();
  const productDropdownOptions = [
    { key: "identity", text: "Identity" },
    { key: "intune", text: "Intune" },
    { key: "mde", text: "MDE" },
    { key: "sentinel", text: "Sentinel" },
    { key: "mda", text: "MDA" },
    { key: "compliance", text: "Compliance" },
    { key: "mdi", text: "MDI" },
    { key: "mdo", text: "MDO" },
    { key: "ediscovery", text: "eDiscovery" }
  ];
  const onProductDropdownChange = (event: any, option?: any): void => {
    setSelectedProductItem(option.key);
  };

  // Scheduled run dropdown settings and handlers
  const [selectedScheduledRunItem, setSelectedScheduledRunItem] = useState<string>("daily");
  const scheduledRunDropdownOptions = [
    { key: "daily", text: "Daily" },
    { key: "weekly", text: "Weekly" },
    { key: "monthly", text: "Monthly" }
  ];
  const onScheduledRunDropdownChange = (event: any, option?: any): void => {
    setSelectedScheduledRunItem(option.key);
  };

  const onLikeButtonClicked = () => {
    setShowLikeButton(false);
    setShowLikeSolidButton(true);

    if (showDislikeSolidButton) {
      setShowDislikeButton(true);
      setShowDislikeSolidButton(false);
    }
  };

  const onDislikeButtonClicked = () => {
    setShowDislikeButton(false);
    setShowDislikeSolidButton(true);

    if (showLikeSolidButton) {
      setShowLikeButton(true);
      setShowLikeSolidButton(false);
    }
  };

  const onLikeSolidButtonClicked = () => {
    setShowLikeButton(true);
    setShowLikeSolidButton(false);
  };

  const onDislikeSolidButtonClicked = () => {
    setShowDislikeButton(true);
    setShowDislikeSolidButton(false);
  };

  const submitButtonClicked = () => {
    setLoading(true);
    setResponseTextValue("");
    setResponseMetricsValue("");
    setShowEmojiButtons(false);

    const loginName = sessionStorage.getItem("loginUserName")
      ? sessionStorage.getItem("loginUserName")?.split("@")[0]
      : undefined;

    const supportInsightsRequestPayload: ISupportInsightsRequest = {
      logDate: new Date(),
      category: selectedAnalyticsTypeItem,
      schedule: "",
      alias: loginName,
      question: promptValue,
      product: selectedProductItem
    };

    logEvent(
      "AI Playground - Support Insights Submit Button CLicked",
      "AI Playground - Support Insights Page",
      "SupportInsights"
    );
    (async () => {
      const supportInsightsResponse = await supportInsightsData(supportInsightsRequestPayload);
      setLoading(false);

      if (selectedAnalyticsTypeItem === "textual") {
        setResponseTextValue(supportInsightsResponse);
      } else if (selectedAnalyticsTypeItem === "metrics") {
        setResponseMetricsValue(supportInsightsResponse);
        setIsTextualResponse(false);
      }
      // Reset like/dislike buttons
      setShowEmojiButtons(true);
      setShowLikeButton(true);
      setShowDislikeButton(true);
      setShowLikeSolidButton(false);
      setShowDislikeSolidButton(false);

      // Send API request again to save the request payload
      // Logging call is done here to save the turn around time on previous API request
      // Fire and forget - Do nothing on UI side. Backend will work on logging the request payload
      await supportInsightsScheduleMetaData(supportInsightsRequestPayload);
    })();
  };

  const scheduleButtonCLicked = () => {
    const loginName = sessionStorage.getItem("loginUserName")
      ? sessionStorage.getItem("loginUserName")?.split("@")[0]
      : undefined;

    const supportInsightsRequestPayload: ISupportInsightsRequest = {
      logDate: new Date(),
      category: selectedAnalyticsTypeItem,
      schedule: selectedScheduledRunItem,
      alias: loginName,
      question: promptValue,
      product: selectedProductItem
    };

    logEvent(
      "AI Playground - Support Insights Schedule Button CLicked",
      "AI Playground - Support Insights Page",
      "SupportInsights"
    );
    (async () => {
      await supportInsightsScheduleMetaData(supportInsightsRequestPayload);
      setscheduleSuccess(true);
      setTimeout(() => {
        setscheduleSuccess(false);
      }, 3000);
    })();
  };

  useEffect(() => {
    logPageView("AI Playground - Support Insights Page", window.location.href);
  }, []);

  return (
    <>
      <div className={styles.supportDataAIPlaygroundContainer}>
        <div className={commonAIPlaygroundStyles.dropDownContainerStyles}>
          <Dropdown
            label="Analytics Type"
            selectedKey={selectedAnalyticsTypeItem ? selectedAnalyticsTypeItem : undefined}
            onChange={onAnalyticsTypeDropdownChange}
            placeholder="Select analytics type for the prompt"
            options={analyticsTypeDropdownOptions}
            className={commonAIPlaygroundStyles.dropDown}
          />
          <Dropdown
            label="Product"
            selectedKey={selectedProductItem ? selectedProductItem : undefined}
            onChange={onProductDropdownChange}
            placeholder="Select a product"
            options={productDropdownOptions}
            className={commonAIPlaygroundStyles.dropDown}
          />
        </div>

        <div className={commonAIPlaygroundStyles.searchLabelAndBox}>
          <Dropdown
            label="Suggested Prompts"
            selectedKey={selectedPrompt ? selectedPrompt : undefined}
            onChange={onPromptSelectionChange}
            placeholder="Select an option"
            options={promptOptions}
            className={commonAIPlaygroundStyles.dropDown}
          />
        </div>

        <div className={commonAIPlaygroundStyles.labelStyles}>
          <div className={commonAIPlaygroundStyles.promptAndButtonGroup}>
            <TextField
              label="Custom Prompt"
              multiline
              rows={2}
              className={commonAIPlaygroundStyles.promptTextBoxStyles}
              placeholder="Please enter your question here"
              value={promptValue}
              onChange={(event: any) => {
                setSelectedPrompt("Custom Prompt");
                setPromptValue(event.target.value);
              }}
            />
            <PrimaryButton
              text="Submit"
              className={commonAIPlaygroundStyles.submitButtonStyles}
              onClick={submitButtonClicked}
            />
          </div>
          <div className={commonAIPlaygroundStyles.responseAndScheduleRunGroup}>
            {!isTextualResponse && (
              <TextField
                label="Response"
                multiline
                rows={15}
                autoAdjustHeight
                scrollContainerRef={containerRef}
                className={commonAIPlaygroundStyles.textBoxStyles}
                onRenderPrefix={() => (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: responseMetricsValue
                    }}
                    style={{
                      width: "978px",
                      height: "300px",
                      overflow: "scroll",
                      alignContent: "center"
                    }}
                  />
                )}
              />
            )}
            {isTextualResponse && (
              <TextField
                label="Response"
                multiline
                rows={15}
                autoAdjustHeight
                scrollContainerRef={containerRef}
                className={commonAIPlaygroundStyles.textBoxStyles}
                value={responseTextValue}
              />
            )}
            <div className={commonAIPlaygroundStyles.spinnerStyles}>
              {loading && <Spinner label="Hang tight! Something awesome is getting cooked..." />}
            </div>
            <div className={commonAIPlaygroundStyles.scheduleItemsStyles}>
              {showEmojiButtons && (
                <Label className={commonAIPlaygroundStyles.likeButtonLabel}>Do you like the response?</Label>
              )}
              {showEmojiButtons && (
                <div className={commonAIPlaygroundStyles.likeDislikeButtonContainerStyles}>
                  {showLikeButton && (
                    <DefaultButton
                      title="Like"
                      ariaLabel="likeEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onLikeButtonClicked}
                    >
                      <FontIcon
                        aria-label="LikeButton"
                        iconName="Like"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                  {showLikeSolidButton && (
                    <DefaultButton
                      title="LikeSolid"
                      ariaLabel="likeSolidEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onLikeSolidButtonClicked}
                    >
                      <FontIcon
                        aria-label="LikeSolidButton"
                        iconName="LikeSolid"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                  {showDislikeButton && (
                    <DefaultButton
                      title="Dislike"
                      ariaLabel="dislikeEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onDislikeButtonClicked}
                    >
                      <FontIcon
                        aria-label="DislikeButton"
                        iconName="Dislike"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                  {showDislikeSolidButton && (
                    <DefaultButton
                      title="DislikeSolid"
                      ariaLabel="dislikeSolidEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onDislikeSolidButtonClicked}
                    >
                      <FontIcon
                        aria-label="DislikeSolidButton"
                        iconName="DislikeSolid"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                </div>
              )}
              <Label className={commonAIPlaygroundStyles.scheduleChoiceGroupLabel}>
                <Stack horizontal verticalAlign="center">
                  <span>Schedule this prompt?&nbsp;&nbsp;</span>
                  <TooltipHost content={toolTipMessage}>
                    <FontIcon className={commonAIPlaygroundStyles.tooltipStyles} aria-label="tooltip" iconName="Info" />
                  </TooltipHost>
                </Stack>
              </Label>
              <ChoiceGroup
                defaultSelectedKey="daily"
                className={commonAIPlaygroundStyles.scheduledRun}
                options={scheduledRunDropdownOptions}
                onChange={onScheduledRunDropdownChange}
              />
              <PrimaryButton
                text="Schedule"
                className={commonAIPlaygroundStyles.scheduleButtonStyles}
                onClick={scheduleButtonCLicked}
              />
              {scheduleSuccess && (
                <MessageBar
                  className={commonAIPlaygroundStyles.scheduleMessageStyles}
                  messageBarType={MessageBarType.success}
                  isMultiline={false}
                >
                  Successfully scheduled.
                </MessageBar>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
