import { Card, makeStyles } from "@fluentui/react-components";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  DocumentSearch16Regular,
  PresenceAvailableRegular,
  Person20Regular,
  PlayCircleHint20Regular
} from "@fluentui/react-icons";
import { logEvent } from "../../helper/AppInsightsHelper";

const customerDescription = "Search Customer and view their usage metrics and charts.";
const productDescription = "Insights into product usage.";
const reportsDescription = "To help you find report you are looking for.";
const aiPlaygroundDescription = "Security360 AI Playground";

const useStyles = makeStyles({
  cardHover: {
    ":hover": {
      backgroundColor: "lightgrey"
    }
  },
  content: {
    textAlign: "center",
    justifyContent: "space-evenly",
    cursor: "pointer",
    height: "80px",
    width: "200px"
  },
  textStyle: {
    paddingLeft: "5px",
    fontWeight: "bold",
    fontSize: "14px"
  },
  descriptionStyle: {
    fontSize: "10px"
  },
  mainContainerStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginBottom: "20px",
    width: "100%"
  },
  imageStyle: {
    width: "15px",
    height: "15px",
    paddingTop: "2px"
  }
});

const HomeCardLinks = () => {
  const navigate = useNavigate();
  const styles = useStyles();

  const onKeyChanges = (e: React.KeyboardEvent<HTMLDivElement>, route: string) => {
    if (e.key === "Enter") {
      navigate(route);
    }
  };
  return (
    <div className={styles.mainContainerStyle}>
      <Card className={styles.cardHover} size="small">
        <div
          className={styles.content}
          title={customerDescription}
          tabIndex={0}
          onClick={() => {
            logEvent("Explore Customer Button Click", "Home Page", "HomeCardLinks");
            navigate("/customersearch");
          }}
          onKeyDown={(e) => {
            onKeyChanges(e, "/customersearch");
          }}
        >
          <div>
            <div className="row">
              <Person20Regular className={styles.imageStyle} />
              <span className={styles.textStyle}>Explore Customers</span>
            </div>
            <span className={styles.descriptionStyle}>{customerDescription}</span>
          </div>
        </div>
      </Card>
      <Card className={styles.cardHover} size="small">
        <div
          className={styles.content}
          title={productDescription}
          tabIndex={0}
          onClick={() => {
            logEvent("Explore Product Button Click", "Home Page", "HomeCardLinks");
            navigate("/productview");
          }}
          onKeyDown={(e) => {
            onKeyChanges(e, "/productview");
          }}
        >
          <div>
            <div className="row">
              <DocumentSearch16Regular className={styles.imageStyle} />
              <span className={styles.textStyle}>Explore Products</span>
            </div>
            <span className={styles.descriptionStyle}>{productDescription}</span>
          </div>
        </div>
      </Card>
      <Card className={styles.cardHover} size="small">
        <div
          className={styles.content}
          title={reportsDescription}
          tabIndex={0}
          onClick={() => {
            logEvent("Explore Report Catalog Button Click", "Home Page", "HomeCardLinks");
            navigate("/reportcatalog");
          }}
          onKeyDown={(e) => {
            onKeyChanges(e, "/reportcatalog");
          }}
        >
          <div>
            <div className="row">
              <PresenceAvailableRegular />
              <span className={styles.textStyle}>Explore Reports</span>
            </div>
            <span className={styles.descriptionStyle}>{reportsDescription}</span>
          </div>
        </div>
      </Card>
      <Card className={styles.cardHover} size="small">
        <div
          className={styles.content}
          title={aiPlaygroundDescription}
          tabIndex={0}
          onClick={() => {
            logEvent("AI Playground Button Click", "Home Page", "HomeCardLinks");
            navigate("/aiplayground");
          }}
          onKeyDown={(e) => {
            onKeyChanges(e, "/aiplayground");
          }}
        >
          <div>
            <div className="row">
              <PlayCircleHint20Regular />
              <span className={styles.textStyle}>AI Playground</span>
            </div>
            <span className={styles.descriptionStyle}>{aiPlaygroundDescription}</span>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default HomeCardLinks;
