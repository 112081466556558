/* eslint-disable no-undef */
import { DocumentCardImage, IIconProps, ImageFit } from "@fluentui/react";
import React from "react";
import { CardData, CardProps } from "../../ReportCatalog.interfaces";
const powerbiReportIcon = require("../../../../assets/powerbi-report-icon.svg").default;

const iconProps: IIconProps = {
  imageProps: {
    onError: ({ currentTarget }: React.SyntheticEvent<HTMLImageElement, Event>) => {
      currentTarget.onerror = null; // Prevents looping
      currentTarget.src = powerbiReportIcon;
      currentTarget.style.width = "50%";
      currentTarget.style.height = "50%";
    },
    styles: {
      root: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "5px",
        boxSizing: "border-box"
      },
      image: {
        width: "inherit",
        height: "inherit",
        opacity: "1"
      }
    }
  },
  styles: {
    root: {
      color: "#813a7c",
      fontSize: "120px",
      width: "100%",
      height: "100%",
      userSelect: "none"
    }
  }
};

export const CardContext = React.createContext<CardData | undefined>(undefined);

const TopReportsCardImage = (props: CardProps) => {
  const _iconProps = {
    ...iconProps,
    imageProps: { ...iconProps.imageProps, src: props.card.thumbnail },
    onClick: () => {
      props.onCardSelected(props.card);
    }
  };

  return (
    <DocumentCardImage
      height={170}
      imageFit={ImageFit.cover}
      iconProps={_iconProps}
      styles={{ root: { cursor: "pointer" } }}
    />
  );
};

export default TopReportsCardImage;
