import {
  ChoiceGroup,
  DefaultButton,
  FontIcon,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
  TooltipHost,
  mergeStyleSets
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { ITechnicalAssistanceRequest } from "../../ServiceModels/CustomerService-Models";
import {
  technicalAssistanceInsightsData,
  technicalAssistanceInsightsScheduleMetadata
} from "../../Services/customerServices";
import { commonAIPlaygroundStyles } from "./AIPlayground-Common.Styles";
import { logEvent, logPageView } from "../../helper/AppInsightsHelper";

const styles = mergeStyleSets({
  technicalAssistanceContainer: {
    width: 1500,
    border: "1px solid black",
    margin: "30px 30px 30px 30px"
  }
});

export const TechnicalAssistance = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [showEmojiButtons, setShowEmojiButtons] = useState<boolean>(false);
  const [showLikeButton, setShowLikeButton] = useState<boolean>(true);
  const [showDislikeButton, setShowDislikeButton] = useState<boolean>(true);
  const [showLikeSolidButton, setShowLikeSolidButton] = useState<boolean>(false);
  const [showDislikeSolidButton, setShowDislikeSolidButton] = useState<boolean>(false);

  const [promptValue, setPromptValue] = useState("");
  const [responseTextValue, setResponseTextValue] = useState("");

  const [loading, setLoading] = useState<boolean>(false);
  const [scheduleSuccess, setscheduleSuccess] = useState<boolean>(false);

  const toolTipMessage =
    "Schedule prompt-based report deliveries according to your selected frequency, reports will be sent at 1 AM UTC";

  // Scheduled run dropdown settings and handlers
  const [selectedScheduledRunItem, setSelectedScheduledRunItem] = useState<string>("daily");
  const scheduledRunDropdownOptions = [
    { key: "daily", text: "Daily" },
    { key: "weekly", text: "Weekly" },
    { key: "monthly", text: "Monthly" }
  ];
  const onScheduledRunDropdownChange = (event: any, option?: any): void => {
    setSelectedScheduledRunItem(option.key);
  };

  const onLikeButtonClicked = () => {
    setShowLikeButton(false);
    setShowLikeSolidButton(true);

    if (showDislikeSolidButton) {
      setShowDislikeButton(true);
      setShowDislikeSolidButton(false);
    }
  };

  const onDislikeButtonClicked = () => {
    setShowDislikeButton(false);
    setShowDislikeSolidButton(true);

    if (showLikeSolidButton) {
      setShowLikeButton(true);
      setShowLikeSolidButton(false);
    }
  };

  const onLikeSolidButtonClicked = () => {
    setShowLikeButton(true);
    setShowLikeSolidButton(false);
  };

  const onDislikeSolidButtonClicked = () => {
    setShowDislikeButton(true);
    setShowDislikeSolidButton(false);
  };

  const submitButtonClicked = () => {
    setLoading(true);
    setResponseTextValue("");
    setShowEmojiButtons(false);

    const loginName = sessionStorage.getItem("loginUserName")
      ? sessionStorage.getItem("loginUserName")?.split("@")[0]
      : undefined;

    const technicalAssistanceRequestPayload: ITechnicalAssistanceRequest = {
      logDate: new Date(),
      category: "technicalAssistance",
      schedule: "",
      alias: loginName,
      question: promptValue
    };

    logEvent(
      "AI Playground - Technical Assistance Submit Button CLicked",
      "AI Playground - Technical Assistance Page",
      "TechnicalAssistance"
    );
    (async () => {
      const technicalAssistanceResponse = await technicalAssistanceInsightsData(technicalAssistanceRequestPayload);
      setLoading(false);
      setResponseTextValue(technicalAssistanceResponse);

      // Reset like/dislike buttons
      setShowEmojiButtons(true);
      setShowLikeButton(true);
      setShowDislikeButton(true);
      setShowLikeSolidButton(false);
      setShowDislikeSolidButton(false);

      // Send API request again to save the request payload
      // Logging call is done here to save the turn around time on previous API request
      // Fire and forget - Do nothing on UI side. Backend will work on logging the request payload
      await technicalAssistanceInsightsScheduleMetadata(technicalAssistanceRequestPayload);
    })();
  };

  const scheduleButtonCLicked = () => {
    const loginName = sessionStorage.getItem("loginUserName")
      ? sessionStorage.getItem("loginUserName")?.split("@")[0]
      : undefined;

    const technicalAssistanceRequestPayload: ITechnicalAssistanceRequest = {
      logDate: new Date(),
      category: "technicalAssistance",
      schedule: selectedScheduledRunItem,
      alias: loginName,
      question: promptValue
    };

    logEvent(
      "AI Playground - Technical Assistance Schedule Button CLicked",
      "AI Playground - Technical Assistance Page",
      "TechnicalAssistance"
    );
    (async () => {
      await technicalAssistanceInsightsScheduleMetadata(technicalAssistanceRequestPayload);
      setscheduleSuccess(true);
      setTimeout(() => {
        setscheduleSuccess(false);
      }, 3000);
    })();
  };

  useEffect(() => {
    logPageView("AI Playground - Technical Assistance Page", window.location.href);
  }, []);

  return (
    <>
      <div className={styles.technicalAssistanceContainer}>
        <div className={commonAIPlaygroundStyles.labelStyles}>
          <div className={commonAIPlaygroundStyles.promptAndButtonGroup}>
            <TextField
              label="Prompt"
              multiline
              rows={2}
              className={commonAIPlaygroundStyles.promptTextBoxStyles}
              placeholder="Enter your question here"
              value={promptValue}
              onChange={(event: any) => {
                setPromptValue(event.target.value);
              }}
            />
            <PrimaryButton
              text="Submit"
              className={commonAIPlaygroundStyles.submitButtonStyles}
              onClick={submitButtonClicked}
            />
          </div>
          <div className={commonAIPlaygroundStyles.responseAndScheduleRunGroup}>
            <TextField
              label="Response"
              multiline
              rows={15}
              autoAdjustHeight
              scrollContainerRef={containerRef}
              className={commonAIPlaygroundStyles.textBoxStyles}
              value={responseTextValue}
            />
            <div className={commonAIPlaygroundStyles.spinnerStyles}>
              {loading && <Spinner label="Hang tight! Something awesome is getting cooked..." />}
            </div>
            <div className={commonAIPlaygroundStyles.scheduleItemsStyles}>
              {showEmojiButtons && (
                <Label className={commonAIPlaygroundStyles.likeButtonLabel}>Do you like the response?</Label>
              )}
              {showEmojiButtons && (
                <div className={commonAIPlaygroundStyles.likeDislikeButtonContainerStyles}>
                  {showLikeButton && (
                    <DefaultButton
                      title="Like"
                      ariaLabel="likeEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onLikeButtonClicked}
                    >
                      <FontIcon
                        aria-label="LikeButton"
                        iconName="Like"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                  {showLikeSolidButton && (
                    <DefaultButton
                      title="LikeSolid"
                      ariaLabel="likeSolidEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onLikeSolidButtonClicked}
                    >
                      <FontIcon
                        aria-label="LikeSolidButton"
                        iconName="LikeSolid"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                  {showDislikeButton && (
                    <DefaultButton
                      title="Dislike"
                      ariaLabel="dislikeEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onDislikeButtonClicked}
                    >
                      <FontIcon
                        aria-label="DislikeButton"
                        iconName="Dislike"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                  {showDislikeSolidButton && (
                    <DefaultButton
                      title="DislikeSolid"
                      ariaLabel="dislikeSolidEmoji"
                      className={commonAIPlaygroundStyles.likeDislikeButtonStyles}
                      onClick={onDislikeSolidButtonClicked}
                    >
                      <FontIcon
                        aria-label="DislikeSolidButton"
                        iconName="DislikeSolid"
                        className={commonAIPlaygroundStyles.likeDislikeIconStyles}
                      />
                    </DefaultButton>
                  )}
                </div>
              )}
              <Label className={commonAIPlaygroundStyles.scheduleChoiceGroupLabel}>
                <Stack horizontal verticalAlign="center">
                  <span>Schedule this prompt?&nbsp;&nbsp;</span>
                  <TooltipHost content={toolTipMessage}>
                    <FontIcon className={commonAIPlaygroundStyles.tooltipStyles} aria-label="tooltip" iconName="Info" />
                  </TooltipHost>
                </Stack>
              </Label>
              <ChoiceGroup
                defaultSelectedKey="daily"
                className={commonAIPlaygroundStyles.scheduledRun}
                options={scheduledRunDropdownOptions}
                onChange={onScheduledRunDropdownChange}
              />
              <PrimaryButton
                text="Schedule"
                className={commonAIPlaygroundStyles.scheduleButtonStyles}
                onClick={scheduleButtonCLicked}
              />
              {scheduleSuccess && (
                <MessageBar
                  className={commonAIPlaygroundStyles.scheduleMessageStyles}
                  messageBarType={MessageBarType.success}
                  isMultiline={false}
                >
                  Successfully scheduled.
                </MessageBar>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
