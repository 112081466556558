import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FeatureRequestProps, FeatureResponseProps } from "../components/ProductView/Common/ProductView.interfaces";
import { serviceApi } from "../Services/ServiceConfig";
import { RootState } from "../store/store";

// Define a service using a base URL and expected endpoints
export const FeatureProductAPI = createApi({
  reducerPath: "featureproductApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serviceApi.security360 + "FeatureProduct/",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).msal.token?.accessToken;
      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getFeatureProductDeatails: builder.mutation<FeatureResponseProps, FeatureRequestProps>({
      query(body) {
        return {
          url: "getProductViewFeatureMetrics",
          method: "POST",
          body
        };
      }
    })
  })
});

// Export hooks for usage in functional components, which are
// Auto-generated based on the defined endpoints
export const { useGetFeatureProductDeatailsMutation } = FeatureProductAPI;

export const getFeaturesSelector = FeatureProductAPI.endpoints.getFeatureProductDeatails.select("getFeatures");
