// Define the enum
export enum CustomerViewTabs {
  Product = "product",
  Subscription = "subscription",
  FeatureRequest = "featureRequest",
  RequestForAssistance = "requestForAssistance",
  Programs = "programs",
  Engagements = "engagements",
  Support = "support",
  CRMCustomerProduct = "crmCustomerProduct",
  CustomerEngagements = "customerEngagements",
  Partner = "partner"
}
