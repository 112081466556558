import { FontIcon, Link, Shimmer, Stack } from "@fluentui/react";
import { IChartDataPoint, IChartProps, getNextColor } from "@fluentui/react-charting";
import * as React from "react";
import FluentDonutChart from "../../ChartComponents/FluentDountChart";
import CustomCard from "../../SharedComponents/CustomCard";
import { getSumForNumberValues } from "../../Utils/js-common";
import { CUSTOMER_VIEW_STYLES } from "./CustomerView.styles";

const horizontalBarHeaderShimmerStyles = {
  root: { width: "50%" },
  shimmerWrapper: {
    backgroundColor: "var(--shimmer-color)",
    borderTopLeftRadius: 6,
    height: 20
  },
  shimmerGradient: {
    backgroundColor: "var(--shimmer-color)"
  }
};
const SubscriptionCharts = (props: any) => {
  const [skuAssigned, setSkuAssigned] = React.useState<IChartProps>({});
  const [skuFamily, setSkuFamily] = React.useState<IChartProps>({});
  const [skuSbscriptionStatus, setSbscriptionStatus] = React.useState<IChartProps>({});
  const [isFilterApplied, setIsFilterApplied] = React.useState(false);
  const [highlightedCard, setHighlightedCard] = React.useState(-1);
  const customCardContainerRef = React.useRef<HTMLDivElement>(null);
  const width = React.useMemo(() => customCardContainerRef.current?.clientWidth, [customCardContainerRef.current]);
  const onDonutChartSlicesClicked = (itemKey: string, value: string | null) => {
    if (props.setChartFilter) {
      props.setChartFilter({
        filterKey: itemKey,
        value: value
      });
    }
    setIsFilterApplied(true);
  };
  const customCardPropsMemo = React.useMemo(
    () =>
      [
        {
          ...skuAssigned,
          key: "customer-view-customer-license-subscription-chart-by-sku assigned",
          title: "SKU Assigned"
        },
        {
          ...skuFamily,
          key: "customer-view-customer-license-subscription-chart-by-sku family",
          title: "SKU Family"
        },
        {
          ...skuSbscriptionStatus,
          key: "customer-view-customer-license-subscription-chart-by-subscription-status",
          title: "Subscription Status"
        }
      ]
        .filter(({ chartData }) => chartData === undefined || chartData?.length)
        .map(({ key, title, ...restData }) => ({
          key,
          title,
          component: () => (
            <FluentDonutChart
              data={restData as IChartProps}
              isChartLoading={props.isChartLoading}
              styles={CUSTOMER_VIEW_STYLES.COMMON_STYLES.CSS_STYLES.donutChart}
              valueInsideDonut={getSumForNumberValues(
                ((restData as IChartProps).chartData || []).map(({ data }) => data || 0)
              )}
              width={width}
            />
          )
        })),
    [skuAssigned, skuFamily, skuSbscriptionStatus]
  );

  const getHighlightedStatus = (index: number) => isFilterApplied && highlightedCard === index;
  const horizontalBarHeader = (title: string, index: number, isLoading: boolean) =>
    isLoading ? (
      <Shimmer styles={horizontalBarHeaderShimmerStyles} />
    ) : (
      <Stack horizontal>
        <div>{title}</div>
        {getHighlightedStatus(index) && (
          <Link
            underline
            onClick={() => {
              setIsFilterApplied(false);
              setHighlightedCard(-1);
              props.setChartFilter({
                filterKey: "",
                value: ""
              });
            }}
            styles={{ root: { position: "absolute", right: 13 } }}
          >
            <FontIcon title="Clear Filter" iconName="ClearFilter" aria-label="Clear Filter"></FontIcon>
          </Link>
        )}
      </Stack>
    );

  React.useEffect(() => {
    if (props.chartData && props.keys) {
      props.keys.forEach((itemKey: string) => {
        switch (itemKey) {
          case "skuAssigned":
            {
              const tempData = props?.chartData?.skuAssigned.map((item: any, idx: number) => {
                const obj = {
                  legend: item.name,
                  data: item.count,
                  color: getNextColor(idx),
                  onClick: () => onDonutChartSlicesClicked(itemKey, item.name)
                };
                return obj;
              });
              setSkuAssigned({
                chartTitle: "Donut Chart",
                chartData: tempData
              });
            }
            break;

          case "skuFamily":
            {
              const tempData = props?.chartData?.skuFamily.map((item: any, idx: number) => {
                const obj = {
                  legend: item.name,
                  data: item.count,
                  color: getNextColor(idx),
                  onClick: () => onDonutChartSlicesClicked(itemKey, item.name)
                };
                return obj;
              });
              setSkuFamily({
                chartTitle: "Donut Chart",
                chartData: tempData
              });
            }
            break;

          case "subscriptionStatus":
            {
              const tempData = props?.chartData?.subscriptionStatus.map((item: any, idx: number) => {
                const obj: IChartDataPoint = {
                  legend: item.name,
                  data: item.count,
                  color: getNextColor(idx),
                  onClick: () => onDonutChartSlicesClicked(itemKey, item.name)
                };
                return obj;
              });
              setSbscriptionStatus({
                chartTitle: "Donut Chart",
                chartData: tempData
              });
            }
            break;

          default: {
            return { chartTitle: "Donut Chart", chartData: [] };
          }
        }
      });
    }
  }, [props?.chartData, props?.keys]);

  return (
    <div
      style={{
        columnGap: 8,
        display: "flex",
        margin: 8
      }}
    >
      {customCardPropsMemo.map(({ component, key, title }, index) => (
        <div
          ref={customCardContainerRef}
          key={key}
          style={{ flex: 1 }}
          onMouseEnter={() => setHighlightedCard(index)}
          onMouseLeave={() => setHighlightedCard(-1)}
        >
          <CustomCard
            cardHeaderTitle={horizontalBarHeader(title, index, props.isChartLoading)}
            styles={{
              root: { flex: 1, height: "100%" },
              cardHeaderStart: { flex: 1 },
              cardHeaderTitleContainer: { flex: 1 }
            }}
          >
            {component()}
          </CustomCard>
        </div>
      ))}
    </div>
  );
};

export default SubscriptionCharts;
