import { IButtonProps, IRawStyle, IStyle, mergeStyleSets } from "@fluentui/react";

export const RED_COLOR = "red";
export type ButtonLoadingState = "initial" | "disabled" | "loading" | "loaded";
export const BUTTON_APPEARANCE_PRIMARY = "primary";
export const BUTTON_APPEARANCE_SECONDARY = "secondary";
export const INITIAL_BUTTON_LOADING_STATE: ButtonLoadingState = "initial";
export const DISABLED_BUTTON_LOADING_STATE: ButtonLoadingState = "disabled";
export const CUSTOM_CARD = {
  OVERFLOW_BUTTON_PROPS: {
    ariaLabel: "More commands",
    styles: { menuIcon: { fontWeight: 600 } }
  } as IButtonProps,
  HEADER_STYLES: {
    root: { justifyContent: "flex-start", alignItems: "center", gridGap: "4px" } as IStyle,
    toolTipStyles: { display: "inline-block", cursor: "text" } as IStyle
  },
  CUSTOM_CARD_DEFAULT_STYLES: mergeStyleSets({
    card: {
      maxWidth: "auto",
      userSelect: "auto",
      borderRadius: 6,
      boxShadow: "0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14)",
      background: "var(--colorCardBodyBackground1)"
    } as IRawStyle,
    cardHeaderOuter: {
      alignItems: "center",
      backgroundColor: "#ffffff",
      borderBottom: "2px solid #F0F0F0",
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      minHeight: 45,
      overflow: "hidden"
    } as IStyle,
    cardHeaderInner: {
      alignItems: "center",
      boxSizing: "border-box",
      color: "rgb(27, 26, 25)",
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: 13,
      height: 32,
      width: "100%"
    } as IStyle,
    cardHeaderStart: {
      alignItems: "center",
      boxSizing: "border-box",
      color: "rgb(27, 26, 25)",
      display: "flex",
      textAlign: "left",
      textSizeAdjust: "100%"
    } as IStyle,
    cardHeaderEnd: {} as IStyle,
    cardHeaderTitle: {
      borderRadius: 2,
      cursor: "pointer",
      fontSize: 14,
      fontWeight: 600,
      paddingLeft: 0,
      paddingRight: 4
    } as IStyle
  })
};
