import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store/store";
import { serviceApi } from "../Services/ServiceConfig";

export const TopTenantsApi = createApi({
  reducerPath: "TopTenantsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serviceApi.security360 + "product/",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).msal.token?.accessToken;
      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    }
  }),

  endpoints: (builder) => ({
    getTopTenantsOfCustomer: builder.mutation({
      query(body) {
        return {
          url: "getTopTenantsOfCustomer",
          method: "POST",
          body
        };
      }
    })
  })
});
export const topTenantsResult = TopTenantsApi.endpoints.getTopTenantsOfCustomer.select("getTopTenants");
export const { useGetTopTenantsOfCustomerMutation } = TopTenantsApi;
