import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { serviceApi } from "../Services/ServiceConfig";
import { RootState } from "../store/store";

// Define a service using a base URL and expected endpoints
export const ProductAPI = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serviceApi.security360 + "product/",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).msal.token?.accessToken;
      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: () => "getAllProductsNames"
    }),
    getProductViewMetrics: builder.mutation({
      query(body) {
        return {
          url: "getProductViewMetrics",
          method: "POST",
          body
        };
      }
    }),
    getProductMetricsCardsGraphs: builder.mutation<{ cardDataValues: any[]; metricsData: any[] }, any>({
      query(body) {
        return {
          url: "getProductMetricsCardsGraphs",
          method: "POST",
          body
        };
      }
    })
  })
});

export const PRODUCT_METRICS_CARDS_GRAPHS_CACHE_KEY = "getProductMetricsCardsGraphs";

// Export hooks for usage in functional components, which are
// Auto-generated based on the defined endpoints
export const { useGetProductsQuery, useGetProductViewMetricsMutation, useGetProductMetricsCardsGraphsMutation } =
  ProductAPI;

export const getProductMetricsCardsGraphsSelector = ProductAPI.endpoints.getProductMetricsCardsGraphs.select(
  PRODUCT_METRICS_CARDS_GRAPHS_CACHE_KEY
);
