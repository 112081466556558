import { Box, Paper } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { CustomCustomerList } from "../../SharedComponents/CustomCustomerList";
import { useWindowDimensions } from "../../SharedComponents/useWindowDimensions";
import { CustomerFilterPill } from "./CustomerFilterPill";
import { CUSTOMER_SEARCH_STYLES } from "./CustomerSearch.styles";
import { CustomerSearchDataGridProps } from "./CustomSearch.interfaces";

export default function CustomerDataGrid({ searchText }: CustomerSearchDataGridProps) {
  const [filters, setFilters] = useState<any>({});
  const { height } = useWindowDimensions();
  const [filterPillContainerHeight, setFilterPillContainerHeight] = useState(0);
  const filterPillConainerRef = useRef<HTMLDivElement>(null);

  const onPrepareExtraFilter = (props: {
    field: string;
    type: BooleanConstructor | StringConstructor;
    data: string;
  }) => {
    const formFilterPredicate = (value: string) => {
      if (props.type === Boolean) {
        if (value === "1") {
          return `${props.field} eq true`;
        }
        return `${props.field} eq false`;
      }
      return `${props.field} eq '${value}'`;
    };
    const formFieldValuePredicate = () => {
      if (props.data) {
        const values = props.data.replace(/;$/, "").split(";");
        if (values.length > 1) {
          return "(" + values.map(formFilterPredicate).join(" or ") + ")";
        } else {
          return values.map(formFilterPredicate).join(" or ");
        }
      }
      return "";
    };

    if (props.data) {
      setFilters((prev: any) => ({
        ...prev,
        [props.field]: formFieldValuePredicate()
      }));
    } else {
      setFilters((prev: any) => {
        const current = { ...prev };
        delete current[props.field];
        return current;
      });
    }
  };
  const filterPillProps = {
    onFastTrackChange: (data: any) => onPrepareExtraFilter({ data: data, field: columns.fastTrack, type: Boolean }),
    onHasPartnerChange: (data: any) => onPrepareExtraFilter({ data: data, field: columns.partner, type: Boolean }),
    onIndustryChange: (data: any) => onPrepareExtraFilter({ data: data, field: columns.industry, type: String }),
    onIsCatChange: (data: any) => onPrepareExtraFilter({ data: data, field: columns.isCAT, type: Boolean }),
    onIsSstChange: (data: any) => onPrepareExtraFilter({ data: data, field: columns.isSST, type: Boolean }),
    onRegionChange: (data: any) => onPrepareExtraFilter({ data: data, field: columns.region, type: String }),
    onSegmentChange: (data: any) => onPrepareExtraFilter({ data: data, field: columns.segment, type: String }),
    onSubSegmentChange: (data: any) => onPrepareExtraFilter({ data: data, field: columns.subSegment, type: String }),
    onSegmentGroupChange: (data: any) => onPrepareExtraFilter({ data: data, field: columns.group, type: String })
  };

  useEffect(() => {
    const handleResize = () => {
      setFilterPillContainerHeight(filterPillConainerRef.current?.clientHeight ?? 0);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Paper className={CUSTOMER_SEARCH_STYLES.CUSTOMER_SEARCH_DATA_GRID.CLASS_NAMES.GRID_AND_FILTER_PILL_CONTAINER}>
      <div ref={filterPillConainerRef} style={{ paddingBottom: 18 }}>
        <CustomerFilterPill {...filterPillProps} />
      </div>
      <Box
        sx={{
          width: "100%",
          height: `calc(${height}px - (185px + ${filterPillContainerHeight}px))`
        }}
      >
        <div
          role="tabpanel"
          id="customer-search-grid-tabpanel"
          aria-labelledby="aria-labelledby-customer-search-grid-tabpanel"
          style={{
            height: "100%"
          }}
        >
          <CustomCustomerList
            initialFilters={Object.values(filters).join(" and ")}
            searchText={searchText}
            skipColumnsFilterable={skipColumnsFilterable}
          />
        </div>
      </Box>
    </Paper>
  );
}

const columns = {
  industry: "IndustryName",
  region: "RegionName",
  group: "SegmentGroup",
  segment: "SegmentName",
  subSegment: "SubSegmentName",
  isSST: "IsSST",
  partner: "HasPartner",
  fastTrack: "IsFastTrackStrategic",
  isCAT: "IsCAT"
};

const skipColumnsFilterable: string[] = ["segmentGroup", "segmentName", "subSegmentName", "regionName", "industryName"];
