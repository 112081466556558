import { FontIcon, Link, Panel, PanelType, PrimaryButton, Shimmer, Spinner, Stack } from "@fluentui/react";
import { IChartProps, getNextColor } from "@fluentui/react-charting";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useEffect, useMemo, useRef, useState } from "react";
import FluentDonutChart from "../../ChartComponents/FluentDountChart";
import InsightsComponent from "../../helper/InsightsComponent";
import { getChatInsightCompletion } from "../../Services/openAIServices";
import CustomCard from "../../SharedComponents/CustomCard";
import { getSumForNumberValues } from "../../Utils/js-common";
import { CUSTOMER_VIEW_STYLES } from "./CustomerView.styles";

const horizontalBarHeaderShimmerStyles = {
  root: { width: "50%" },
  shimmerWrapper: {
    backgroundColor: "var(--shimmer-color)",
    borderTopLeftRadius: 6,
    height: 20
  },
  shimmerGradient: {
    backgroundColor: "var(--shimmer-color)"
  }
};
const SupportChart = (props: any) => {
  const [serviceRequestState, setServiceRequestState] = React.useState<IChartProps>({});
  const [derivedProductName, setDerivedProductName] = React.useState<IChartProps>({});
  const [isFilterApplied, setIsFilterApplied] = React.useState(false);
  const [highlightedCard, setHighlightedCard] = React.useState(-1);
  const customCardContainerRef = useRef<HTMLDivElement>(null);
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [insightData, setInsightData] = useState("");
  const width = useMemo(() => customCardContainerRef.current?.clientWidth, [customCardContainerRef.current]);
  const onDonutChartSlicesClicked = (itemKey: string, value: string | null) => {
    if (props.setChartFilter) {
      props.setChartFilter({
        filterKey: itemKey,
        value: value
      });
    }
    setIsFilterApplied(true);
  };
  const customCardPropsMemo = useMemo(
    () =>
      [
        {
          ...serviceRequestState,
          key: "requestStatus",
          title: "Request Status"
        },
        {
          ...derivedProductName,
          key: "product",
          title: "Product"
        }
      ]
        .filter(({ chartData }) => chartData === undefined || chartData?.length)
        .map(({ key, title, ...restData }) => {
          return {
            key,
            title,
            component: () => (
              <FluentDonutChart
                data={restData as IChartProps}
                isChartLoading={props.isChartLoading}
                styles={CUSTOMER_VIEW_STYLES.COMMON_STYLES.CSS_STYLES.donutChart}
                valueInsideDonut={getSumForNumberValues(
                  ((restData as IChartProps).chartData || []).map(({ data }) => data || 0)
                )}
                width={width}
              />
            )
          };
        }),
    [serviceRequestState, derivedProductName]
  );

  const getHighlightedStatus = (index: number) => isFilterApplied && highlightedCard === index;
  const getInsights = async () => {
    await getChatInsightCompletion("support", props.tenantId, props.tenantName).then((response) => {
      if (response !== "") {
        setInsightData(response);
      } else {
        setInsightData("No data found for given prompt");
      }
    });
  };
  const horizontalBarHeader = (title: string, index: number, isLoading: boolean) =>
    isLoading ? (
      <Shimmer styles={horizontalBarHeaderShimmerStyles} />
    ) : (
      <Stack horizontal>
        <div>{title}</div>
        {getHighlightedStatus(index) && (
          <Link
            underline
            onClick={() => {
              setIsFilterApplied(false);
              setHighlightedCard(-1);
              props.setChartFilter({
                filterKey: "",
                value: ""
              });
            }}
            styles={{ root: { position: "absolute", right: 13 } }}
          >
            <FontIcon title="Clear Filter" iconName="ClearFilter" aria-label="Clear Filter"></FontIcon>
          </Link>
        )}
      </Stack>
    );

  useEffect(() => {
    if (props.chartData && props.keys) {
      props.keys.forEach((itemKey: string) => {
        switch (itemKey) {
          case "serviceRequestState":
            {
              const tempData = props?.chartData?.serviceRequestState.map((item: any, idx: number) => {
                const obj = {
                  legend: item.RequestState,
                  data: item.RequestStateCount,
                  color: getNextColor(idx),
                  onClick: () => onDonutChartSlicesClicked(itemKey, item.RequestState)
                };
                return obj;
              });
              setServiceRequestState({
                chartTitle: "Donut Chart",
                chartData: tempData
              });
            }
            break;

          case "derivedProductName":
            {
              const tempData = props?.chartData?.derivedProductName.map((item: any, idx: number) => {
                const obj = {
                  legend: item.ProductName,
                  data: item.ProductNameCount,
                  color: getNextColor(idx),
                  onClick: () => onDonutChartSlicesClicked(itemKey, item.ProductName)
                };
                return obj;
              });
              setDerivedProductName({
                chartTitle: "Donut Chart",
                chartData: tempData
              });
            }
            break;
          default: {
            return { chartTitle: "Donut Chart", chartData: [] };
          }
        }
      });
    }
  }, [props?.chartData, props?.keys]);

  useEffect(() => {
    const fetchInsights = async () => {
      await getInsights();
    };
    fetchInsights();
  }, []);

  return (
    <>
      <PrimaryButton
        style={{ position: "relative", left: "90%" }}
        text="AI Insights"
        onClick={() => {
          openPanel();
        }}
      />
      <Panel
        onRenderHeader={() => (
          <div style={{ fontSize: 26, fontWeight: 700, paddingLeft: 32 }}>Support Request Insights</div>
        )}
        isLightDismiss
        isOpen={isOpen}
        onDismiss={dismissPanel}
        type={PanelType.medium}
        closeButtonAriaLabel="Close"
        styles={{
          navigation: {
            justifyContent: "space-between",
            alignItems: "center"
          }
        }}
      >
        {insightData?.length === 0 && (
          <Spinner
            style={{
              position: "absolute",
              left: "40%",
              top: "40%"
            }}
            label="Loading"
          />
        )}
        {insightData?.length > 0 && <InsightsComponent insightDataInfo={insightData} />}
      </Panel>
      <div className={CUSTOMER_VIEW_STYLES.COMMON_STYLES.CLASS_NAMES.customCardContainer}>
        {customCardPropsMemo.map(({ component, key, title }, index) => (
          <div
            ref={customCardContainerRef}
            key={key}
            style={{ flex: 1 }}
            onMouseEnter={() => setHighlightedCard(index)}
            onMouseLeave={() => setHighlightedCard(-1)}
          >
            <CustomCard
              cardHeaderTitle={horizontalBarHeader(title, index, props.isChartLoading)}
              styles={{
                root: {
                  cardHeaderStart: { flex: 1 },
                  cardHeaderTitleContainer: { flex: 1 },
                  root: { flex: 1, height: "100%" }
                }
              }}
            >
              {component()}
            </CustomCard>
          </div>
        ))}
      </div>
    </>
  );
};

export default SupportChart;
