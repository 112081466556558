import { Panel, PanelType, PrimaryButton, Spinner } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DisplayAPIsException from "../../BasicComponents/DisplayAPIsExceptionAlert";
import { logEvent, logException } from "../../helper/AppInsightsHelper";
import { CustomShimmer } from "../../helper/CustomShimmer";
import { groupBy } from "../../Utils/js-common";
import InsightsComponent from "../../helper/InsightsComponent";
import {
  skuAssignedType,
  skuFamilyType,
  subscriptionChartType,
  subscriptionStatusType
} from "../../interfaces/subscription-types";
import { getChatInsightCompletion } from "../../Services/openAIServices";
import {
  useGetSubscriptionByTenantIdQuery,
} from "../../slices/Subscription.slice";
import SubscriptionCharts from "./SubscriptionCharts";
import SubscriptionTable from "./SubscriptionTable";

const SubscriptionContainer = (props: any) => {
  const [items, setitems] = React.useState([]);
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [tenantName, setTenantName] = React.useState("");
  const [filteredChartData, setFilteredChartData] = React.useState<subscriptionChartType>();
  const [searchParams] = useSearchParams();
  const [isChartLoading, setIsChartLoading] = React.useState(true);
  const [isTableLoading, setIsTableLoading] = React.useState(true);
  const [chartKeys, setChartKeys] = React.useState<string[]>([]);
  const [chartFilter, setChartFilter] = React.useState({
    filterKey: "",
    value: ""
  });
  const tenantId = searchParams.get("id");
  const [filterChData, setFilterChData] = React.useState<any>({
    skuAssigned: "",
    skuFamily: "",
    subscriptionStatus: ""
  });

  const subscriptionData = useGetSubscriptionByTenantIdQuery(tenantId);
  const HandleAPIExcpetion = () => {
    const enableAPIException = subscriptionData.error;
    return (enableAPIException && <DisplayAPIsException height={250} />) || "";
  };

  React.useEffect(() => {
    logEvent("License and Subscription", "Customer Page", "SubscriptionContainer");
  }, []);

  React.useEffect(() => {
    if (subscriptionData && subscriptionData?.data && subscriptionData?.data?.table) {
      setIsTableLoading(false);
      setitems(subscriptionData?.data?.table);
      setTenantName(props.tenantName);
      setFilteredItems(subscriptionData?.data?.table);
      logEvent("getSubscriptionByTenant API success and table data loaded", "Customer Page - License and Subscription", "SubscriptionContainer");
    }
    if (subscriptionData && subscriptionData?.data && subscriptionData?.data?.charts) {
      setIsChartLoading(false);
      setFilterChData({
        skuAssigned: "",
        skuFamily: "",
        subscriptionStatus: ""
      });
      setFilteredChartData(subscriptionData?.data?.charts);
      const keys: string[] = Object.keys(subscriptionData?.data?.charts);
      setChartKeys(keys);
      logEvent("getSubscriptionByTenant API success and chart data loaded", "Customer Page - License and Subscription", "SubscriptionContainer");
    }
    if (subscriptionData.error) {
      setIsTableLoading(false);
      setIsChartLoading(false);
      logException("Customer Page", "SubscriptionContainer", "useGetSubscriptionByTenantIdQuery", subscriptionData.error);
    }
  }, [
    subscriptionData.error,
    subscriptionData,
    subscriptionData?.data
  ]);

  React.useEffect(() => {
    switch (chartFilter.filterKey) {
      case "skuAssigned":
        {
          let filteredData: any = [];
          filteredData = items.filter((item: any) => item.skuAssigned === chartFilter.value);
          setFilterChData({
            ...filterChData,
            skuAssigned: chartFilter.value
          });
          if (filterChData.skuFamily !== "") {
            filteredData = filteredData.filter((item: any) => item.skuFamily === filterChData.skuFamily);
          }
          if (filterChData.subscriptionStatus !== "") {
            filteredData = filteredData.filter(
              (item: any) => item.subscriptionStatus === filterChData.subscriptionStatus
            );
          }
          setFilteredItems(filteredData);
          const skuFamily = groupBy(filteredData, "skuFamily");
          const filteredFamily: skuFamilyType[] = [];
          Object.keys(skuFamily).forEach((item) => {
            filteredFamily.push({
              name: item,
              count: skuFamily[item].length
            });
          });

          const subscriptionStatus = groupBy(filteredData, "subscriptionStatus");
          const filteredStatus: subscriptionStatusType[] = [];
          Object.keys(subscriptionStatus).forEach((item) => {
            filteredStatus.push({
              name: item,
              count: subscriptionStatus[item].length
            });
          });
          if (filteredFamily && filteredStatus && filteredChartData?.skuAssigned) {
            setFilteredChartData({
              ...filteredChartData,
              skuFamily: filteredFamily,
              subscriptionStatus: filteredStatus
            });
          }
        }
        break;
      case "skuFamily":
        {
          let filteredData: any = [];
          filteredData = items.filter((item: any) => item.skuFamily === chartFilter.value);
          setFilterChData({
            ...filterChData,
            skuFamily: chartFilter.value
          });
          if (filterChData.skuAssigned !== "") {
            filteredData = filteredData.filter((item: any) => item.skuAssigned === filterChData.skuAssigned);
          }
          if (filterChData.subscriptionStatus !== "") {
            filteredData = filteredData.filter(
              (item: any) => item.subscriptionStatus === filterChData.subscriptionStatus
            );
          }
          setFilteredItems(filteredData);
          const skuAssigned = groupBy(filteredData, "skuAssigned");
          const assignedFiltered: skuAssignedType[] = [];
          Object.keys(skuAssigned).forEach((item) => {
            assignedFiltered.push({
              name: item,
              count: skuAssigned[item].length
            });
          });
          const subscriptionStatus = groupBy(filteredData, "subscriptionStatus");
          const filteredStatus: subscriptionStatusType[] = [];
          Object.keys(subscriptionStatus).forEach((item) => {
            filteredStatus.push({
              name: item,
              count: subscriptionStatus[item].length
            });
          });
          if (assignedFiltered && filteredStatus && filteredChartData?.skuFamily) {
            setFilteredChartData({
              ...filteredChartData,
              skuAssigned: assignedFiltered,
              subscriptionStatus: filteredStatus
            });
          }
        }
        break;
      case "subscriptionStatus":
        {
          let filteredData: any = [];
          filteredData = items.filter((item: any) => item.subscriptionStatus === chartFilter.value);
          setFilterChData({
            ...filterChData,
            subscriptionStatus: chartFilter.value
          });
          if (filterChData.skuAssigned !== "") {
            filteredData = filteredData.filter((item: any) => item.skuAssigned === filterChData.skuAssigned);
          }
          if (filterChData.skuFamily !== "") {
            filteredData = filteredData.filter((item: any) => item.skuFamily === filterChData.skuFamily);
          }
          setFilteredItems(filteredData);
          const skuFamily = groupBy(filteredData, "skuFamily");
          const filteredFamily: skuFamilyType[] = [];
          Object.keys(skuFamily).forEach((item) => {
            filteredFamily.push({
              name: item,
              count: skuFamily[item].length
            });
          });
          const skuAssigned = groupBy(filteredData, "skuAssigned");
          const assignedFiltered: skuAssignedType[] = [];
          Object.keys(skuAssigned).forEach((item) => {
            assignedFiltered.push({
              name: item,
              count: skuAssigned[item].length
            });
          });
          if (filteredFamily && assignedFiltered && filteredChartData?.subscriptionStatus) {
            setFilteredChartData({
              ...filteredChartData,
              skuAssigned: assignedFiltered,
              skuFamily: filteredFamily
            });
          }
        }
        break;
      default: {
        setFilteredItems(subscriptionData?.data?.table);
        setFilteredChartData(subscriptionData?.data?.charts);
        setFilterChData({
          skuAssigned: "",
          skuFamily: "",
          subscriptionStatus: ""
        });
      }
    }
  }, [chartFilter]);
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [insightData, setInsightData] = useState("");

  const getInsights = async () => {
    await getChatInsightCompletion("licenseAndSubscription", tenantId, props.tenantName).then((response) => {
      if (response !== "") {
        setInsightData(response);
      } else {
        setInsightData("No data found for given prompt");
      }
    });
  };
  useEffect(() => {
    getInsights();
  }, []);

  return (
    <>
      <>
        <PrimaryButton
          style={{ position: "relative", left: "90%" }}
          text="AI Insights"
          onClick={() => {
            openPanel();
          }}
        />
        <Panel
          onRenderHeader={() => (
            <div style={{ fontSize: 26, fontWeight: 700, paddingLeft: 32 }}>License and Subscription Insights</div>
          )}
          isLightDismiss
          isOpen={isOpen}
          onDismiss={dismissPanel}
          type={PanelType.medium}
          closeButtonAriaLabel="Close"
          styles={{
            navigation: {
              justifyContent: "space-between",
              alignItems: "center"
            }
          }}
        >
          {insightData?.length === 0 && (
            <Spinner style={{ position: "absolute", left: "40%", top: "40%" }} label="Loading" />
          )}
          {insightData?.length > 0 && <InsightsComponent insightDataInfo={insightData} />}
        </Panel>
        <SubscriptionCharts chartData={filteredChartData} isChartLoading={isChartLoading} keys={chartKeys} setChartFilter={setChartFilter} />
      </>
      {isTableLoading ? (
        <CustomShimmer columnCount={11} />
      ) : (
        (!subscriptionData.error && (
          <div style={{ marginLeft: 8, marginRight: 5 }}>
            <SubscriptionTable tableData={filteredItems} tenantName={tenantName} />
          </div>
        )) ||
        ""
      )}
      <HandleAPIExcpetion />
    </>
  );
};

export default SubscriptionContainer;
