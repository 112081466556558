import { Shimmer } from "@fluentui/react";
import { TOP_REPORT_CARD_SHIMMER_DATA as INITIAL_SHIMMER_DATA } from "../../../ReportCatalog.common";
import { ShimmerDataProps } from "../../../ReportCatalog.interfaces";

const ShimmerElement = (props: ShimmerDataProps) => {
  return (
    <Shimmer
      styles={props.styles}
      shimmerElements={[{ type: props.shimmerElementType, height: props.height }]}
      shimmerColors={{ shimmer: "var(--shimmer-color)" }}
    />
  );
};

export const ShimmerElements = () => {
  return INITIAL_SHIMMER_DATA.map((shimmer) => <ShimmerElement {...shimmer} key={shimmer.key} />);
};
