import { PrimaryButton } from "@fluentui/react";
import { useEffect, useState } from "react";
import Compliment from "./compliment";
import Problem from "./problem";
import Suggestions from "./Suggestions";
import { Edit12Regular, Heart16Regular, PageFit20Regular } from "@fluentui/react-icons";
import { useGetFeedbackQuestionsQuery } from "../../slices/Feedback.slice";
import { logEvent } from "../../helper/AppInsightsHelper";

export const FeedbackBody = () => {
  const [showComplaint, setShowComplaint] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showSuggestion, setshowSuggestion] = useState(false);
  const [complement, setComplement] = useState();
  const [feedback, setFeedback] = useState();
  const [suggestion, setSuggestion] = useState();
  const ShowComplaint = () => {
    setShowComplaint(true);
    setshowSuggestion(false);
    setShowReport(false);
  };
  const ShowReport = () => {
    setShowReport(true);
    setshowSuggestion(false);
    setShowComplaint(false);
  };
  const ShowSuggestion = () => {
    setshowSuggestion(true);
    setShowReport(false);
    setShowComplaint(false);
  };
  const cancelClick = () => {
    setshowSuggestion(false);
    setShowReport(false);
    setShowComplaint(false);
  };
  const { data } = useGetFeedbackQuestionsQuery(null);

  useEffect(() => {
    logEvent("Feedback Blade", "Feedback Blade", "FeedbackBody");
  }, []);

  useEffect(() => {
    if (data) {
      setComplement(data.filter((item: any) => item.feedbackType.includes("compliment")));
      setFeedback(data.filter((item: any) => item.feedbackType.includes("problem")));
      setSuggestion(data.filter((item: any) => item.feedbackType.includes("suggestion")));
    }
  }, [data]);

  return (
    <>
      <div style={{ marginRight: 5, background: "#FFF" }}>
        <br />
        <div style={{ paddingLeft: "5px", marginLeft: "-20px" }}>
          <label style={{ paddingBottom: "10px", fontWeight: 600 }}>
            Classify your feedback <label style={{ color: "#d50000" }}>*</label>
          </label>
          <br />
          <br />
          {!showSuggestion && !showReport && (
            <>
              <PrimaryButton
                style={{
                  marginBottom: "12px;",
                  height: "45px",
                  width: "300px",
                  marginTop: "7px"
                }}
                onClick={() => {
                  ShowComplaint();
                }}
              >
                <Heart16Regular
                  style={{
                    width: "15px",
                    height: "15px",
                    paddingTop: "2px"
                  }}
                />
                &nbsp;Give a compliment
              </PrimaryButton>
              <br />
              <br />
            </>
          )}

          {showComplaint ? <Compliment cancelClick={cancelClick} questions={complement} /> : null}
          {!showComplaint && !showSuggestion && (
            <>
              <PrimaryButton
                style={{
                  marginBottom: "12px;",
                  height: "45px",
                  width: "300px"
                }}
                text=""
                onClick={() => {
                  ShowReport();
                }}
              >
                <Edit12Regular
                  style={{
                    width: "15px",
                    height: "15px",
                    paddingTop: "2px"
                  }}
                />
                &nbsp;Report a problem
              </PrimaryButton>
              <br />
              <br />
            </>
          )}
          {showReport ? <Problem cancelClick={cancelClick} questions={feedback} /> : null}

          {!showComplaint && !showReport && (
            <>
              {" "}
              <PrimaryButton
                onClick={() => {
                  ShowSuggestion();
                }}
                style={{
                  marginTop: "12px;",
                  height: "45px",
                  width: "300px"
                }}
              >
                {" "}
                <PageFit20Regular
                  style={{
                    width: "15px",
                    height: "15px",
                    paddingTop: "2px"
                  }}
                />
                &nbsp;Make a suggestion
              </PrimaryButton>
              <br />
              <br />
            </>
          )}
          {showSuggestion ? <Suggestions cancelClick={cancelClick} questions={suggestion} /> : null}

          {(showComplaint || showReport || showSuggestion) && <br />}
          <label
            style={{
              fontSize: "10px",
              fontWeight: 400,
              display: "block",
              color: "rgb(96, 94, 92)"
            }}
          >
            By pressing Submit, your feedback will be used to improve Microsoft products and services.
            <a
              target="_blank"
              href="https://privacy.microsoft.com/en-us/privacystatement"
              style={{
                color: "hsl(206, 100%, 42%)",
                cursor: "pointer"
              }}
              rel="noreferrer"
            >
              Privacy statement.
            </a>
          </label>
          <br />
        </div>
      </div>
    </>
  );
};

export default FeedbackBody;
