import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { ReportCatalogApi } from "../components/ReportCatalog/ReportCatalog.slice";
import { ContactApi } from "../slices/Contact.slice";
import { CountryCodesAndKpis } from "../slices/CountryCodesKPI.slice";
import { CRMCustomerProductApi } from "../slices/CRMCustomerProduct.slice";
import { CustomerAPI } from "../slices/Customer.slice";
import { DomainDetailsApi } from "../slices/Domain.slice";
import { FeatureApi } from "../slices/Feature.slice";
import { FeatureProductAPI } from "../slices/FeatureProduct.slice";
import { FeedbackApi } from "../slices/Feedback.slice";
import { homeApi } from "../slices/Home.slice";
import msalReducer from "../slices/Msal.slice";
import { ProductAPI } from "../slices/Product.slice";
import { RequestAssistanceApi } from "../slices/RequestAssistance.slice";
import resizeReducer from "../slices/Resize.slice";
import { SubscriptionApi } from "../slices/Subscription.slice";
import { TopTenantsApi } from "../slices/TopTenant.slice";

export const store = configureStore({
  reducer: {
    msal: msalReducer,
    resize: resizeReducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [CustomerAPI.reducerPath]: CustomerAPI.reducer,
    [DomainDetailsApi.reducerPath]: DomainDetailsApi.reducer,
    [TopTenantsApi.reducerPath]: TopTenantsApi.reducer,
    [SubscriptionApi.reducerPath]: SubscriptionApi.reducer,
    [FeatureApi.reducerPath]: FeatureApi.reducer,
    [ProductAPI.reducerPath]: ProductAPI.reducer,
    [CRMCustomerProductApi.reducerPath]: CRMCustomerProductApi.reducer,
    [CountryCodesAndKpis.reducerPath]: CountryCodesAndKpis.reducer,
    [ContactApi.reducerPath]: ContactApi.reducer,
    [RequestAssistanceApi.reducerPath]: RequestAssistanceApi.reducer,
    [ReportCatalogApi.reducerPath]: ReportCatalogApi.reducer,
    [FeedbackApi.reducerPath]: FeedbackApi.reducer,
    [FeatureProductAPI.reducerPath]: FeatureProductAPI.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      homeApi.middleware,
      CustomerAPI.middleware,
      DomainDetailsApi.middleware,
      TopTenantsApi.middleware,
      SubscriptionApi.middleware,
      FeatureApi.middleware,
      ProductAPI.middleware,
      ProductAPI.middleware,
      CRMCustomerProductApi.middleware,
      CountryCodesAndKpis.middleware,
      ContactApi.middleware,
      RequestAssistanceApi.middleware,
      ReportCatalogApi.middleware,
      FeedbackApi.middleware,
      FeatureProductAPI.middleware
    ])
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
