import { IStyle, mergeStyleSets } from "@fluentui/react";
import { IFilterPillStyles } from "@m365-admin/in-page-filter";
import { CSSProperties } from "react";

const COMMON_STYLES = {
  CUSTOMER_SEARCH_DATA_GRID: {
    GRID_COLUMN_HYPERLINK: {
      color: "rgb(0, 120, 212)",
      cursor: "pointer",
      fontSize: 12,
      textDecoration: "none"
    } as CSSProperties
  }
};

export const CUSTOMER_SEARCH_STYLES = {
  CUSTOMER_SEARCH_FILTER_PILL: {
    STYLES: {
      root: {
        margin: "4px",
        flex: "0 1 auto",
        minWidth: 0
      },
      subComponentStyles: {
        contextualMenu: {
          container: { height: "auto", transition: "min-width 1s ease-in-out" },
          list: { maxHeight: "calc(80vh / 2)", overflow: "auto", "& .ms-ContextualMenu-link": { height: "auto" } }
        },
        callout: { calloutMain: {} }
      }
    } as Partial<IFilterPillStyles>
  },
  CUSTOMER_SEARCH_DATA_GRID: {
    CLASS_NAMES: mergeStyleSets({
      GRID_COLUMN_HYPERLINK: COMMON_STYLES.CUSTOMER_SEARCH_DATA_GRID.GRID_COLUMN_HYPERLINK,
      GRID_COLUMN_HYPERLINK_TENANT_NAME: {
        ...COMMON_STYLES.CUSTOMER_SEARCH_DATA_GRID.GRID_COLUMN_HYPERLINK,
        minWidth: 150,
        overflow: "hidden",
        textAlign: "left",
        textOverflow: "ellipsis"
      } as IStyle,
      GRID_AND_FILTER_PILL_CONTAINER: {
        paddingLeft: 15,
        paddingRight: 5,
        paddingTop: 15
      } as IStyle
    })
  }
};
