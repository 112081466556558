import {
  ContextualMenu,
  FontWeights,
  getTheme,
  IButtonStyles,
  IconButton,
  IIconProps,
  IStyle,
  mergeStyleSets,
  Modal
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { CSSProperties, PropsWithChildren, useEffect } from "react";
import { CustomModalProps } from "./Interfaces/CustomModal.interfaces";

export const CustomModal = ({
  children,
  draggable,
  headerTitle,
  onToggleHideDialog,
  showModal
}: PropsWithChildren<CustomModalProps>) => {
  const [isModalOpen, { setTrue, setFalse }] = useBoolean(false);
  const dragOptions = {
    moveMenuItemText: "Move",
    closeMenuItemText: "Close",
    menu: ContextualMenu
  };
  const handleDismissModal = () => {
    if (onToggleHideDialog) {
      onToggleHideDialog();
    }
    setFalse();
  };

  useEffect(() => {
    if (showModal) {
      setTrue();
    }
  }, [showModal]);

  return (
    <Modal
      containerClassName={contentStyles.container}
      dragOptions={draggable ? dragOptions : undefined}
      isBlocking={false}
      isOpen={isModalOpen}
      onDismiss={handleDismissModal}
      styles={{ main: { maxWidth: "85%", minHeight: 50 } }}
    >
      <div className={contentStyles.header}>
        <h2 className={contentStyles.heading}>{headerTitle}</h2>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={handleDismissModal}
        />
      </div>
      <div className={contentStyles.body}>{children}</div>
    </Modal>
  );
};

const cancelIcon: IIconProps = { iconName: "Cancel" };
const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch"
  } as CSSProperties,
  header: [
    theme.fonts.xxLarge,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px"
    } as CSSProperties
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: "inherit",
    margin: "0"
  } as CSSProperties,
  body: {
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 }
    } as CSSProperties
  } as CSSProperties
});
const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: 4,
    marginRight: 2
  } as IStyle,
  rootHovered: {
    color: theme.palette.neutralDark
  } as IStyle
};
