import { Carousel, ICarouselProps } from "@coherence-design-system/carousel";
import { IChartProps, getNextColor } from "@fluentui/react-charting";
import { useId } from "@fluentui/react-hooks";
import { Triangle12Filled, TriangleDown12Filled } from "@fluentui/react-icons";
import { IconButton } from "@fluentui/react/lib/Button";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { LineChartComponent } from "../../../ChartComponents/LineChartFluent";
import { CarouselProps } from "../../../interfaces/carousel-props.interfaces";
import { IChartVariances } from "../../../interfaces/chart-props.interfaces";
import { IProductKPI } from "../../../interfaces/customer-product-v2.interfaces";
import { useAppSelector } from "../../../store/hooks";
import CustomCard from "../../../SharedComponents/CustomCard";

export const CustomerProductCarousel: React.FC<CarouselProps> = ({ carouseldata }) => {
  const tooltipId = useId("tooltip");
  const [width, setWidth] = useState<number>(400);
  const cardRef = useRef<HTMLDivElement>(null);
  const resizedWidth = useAppSelector(({ resize }) => resize.offsetWidth);
  const [, setOffsetWidth] = useState(resizedWidth);
  const carouselProps: ICarouselProps = {
    items: [],
    title: carouseldata.name,
    responsive: true,
    itemWidth: 298,
    viewAllOnClick: undefined,
    breakpoints: {
      xxxl: { width: 1805, items: 6 },
      xxl: { width: 1366, items: 4 },
      xl: { width: 1024, items: 3 },
      l: { width: 640, items: 3 },
      m: { width: 480, items: 2 },
      s: { width: 320, items: 1 }
    }
  };

  const CardVariance = ({ variances }: { variances: IChartVariances[] }) => {
    const _rootStyle = {
      display: "flex",
      justifyContent: variances.length < 2 ? "flex-start" : "space-around",
      background: "#f0f0f0",
      fontSize: "12px",
      padding: "10px 0",
      paddingTop: 0,
      paddingLeft: variances.length < 2 ? "26px" : "auto",
      currMonthVal: {
        fontSize: "14px",
        fontWeight: "600",
        padding: "5px 0 5px 0"
      },
      varianceGreen: {
        color: "green",
        fontWeight: 600,
        display: "flex",
        alignItems: "center"
      },
      varianceRed: {
        color: "red",
        fontWeight: 600,
        display: "flex",
        alignItems: "center"
      }
    };

    return (
      <div style={_rootStyle}>
        {variances.map((v, idx) => (
          <div key={`cvd-${idx}`}>
            <div
              style={{
                alignItems: "center",
                borderBottom: "1px solid #d5d5d580",
                color: getNextColor(idx),
                display: "flex",
                fontWeight: "bold",
                marginBottom: 2
              }}
            >
              <div> {v.name} </div>
              <TooltipHost content={v.description} id={tooltipId}>
                <IconButton iconProps={{ iconName: "Info" }} aria-label="Info" />
              </TooltipHost>
            </div>
            <div>This month (till date)</div>
            <div style={_rootStyle.currMonthVal}>{v.variance.nextValue || "0"}</div>
            <div style={v.variance.diffValue.includes("-") ? _rootStyle.varianceRed : _rootStyle.varianceGreen}>
              {(v.variance.diffValue.includes("-") && <TriangleDown12Filled aria-label="TriangleDown12Filled" />) ||
                (v.variance.diffValue !== "0" && <Triangle12Filled aria-label="Triangle12Filled" />)}
              <div>
                {v.variance.diffValue || 0} ({v.variance.diffPerc || "0%"})
              </div>{" "}
            </div>
            <div>v/s previous month ({v.variance.prevValue || 0})</div>
          </div>
        ))}
      </div>
    );
  };

  const GetItemData = (item: IProductKPI, idx: number) => {
    const chartData: IChartProps = {
      lineChartData: item.data.map((m, idx) => ({
        legend: m.name,
        data: m.data.map((m) => ({
          x: new Date(m.x),
          y: Number(m.y)
        })),
        color: getNextColor(idx),
        lineOptions: {
          lineBorderWidth: "4"
        }
      }))
    };
    const chartVariances: IChartVariances[] = item.data.map(({ description, name, variance }) => ({
      description,
      name,
      variance
    }));

    return (
      <div ref={cardRef} className="card-container">
        <CustomCard
          cardHeaderTitle={item.name}
        >
          <CardVariance key={`CardVariance${idx + 1}`} variances={chartVariances} />
          <div style={{ backgroundColor: "white" }}>
            <LineChartComponent data={chartData} height={300} width={width} allowResize={true} />
          </div>
        </CustomCard>
      </div>
    );
  };

  useEffect(() => {
    setOffsetWidth(resizedWidth);
  }, [resizedWidth]);

  useLayoutEffect(() => {
    if (cardRef.current) {
      setWidth(cardRef.current?.offsetWidth);
    }
  });

  return <Carousel {...carouselProps} items={carouseldata.items.map((m, idx) => GetItemData(m, idx))} />;
};
