import { mergeStyles, Stack } from "@fluentui/react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Tooltip } from "@mui/material";
import {
  getGridNumericOperators,
  getGridStringOperators,
  GridColDef,
  GridFilterModel,
  GridSortModel
} from "@mui/x-data-grid";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getCustomers } from "../Services/customerServices";
import { callSearchAPIs, copy, getSearchInput } from "../Utils/js-common";
import { COPY_TENANT_ID, DATA_GRID_CONFIG } from "./Constants/CustomCustomerList.constants";
import { CustomDataGrid } from "./CustomDataGrid";
import { CustomLinkNavigate } from "./CustomLinkNavigate";
import { IRows } from "./Interfaces/CustomDataGrid.interfaces";
import { CUSTOM_CUSTOMER_LIST_STYLES } from "./Styles/CustomCustomerList.styles";

export function CustomCustomerList({
  initialFilters,
  searchText,
  skipColumnsFilterable
}: {
  initialFilters: string;
  searchText: any;
  skipColumnsFilterable?: string[];
}) {
  const [title, setTitle] = useState(COPY_TENANT_ID);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [rows, setRows] = useState<IRows>(DATA_GRID_CONFIG.rows);
  const [paginationOptions, setPaginationOptions] = useState(DATA_GRID_CONFIG.pagination);
  const [filterOptions, setFilterOptions] = useState<GridFilterModel>({
    items: []
  });
  const [sortOptions, setSortOptions] = useState<GridSortModel>([]);
  const columns: GridColDef[] = [
    {
      headerName: "Tenant Id",
      field: "tenantId",
      width: 300,
      sortable: true,
      renderCell: ({ row }: any) => (
        <Stack horizontal styles={{ root: CUSTOM_CUSTOMER_LIST_STYLES.customDataGridColumns.tenantIdContainer }}>
          <Tooltip title={title} leaveDelay={500} arrow>
            <IconButton
              size="small"
              onClick={() => copyValue(row.tenantId, setTitle)}
              onMouseEnter={() => onMouseEnterTenantId(setTitle)}
              className={mergeStyles(CUSTOM_CUSTOMER_LIST_STYLES.customDataGridColumns.tenantIdTooltipContainerBtn)}
            >
              <ContentCopyIcon sx={CUSTOM_CUSTOMER_LIST_STYLES.customDataGridColumns.tenantIdTooltipCopyIconBtn} />
            </IconButton>
          </Tooltip>
          <CustomLinkNavigate to={navigateTo(row.tenantId)} text={row.tenantId} />
        </Stack>
      )
    },
    {
      headerName: "Tenant Name",
      field: "tenantName",
      minWidth: 230,
      flex: 1.5,
      sortable: true,
      renderCell: ({ row }: any) => <CustomLinkNavigate to={navigateTo(row.tenantId)} text={row.tenantName} />
    },
    {
      headerName: "TPID",
      field: "topParentId",
      minWidth: 100,
      flex: 0.2,
      sortable: true,
      renderCell: ({ row }: any) => <div>{row.topParentId}</div>
    },
    {
      headerName: "Segement Group",
      field: "segmentGroup",
      minWidth: 200,
      flex: 0.3,
      sortable: true
    },
    {
      headerName: "Segement",
      field: "segmentName",
      minWidth: 150,
      flex: 0.3,
      sortable: true
    },
    {
      headerName: "Sub Segment",
      field: "subSegmentName",
      minWidth: 200,
      flex: 0.2,
      sortable: true
    },
    {
      headerName: "Region",
      field: "regionName",
      minWidth: 150,
      flex: 0.1,
      sortable: true
    },
    {
      headerName: "Industry",
      field: "industryName",
      minWidth: 200,
      flex: 0.2,
      sortable: true
    },
    {
      headerName: "EntraID Logins",
      field: "currentValue",
      minWidth: 150,
      flex: 0.2,
      sortable: true
    }
  ];

  useEffect(() => {
    const searchInput = getSearchInput(
      initialFilters,
      searchText,
      filterOptions,
      sortOptions,
      paginationOptions,
      "customer"
    );
    callSearchAPIs(getCustomers({ ...searchInput }), setLoading, setRows, setIsError);
  }, [initialFilters, paginationOptions, filterOptions, searchText, sortOptions]);

  return (
    <CustomDataGrid
      columns={columns.map((column) => getColumn(column, skipColumnsFilterable))}
      filterMode="server"
      isError={isError}
      loading={loading}
      paginationMode="server"
      paginationOptions={paginationOptions}
      rows={rows}
      setFilterOptions={setFilterOptions}
      setPaginationOptions={setPaginationOptions}
      setSortOptions={setSortOptions}
      sortingMode="server"
    />
  );
}

function navigateTo(tenantId: string) {
  return `/customer?id=${tenantId}`;
}

function getColumn(col: GridColDef, skipColumnsFilterable?: string[]) {
  const stringOperators = getGridStringOperators().filter(
    ({ value }) => !["doesNotContain", "doesNotEqual", "isEmpty", "isNotEmpty", "isAnyOf"].includes(value)
  );

  col.filterable = !(skipColumnsFilterable ?? []).includes(col.field);

  if (["topParentId", "currentValue"].includes(col.field)) {
    return {
      ...col,
      filterOperators: getGridNumericOperators().filter(
        (operator) => !["isAnyOf", "isNotEmpty", "isEmpty"].includes(operator.value)
      )
    };
  } else if (["tenantId", "tenantName"].includes(col.field)) {
    return {
      ...col,
      filterOperators: stringOperators
    };
  } else {
    return {
      ...col,
      filterOperators: stringOperators.filter(({ value }) => value === "equals")
    };
  }
}

function copyValue(tenantId: string, setTitle: Dispatch<SetStateAction<string>>) {
  copy(tenantId).then(() => {
    setTitle(COPY_TENANT_ID);
  });
}

function onMouseEnterTenantId(setTitle: Dispatch<SetStateAction<string>>) {
  setTitle(COPY_TENANT_ID);
}
