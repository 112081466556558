import { LinkRegular } from "@fluentui/react-icons";
import { DataGrid, GridColDef, GridRowSelectionModel, GridToolbar } from "@mui/x-data-grid";
import React from "react";
import { formatDate } from "../../helper/DateFormat";
import { handleToggleDataGridRowSelection } from "../../Utils/js-common";

const SupportTable = (props: any) => {
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const handleSelection = handleToggleDataGridRowSelection(selectionModel, setSelectionModel);
  const showNoData = () => {
    const rootStyle = {
      alignItems: "center",
      display: "flex",
      fontSize: 18,
      fontWeight: 600,
      justifyContent: "center",
      padding: 200
    };
    return (
      <div style={rootStyle}>{`Support details are not available for ${props.tenantName} .`} </div>
    );
  };

  const columns: GridColDef[] = [
    {
      headerName: "Case Uri",
      field: "caseUri",
      width: 160,
      sortable: true,
      renderCell: (params) => (
        <a
          style={{
            color: "rgb(0, 120, 212)",
            textDecoration: "none",
            fontSize: 26,
            cursor: "pointer"
          }}
          href={params.row.caseUri}
          target="_blank"
          rel="noopener noreferrer"
          title={params.row.caseUri}
          tabIndex={params.row.caseUri}
        >
          <LinkRegular />
        </a>
      )
    },
    {
      headerName: "Request Number",
      field: "serviceRequestNumber",
      width: 180,
      sortable: true
    },
    {
      headerName: "Title",
      field: "title",
      width: 400,
      sortable: true
    },
    {
      headerName: "Created Time",
      type: "date",
      field: "createdTime",
      width: 120,
      sortable: true,
      valueGetter: (value, row) => new Date(row.createdTime),
      renderCell: (params) => formatDate(params.row.createdTime)
    },

    {
      headerName: "Closed Time",
      field: "closedDateTime",
      width: 120,
      sortable: true,
      valueGetter: (vaue, row) => new Date(row.closedDateTime),
      renderCell: (params) => formatDate(params.row.closedDateTime)
    },
    {
      headerName: "Service Request State",
      field: "serviceRequestState",
      width: 170,
      sortable: true
    },
    {
      headerName: "Service Request Current Severity",
      field: "serviceRequestCurrentSeverity",
      width: 240,
      sortable: true
    },
    {
      headerName: "Derived Product Name",
      field: "derivedProductName",
      width: 160,
      sortable: true,
      flex: 1
    }
  ];

  React.useEffect(() => {
    setTimeout(() => {
      const elements = document.getElementsByClassName("MuiDataGrid-virtualScroller");
      Array.from(elements).forEach(function (element) {
        element.setAttribute("tabIndex", "0");
      });
    }, 1000);
  }, []);
  return (
    <>
      {props.tableData && props.tableData.length > 0 ? (
        <div style={{ height: 600, width: "100%" }} tabIndex={0}>
          <DataGrid
            rows={props.tableData}
            columns={columns}
            getRowId={(row) => row.id}
            density="compact"
            disableDensitySelector
            disableRowSelectionOnClick
            showCellVerticalBorder
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true
              }
            }}
            onRowSelectionModelChange={handleSelection}
            rowSelectionModel={selectionModel}
          />
        </div>
      ) : (
        showNoData() || ""
      )}
    </>
  );
};

export default SupportTable;
