import { timeFormat } from "d3-time-format";

export const formatDate = (params: string) => {
  if (params) {
    const dateObj = new Date(params);
    if (isNaN(dateObj.getTime())) {
      return "-";
    }
    const date = timeFormat("%d-%b-%Y")(dateObj);
    return <>{`${date}`}</>;
  } else {
    return "-";
  }
};
