import { LinkRegular } from "@fluentui/react-icons";
import { DataGrid, GridColDef, GridRowSelectionModel, GridToolbar } from "@mui/x-data-grid";
import React from "react";
import { formatDate } from "../../helper/DateFormat";
import { handleToggleDataGridRowSelection } from "../../Utils/js-common";

const EngagementTable = (props: any) => {
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const handleSelection = handleToggleDataGridRowSelection(selectionModel, setSelectionModel);

  React.useEffect(() => {
    setTimeout(() => {
      const elements = document.getElementsByClassName("MuiDataGrid-virtualScroller");
      Array.from(elements).forEach(function (element) {
        element.setAttribute("tabIndex", "0");
      });
      const rowgrpElements = document.querySelectorAll("[role=\"grid\"]");
      rowgrpElements.forEach(function (element) {
        element.setAttribute("aria-busy", "true");
      });
    }, 1000);
  }, []);
  const showNoData = () => {
    const rootStyle = {
      alignItems: "center",
      display: "flex",
      fontSize: 18,
      fontWeight: 600,
      justifyContent: "center",
      padding: 200
    };
    return (
      <div style={rootStyle}>{`Program details are not available for ${props.tenantName} .`} </div>
    );
  };

  const columns: GridColDef[] = [
    {
      headerName: "Program Link",
      field: "programLink",
      width: 130,
      sortable: true,
      renderCell: (params) => (
        <a
          style={{
            color: "rgb(0, 120, 212)",
            textDecoration: "none",
            fontSize: 26,
            cursor: "pointer"
          }}
          href={params.row.programLink}
          target="_blank"
          rel="noopener noreferrer"
          title={params.row.programLink}
          tabIndex={params.row.tabIndex}
        >
          <LinkRegular />
        </a>
      )
    },
    {
      headerName: "Program Name",
      field: "programName",
      width: 270,
      sortable: true
    },
    {
      headerName: "Program Description",
      field: "programDescription",
      width: 240,
      sortable: true
    },
    {
      headerName: "Program Status",
      field: "programState",
      width: 190,
      sortable: true,
      align: "left",
      headerAlign: "left"
    },

    {
      headerName: "Program Owner",
      field: "programOwner",
      width: 170,
      sortable: true
    },
    {
      headerName: "Start Date",
      field: "programStart",
      type: "date",
      width: 150,
      sortable: true,
      valueGetter: (value, row) => new Date(row.programStart),
      renderCell: (params) => formatDate(params.row.programStart)
    },
    {
      headerName: "End Date",
      field: "programEnd",
      type: "date",
      width: 150,
      sortable: true,
      valueGetter: (value, row) => new Date(row.programEnd),
      renderCell: (params) => formatDate(params.row.programEnd)
    },
    {
      headerName: "Product",
      field: "programProduct",
      minWidth: 150,
      flex: 1,
      sortable: true
    }
    /* {
          headerName: "Meeting Status",
          field: "FriendlySubscriptionName",
          width: 200,
          sortable: true,
        },
        {
          headerName: "Meeting Type",
          field: "SubscriptionCreatedDate",
          width: 190,
          sortable: true,
        },
        {
            headerName: "Customer Product",
            field: "SubscriptionCreatedDate",
            width: 190,
            sortable: true,
          },
          {
            headerName: "Engagement Type",
            type:"date",
            field: "SubscriptionCreatedDate",
            width: 190,
            sortable: true,
          },
        {
          headerName: "Start Date",
          type:"date",
          field: "SubscriptionStartDate",
          width: 190,
          sortable: true,
          valueGetter: (params) => new Date(params.row.SubscriptionStartDate),
          renderCell:(params)=>formatDate(params.row.SubscriptionStartDate)
        },
        {
          headerName: "End Date",
          type:"date",
          field: "SubscriptionEndDate",
          width: 190,
          sortable: true,
          valueGetter: (params) => new Date(params.row.SubscriptionEndDate)
          ,
          renderCell:(params)=>formatDate(params.row.SubscriptionEndDate)
        },
        {
            headerName: "Engagement Notes",
            field: "SubscriptionCreatedDate",
            width: 190,
            sortable: true,
          },
          {
            headerName: "Sales Stage",
            field: "SubscriptionCreatedDate",
            width: 190,
            sortable: true,
          },
          {
            headerName: "Notes from Customer Product",
            field: "SubscriptionCreatedDate",
            width: 190,
            sortable: true,
          },
          {
            headerName: "Next action date",
            field: "SubscriptionCreatedDate",
            width: 190,
            sortable: true,
          }, */
  ];

  return props?.tableData && props?.tableData.length > 0 ? (
    <div style={{ height: 600 }}>
      <DataGrid
        rows={props.tableData}
        columns={columns}
        getRowId={(row) => row.id}
        density="compact"
        disableDensitySelector
        disableRowSelectionOnClick
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true
          }
        }}
        onRowSelectionModelChange={handleSelection}
        rowSelectionModel={selectionModel}
      />
    </div>
  ) : (
    showNoData()
  );
};

export default EngagementTable;
