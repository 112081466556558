import { FontIcon, Link, Shimmer, Stack } from "@fluentui/react";
import { IChartProps, getNextColor } from "@fluentui/react-charting";
import React from "react";
import FluentDonutChart from "../../ChartComponents/FluentDountChart";
import CustomCard from "../../SharedComponents/CustomCard";
import { getSumForNumberValues } from "../../Utils/js-common";
import { CUSTOMER_VIEW_STYLES } from "./CustomerView.styles";

const horizontalBarHeaderShimmerStyles = {
    root: { width: "50%" },
    shimmerWrapper: {
        backgroundColor: "var(--shimmer-color)",
        borderTopLeftRadius: 6,
        height: 20
    },
    shimmerGradient: {
        backgroundColor: "var(--shimmer-color)"
    }
};
const PartnerChart = (props: any) => {
    const [partnerType, setPartnerType] = React.useState<IChartProps>({});
    const [partnerOneName, setPartnerOneName] = React.useState<IChartProps>({});
    const [isFilterApplied, setIsFilterApplied] = React.useState(false);
    const [highlightedCard, setHighlightedCard] = React.useState(-1);
    const customCardContainerRef = React.useRef<HTMLDivElement>(null);
    const width = React.useMemo(() => customCardContainerRef.current?.clientWidth, [customCardContainerRef.current]);
    const onDonutChartSlicesClicked = (itemKey: string, value: string | null) => {
        if (props.setChartFilter) {
            props.setChartFilter({
                filterKey: itemKey,
                value: value === "null" ? null : value
            });
        }
        setIsFilterApplied(true);
    };
    const customCardPropsMemo = React.useMemo(
      () =>
        [
          {
            ...partnerType,
            key: "customer-view-customer-partner-chart-by-partner-type",
            title: "Partner Type"
          },
          {
            ...partnerOneName,
            key: "customer-view-customer-partner-chart-by-partner-name",
            title: "Partner Name"
          }
        ]
          .filter(({ chartData }) => chartData === undefined || chartData?.length)
          .map(({ key, title, ...restData }) => {
            return {
              key,
              title,
              component: () => (
                <FluentDonutChart
                  data={restData as IChartProps}
                  isChartLoading={props.isChartLoading}
                  styles={CUSTOMER_VIEW_STYLES.COMMON_STYLES.CSS_STYLES.donutChart}
                  valueInsideDonut={getSumForNumberValues(
                    ((restData as IChartProps).chartData || []).map(({ data }) => data || 0)
                  )}
                  width={width}
                />
              )
            };
          }),
      [partnerType, partnerOneName]
    );

    const getHighlightedStatus = (index: number) => isFilterApplied && highlightedCard === index;
    const horizontalBarHeader = (title: string, index: number, isLoading: boolean) =>
        isLoading ? (
            <Shimmer styles={horizontalBarHeaderShimmerStyles} />
        ) : (
            <Stack horizontal>
                <div>{title}</div>
                {getHighlightedStatus(index) && (
                    <Link
                        underline
                        onClick={() => {
                            setIsFilterApplied(false);
                            setHighlightedCard(-1);
                            props.setChartFilter({
                                filterKey: "",
                                value: ""
                            });
                        }}
                        styles={{ root: { position: "absolute", right: 13 } }}
                    >
                        <FontIcon title="Clear Filter" iconName="ClearFilter" aria-label="Clear Filter"></FontIcon>
                    </Link>
                )}
            </Stack>
        );

    React.useEffect(() => {
        if (props.chartData && props.keys) {
            props.keys.forEach((itemKey: string) => {
                switch (itemKey) {
                    case "partnerType":
                        {
                            const tempData = props?.chartData?.partnerType.map((item: any, idx: number) => {
                                const obj = {
                                    legend: item.partnerType,
                                    data: item.partnerTypeCount,
                                    color: getNextColor(idx),
                                    onClick: () => onDonutChartSlicesClicked(itemKey, item.partnerType)
                                };
                                return obj;
                            });
                            setPartnerType({
                                chartTitle: "Donut Chart",
                                chartData: tempData
                            });
                        }
                        break;

                    case "partnerOneName":
                        {
                            const tempData = props?.chartData?.partnerOneName.map((item: any, idx: number) => {
                                const obj = {
                                    legend: item.partnerOneName,
                                    data: item.partnerOneNameCount,
                                    color: getNextColor(idx),
                                    onClick: () => onDonutChartSlicesClicked(itemKey, item.partnerOneName)
                                };
                                return obj;
                            });
                            setPartnerOneName({
                                chartTitle: "Donut Chart",
                                chartData: tempData
                            });
                        }
                        break;
                    default: {
                        return { chartTitle: "Donut Chart", chartData: [] };
                    }
                }
            });
        }
    }, [props?.chartData, props?.keys]);

    return (
        <div className={CUSTOMER_VIEW_STYLES.COMMON_STYLES.CLASS_NAMES.customCardContainer}>
            {customCardPropsMemo.map(({ component, key, title }, index) => (
                <div
                    ref={customCardContainerRef}
                    key={key}
                    style={{ flex: 1 }}
                    onMouseEnter={() => setHighlightedCard(index)}
                    onMouseLeave={() => setHighlightedCard(-1)}
                >
                    <CustomCard
                        cardHeaderTitle={horizontalBarHeader(title, index, props.isChartLoading)}
                        styles={{
                            root: { flex: 1, height: "100%" },
                            cardHeaderStart: { flex: 1 },
                            cardHeaderTitleContainer: { flex: 1 }
                        }}
                    >
                        {component()}
                    </CustomCard>
                </div>
            ))}
        </div>
    );
};

export default PartnerChart;
