import debounce from "@mui/material/utils/debounce";
import { useEffect, useState } from "react";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowDimensions(getWindowDimensions());
    }, 100);

    window.addEventListener("resize", handleResize);
    return () => {
      handleResize.clear();
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowDimensions;
};
