import { DocumentCard } from "@fluentui/react";
import { getNextColor } from "@fluentui/react-charting";
import { Card, FluentProvider, webLightTheme } from "@fluentui/react-components";
import { useContext, useRef } from "react";
import DisplayAPIsException from "../../../BasicComponents/DisplayAPIsExceptionAlert";
import CountAnnotation from "../../../helper/CountAnnotation";
import { CustomShimmer } from "../../../helper/CustomShimmer";
import { arrSortAscByKeyValue } from "../../../Utils/js-common";
import { PRODUCT_VIEW_DATA } from "../Common/ProductView.constants";
import { PRODUCT_VIEW_STYLES } from "../Common/ProductView.styles";
import { OverviewAndSummaryMutationContext } from "../ProductView";

export function ProductViewOverviewContainer() {
  const documentCardRef = useRef<HTMLDivElement>(null);
  const { data, isError, isLoading } = useContext(OverviewAndSummaryMutationContext);

  const getOverviewData = (data: any[]) => {
    const sortedData = arrSortAscByKeyValue({
      key: "productKPIName",
      data: JSON.parse(JSON.stringify(data))
    });

    return sortedData.map((mmetric: any, index: number) => ({
      ...mmetric,
      color: getNextColor(index)
    }));
  };

  const displayContents = (
    <FluentProvider theme={webLightTheme}>
      <Card style={PRODUCT_VIEW_STYLES.OVERVIEW_SECTOIN.cardContent}>
        {data?.overviewData && data.overviewData.length > 0 ? (
          <CountAnnotation cardData={getOverviewData(data.overviewData)} />
        ) : (
          <div style={PRODUCT_VIEW_STYLES.OVERVIEW_SECTOIN.NoDataStyles}>
            {PRODUCT_VIEW_DATA.PRODUCT_VIEW.CARD_N0_DATA_MESSAGE}
          </div>
        )}
      </Card>
    </FluentProvider>
  );

  const displayAPIsException = isError && <DisplayAPIsException height={documentCardRef.current?.offsetHeight} />;

  return (
    <div ref={documentCardRef} style={PRODUCT_VIEW_STYLES.OVERVIEW_SECTOIN.overviewContainer}>
      <DocumentCard styles={{ root: PRODUCT_VIEW_STYLES.OVERVIEW_SECTOIN.cardRoot }}>
        {isLoading ? (
          <CustomShimmer rowCount={1} columnCount={4} styles={PRODUCT_VIEW_STYLES.OVERVIEW_SECTOIN.shimmerStyles} />
        ) : (
          displayContents || displayAPIsException
        )}
      </DocumentCard>
    </div>
  );
}
