import { CustomFilterPillProps } from "../../SharedComponents/Interfaces/CustomFilterPill.interfaces";
import { createFilterPill, mapFilterPillMenuItemsPredicate } from "./CustomerSearch.common";

export const FILTER_PILL = {
  INITIAL_STATE: { menuItems: [], selectedMenuItems: [] },
  INITIAL_FILTER_VALUES: ["Yes", "No"].map(mapFilterPillMenuItemsPredicate),
  INITIAL_DATA: [
    createFilterPill("regions", "Regions"),
    createFilterPill("segmentGroup", "Segment Group"),
    createFilterPill("segments", "Segments"),
    createFilterPill("subSegments", "SubSegments"),
    createFilterPill("industry", "Industry"),
    createFilterPill("hasPartner", "HasPartner"),
    createFilterPill("isFastTrackStrategic", "IsFastTrackStrategic"),
    createFilterPill("isCAT", "IsCAT"),
    createFilterPill("isSST", "IsSST")
  ] as CustomFilterPillProps[]
};
