import { CohortsTab } from "../Containers/ProductViewTabs/CohortsTab";
import { DetailedViewTab } from "../Containers/FeatureTabs/DetailedViewTab";
import { FeatureTab } from "../Containers/ProductViewTabs/FeatureTab";
import { ICarouselProps } from "@coherence-design-system/controls";
import { KeyMetricsFunnelView } from "../Containers/ProductViewTabs/KeyMetricsFunnelView";
import { OpportunityTenantsTab } from "../Containers/ProductViewTabs/OpportunityTenantsTab";
import { OverviewAndSummaryMutationProps, ProductViewFiltersProps, ProductViewSummaryTabCardProps } from "../Common/ProductView.interfaces";
import { SegmentationTab } from "../Containers/FeatureTabs/SegmentationTab";
import { SummaryTab as FeatureViewSummaryTab } from "../Containers/FeatureTabs/SummaryTab";
import { SummaryTab as ProductViewSummaryTab, tenantCountMetricsTooltipMessage } from "../Containers/ProductViewTabs/SummaryTab";

export const PRODUCT_VIEW_DATA = {
  PRODUCT_VIEW: {
    INITIAL_TAB_NAME: "industryname",
    INITIAL_MUTATION: {
      data: { overviewData: [], summaryData: [] }
    } as OverviewAndSummaryMutationProps,
    INITIAL_PRODUCT_VIEW_FILTERS: {
      hasPartner: "",
      industry: "",
      isCAT: "",
      isFastTrackStrategic: "",
      isSST: "",
      productName: "",
      region: "",
      segment: "",
      subSegment: ""
    } as ProductViewFiltersProps,
    DEFAULT_PRODUCT_NAME: "Microsoft Intune",
    CARD_N0_DATA_MESSAGE: "No data is available. Try resetting filters",
    VIEW_NO_DATA_MESSAGE: "This view has no data to display"
  },
  TABS: {
    DATA: {
      pivot: { ariaLabel: "Pivot Selection" },
      pivotItems: [
        { headerText: "Summary", itemKey: "SummaryTab", component: ProductViewSummaryTab },
        { headerText: "Cohorts", itemKey: "CohortsTab", component: CohortsTab },
        { headerText: "Opportunity Tenants", itemKey: "OpportunityTenantsTab", component: OpportunityTenantsTab },
        { headerText: "Funnel View", itemKey: "KeyMetricsFunnelView", component: KeyMetricsFunnelView },
        { headerText: "Feature View", itemKey: "FeatureProductView", component: FeatureTab }
      ]
    },
    TAB: {
      SUMMARY: {
        DATA: [
          {
            key: "product-view-summary-tab-key-metrics",
            header: {
              title: "How are usage and licenses trending?",
              tooltip:
                "See how important metrics are trending over time for security products. The graph below shows 6 month trend."
            },
            isTenantMetrics: 0
          },
          {
            key: "product-view-summary-tab-tenant-count-metric",
            header: {
              title: "How are tenants with usage and licenses trending?",
              tooltip: tenantCountMetricsTooltipMessage
            },
            isTenantMetrics: 1
          }
        ] as ProductViewSummaryTabCardProps[]
      },
      COHORTS: {
        CARD_HEADER_TOOLTIPS: {
          METRICS_DETAILS:
            "See which cohorts are growing in the grid below. Current shows current month value. MoM shows month on month change. You can sort based on current month numbers or MoM growth.",
          METRICS_BY_CATEGORY:
            "See top cohorts by a specific metric. Use the up and down buttons on right-bottom corner to navigate to different pages."
        }
      },
      OPPORTUNITY_TENANTS: {
        PAGE_SIZE_OPTIONS: [10, 25, 50, 75, 100],
        PAGINATION_MODEL: {
          page: 0,
          pageSize: 10
        },
        CARD_HEADER: {
          TOP_GROWING: {
            key: "product-view-opportunity-tenants-tab-growing-metrics",
            title: "",
            tooltip:
              "The grid below shows top growing tenants by comparing usage with previous month. Click on the the name hyperlink in the table to navigate to customer view."
          },
          TOP_DECLINING: {
            key: "product-view-opportunity-tenants-tab-declining-metrics",
            title: "",
            tooltip:
              "The grid below shows top declining tenants by comparing usage with previous month. Click on the the name hyperlink in the table to navigate to customer view."
          }
        }
      },
      KEY_METRICS_FUNNEL_VIEW: {
        CARD_HEADER: {
          key: "product-view-funnel-view-tab-funnel-key-metrics",
          title: "Key Metrics Funnel",
          tooltip: "Key metrics being shown in a funnel view in the chart below. These are current month values."
        }
      },
      TENANTS_METRICS_FUNNEL_VIEW: {
        CARD_HEADER: {
          key: "product-view-funnel-view-tab-funnel-tenants-count-metrics",
          title: "Tenants Count Metrics Funnel",
          tooltip: "Tenants Count metrics being shown in a funnel view in the chart below. These are current month values."
        }
      },
      FEATURE_VIEW: {
        TABS_DATA: {
          pivot: { ariaLabel: "product-view-feature-tab-aria-label" },
          pivotItems: [
            {
              headerText: "Summary",
              itemKey: "FeatureTabSummary",
              component: FeatureViewSummaryTab
            },
            { headerText: "Detailed View", itemKey: "FeatureTabDetailedView", component: DetailedViewTab },
            { headerText: "Segmentation", itemKey: "FeatureTabCohorts", component: SegmentationTab }
          ]
        },
        SUMMARY_TAB: {
          FEATURE_VIEW_ALL_UP: "Feature View - All Up",
        },
        DETAILED_VIEW: {
          INITIAL_CAROUSEL_DATA: {
            items: [],
            title: "",
            responsive: true,
            itemWidth: 298,
            breakpoints: {
              xxxl: { width: 1805, items: 4 },
              xxl: { width: 1366, items: 3 },
              xl: { width: 1024, items: 3 },
              l: { width: 640, items: 2 },
              m: { width: 480, items: 2 },
              s: { width: 320, items: 1 }
            },
            styles: {
              container: {
                "& > div": {
                  marginBottom: 5
                },
                "& > div:nth-child(2)": {
                  marginBottom: 0
                }
              }
            }
          } as ICarouselProps
        },
        FEATURE_VIEW_COHORTS_TAB: {}
      }
    }
  },
};
