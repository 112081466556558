import React from "react";
import ReactMarkdown from "react-markdown";

interface InsightsComponentProps {
  insightDataInfo: string;
}

const InsightsComponent: React.FC<InsightsComponentProps> = ({
  insightDataInfo,
}) => {
  return (
    <ReactMarkdown>
      {insightDataInfo}
    </ReactMarkdown>
  );
};

export default InsightsComponent;
