import { appInsights } from "../AppInsights";

export const logPageView = (pageName: string, pageUri: string, customProps?: any) => {
  appInsights.trackPageView({
    name: pageName,
    uri: pageUri,
    properties: {
      loginName: sessionStorage.getItem("loginName"),
      email: sessionStorage.getItem("loginUserName"),
      customProps: customProps
    }
  });
};

export const startPageLoadTimeTracker = (pageName: string) => {
  appInsights.startTrackPage(pageName);
};

export const stopPageLoadTimeTracker = (pageName: string, pageUri: string) => {
  appInsights.stopTrackPage(pageName, pageUri);
};

export const logException = (pageName: string, componentName: string, apiName: string, errorDetails?: any) => {
  appInsights.trackException({
    severityLevel: 2,
    properties: {
      loginName: sessionStorage.getItem("loginName"),
      email: sessionStorage.getItem("loginUserName"),
      pageName: pageName,
      componentName: componentName,
      apiName: apiName,
      errorDetails: errorDetails
    }
  });
};

export const logEvent = (eventName: string, pageName: string, componentName: string, customProps?: any) => {
  appInsights.trackEvent({
    name: eventName,
    properties: {
      loginName: sessionStorage.getItem("loginName"),
      email: sessionStorage.getItem("loginUserName"),
      pageName: pageName,
      componentName: componentName,
      customProps: customProps
    }
  });
};
