import React from "react";
import openAI from "../../assets/openai.svg";
import { shimmerWithElementGapRow } from "../../helper/ShimmerElement";
import { Shimmer } from "@fluentui/react";

export default function CustomerInfo(props) {
  return (
    <>
      {" "}
      <div
        style={{
          maxWidth: "38%",
          minWidth: "38%",
          marginRight: 5,
          background: "#FFF",
          overflow: "auto",
          marginTop: "-10px"
        }}
      >
        {(props.loading || props.custinfoload) && (
          <>
            <div style={{ marginTop: 24 }}>
              <Shimmer
                style={{
                  marginTop: 10,
                  paddingLeft: 4,
                  paddingRight: 3
                }}
                shimmerColors={{ shimmer: "rgb(222, 236, 249)" }}
              ></Shimmer>
              <Shimmer
                style={{
                  marginTop: 10,

                  paddingLeft: 4,
                  paddingRight: 3
                }}
                shimmerColors={{ shimmer: "rgb(222, 236, 249)" }}
              ></Shimmer>
              <Shimmer
                style={{
                  marginTop: 10,
                  paddingLeft: 4,
                  paddingRight: 3
                }}
                shimmerElements={shimmerWithElementGapRow}
                shimmerColors={{ shimmer: "rgb(222, 236, 249)" }}
              ></Shimmer>
              <Shimmer
                style={{
                  marginTop: 10,
                  paddingLeft: 4,
                  paddingRight: 3
                }}
                shimmerElements={shimmerWithElementGapRow}
                shimmerColors={{ shimmer: "rgb(222, 236, 249)" }}
              ></Shimmer>
              <Shimmer
                style={{
                  marginTop: 10,
                  paddingLeft: 4,
                  paddingRight: 3
                }}
                shimmerElements={shimmerWithElementGapRow}
                shimmerColors={{ shimmer: "rgb(222, 236, 249)" }}
              ></Shimmer>
            </div>
          </>
        )}
        {!props.loading && !props.custinfoload && (
          <div style={{ paddingLeft: "7px" }}>
            <h4 style={{ display: "flex" }}>
              <img src={openAI} width={20} height={20} alt="OpenAI Image" />
              <span style={{ paddingLeft: 10 }}> Powered by Azure OpenAI </span>{" "}
            </h4>
            <p style={{ marginTop: -10 }}>{props.displayext}</p>
          </div>
        )}
      </div>
    </>
  );
}
