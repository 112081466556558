import { Stack } from "@fluentui/react";
import {
  Button,
  Dropdown,
  Field,
  InfoLabel,
  Input,
  Option,
  OptionOnSelectData,
  SelectionEvents,
  Textarea,
  useId
} from "@fluentui/react-components";
import * as React from "react";

import { useController, UseControllerProps, useForm } from "react-hook-form";
import { FormValidationErrorMessage } from "../../../../SharedComponents/FormValidationErrorMessage";
import {
  ButtonLoadingState,
  DISABLED_BUTTON_LOADING_STATE as DISABLED,
  INITIAL_BUTTON_LOADING_STATE as INITIAL,
  RED_COLOR
} from "../../../../SharedComponents/Styles/CustomCard.styles";
import { Timer } from "../../../../Utils/js-common";
import { FONT_FAMILY_STYLE, INITIAL_TIMER_PROPS } from "../../ReportCatalog.common";
import { FormValues, SelectFieldProps, TimerProps } from "../../ReportCatalog.interfaces";
import { useGetReportCatalogDataQuery, useUpsertReportMutation } from "../../ReportCatalog.slice";

const splittedStackStyles = {
  root: {
    flex: "1 0 calc(50% - 10px)"
  }
};

const defaultValues = {
  description: "",
  owner: "",
  tag: "",
  title: "",
  url: ""
};

const AsteriskMark = (style?: React.CSSProperties) => <b style={{ color: RED_COLOR, padding: 2, ...style }}>*</b>;

export const LabelOrAsteriskLabel = (props: { label: string; enableAsterisk?: boolean }) => {
  return props.enableAsterisk ? (
    <>
      {`${props.label}`}
      <AsteriskMark />
    </>
  ) : (
    props.label
  );
};

const InputField = ({
  allowMultipleLines,
  children,
  controllerProps,
  label
}: {
  allowMultipleLines?: boolean;
  children?: React.ReactNode;
  controllerProps: UseControllerProps<FormValues>;
  label?: string;
}) => {
  const {
    field,
    fieldState: { error }
  } = useController(controllerProps);

  const errorType = error?.type;
  const required = errorType === "required" ? `${label} is required` : "";
  const pattern = errorType === "pattern" ? `${label} is invalid` : "";
  const maxLength =
    errorType === "maxLength" ? `${label} maximum ${controllerProps.rules?.maxLength} characters allowed` : "";

  return (
    <Field
      label={{
        children: (
          <>
            <LabelOrAsteriskLabel label={label!} enableAsterisk={!!controllerProps.rules?.required} />
            {children}
          </>
        )
      }}
      validationMessage={required || pattern || maxLength}
    >
      {(allowMultipleLines && (
        <Textarea
          aria-invalid={error ? "true" : "false"}
          maxLength={controllerProps.rules?.maxLength as number}
          resize="vertical"
          {...field}
        />
      )) || (
        <Input
          appearance="outline"
          aria-invalid={error ? "true" : "false"}
          maxLength={controllerProps.rules?.maxLength as number}
          {...field}
        />
      )}
    </Field>
  );
};

const SelectField = ({
  clearable,
  data,
  formState,
  id,
  label,
  name,
  onOptionSelect,
  register,
  required,
  selectedOptions,
  selectedValue
}: SelectFieldProps) => {
  return (
    <Field
      label={<LabelOrAsteriskLabel label={label!} enableAsterisk={required} />}
      validationMessage={formState.errors[name] ? `${label} is required` : ""}
    >
      <Dropdown
        aria-labelledby={id}
        placeholder="--Select--"
        id={`${id}-controlled`}
        value={selectedValue}
        selectedOptions={selectedOptions || []}
        onOptionSelect={onOptionSelect}
        {...register(name, { required })}
        clearable={clearable}
      >
        {data?.map((option) => (
          <Option text={option} value={option} key={`option-${id}-${option}`}>
            {option}
          </Option>
        ))}
      </Dropdown>
    </Field>
  );
};

const REPORT_URL_HINT = (
  <InfoLabel
    info={
      <div style={{ ...FONT_FAMILY_STYLE, fontSize: 12 }}>
        <div style={{ fontWeight: 600, color: RED_COLOR }}>URL formats:</div>
        <div>{"https://msit.powerbi.com/groups/me/reports/{report-id}"}</div>
        <div>{"https://msit.powerbi.com/groups/{group-id}/reports/{report-id}"}</div>
        <div>{"https://msit.powerbi.com/groups/me/reports/{report-id}/{report-section}"}</div>
        <div>{"https://msit.powerbi.com/groups/{group-id}/reports/{report-id}/{report-section}"}</div>
      </div>
    }
  />
);

const KEYWORDS_HINT = (
  <InfoLabel
    info={
      <div style={{ ...FONT_FAMILY_STYLE, fontSize: 12 }}>
        <div style={{ fontWeight: 600, color: RED_COLOR }}>Keywords format:</div>
        <div>Defender, Serverless</div>
      </div>
    }
  />
);

const OnboardReportForm = React.forwardRef(
  (props: { onLoadingState: (state: ButtonLoadingState) => void }, ref: any) => {
    const productId = useId("dropdown-default-product");
    const { data, isSuccess } = useGetReportCatalogDataQuery(null);
    const [upsertReport] = useUpsertReportMutation({
      fixedCacheKey: "ReportCatalogData"
    });
    const inputRef = React.useRef<HTMLButtonElement>(null);
    const timer = React.useRef<TimerProps>(INITIAL_TIMER_PROPS);
    const [errorMessage, setErrorMessage] = React.useState<string>();
    // Form Validation
    const { control, formState, handleSubmit, register, reset, setValue } = useForm<FormValues>({
      defaultValues,
      mode: "onChange"
    });
    // Product Dropdown
    const [productSelected, setProductSelected] = React.useState<string[]>([]);
    const [productValue, setProductValue] = React.useState("");
    const onProductSelect = (event: SelectionEvents, data: OptionOnSelectData) => {
      setProductSelected(data.selectedOptions);
      setProductValue(data.optionText ?? "");
    };

    const getSelectValues = React.useCallback(
      (predicate: (value: string) => string) => (isSuccess ? Array.from<string>(new Set(data.map(predicate))) : []),
      [data, isSuccess]
    );

    const handleProductValues = React.useCallback(
      (values: string[]) => {
        const element = values.at(0) as string;
        setProductSelected([element]);
        setProductValue(element);
        setValue("productValue", element);
      },
      [setValue]
    );

    const handleErrorMessage = React.useCallback((message: any) => {
      setErrorMessage(message);
      timer.current = Timer(() => {
        setErrorMessage("");
      }, 5000);
      return () => timer.current.clearTimeout();
    }, []);

    const onSubmit = (data: FormValues) => {
      props.onLoadingState("loading");
      upsertReport({
        description: data.description,
        id: 0,
        keywords: data.tag,
        owner: data.owner,
        product: data.productValue,
        report: data.title,
        reportLink: data.url
      })
        .then(({ error }: any) => {
          if (error) {
            handleErrorMessage(error.data.title);
            props.onLoadingState(INITIAL);
          } else {
            alert("Saved Successfully");
            handleErrorMessage("");
            reset({ ...defaultValues });
            props.onLoadingState(DISABLED);
          }
        })
        .catch(() => props.onLoadingState(INITIAL));
    };

    React.useImperativeHandle(
      ref,
      () => ({
        onClick: () => inputRef.current?.click()
      }),
      []
    );

    React.useEffect(() => {
      if (isSuccess) {
        handleProductValues(getSelectValues(({ product }: any) => product));
      }
    }, [getSelectValues, handleProductValues, isSuccess]);

    const handleLoadingState = React.useCallback((state: ButtonLoadingState) => props.onLoadingState(state), [props]);

    React.useEffect(() => {
      if (formState.isValid) {
        handleLoadingState(INITIAL);
      } else {
        handleLoadingState(DISABLED);
      }
    }, [formState.isValid]);

    return (
      <Stack tokens={{ childrenGap: 10 }}>
        <Stack>
          <InputField
            label="Title"
            controllerProps={{
              control,
              name: "title",
              rules: { required: true, maxLength: 200 }
            }}
          />
        </Stack>
        <Stack>
          <InputField
            label="Description"
            controllerProps={{
              control,
              name: "description",
              rules: { required: true, maxLength: 4000 }
            }}
            allowMultipleLines
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <Stack styles={splittedStackStyles}>
            <SelectField
              id={productId}
              name="productValue"
              label="Product"
              selectedValue={productValue}
              data={getSelectValues(({ product }: any) => product)}
              selectedOptions={productSelected}
              onOptionSelect={onProductSelect}
              register={register}
              formState={formState}
              required
            />
          </Stack>
          <Stack styles={splittedStackStyles}>
            <InputField
              label="Owner"
              controllerProps={{
                control,
                name: "owner",
                rules: { required: true, maxLength: 100 }
              }}
            />
          </Stack>
        </Stack>
        <Stack>
          <InputField
            label="Keywords"
            controllerProps={{
              control,
              name: "tag",
              rules: { required: true, maxLength: 2000 }
            }}
          >
            {KEYWORDS_HINT}
          </InputField>
        </Stack>
        <Stack>
          <InputField
            label="Report URL"
            controllerProps={{
              control,
              name: "url",
              rules: {
                required: true,
                maxLength: 2000,
                pattern:
                  /https?:\/\/(?:msit\.powerbi\.com)(\/groups\/)([a-zA-Z0-9\-_]{36}|[a-zA-Z0-9\-_]{2})(\/reports\/)(?:\S+)?/
              }
            }}
          >
            {REPORT_URL_HINT}
          </InputField>
        </Stack>
        <Stack>
          <FormValidationErrorMessage
            message={errorMessage}
            onMouseEnter={timer.current.pause}
            onMouseLeave={timer.current.resume}
          />
        </Stack>
        <Button appearance="primary" style={{ display: "none" }} onClick={handleSubmit(onSubmit)} ref={inputRef}>
          Submit
        </Button>
      </Stack>
    );
  }
);

OnboardReportForm.displayName = "OnboardReportForm";

export default OnboardReportForm;
