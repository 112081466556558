import { PrimaryButton } from "@fluentui/react";
import React from "react";

const AccessDenied = () => {
  return (
    <div className="access-denied">
      <svg className="access-denied-gif" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <path
          fill="#59B4D9"
          d="M27.969 9c0 4.363-3.568 7.9-7.969 7.9s-7.968-3.537-7.968-7.9S15.598 1.1 20 1.1s7.969 3.537 7.969 7.9m-2.647 11L20 28.516 14.677 20H7v21h26V20z"
        />
        <circle fill="#FFF" cx="31" cy="39" r="9.342" />
        <path
          fill="#BA141A"
          d="M31 31.214c4.292 0 7.786 3.492 7.786 7.786S35.292 46.786 31 46.786 23.214 43.292 23.214 39s3.494-7.786 7.786-7.786m0-3.114c-6.02 0-10.9 4.88-10.9 10.9S24.98 49.9 31 49.9 41.9 45.02 41.9 39 37.02 28.1 31 28.1z"
        />
        <path fill="#BA141A" d="M23.292 33.495l2.202-2.201 13.213 13.212-2.202 2.202z" />
      </svg>
      <div className="access-denied-div">
        <h1>Access Denied</h1>
        <p>
          As part of SFI, we have changed our Access control policies.
          <br></br>
          To gain access to Security360 portal, please request membership <br></br>to Security CxE Data Services
          entitlement via CoreIdentity.
        </p>
        <PrimaryButton
          className="core-identity-button"
          text="Request Membership"
          onClick={() =>
            window.open("https://coreidentity.microsoft.com/manage/Entitlement/entitlement/securitycxed-mnwm")
          }
        />
        <p>
          For more details on membership roles, refer&nbsp;
          <a href="https://dev.azure.com/seccxeds/DataServices/_wiki/wikis/DataServices.wiki/39/Request-Access">
            Request Access - Overview
          </a>
        </p>
        <p>
          For any questions, contact us at&nbsp;
          <a href="mailto:security360core@microsoft.com">security360core@microsoft.com</a>
        </p>
      </div>
    </div>
  );
};

export default AccessDenied;
