import { Stack } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { logException, logPageView } from "../../../helper/AppInsightsHelper";
import { CustomShimmer } from "../../../helper/CustomShimmer";
import { ALLREPORTS_STACK_PROPS, FONT_FAMILY_STYLE } from "../ReportCatalog.common";
import { useGetReportCatalogDataQuery } from "../ReportCatalog.slice";
import AllReportsDataGrid from "./AllReportsDataGrid";
import ProductSelectFilter from "./Filters/ProductSelectFilter";
import { SearchInputFilter } from "./Filters/SearchInputFilter";
import { useSearchParams } from "react-router-dom";

const ReportCatalogAllReports = () => {
  const [searchParams] = useSearchParams();
  const productName = searchParams.get("id");
  const [searchReportValue, setSearchReportValue] = useState("");
  const { data, error, isLoading } = useGetReportCatalogDataQuery(null);

  const onSearchInputFilterChange = useCallback(
    (e?: React.ChangeEvent<HTMLInputElement>) => setSearchReportValue(e?.target.value ?? ""),
    []
  );

  useEffect(() => {
    logPageView("Report Catalog Page", window.location.href);
  }, []);

  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [defaultSelectedProducts, setDefaultSelectedProducts] = useState<string[]>([]);

  const onDefaultSelectedProducts = useCallback((selected: string[]) => {
    setDefaultSelectedProducts(selected);
    setSelectedProducts(selected);
  }, []);

  const onSelectedProducts = useCallback((selected: string[]) => {
    setSelectedProducts(selected);
  }, []);

  useEffect(() => {
    if (data) {
      const distinctProducts: string[] = Array.from(new Set(data.map((item: any) => item.product)));
      if (productName !== null) {
        onDefaultSelectedProducts(distinctProducts.filter((name) => name.includes(productName)));
      } else {
        onDefaultSelectedProducts(["selectAll", ...distinctProducts]);
      }
      setTimeout(() => {
        const rowgrpElements = document.querySelectorAll("[role=\"grid\"]");
        rowgrpElements.forEach(function (element) {
          element.setAttribute("aria-busy", "true");
        });
      }, 1000);
    }
    if (error) {
      logException("Report Catalog", "ReportCatalog", "useGetReportCatalogDataQuery", error);
    }
  }, [data, error, onDefaultSelectedProducts, productName]);

  return (
    <Stack {...ALLREPORTS_STACK_PROPS.container}>
      <Stack {...ALLREPORTS_STACK_PROPS.horizontalContainer}>
        <Stack {...ALLREPORTS_STACK_PROPS.innerStack}>
          <b style={FONT_FAMILY_STYLE}>All reports</b>
          <SearchInputFilter onChange={onSearchInputFilterChange} />
        </Stack>
        <Stack {...ALLREPORTS_STACK_PROPS.dropdownStack}>
          <ProductSelectFilter
            selectedProductOptions={defaultSelectedProducts}
            onSelectedProducts={onSelectedProducts}
            data={data || []}
          />
        </Stack>
      </Stack>

      {isLoading ? (
        <CustomShimmer />
      ) : (
        <AllReportsDataGrid
          selProds={selectedProducts}
          searchReportVal={searchReportValue}
          rowData={data || []}
          error={error}
        />
      )}
    </Stack>
  );
};

export default ReportCatalogAllReports;
