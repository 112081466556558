import React, { useState } from "react";
import { IStackStyles, IStackTokens, Link, Shimmer, Stack } from "@fluentui/react";
import { Divider } from "@mui/material";
import { shimmerWithElementGapRow } from "../../helper/ShimmerElement";
import DomainPanel from "../Domain/DomainPanel";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

export default function CustomerDetails(props: any) {
  const [isOpen, setIsOpen] = useState(false);
  const stackTokens: IStackTokens = { childrenGap: 10 };
  const labelStyles: IStackStyles = {
    root: {
      width: "200",
      label: {
        foreGroundColor: "#747372 "
      }
    }
  };

  return (
    <>
      <>
        <div
          style={{
            minWidth: 300,
            maxWidth: 300,
            marginLeft: 5,
            marginRight: 5,
            background: "#FFF",
            lineHeight: 2,
            paddingLeft: 10
          }}
        >
          <>
            <Stack horizontal tokens={stackTokens}>
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 90,
                    paddingLeft: 5
                  }}
                  shimmerElements={shimmerWithElementGapRow}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "90px" }}>Industry </span>
                </Stack>
              )}
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 200,
                    paddingLeft: 4,
                    paddingRight: 3
                  }}
                  shimmerElements={shimmerWithElementGapRow}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "200px" }}>:&nbsp;{props.custDetails?.industryName}</span>
                </Stack>
              )}
            </Stack>

            <Stack horizontal tokens={stackTokens}>
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 90,
                    paddingLeft: 5
                  }}
                  shimmerElements={shimmerWithElementGapRow}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "90px" }}>Vertical </span>
                </Stack>
              )}

              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 200,
                    paddingLeft: 4,
                    paddingRight: 3
                  }}
                  shimmerElements={shimmerWithElementGapRow}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "200px" }}>:&nbsp;{props.custDetails?.verticalName}</span>
                </Stack>
              )}
            </Stack>

            <Stack horizontal tokens={stackTokens}>
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 90,
                    paddingLeft: 5
                  }}
                  shimmerElements={shimmerWithElementGapRow}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "90px" }}>Segment </span>
                </Stack>
              )}
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 200,
                    paddingLeft: 4,
                    paddingRight: 3
                  }}
                  shimmerElements={shimmerWithElementGapRow}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "200px" }}>:&nbsp;{props.custDetails?.segmentName}</span>
                </Stack>
              )}
            </Stack>
            <Stack horizontal tokens={stackTokens}>
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 90,
                    paddingLeft: 5
                  }}
                  shimmerElements={shimmerWithElementGapRow}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "90px" }}>Country </span>
                </Stack>
              )}
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 200,
                    paddingLeft: 4,
                    paddingRight: 2
                  }}
                  shimmerElements={shimmerWithElementGapRow}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "200px" }}>:&nbsp;{props.custDetails?.countryName}</span>
                </Stack>
              )}
            </Stack>
            <Stack horizontal tokens={stackTokens}>
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 90,
                    paddingLeft: 5
                  }}
                  shimmerElements={shimmerWithElementGapRow}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "90px" }}>Region </span>
                </Stack>
              )}
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 200,
                    paddingLeft: 4,
                    paddingRight: 2
                  }}
                  shimmerElements={shimmerWithElementGapRow}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "200px" }}>:&nbsp;{props.custDetails?.regionName}</span>
                </Stack>
              )}
            </Stack>
            <Stack horizontal tokens={stackTokens}>
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 90,
                    paddingLeft: 5
                  }}
                  shimmerElements={shimmerWithElementGapRow}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "90px" }}>Sub Segment </span>
                </Stack>
              )}

              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 200,
                    paddingLeft: 4,
                    paddingRight: 2
                  }}
                  shimmerElements={shimmerWithElementGapRow}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "200px" }}>
                    :&nbsp;
                    {props.custDetails?.subSegmentName}
                  </span>
                </Stack>
              )}
            </Stack>
          </>
          <br />
        </div>
        <Divider orientation="vertical" flexItem style={{ marginBottom: "20px" }} />
        <div
          style={{
            marginLeft: 5,
            marginRight: 5,
            background: "#FFF",
            lineHeight: 2,
            paddingLeft: 10,
            minWidth: 150,
            maxWidth: 150
          }}
        >
          <>
            <Stack horizontal tokens={stackTokens}>
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 95,
                    paddingLeft: 5
                  }}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "95px" }}>HasPartner </span>
                </Stack>
              )}

              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 95,
                    paddingLeft: 4,
                    paddingRight: 3
                  }}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span title={props.custDetails?.hasPartner ? "Has a partner" : "No partner"}>
                    :&nbsp;{" "}
                    {props.custDetails?.hasPartner === false ? (
                      <CloseIcon
                        titleAccess="false"
                        sx={{
                          color: "red",
                          fontSize: "1.3rem"
                        }}
                        style={{ marginBottom: "-7px" }}
                      />
                    ) : (
                      <CheckIcon
                        titleAccess="true"
                        sx={{
                          color: "green",
                          fontSize: "1.3rem"
                        }}
                        style={{ marginBottom: "-7px" }}
                      />
                    )}
                  </span>
                  {/*   <span>
                    :&nbsp;{props.custDetails?.HasPartner === false ? "false" : "true"}
                    </span> */}
                </Stack>
              )}
            </Stack>
            <Stack horizontal tokens={stackTokens}>
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 95,
                    paddingLeft: 5
                  }}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "95px" }}>isFasttrack </span>
                </Stack>
              )}
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 95,
                    paddingLeft: 4,
                    paddingRight: 3
                  }}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span title={props.custDetails?.isFasttrackTenant ? "Fast Track tenant" : "No Fast Track tenant"}>
                    :&nbsp;{" "}
                    {props.custDetails?.isFasttrackTenant === false ? (
                      <CloseIcon
                        titleAccess="false"
                        sx={{
                          color: "red",
                          fontSize: "1.3rem"
                        }}
                        style={{ marginBottom: "-7px" }}
                      />
                    ) : (
                      <CheckIcon
                        titleAccess="true"
                        sx={{
                          color: "green",
                          fontSize: "1.3rem"
                        }}
                        style={{ marginBottom: "-7px" }}
                      />
                    )}
                  </span>
                  {/*    <span>
                    :&nbsp;{props.custDetails?.IsfasttrackTenant === false
                        ? "false"
                        : "true"}
                    </span> */}
                </Stack>
              )}
            </Stack>

            <Stack horizontal tokens={stackTokens}>
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 95,
                    paddingLeft: 5
                  }}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "95px" }}>isSST </span>
                </Stack>
              )}
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 95,
                    paddingLeft: 4,
                    paddingRight: 2
                  }}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span title={props.custDetails?.isSST ? "SST" : "No SST"}>
                    :&nbsp;{" "}
                    {props.custDetails?.isSST === false ? (
                      <CloseIcon
                        titleAccess="false"
                        sx={{
                          color: "red",
                          fontSize: "1.3rem"
                        }}
                        style={{ marginBottom: "-7px" }}
                      />
                    ) : (
                      <CheckIcon
                        titleAccess="true"
                        sx={{
                          color: "green",
                          fontSize: "1.3rem"
                        }}
                        style={{ marginBottom: "-7px" }}
                      />
                    )}
                  </span>
                  {/*                    <span>:&nbsp;{props.custDetails?.isSST === false ? "false" : "true"}</span> */}
                </Stack>
              )}
            </Stack>
            <Stack horizontal tokens={stackTokens}>
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 95,
                    paddingLeft: 5
                  }}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "95px" }}>isCAT</span>
                </Stack>
              )}
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 95,
                    paddingLeft: 4,
                    paddingRight: 2
                  }}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  {/*    <span>:&nbsp;{props.custDetails?.isCAT === false ? "false" : "true"}</span>  */}
                  <span title={props.custDetails?.isCAT ? "CAT" : "No CAT"}>
                    :&nbsp;{" "}
                    {props.custDetails?.isCAT === false ? (
                      <CloseIcon
                        titleAccess="false"
                        sx={{
                          color: "red",
                          fontSize: "1.3rem"
                        }}
                        style={{ marginBottom: "-7px" }}
                      />
                    ) : (
                      <CheckIcon
                        titleAccess="true"
                        sx={{
                          color: "green",
                          fontSize: "1.3rem"
                        }}
                        style={{ marginBottom: "-7px" }}
                      />
                    )}
                  </span>
                </Stack>
              )}
            </Stack>
            <Stack horizontal tokens={stackTokens}>
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 95,
                    paddingLeft: 5
                  }}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span style={{ width: "95px" }}>Domain Count </span>
                </Stack>
              )}
              {props.loading ? (
                <Shimmer
                  style={{
                    marginTop: 10,
                    width: 95,
                    paddingLeft: 4,
                    paddingRight: 2
                  }}
                  shimmerColors={{
                    shimmer: "rgb(222, 236, 249)"
                  }}
                ></Shimmer>
              ) : (
                <Stack styles={labelStyles}>
                  <span>
                    :&nbsp;&nbsp;
                    <Link onClick={() => setIsOpen(true)}>{props.custDetails?.domainCount}</Link>
                    <DomainPanel isOpen={isOpen} dismissPanel={setIsOpen} tenantName={props.custDetails?.tenantName} />
                  </span>
                </Stack>
              )}
            </Stack>
          </>
        </div>
      </>
    </>
  );
}
