import { DetailsListLayoutMode, mergeStyleSets, SelectionMode } from "@fluentui/react";
import { ICompositeListColumn, ICompositeListDetailsList } from "@m365-admin/composite-list";
import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { CustomCompositeList } from "../../SharedComponents/CustomCompositeList";
import { formatDate } from "../../helper/DateFormat";
import { logEvent, logException } from "../../helper/AppInsightsHelper";
import { useGetDomainDetailsQuery } from "../../slices/Domain.slice";
import { copyAndSort } from "../../Utils/js-common";

const styles = mergeStyleSets({
  detailsListsWrapper: {
    ".ms-DetailsList-headerWrapper": {
      padding: 0,
      fontSize: "14px",
      fontWeight: 600,
      "& .ms-DetailsHeader-cellName": {
        fontSize: "14px !important"
      }
    },
    ".ms-DetailsRow-cell": {
      display: "flex",
      alignItems: "center",
      fontSize: "12px",
      fontWeight: 600,
      color: "black",
      borderRight: "1px solid #ececec",
      selectors: {
        svg: {
          width: "inherit"
        }
      }
    }
  }
});

const DomainDataGrid = (props: any) => {
  const [searchParams] = useSearchParams();
  const tenantId = searchParams.get("id");
  const { data: items, error } = useGetDomainDetailsQuery(tenantId || "");
  const columns: ICompositeListColumn[] = [
    {
      key: "domainName",
      name: "Domain Name",
      fieldName: "domainName",
      flexGrow: 2,
      minWidth: 250,
      maxWidth: 350,
      isSorted: false,
      isResizable: true,
      onColumnClick: _onColumnClick
    },
    {
      key: "isVerified",
      name: "Is Verified",
      fieldName: "isVerified",
      flexGrow: 1,
      minWidth: 100,
      maxWidth: 200,
      isSorted: false,
      isResizable: true,
      onColumnClick: _onColumnClick
    },
    {
      key: "verificationMethod",
      name: "Verification Method",
      fieldName: "verificationMethod",
      flexGrow: 1,
      minWidth: 170,
      maxWidth: 200,
      isSorted: false,
      isResizable: true,
      onColumnClick: _onColumnClick
    },
    {
      key: "versionStartDate",
      name: "Version Start Date",
      fieldName: "versionStartDate",
      flexGrow: 1,
      minWidth: 170,
      maxWidth: 200,
      isSorted: false,
      isResizable: true,
      onColumnClick: _onColumnClick,
      onRender: (item?: any) => formatDate(item.versionStartDate)
    },
    {
      key: "versionEndDate",
      name: "Version End Date",
      fieldName: "versionEndDate",
      flexGrow: 1,
      minWidth: 170,
      maxWidth: 200,
      isSorted: false,
      isResizable: true,
      onColumnClick: _onColumnClick,
      onRender: (item?: any) => formatDate(item.versionEndDate)
    }
  ];
  const [list, setList] = React.useState<ICompositeListDetailsList>({
    listProps: {
      items: [],
      columns: columns,
      ariaLabelForSelectAllCheckbox: "Select all items",
      selectionMode: SelectionMode.none,
      layoutMode: DetailsListLayoutMode.justified
    },
    key: "domain-data-grid"
  });
  const [showEmptyState, setShowEmptyState] = React.useState<boolean>(false);

  const handleData = useCallback((data: any[]) => {
    setList((prev) => ({
      ...prev,
      listProps: {
        ...prev.listProps,
        items: data
      }
    }));
  }, []);

  const handleColumns = useCallback((data: ICompositeListColumn[], currColumn: ICompositeListColumn) => {
    setList((prev) => {
      const newItems = copyAndSort(prev.listProps.items, currColumn.fieldName!, currColumn.isSortedDescending);
      return {
        ...prev,
        listProps: {
          ...prev.listProps,
          columns: data,
          items: newItems
        }
      };
    });
  }, []);

  React.useEffect(() => {
    if (items) {
      handleData(items);
      logEvent("useGetDomainDetailsQuery API call success", "Customer Page", "DomainDataGrid");
    }
    if (error) {
      setShowEmptyState(true);
      logException("Customer Page", "DomainDataGrid", "useGetDomainDetailsQuery", error);
    }
  }, [items, error, handleData]);

  function _onColumnClick(ev: React.MouseEvent<HTMLElement>, column: ICompositeListColumn): void {
    const { columns } = list.listProps;
    const newColumns: ICompositeListColumn[] = columns!.slice();
    const currColumn: ICompositeListColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

    newColumns.forEach((newCol: ICompositeListColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    handleColumns(newColumns, currColumn);
  }

  return (
    <div>
      <p style={{ fontSize: "18px", marginTop: "0" }}>
        List of Domains for <b>{props?.tenantName}</b>
      </p>
      <CustomCompositeList
        data={list}
        hideCommandBar={true}
        isCompactMode={true}
        isEmpty={showEmptyState}
        styles={styles}
      />
    </div>
  );
};

export default DomainDataGrid;
