import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IResizeState {
  clientWidth: number;
  offsetWidth: number;
  scrollWidth: number;
  clientLeft: number;
  scrollLeft: number;
}

const initialState: IResizeState = {
  clientWidth: 0,
  offsetWidth: 0,
  scrollWidth: 0,
  clientLeft: 0,
  scrollLeft: 0
};

const resizeSlice = createSlice({
  name: "msal",
  initialState,
  reducers: {
    updateTarget(state, { payload }: PayloadAction<HTMLDivElement>) {
      state.clientWidth = payload.clientWidth;
      state.offsetWidth = payload.offsetWidth;
      state.scrollWidth = payload.scrollWidth;
      state.clientLeft = payload.clientLeft;
      state.scrollLeft = payload.scrollLeft;
    }
  }
});

export const { updateTarget } = resizeSlice.actions;
export default resizeSlice.reducer;
