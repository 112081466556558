import { Stack, Spinner, SpinnerSize } from "@fluentui/react";
import { LoaderProps } from "../interfaces/loader-props.interfaces";
import React from "react";

export const Loader: React.FC<LoaderProps> = ({ rowProps, spinnerStack, spinnerSize, style, loading }) => {
  const defaultStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#FFF",
    lineHeight: 2
  };
  return (
    <>
      {(loading && (
        <div style={style || defaultStyle}>
          <Stack {...rowProps} tokens={spinnerStack}>
            <Spinner label="Loading......" size={spinnerSize || SpinnerSize.large} />
          </Stack>
        </div>
      )) ||
        ""}
    </>
  );
};
