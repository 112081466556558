import PowerBIReportModal from "../../../MuiComponents/PowerBIReportModal";
import { ReportsViewModalProps } from "../ReportCatalog.interfaces";

export const TopReportsViewModal = (props: ReportsViewModalProps) => {
  return (
    <>
      {props.isModelOpen && (
        <PowerBIReportModal
          ismodelOpen={props.isModelOpen}
          selectedItem={{ row: { reportLink: props.card?.url } }}
          CloseModalReoprt={props.onCloseModel}
        />
      )}
    </>
  );
};
