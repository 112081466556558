/* eslint-disable no-undef */
import { getNextColor, IChartProps } from "@fluentui/react-charting";
import { GridFilterModel, GridRowSelectionModel, GridSortModel } from "@mui/x-data-grid";
import axios from "axios";
import { Dispatch, SetStateAction } from "react";
import { ILineChartData } from "../components/ProductView/Common/ProductView.interfaces";
import { IAzSearchInput } from "../interfaces/Customer.interfaces";
import { IRows } from "../SharedComponents/Interfaces/CustomDataGrid.interfaces";

export const MONTHS_SHORT = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const objSortByValLength = (obj: { [key: string]: any[] }) =>
  Object.entries(obj)
    .sort(([, a], [, b]): any => b.length - a.length)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

export const groupBy = (xs: any[], key: string) =>
  xs.reduce((rv, x) => {
    (rv[x[key]] || (rv[x[key]] = [])).push(x);
    return rv;
  }, {});

export const arrSortAscByKeyLength = ({ key, data }: { key: string; data: any[] }) =>
  data.toSorted((a, b) => a[key].length - b[key].length);

export const arrSortDescByKeyLength = ({ key, data }: { key: string; data: any[] }) =>
  data.toSorted((a, b) => b[key].length - a[key].length);

export const arrSortAscByKeyValue = ({ key, data }: { key: string; data: any[] }) => {
  return data.toSorted((a, b) => {
    if (a[key] > b[key]) return 1;
    return b[key] > a[key] ? -1 : 0;
  });
};

export const getLastDateUTC = (dateMonthNumber: string, dateYear: number) => {
  const dateMonthIndex = MONTHS_SHORT.indexOf(dateMonthNumber) + 1;
  const dateMonth = dateMonthIndex < 10 ? `0${dateMonthIndex}` : dateMonthIndex;
  const dateLastDay = new Date(Number(dateYear), Number(dateMonth), 0).getDate();
  return new Date(`${Number(dateYear)}-${dateMonth}-${dateLastDay}T00:00:00.000Z`);
};

export const configAxiosToken = (token: string) =>
  axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
      Environment: process.env.REACT_APP_ENVIRONMENT
    }
  });

export const copy = async (message: string) => {
  let status = false;
  const textArea = document.createElement("textarea");
  textArea.value = message;
  textArea.style.opacity = "0";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const toCopy = message;
    await navigator.clipboard.writeText(toCopy);
    status = true;
  } catch (err) {
    console.error("Failed to copy: ", err);
    status = false;
  } finally {
    document.body.removeChild(textArea);
  }
  return status ? Promise.resolve(true) : Promise.reject(new Error("Failed to copy"));
};

export const applyAzSearchInputFilter = (
  operator: string,
  field: string,
  value: string | undefined
): string | undefined => {
  let filter = undefined;

  switch (operator) {
    case "contains":
      if ("+-&|!(){}[]^\"~*?:/ ".split("").filter((f) => (value ?? "").includes(f)).length) {
        filter = `search.ismatch('"${value}"', '${field}', 'full', 'any')`;
        break;
      }
      filter = `search.ismatch('/.*${value}*./', '${field}', 'full', 'any')`;
      break;
    case "equals":
      filter = `${field} eq '${value}'`;
      break;
    case "startsWith":
      filter = `search.ismatch('${value}*', '${field}', 'full', 'any')`;
      break;
    case "endsWith":
      filter = `search.ismatch('/.*${value}/', '${field}', 'full', 'any')`;
      break;
    case "=":
      filter = `${field} eq ${value}`;
      break;
    case "!=":
      filter = `${field} ne ${value}`;
      break;
    case ">":
      filter = `${field} gt ${value}`;
      break;
    case ">=":
      filter = `${field} ge ${value}`;
      break;
    case "<":
      filter = `${field} lt ${value}`;
      break;
    case "<=":
      filter = `${field} le ${value}`;
      break;
    case "isEmpty":
      filter = `search.ismatch('/^$|\\s+/', '${field}', 'full', 'any')`;
      break;
    case "isNotEmpty":
      filter = `${field} ne null or ${field} ne '' or ${field} ne ' '`;
      break;
    default:
      break;
  }

  return filter;
};

export const isNumberOnly = (value: any) => new RegExp(/^\d+$/).test(value);

export const isTenantIdOnly = (value: any) =>
  new RegExp(/^[A-Za-z0-9]{8,12}(-[A-Za-z0-9]{4}){3}-[A-Za-z0-9]{12}/).test(value);

export const handleToggleDataGridRowSelection = (
  selectionModel: GridRowSelectionModel,
  setSelectionModel: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>
) => {
  return (rowSelectionModel: GridRowSelectionModel) => {
    if (rowSelectionModel.at(0) !== selectionModel.at(0)) {
      setSelectionModel(rowSelectionModel);
    } else {
      setSelectionModel([]);
    }
  };
};

export function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

export const Timer = (callback: () => void, delay: number = 1000) => {
  let timerId: number | undefined,
    start: number,
    remaining = delay;

  const pause = () => {
    window.clearTimeout(timerId);
    timerId = undefined;
    remaining -= Date.now() - start;
  };

  const resume = () => {
    if (timerId) {
      return;
    }

    start = Date.now();
    timerId = window.setTimeout(callback, remaining);
  };

  const clearTimeout = () => window.clearTimeout(timerId);

  resume();

  return {
    pause,
    resume,
    clearTimeout
  };
};

export const getLineChartData = (data: ILineChartData[]): IChartProps => ({
  chartTitle: "Line Chart",
  lineChartData: data.map(({ name, data }, index) => ({
    legend: name,
    data: data.map(({ x, y }) => ({
      x: new Date(new Date(x).toISOString()),
      y: y
    })),
    color: getNextColor(index),
    lineOptions: {
      lineBorderWidth: "4"
    }
  }))
});

export const getSumForNumberValues = (values: number[]) => values.reduce((total, item) => total + (item || 0), 0);

export function callSearchAPIs(
  promise: Promise<any>,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setRows: Dispatch<SetStateAction<IRows>>,
  setError?: Dispatch<SetStateAction<boolean>>
) {
  setLoading(true);

  promise
    .then((rows) => {
      setRows(rows);
      setLoading(false);
    })
    .catch((error) => {
      console.error(error);

      if (setError) {
        setError(true);
      }
    });
}
export function getSearchInput(
  initialFilters: string,
  searchText: string,
  filterOptions: GridFilterModel,
  sortOptions: GridSortModel,
  pagination: {
    pageSize: number;
    page: number;
  },
  callAPIsType: string
) {
  const {
    items: [item]
  } = filterOptions;

  const searchInput: IAzSearchInput = {
    searchText: `${searchText.replaceAll(" ", "+")}*`,
    size: pagination.pageSize,
    skip: pagination.pageSize * pagination.page,
    orderBy: sortOptions.map((m) => `${m.field.charAt(0).toUpperCase().concat(m.field.slice(1))} ${m.sort}`)
  };

  if (callAPIsType === "customer") {
    if (isNumberOnly(searchText)) {
      searchInput.searchText = "*";
      searchInput.filter = applyAzSearchInputFilter("=", "TopParentId", searchText);
    }
    if (isTenantIdOnly(searchText)) {
      searchInput.searchText = "*";
      searchInput.filter = applyAzSearchInputFilter("=", "TenantId", `'${searchText.replaceAll("'", "\"")}'`);
    }
  }

  if (item?.value) {
    const { field, operator, value } = item;
    const _field = field.charAt(0).toUpperCase().concat(field.slice(1));

    if (item.value) {
      searchInput.filter = applyAzSearchInputFilter(operator, _field, value);
    } else {
      searchInput.filter = applyAzSearchInputFilter(operator, _field, undefined);
    }
  }

  if (initialFilters) {
    if (searchInput.filter) {
      searchInput.filter += ` and ( ${initialFilters} )`;
    } else {
      searchInput.filter = initialFilters;
    }
  }

  return searchInput;
}

// / Milliseconds in one day = 86400000
export const getDaysBetweenDates = (dateOne: Date, dateTwo: Date) =>
  Math.ceil(Math.abs(dateTwo.getTime() - dateOne.getTime()) / 86400000);

// Debounce function
export const debounce = (callback: any, delay: number = 1000) => {
  let debounceTimeout: ReturnType<typeof setTimeout> | null;

  return function (query: string) {
    if (debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      debounceTimeout = null;
      callback(query);
    }, delay);
  };
};
