import { Carousel } from "@coherence-design-system/controls";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { LineChartComponent } from "../../../../ChartComponents/LineChartFluent";
import { CustomShimmer } from "../../../../helper/CustomShimmer";
import CustomCard from "../../../../SharedComponents/CustomCard";
import { getFeaturesSelector } from "../../../../slices/FeatureProduct.slice";
import { useAppSelector } from "../../../../store/hooks";
import { getLineChartData, groupBy } from "../../../../Utils/js-common";
import { PRODUCT_VIEW_DATA } from "../../Common/ProductView.constants";
import { CarouselLineChartDataProps, ILineChartData, FeaturesByNameProps } from "../../Common/ProductView.interfaces";
import { PRODUCT_VIEW_STYLES } from "../../Common/ProductView.styles";

const DisplayLineChart = ({ data }: { data: ILineChartData[] }) => {
  const lineMetricsContainerRef = useRef<HTMLDivElement>(null);
  const resizedWidth = useAppSelector(({ resize }) => resize.offsetWidth);
  const [width, setWidth] = useState(445);
  const [, setOffsetWidth] = useState(resizedWidth);

  const concatMetricsForMinMax = useMemo(
    () =>
      data
        .map(({ data }) => [...data.map(({ y }) => y)])
        .reduce((allUpYAxis, premiumYAxis) => allUpYAxis.concat(premiumYAxis)),
    [data]
  );

  useEffect(() => {
    setOffsetWidth(resizedWidth);
  }, [resizedWidth]);

  useLayoutEffect(() => {
    if (lineMetricsContainerRef.current) {
      setWidth(lineMetricsContainerRef.current?.offsetWidth);
    }
  });

  return (
    <div ref={lineMetricsContainerRef}>
      <LineChartComponent
        data={getLineChartData(data)}
        height={400}
        width={width}
        allowResize={true}
        yMinValue={Math.min(...concatMetricsForMinMax)}
        yMaxValue={Math.max(...concatMetricsForMinMax)}
      />
    </div>
  );
};

const getCarouselItem = (feature: CarouselLineChartDataProps) => (
  <CustomCard cardHeaderTitle={feature.name} styles={PRODUCT_VIEW_STYLES.TAB.DETAILED_VIEW.CSS_STYLES.customCardStyles}>
    <DisplayLineChart data={feature.data} />
  </CustomCard>
);

const formatFeaturesCallback = (data: FeaturesByNameProps[]): CarouselLineChartDataProps => ({
  name: data.at(0)?.featureName || "",
  data: [
    {
      name: "allUp",
      data: data.map(({ allUp, snapshotDate }) => ({ x: snapshotDate, y: allUp }))
    }
  ]
});

export const DetailedViewTab = () => {
  const { data, isLoading, isSuccess } = useAppSelector((state) => getFeaturesSelector(state));
  const formatFeaturesDataPredicate = useCallback(formatFeaturesCallback, []);
  const items = useMemo(
    () =>
      Object.values<FeaturesByNameProps[]>(groupBy(data?.featuresByName || [], "featureId"))
        .map(formatFeaturesDataPredicate)
        .map((result) => getCarouselItem(result)),
    [data]
  );
  const displayShimmer = isLoading && (
    <CustomShimmer columnCount={4} styles={PRODUCT_VIEW_STYLES.TAB.DETAILED_VIEW.CSS_STYLES.customShimmerStyles} />
  );
  const displayCarousel = isSuccess && data.featuresByName.length > 0 && (
    <Carousel {...PRODUCT_VIEW_DATA.TABS.TAB.FEATURE_VIEW.DETAILED_VIEW.INITIAL_CAROUSEL_DATA} items={items} />
  );

  const displayNoDataMessage = isSuccess && <div>{PRODUCT_VIEW_DATA.PRODUCT_VIEW.VIEW_NO_DATA_MESSAGE}</div>;
  return (
    <div className={PRODUCT_VIEW_STYLES.TAB.DETAILED_VIEW.CLASS_NAMES.carouselContainer}>
      {displayShimmer}
      {displayCarousel || displayNoDataMessage}
    </div>
  );
};
