import { CardsProps } from "../../ReportCatalog.interfaces";
import Card from "./TopReportsCard";

const shimmerCards = Array(6).fill({ ariaLabel: "" });

export const TopReportsCards = (props: CardsProps) => {
  const { cards, onCardSelected, loading } = props;
  const LoadCard = (card: any, idx: number) => (
    <Card card={card} loading={loading} onCardSelected={onCardSelected} key={`top-report-card-${idx + 1}`} />
  );

  return (
    <>
      {loading ? shimmerCards.map((card, idx) => LoadCard(card, idx)) : cards.map((card, idx) => LoadCard(card, idx))}
    </>
  );
};
