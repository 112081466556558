import { IContextualMenuItem } from "@fluentui/react";

export type CustomerSearchDataGridProps = { searchText: string };

export enum ReducerType {
  LoadMenuItems,
  UpdateSelectedMenuItems
}

export interface filterPillDispatchProps {
  type: ReducerType;
  data: IContextualMenuItem[] | string[];
}
