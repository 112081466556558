import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { LineChartComponent } from "../../../../ChartComponents/LineChartFluent";
import { CustomShimmer } from "../../../../helper/CustomShimmer";
import CustomCard from "../../../../SharedComponents/CustomCard";
import DisplayEmptyDataMessage from "../../../../SharedComponents/DisplayEmptyDataMessage";
import { getFeaturesSelector } from "../../../../slices/FeatureProduct.slice";
import { useAppSelector } from "../../../../store/hooks";
import { getLineChartData, groupBy } from "../../../../Utils/js-common";
import { PRODUCT_VIEW_DATA } from "../../Common/ProductView.constants";
import { FeaturesByNameProps, ILineChartData } from "../../Common/ProductView.interfaces";
import { PRODUCT_VIEW_STYLES } from "../../Common/ProductView.styles";

const RenderLineMetrics = () => {
  const [width, setWidth] = useState<number | undefined>(undefined);
  const lineMetricsContainerRef = useRef<HTMLDivElement>(null);
  const resizedWidth = useAppSelector(({ resize }) => resize.offsetWidth);
  const { data, isLoading, isSuccess } = useAppSelector((state) => getFeaturesSelector(state));

  const handleResize = useCallback((width: number) => setWidth(width), []);
  const formatFeaturesCallback = useCallback(
    (data: FeaturesByNameProps[]): ILineChartData => ({
      name: data.at(0)?.featureName || "",
      data: data.map(({ allUp, snapshotDate }) => ({ x: snapshotDate, y: allUp }))
    }),
    []
  );
  const mapPredicate = useCallback(formatFeaturesCallback, []);
  const chartData = useMemo(
    () =>
      getLineChartData(
        Object.values<FeaturesByNameProps[]>(groupBy(data?.featuresByName || [], "featureId")).map(mapPredicate)
      ),
    [data]
  );

  useEffect(() => {
    if (lineMetricsContainerRef.current) {
      handleResize(lineMetricsContainerRef.current?.offsetWidth);
    }
  }, [resizedWidth]);

  return (
    <div ref={lineMetricsContainerRef} style={{ height: 400 }}>
      {isSuccess &&
        ((data?.featuresByName.length && (
          <LineChartComponent allowMultipleShapesForPoints allowResize data={chartData} width={width} height={400} />
        )) ||
          (data?.featuresByName.length === 0 && <DisplayEmptyDataMessage />))}
      {isLoading && <CustomShimmer columnCount={4} styles={{ root: { height: 400 } }} />}
    </div>
  );
};

export const SummaryTab = () => (
  <div className={PRODUCT_VIEW_STYLES.TAB.FEATURE_VIEW_SUMMARY_TAB.CLASS_NAMES.featureViewSummaryTabContainer}>
    <CustomCard
      cardHeaderTitle={PRODUCT_VIEW_DATA.TABS.TAB.FEATURE_VIEW.SUMMARY_TAB.FEATURE_VIEW_ALL_UP}
      styles={PRODUCT_VIEW_STYLES.TAB.FEATURE_VIEW_SUMMARY_TAB.CSS_STYLES.customCardStyles}
    >
      <RenderLineMetrics />
    </CustomCard>
  </div>
);
