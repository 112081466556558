import { mergeStyleSets, IStyle } from "@fluentui/react";

export const DISPLAY_EMPTY_DATA_MESSAGE_STYLES = mergeStyleSets({
  emptyDataMessage: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    height: "inherit",
    justifyContent: "center"
  } as IStyle
});
