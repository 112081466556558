import { ISearchBoxStyles, ShimmerElementType } from "@fluentui/react";
import { GridColDef, GridFilterModel } from "@mui/x-data-grid";
import CopilotForSecurityImage from "../../assets/report-catalog-thumbnails/cxe-copilot-for-security.png";
import EntraCatEngagementsImage from "../../assets/report-catalog-thumbnails/entra-cat-engagements.png";
import M365CopilotPurviewUsageSummaryImage from "../../assets/report-catalog-thumbnails/m365-copilot-purview-usage-summary.png";
import MicrosoftSentinelReportImage from "../../assets/report-catalog-thumbnails/microsoft-sentinel-report.png";
import UEMCatDashImage from "../../assets/report-catalog-thumbnails/uem-cat-dash.png";
import UnifiedInsightImage from "../../assets/report-catalog-thumbnails/unified-insight-report.png";
import { AllReportsStackProps, ShimmerDataProps, TimerProps } from "./ReportCatalog.interfaces";
import React from "react";

export const TOP_REPORTS_LIST = [
  {
    id: 12,
    name: "Copilot for Security",
    thumbnail: CopilotForSecurityImage
  },
  {
    id: 9,
    name: "Entra CAT Engagements",
    thumbnail: EntraCatEngagementsImage
  },
  {
    id: 73,
    name: "M365 Copilot Purview Usage Summary",
    thumbnail: M365CopilotPurviewUsageSummaryImage
  },
  { id: 26, name: "UEM CAT Dash", thumbnail: UEMCatDashImage },
  {
    id: 47,
    name: "Microsoft Sentinel Report",
    thumbnail: MicrosoftSentinelReportImage
  },
  {
    id: 165,
    name: "Unified Insights Report",
    thumbnail: UnifiedInsightImage

  }
];

export const isReportExistsPredicate = ({ id }: any) => TOP_REPORTS_LIST.map(({ id }) => id).includes(id);

export const getThumbnail = (id: number) =>
  isReportExistsPredicate({ id }) ? TOP_REPORTS_LIST.filter((f) => f.id === id)[0].thumbnail : "";

export const mapReportsDataPredicate = (m: any) => ({
  id: m.id,
  ariaLabel: "",
  title: m.report,
  description: m.description,
  thumbnail: getThumbnail(m.id),
  url: m.reportLink
});

const TOP_REPORT_CARD_SHIMMER_ELEMENT = {
  shimmerElementType: ShimmerElementType.line,
  height: 15
};

export const TOP_REPORT_CARD_SHIMMER_DATA: ShimmerDataProps[] = [
  {
    height: 170,
    key: "card-shimmer-image",
    styles: {},
    shimmerElementType: ShimmerElementType.line
  },
  {
    ...TOP_REPORT_CARD_SHIMMER_ELEMENT,
    key: "card-shimmer-link",
    styles: { root: { padding: "5px 5px 10px 5px", width: "70%" } }
  },
  {
    ...TOP_REPORT_CARD_SHIMMER_ELEMENT,
    key: "card-shimmer-line-1",
    styles: { root: { padding: "0px 5px 5px 5px" } }
  },
  {
    ...TOP_REPORT_CARD_SHIMMER_ELEMENT,
    key: "card-shimmer-line-2",
    styles: { root: { padding: "0px 5px 5px 5px", width: "85%" } }
  },
  {
    ...TOP_REPORT_CARD_SHIMMER_ELEMENT,
    key: "card-shimmer-line-3",
    styles: { root: { padding: "0px 5px 5px 5px" } }
  }
];

export const TOP_REPORTS_TITLE = "Explore top reports and find quick insights";

export const ONBOARD_REPORT_DIALOG_TITLE = "Onboard new reports";

export const SEARCHBOX_STYLES: Partial<ISearchBoxStyles> = {
  root: { width: "60%", marginTop: 10 }
};

export const INITIAL_GRID_FILTER_MODEL: GridFilterModel = {
  items: [],
  quickFilterExcludeHiddenColumns: true,
  quickFilterValues: [""]
};

export const REPORT_CATALOG_COLUMNS: GridColDef[] = [
  {
    field: "report",
    headerName: "Report",
    width: 250,
    sortable: true
  },
  {
    field: "description",
    headerName: "Description",
    width: 500,
    sortable: true,
    flex: 1
  },
  {
    field: "owner",
    headerName: "Owner",
    width: 130,
    sortable: true
  },
  { field: "product", headerName: "Product", width: 130, sortable: true },
  {
    field: "keywords",
    headerName: "Keywords",
    minWidth: 300,
    sortable: true
  }
];

export const ALLREPORTS_DATAGRID_STYLE = {
  "& .MuiDataGrid-row": {
    minHeight: "42px !important",
    maxHeight: "none !important"
  },
  "& .MuiDataGrid-cell": {
    height: "auto !important",
    lineHeight: "normal",
    boxSizing: "border-box",
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "clip",
    padding: "10px",
    paddingRight: 0,
    wordBreak: "break-word"
  }
};

export const REPORT_LINK_STYLE: React.CSSProperties = {
  color: "rgb(0, 120, 212)",
  fontSize: 12,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100%"
};

export const FONT_FAMILY_STYLE = { fontFamily: "var(--font-family)" };

export const ALLREPORTS_STACK_PROPS: AllReportsStackProps = {
  container: {
    tokens: { childrenGap: 15 },
    styles: { root: { marginTop: 25 } }
  },
  horizontalContainer: {
    horizontal: true,
    disableShrink: false,
    styles: {
      root: {
        justifyContent: "space-between",
        alignItems: "flex-end"
      }
    }
  },
  innerStack: {
    styles: { root: { flex: 1 } }
  },
  dropdownStack: {
    horizontal: true,
    tokens: { childrenGap: 15 },
    styles: {
      root: {
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "flex-end"
      }
    }
  }
};

export const COMBOBOX_STYLES = {
  maxWidth: 300,
  borderRadius: 2,
  border: "1px solid rgb(72, 70, 68)"
};
export const SELECT_ALL_OPTION = {
  text: "Select All",
  value: "selectAll"
};
export const exceptSelectAllPredicate = (value?: string) => value !== "selectAll";
export const INITIAL_TIMER_PROPS: TimerProps = {
  clearTimeout: () => {},
  pause: () => {},
  resume: () => {}
};
