import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DisplayAPIsException from "../../BasicComponents/DisplayAPIsExceptionAlert";
import { logEvent, logException } from "../../helper/AppInsightsHelper";
import { CustomShimmer } from "../../helper/CustomShimmer";
import { supportChartType } from "../../interfaces/support";
import { useGetSupportDataOfTenantQuery } from "../../slices/Customer.slice";
import { groupBy } from "../../Utils/js-common";
import SupportChart from "./SupportCharts";
import SupportTable from "./SupportTable";

const SupportContainer = (props: any) => {
  const [searchParams] = useSearchParams();
  const tenantId = searchParams.get("id");
  const [items, setitems] = useState([]);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredChartData, setFilteredChartData] = useState<supportChartType>();
  const [chartKeys, setChartKeys] = useState<string[]>([]);
  const [chartFilter, setChartFilter] = useState({
    filterKey: "",
    value: ""
  });
  const [filterChData, setFilterChData] = React.useState<any>({
    derivedProductName: "",
    serviceRequestState: ""
  });
  const { data, error, isFetching } = useGetSupportDataOfTenantQuery(tenantId);
  const HandleAPIExcpetion = () => (error && <DisplayAPIsException height={250} />) || "";

  useEffect(() => {
    logEvent("Customer Support", "Customer Page", "SupportContainer");
  }, []);

  useEffect(() => {
    if (data) {
      setitems(data);
      setFilteredItems(data);
      const groupByState = groupBy(data, "serviceRequestState");
      const dataByStatus = Object.keys(groupByState).map((item) => {
        return {
          RequestState: item,
          RequestStateCount: groupByState[item].length
        };
      });
      const groupByProduct = groupBy(data, "derivedProductName");
      const dataByProduct = Object.keys(groupByProduct).map((item) => {
        return {
          ProductName: item,
          ProductNameCount: groupByProduct[item].length
        };
      });
      setFilteredChartData({
        serviceRequestState: dataByStatus,
        derivedProductName: dataByProduct
      });
      setFilterChData({
        serviceRequestState: "",
        derivedProductName: ""
      });
      setIsChartLoading(false);
      setChartKeys(["serviceRequestState", "derivedProductName"]);
    }
    if (error) {
      setIsChartLoading(false);
      logException("Support View", "SupportContainer", "useGetSupportDataOfTenantQuery", error);
    }
  }, [data, error]);

  useEffect(() => {
    switch (chartFilter.filterKey) {
      case "serviceRequestState":
        {
          let filteredData: any = [];
          filteredData = items.filter((item: any) => item.serviceRequestState === chartFilter.value);
          setFilterChData({
            ...filterChData,
            serviceRequestState: chartFilter.value
          });
          if (filterChData.derivedProductName !== "") {
            filteredData = filteredData.filter(
              (item: any) => item.derivedProductName === filterChData.derivedProductName
            );
          }

          setFilteredItems(filteredData);
          const ProductName = groupBy(filteredData, "derivedProductName");
          const filteredProduct: any[] = [];
          Object.keys(ProductName).forEach((item) => {
            filteredProduct.push({
              ProductName: item,
              ProductNameCount: ProductName[item].length
            });
          });

          if (filteredProduct && filteredChartData?.derivedProductName) {
            setFilteredChartData({
              ...filteredChartData,
              derivedProductName: filteredProduct
            });
          }
        }
        break;
      case "derivedProductName":
        {
          let filteredData: any = [];
          filteredData = items.filter((item: any) => item.derivedProductName === chartFilter.value);
          setFilterChData({
            ...filterChData,
            derivedProductName: chartFilter.value
          });
          if (filterChData.serviceRequestState !== "") {
            filteredData = filteredData.filter(
              (item: any) => item.serviceRequestState === filterChData.serviceRequestState
            );
          }

          setFilteredItems(filteredData);
          const requestState = groupBy(filteredData, "serviceRequestState");
          const stateFiltered: any[] = [];
          Object.keys(requestState).forEach((item) => {
            stateFiltered.push({
              RequestState: item,
              RequestStateCount: requestState[item].length
            });
          });

          if (stateFiltered && filteredChartData?.serviceRequestState) {
            setFilteredChartData({
              ...filteredChartData,
              serviceRequestState: stateFiltered
            });
          }
        }
        break;

      default: {
        setFilteredItems(data);
        if (data) {
          const groupByState = groupBy(data, "serviceRequestState");
          const dataByStatus = Object.keys(groupByState).map((item) => {
            return {
              RequestState: item,
              RequestStateCount: groupByState[item].length
            };
          });
          const groupByProduct = groupBy(data, "derivedProductName");
          const dataByProduct = Object.keys(groupByProduct).map((item) => {
            return {
              ProductName: item,
              ProductNameCount: groupByProduct[item].length
            };
          });
          setFilteredChartData({
            serviceRequestState: dataByStatus,
            derivedProductName: dataByProduct
          });
          setFilterChData({
            serviceRequestState: "",
            derivedProductName: ""
          });
        }
      }
    }
  }, [chartFilter]);

  return (
    <>
      <SupportChart
        chartData={filteredChartData}
        isChartLoading={isChartLoading}
        keys={chartKeys}
        setChartFilter={setChartFilter}
        tenantName={props.tenantName}
        tenantId={tenantId}
      />
      {isFetching ? (
        <CustomShimmer columnCount={8} />
      ) : (
        (!error && (
          <>
            <div>
              <SupportTable tableData={filteredItems} tenantName={props.tenantName} />
            </div>
          </>
        )) ||
        ""
      )}
      <HandleAPIExcpetion />
    </>
  );
};

export default SupportContainer;
