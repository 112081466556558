import { Button, buttonClassNames, makeStyles, Spinner, tokens } from "@fluentui/react-components";
import { CheckmarkFilled } from "@fluentui/react-icons";
import * as React from "react";
import {
  BUTTON_APPEARANCE_PRIMARY as PRIMARY,
  ButtonLoadingState,
  DISABLED_BUTTON_LOADING_STATE as DISABLED,
  INITIAL_BUTTON_LOADING_STATE as INITIAL
} from "../../../../SharedComponents/Styles/CustomCard.styles";

const useStyles = makeStyles({
  buttonNonInteractive: {
    cursor: "default",
    pointerEvents: "none",
    [`& .${buttonClassNames.icon}`]: {
      color: tokens.colorStatusSuccessForeground1
    }
  }
});

export const LoadingButton = (props: { loadingState?: ButtonLoadingState; onButtonClick?: () => void }) => {
  const styles = useStyles();
  const [loadingState, setLoadingState] = React.useState<ButtonLoadingState>(DISABLED);

  const onButtonClick = React.useCallback(() => {
    if (props.onButtonClick) props.onButtonClick();
  }, [props]);

  const getButtonContent = React.useCallback(() => {
    if (loadingState === "loading") return "Submitting";
    return loadingState === "loaded" ? "Submitted" : "Submit";
  }, [loadingState]);

  const getButtonIcon = React.useCallback(() => {
    if (loadingState === "loading") return <Spinner size="tiny" />;
    return loadingState === "loaded" ? <CheckmarkFilled /> : null;
  }, [loadingState]);

  const buttonClassName = loadingState === INITIAL ? undefined : styles.buttonNonInteractive;

  const handleLoadingState = React.useCallback((state: ButtonLoadingState) => setLoadingState(state), []);

  React.useEffect(() => {
    if (props.loadingState) handleLoadingState(props.loadingState);
  }, [handleLoadingState, props.loadingState]);

  return (
    <Button
      appearance={PRIMARY}
      icon={getButtonIcon()}
      onClick={onButtonClick}
      className={buttonClassName}
      disabledFocusable={loadingState !== INITIAL}
    >
      {getButtonContent()}
    </Button>
  );
};
