import { mergeStyleSets } from "@fluentui/react";
import { CSSProperties } from "react";

export const commonAIPlaygroundStyles = mergeStyleSets({
  tooltipStyles: {
    marginTop: "5px"
  },
  scheduleChoiceGroupLabel: {
    marginTop: "20px",
    marginLeft: "30px"
  },
  likeButtonLabel: {
    marginTop: "30px",
    marginLeft: "30px",
    display: "flex"
  },
  likeDislikeButtonStyles: {
    width: "50px",
    height: "50px",
    backgroundColor: "white",
    ":hover": {
      backgroundColor: "white"
    },
    ":active": {
      backgroundColor: "white"
    }
  },
  likeDislikeIconStyles: {
    fontSize: 35,
    color: "#0078d4",
    backgroundColor: "white"
  },
  likeDislikeButtonContainerStyles: {
    marginLeft: "20px",
    display: "flex"
  },
  submitButtonStyles: {
    marginTop: "40px",
    marginLeft: "30px",
    marginRight: "30px",
    height: 50
  },
  spinnerStyles: {
    position: "absolute",
    top: "50%" /* Center vertically */,
    left: "30%" /* Center horizontally */,
    transform: "translate('-50%', '-50%')" /* Adjust position to truly center */
  },
  scheduleMessageStyles: {
    marginTop: "10px",
    marginLeft: "5px"
  },
  scheduledRun: {
    marginLeft: "30px",
    fontSize: "20px"
  },
  scheduleItemsStyles: {
    display: "flex",
    flexDirection: "column"
  },
  scheduleButtonStyles: {
    height: 30,
    marginLeft: "30px",
    marginTop: "20px"
  },
  labelStyles: {
    marginLeft: "50px",
    marginTop: "30px",
    marginBottom: "20px",
    maxWidth: "1200px"
  },
  promptAndButtonGroup: {
    display: "flex"
  },
  promptTextBoxStyles: {
    width: 1000
  },
  responseAndScheduleRunGroup: {
    display: "flex",
    position: "relative",
    marginTop: "30px"
  },
  textBoxStyles: {
    width: 1000
  },
  combobox: {
    marginTop: "40px",
    marginLeft: "50px",
    marginRight: "30px",
    width: 650
  },
  startDatePicker: {
    marginTop: 40,
    marginLeft: 50,
    marginRight: 30,
    width: 255
  } as CSSProperties,
  endDatePicker: {
    marginTop: 40,
    marginLeft: 48,
    marginRight: 30,
    width: 255
  } as CSSProperties,
  responseContainer: {
    border: "1px solid",
    width: 995,
    flexShrink: 0,
    height: 300,
    overflow: "auto",
    alignContent: "center"
  },
  responseHeader: {
    textAlign: "right",
    padding: "5px 0px"
  },
  copyClipboardButton: {
    border: "none",
    padding:"5px 7px",
    backgroundColor: "white",
    fontSize: 12,
    borderRadius: 6,
    ":hover": {
      backgroundColor: "lightgray"
    }
  },
  dropDown: {
    marginTop: "40px",
    marginLeft: "50px",
    marginRight: "30px",
    width: 1000,
    dropdownOptionText: {
      overflow: "visible",
      whiteSpace: "normal"
    },
    dropdownItem: { height: "auto" }
  },
  searchLabelAndBox: {
    display: "flex"
  },
  dropDownContainerStyles: {
    display: "flex",
    width: 1000
  }
});
