import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React from "react";

interface IAPIsException {
  height?: number;
  message?: string;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgb(109, 109, 109)",
    color: "rgb(245, 245, 245)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}));

const styles = {
  container: {
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "red"
  } as React.CSSProperties,
  contentStyle: {
    color: "rgb(95, 33, 32)",
    backgroundColor: "rgb(253, 237, 237)",
    padding: "12px 16px",
    borderRadius: "5px"
  } as React.CSSProperties,
  titleStyle: {
    textAlign: "justify",
    WebkitHyphens: "auto",
    MozHyphens: "auto",
    msHyphens: "auto",
    hyphens: "auto"
  } as React.CSSProperties,
  iconStyle: {
    color: "#d32f2f",
    marginLeft: "12px",
    cursor: "pointer"
  } as React.CSSProperties
};

const TitleComponent = ({ titleStyle }: any) => (
  <div style={{ ...titleStyle }}>
    Please refresh the page or contact support:{" "}
    <a style={{ color: "#37B2DB" }} href="mailto:Security360Core@microsoft.com">
      Security360Core@microsoft.com
    </a>
  </div>
);

const DisplayTooltipHtml = ({ enableInfo }: { enableInfo: boolean }) => {
  const TooltipHtml = () => (
    <>
      {" "}
      <HtmlTooltip title={<TitleComponent titleStyle={styles.titleStyle} />} arrow>
        <InfoOutlinedIcon style={{ ...styles.iconStyle }} />
      </HtmlTooltip>
    </>
  );

  return enableInfo ? <TooltipHtml /> : "";
};

const DisplayAPIsException = ({
  height = 150,
  message = "An error occurred while processing your request."
}: IAPIsException) => (
  <div
    style={{
      ...styles.container,
      height: height
    }}
  >
    <div
      style={{
        ...styles.container,
        ...styles.contentStyle
      }}
    >
      {message}
      <DisplayTooltipHtml enableInfo />
    </div>
  </div>
);

export default DisplayAPIsException;
