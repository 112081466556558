import { DocumentCardDetails, ILinkStyles, Link, Stack } from "@fluentui/react";
import { CardData } from "../../ReportCatalog.interfaces";
import React from "react";

const multiLineEllipsis = {
  maxWidth: "100%",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 3, // Adjust the number of lines as needed
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitHyphens: "auto",
  MozHyphens: "auto",
  msHyphens: "auto",
  hyphens: "auto",
  fontFamily: "var(--font-family)",
  fontSize: "12px",
  fontWeight: 400,
  color: "rgba(0, 0, 0, 0.87)",
  letterSpacing: "0.01071em"
} as React.CSSProperties;

const titleStyles: ILinkStyles = {
  root: {
    fontWeight: "bold",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
    fontFamily: "var(--font-family)"
  }
};

const TopReportsCardDetails = (props: { card: CardData; onCardSelected: (card: any) => void }) => {
  const {
    card: { description, title }
  } = props;
  const onClick = () => props.onCardSelected(props.card);
  return (
    <DocumentCardDetails styles={{ root: { padding: 5 } }}>
      <Stack>
        <div title={title} style={multiLineEllipsis}>
          <Link onClick={onClick} styles={titleStyles}>
            {title}
          </Link>
        </div>
        <div title={description} style={multiLineEllipsis}>
          {description}
        </div>
      </Stack>
    </DocumentCardDetails>
  );
};

export default TopReportsCardDetails;
