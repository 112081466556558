import { mergeStyleSets } from "@fluentui/react";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { GridColDef, GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PowerBIReportModal from "../MuiComponents/PowerBIReportModal";
import { getProducts } from "../Services/product.services";
import { getReports } from "../Services/reportServices";
import { CustomCustomerList } from "../SharedComponents/CustomCustomerList";
import { CustomDataGrid } from "../SharedComponents/CustomDataGrid";
import { IRows } from "../SharedComponents/Interfaces/CustomDataGrid.interfaces";
import { callSearchAPIs, getSearchInput } from "../Utils/js-common";

export default function Search() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [value, setValue] = useState(0);
  const searchText = params.get("q");

  const handleChange = (event: SyntheticEvent, newValue: number) => setValue(newValue);

  const onClose = () => navigate(-1);

  return (
    <>
      <div className={style.header}>
        <h2 onClick={onClose}>
          <KeyboardBackspaceIcon titleAccess="Go back" />
        </h2>
        <h2>&nbsp;Search results for &quot;{searchText}&quot;</h2>
        <div className={style.closeBtn} title="Close" onClick={onClose}>
          <CancelIcon />
        </div>
      </div>
      <Box sx={{ width: "100%", height: "calc(100% - 60px)" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", maxHeight: "60px" }}>
          <Tabs value={value} onChange={handleChange} aria-label="user search results">
            <Tab label="Customers" {...a11yProps(0)} sx={textTransform} />
            <Tab label="Products" {...a11yProps(1)} sx={textTransform} />
            <Tab label="Reports" {...a11yProps(2)} sx={textTransform} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <CustomCustomerList initialFilters="" searchText={searchText} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <RenderProducts searchText={searchText} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <RenderReports searchText={searchText} />
        </CustomTabPanel>
      </Box>
    </>
  );
}

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const textTransform = {
  textTransform: "none",
  fontSize: "14px",
  fontWeight: 600
};
const linkStyle = {
  color: "rgb(0, 120, 212)",
  textDecoration: "none",
  fontSize: 12,
  cursor: "pointer",
  overflow: "hidden",
  textOverflow: "ellipsis"
};
const style = mergeStyleSets({
  header: {
    display: "flex",
    alignItems: "center",
    "& > h2:first-child": { cursor: "pointer", flex: 0 },
    "& > h2:not(:first-child)": { flex: 1 }
  },
  closeBtn: {
    flex: 0,
    display: "flex",
    justifyContent: "right",
    cursor: "pointer",
    "& svg": {
      color: "gray",
      ":hover": {
        color: "blue"
      }
    }
  }
});
const gridConfig = {
  rows: {
    count: 0,
    values: [],
    size: 0,
    skip: 0
  },
  pagination: {
    pageSize: 25,
    page: 0
  }
};
const navigateTo = (key: string, value: any) => {
  let result = "/";
  switch (key) {
    case "customer":
      result = `/customer?id=${value}`;
      break;
    case "product":
      result = `/productview?productname=${value}`;
      break;
    default:
      break;
  }
  return result;
};
function LinkNavigate(props: { text: string; to: string }) {
  const navigate = useNavigate();
  return (
    <Link sx={linkStyle} title={props.text} underline="hover" onClick={() => navigate(props.to)}>
      {props.text}
    </Link>
  );
}
function LinkModal({ row, text }: any) {
  const [reportModal, setReportModal] = useState<{
    active: boolean;
    data: any;
  }>({
    active: false,
    data: undefined
  });
  const onReportModalClose = () => {
    setReportModal({ active: false, data: undefined });
  };
  return (
    <>
      <Link
        sx={linkStyle}
        title={text}
        underline="hover"
        onClick={() => setReportModal({ active: true, data: { row } })}
      >
        {text}
      </Link>
      {reportModal.active ? (
        <PowerBIReportModal
          ismodelOpen={reportModal.active}
          selectedItem={reportModal.data}
          CloseModalReoprt={onReportModalClose}
        />
      ) : (
        ""
      )}
    </>
  );
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const style = {
    display: `${value === index ? "block" : "none"}`,
    height: "calc(100% - 68px)",
    marginTop: 18
  };

  return (
    <div role="tabpanel" id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} style={style} {...other}>
      {children}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`
  };
}
function RenderReports({ searchText }: any) {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<IRows>(gridConfig.rows);
  const [paginationOptions, setPaginationOptions] = useState(gridConfig.pagination);
  const columns: GridColDef[] = [
    {
      field: "report",
      headerName: "Report",
      width: 250,
      sortable: true,
      renderCell: ({ row }: any) => <LinkModal row={row} text={row.report} />
    },
    {
      field: "description",
      headerName: "Description",
      width: 500,
      sortable: true,
      flex: 1
    },
    {
      field: "owner",
      headerName: "Owner",
      width: 130,
      sortable: true
    },
    { field: "product", headerName: "Product", width: 130, sortable: true },
    {
      field: "keywords",
      headerName: "Keywords",
      minWidth: 300,
      sortable: true
    }
  ];
  const [filterOptions, setFilterOptions] = useState<GridFilterModel>({
    items: []
  });
  const [sortOptions, setSortOptions] = useState<GridSortModel>([]);

  useEffect(() => {
    const searchInput = getSearchInput("", searchText, filterOptions, sortOptions, paginationOptions, "reports");
    callSearchAPIs(getReports(searchInput), setLoading, setRows);
  }, [searchText, filterOptions, sortOptions, paginationOptions]);

  return (
    <CustomDataGrid
      columns={columns}
      filterMode="server"
      loading={loading}
      paginationMode="server"
      paginationOptions={paginationOptions}
      rows={rows}
      setFilterOptions={setFilterOptions}
      setPaginationOptions={setPaginationOptions}
      setSortOptions={setSortOptions}
      sortingMode="server"
    />
  );
}
function RenderProducts({ searchText }: any) {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<IRows>(gridConfig.rows);
  const [paginationOptions, setPaginationOptions] = useState(gridConfig.pagination);
  const columns: GridColDef[] = [
    {
      headerName: "Product Name",
      field: "productName",
      minWidth: 250,
      flex: 1,
      sortable: true,
      renderCell: ({ row }: any) => <LinkNavigate to={navigateTo("product", row.productName)} text={row.productName} />
    }
  ];
  const [filterOptions, setFilterOptions] = useState<GridFilterModel>({
    items: []
  });
  const [sortOptions, setSortOptions] = useState<GridSortModel>([]);

  useEffect(() => {
    const searchInput = getSearchInput("", searchText, filterOptions, sortOptions, paginationOptions, "product");
    callSearchAPIs(getProducts(searchInput), setLoading, setRows);
  }, [searchText, filterOptions, sortOptions, paginationOptions]);

  return (
    <CustomDataGrid
      columns={columns}
      filterMode="server"
      loading={loading}
      paginationMode="server"
      paginationOptions={paginationOptions}
      rows={rows}
      setFilterOptions={setFilterOptions}
      setPaginationOptions={setPaginationOptions}
      setSortOptions={setSortOptions}
      sortingMode="server"
    />
  );
}
