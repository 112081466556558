import { IContextualMenuItem } from "@fluentui/react";
import { CustomFilterPillProps } from "../../SharedComponents/Interfaces/CustomFilterPill.interfaces";
import { ReducerType } from "./CustomSearch.interfaces";
import { CUSTOMER_SEARCH_STYLES } from "./CustomerSearch.styles";

/**
 * Custom function named `mapFilterPillMenuItemsPredicate` that
 * converts a string value into an object of type `IContextualMenuItem`.
 */
export const mapFilterPillMenuItemsPredicate = (value: string): IContextualMenuItem => ({ key: value, text: value });

export const customerFilterPillReducer = (
  state: { menuItems: IContextualMenuItem[]; selectedMenuItems: string[] },
  action: { type: ReducerType; data: IContextualMenuItem[] | string[] }
) => {
  switch (action.type) {
    case ReducerType.LoadMenuItems:
      return { ...state, menuItems: action.data as IContextualMenuItem[] };
    case ReducerType.UpdateSelectedMenuItems:
      return { ...state, selectedMenuItems: action.data as string[] };
    default:
      return state;
  }
};

export const createFilterPill = (key: string, name: string): CustomFilterPillProps => {
  return {
    defaultValue: "All",
    isRemovable: true,
    key,
    name,
    styles: CUSTOMER_SEARCH_STYLES.CUSTOMER_SEARCH_FILTER_PILL.STYLES
  };
};
