import { DocumentCard, IDocumentCardStyles } from "@fluentui/react";
import { CardProps } from "../../ReportCatalog.interfaces";
import { TopReportsCardShimmer } from "./Shimmer/TopReportsCardShimmer";
import CardDetails from "./TopReportsCardDetails";
import CardImage from "./TopReportsCardImage";

const cardStyles: IDocumentCardStyles = {
  root: {
    display: "inline-block",
    width: "16.67%",
    maxWidth: "none",
    minWidth: "auto",
    userSelect: "auto",
    boxShadow: "var(--shadow4)",
    borderRadius: "var(--borderRadiusMedium)",
    "&:hover": {
      borderColor: "rgb(200, 200, 200)"
    }
  }
};

const TopReportsCard = ({ card, onCardSelected, loading }: CardProps) => {
  const LoadCard = () => (
    <>
      <CardImage card={card} onCardSelected={onCardSelected} />
      <CardDetails card={card} onCardSelected={onCardSelected} />
    </>
  );
  const LoadShimmerOrCard = () => (loading && <TopReportsCardShimmer />) || <LoadCard />;

  return (
    <DocumentCard aria-label={card.ariaLabel} styles={cardStyles}>
      <LoadShimmerOrCard />
    </DocumentCard>
  );
};

export default TopReportsCard;
