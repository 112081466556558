import { mergeStyles } from "@fluentui/react";
import { createContext, useContext, useEffect, useMemo } from "react";
import { logEvent, logException } from "../../helper/AppInsightsHelper";
import { ProductViewFiltersContext } from "../../pages/ProductView";
import {
  PRODUCT_METRICS_CARDS_GRAPHS_CACHE_KEY,
  useGetProductMetricsCardsGraphsMutation
} from "../../slices/Product.slice";
import { PRODUCT_VIEW_DATA } from "./Common/ProductView.constants";
import { OverviewAndSummaryMutationProps } from "./Common/ProductView.interfaces";
import { PRODUCT_VIEW_STYLES } from "./Common/ProductView.styles";
import { ProductViewOverviewContainer } from "./Containers/ProductViewOverviewContainer";
import { ProductViewTabsContainer } from "./Containers/ProductViewTabsContainer";

export const OverviewAndSummaryMutationContext = createContext<OverviewAndSummaryMutationProps>({
  data: { overviewData: [], summaryData: [] }
});

export default function ProductView() {
  const [getData, { data, error, isLoading, isError, isSuccess }] = useGetProductMetricsCardsGraphsMutation({
    fixedCacheKey: PRODUCT_METRICS_CARDS_GRAPHS_CACHE_KEY
  });
  const filters = useContext(ProductViewFiltersContext);
  const mutationValue = useMemo(
    () => ({
      data: {
        overviewData: data?.cardDataValues,
        summaryData: data?.metricsData
      },
      isError: isError,
      isLoading: isLoading
    }),
    [data, isError, isLoading]
  );

  useEffect(() => {
    if (isSuccess) {
      logEvent("useGetProductMetricsCardsGraphsMutation API success", "Product Page", "ProductView");
    }
    if (isError) {
      logException("Product Page", "ProductView", "useGetProductMetricsCardsGraphsMutation", error);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (filters.productName) {
      const requestData = {
        hasPartner: filters.hasPartner,
        industry: filters.industry,
        isCAT: filters.isCAT,
        isFastTrackStrategic: filters.isFastTrackStrategic,
        isSST: filters.isSST,
        ProductName: filters.productName,
        Region: filters.region,
        segmentGroup: filters.segmentGroup,
        segment: filters.segment,
        SelectedTabName: PRODUCT_VIEW_DATA.PRODUCT_VIEW.INITIAL_TAB_NAME,
        subsegment: filters.subSegment,
        Vertical: ""
      };
      getData(requestData);
    }
  }, [
    filters.hasPartner,
    filters.industry,
    filters.isCAT,
    filters.isFastTrackStrategic,
    filters.isSST,
    filters.productName,
    filters.region,
    filters.segmentGroup,
    filters.segment,
    filters.subSegment,
    getData
  ]);

  return (
    <div className={mergeStyles(PRODUCT_VIEW_STYLES.PRODUCT_VIEW.container)}>
      <OverviewAndSummaryMutationContext.Provider value={mutationValue}>
        <ProductViewOverviewContainer />
        <ProductViewTabsContainer />
      </OverviewAndSummaryMutationContext.Provider>
    </div>
  );
}
