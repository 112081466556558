import { DataGrid, GridColDef, GridRowSelectionModel, GridToolbar } from "@mui/x-data-grid";
import React from "react";
import { handleToggleDataGridRowSelection } from "../../Utils/js-common";

const PartnerTable = (props: any) => {
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const handleSelection = handleToggleDataGridRowSelection(selectionModel, setSelectionModel);
    const showNoData = () => {
        const rootStyle = {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "200px"
        };
        return (
            <div style={rootStyle}>{`Partner details are not available for ${props.tenantName} .`} </div>
        );
    };

    const columns: GridColDef[] = [
        {
            headerName: "Partner OneID",
            field: "partnerOneId",
            width: 160,
            sortable: true,
        },
        {
            headerName: "Partner OneName",
            field: "partnerOneName",
            width: 180,
            sortable: true
        },
        {
            headerName: "Partner ID",
            field: "partnerId",
            width: 180,
            sortable: true
        },
        {
            headerName: "Partner Name",
            field: "partnerName",
            width: 180,
            sortable: true
        },
        {
            headerName: "Workload Name",
            field: "workloadName",
            width: 200,
            sortable: true
        },
        {
            headerName: "Partner Type",
            field: "partnerType",
            width: 100,
            sortable: true
        },
        {
            headerName: "Partner Country",
            field: "partnerCountry",
            width: 180,
            sortable: true
        },
        {
            headerName: "IsElite",
            field: "isElite",
            width: 100,
            sortable: true
        },
        // {
        //     headerName: "IsDGPartner",
        //     field: "isDGPartner",
        //     width: 100,
        //     sortable: true
        // },
        {
            headerName: "IsSSTPartner",
            field: "isSSTPartner",
            width: 130,
            sortable: true
        },
        {
            headerName: "Partner Role",
            field: "partnerRole",
            width: 130,
            sortable: true,
            flex: 1
        }

        // {
        //     headerName: "Claim ID",
        //     field: "claimId",
        //     width: 180,
        //     minWidth: 180,
        //     sortable: true,
        //     flex: 1
        // },
    ];

    React.useEffect(() => {
        setTimeout(() => {
            const elements = document.getElementsByClassName("MuiDataGrid-virtualScroller");
            Array.from(elements).forEach(function (element) {
                element.setAttribute("tabIndex", "0");
            });
        }, 1000);
    }, []);
    return (
        <>
            {props.tableData && props.tableData.length > 0 ? (
                <div style={{ height: 600, width: "100%" }} tabIndex={0}>
                    <DataGrid
                        rows={props.tableData}
                        columns={columns}
                        getRowId={(row) => row.id}
                        density="compact"
                        disableDensitySelector
                        disableRowSelectionOnClick
                        showCellVerticalBorder
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true
                            }
                        }}
                        onRowSelectionModelChange={handleSelection}
                        rowSelectionModel={selectionModel}
                    />
                </div>
            ) : (
                showNoData() || ""
            )}
        </>
    );
};

export default PartnerTable;
