import { IStyle } from "@fluentui/react";
import { CSSProperties } from "react";

export const CUSTOM_CUSTOMER_LIST_STYLES = {
  customDataGridColumns: {
    tenantIdContainer: {
      alignItems: "center"
    } as IStyle,
    tenantIdTooltipCopyIconBtn: { fontSize: 12 } as CSSProperties,
    tenantIdTooltipContainerBtn: {
      color: "#000 !important",
      height: 22,
      marginLeft: "2px !important"
    } as unknown as IStyle
  }
};
