import { ICommandBarItemProps, Shimmer, ShimmerElementType } from "@fluentui/react";
import { OptionOnSelectData } from "@fluentui/react-components";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DisplayAPIsException from "../../../BasicComponents/DisplayAPIsExceptionAlert";
import { logEvent, logException } from "../../../helper/AppInsightsHelper";
import { CustomShimmer } from "../../../helper/CustomShimmer";
import { ProductViewFiltersContext } from "../../../pages/ProductView";
import CustomCard from "../../../SharedComponents/CustomCard";
import { useGetTopTenantsOfCustomerMutation } from "../../../slices/TopTenant.slice";
import CustomDropdown from "./CustomDropdown";
import { OverviewAndSummaryMutationContext } from "../ProductView";
import { PRODUCT_VIEW_DATA } from "./ProductView.constants";
import { PRODUCT_VIEW_STYLES } from "./ProductView.styles";
import { ExportToExcel } from "../../../Utils/FileMenuFunctions";

const TopTenants = ({
  pageSizeOptions,
  paginationModel
}: {
  pageSizeOptions: number[];
  paginationModel: { page: number; pageSize: number };
}) => {
  const navigate = useNavigate();
  const { hasPartner, industry, isCAT, isFastTrackStrategic, isSST, productName, region,segmentGroup, segment, subSegment } =
    useContext(ProductViewFiltersContext);
  const [paginationModelForTopImproving, setPaginationModelForTopImproving] = useState(paginationModel);
  const [paginationModelForTopDeclining, setPaginationModelForTopDeclining] = useState(paginationModel);
  const [selectedMetric, setSelectedMetric] = useState<string | undefined>("");
  const { data: overviewAndSummaryData } = useContext(OverviewAndSummaryMutationContext);
  const [getTopTenants, { data, error, isError, isLoading, isSuccess }] = useGetTopTenantsOfCustomerMutation({
    fixedCacheKey: "getTopTenants"
  });
  const cardShimmRef = useRef<any>();
  const selectedMetricRef = useRef(selectedMetric);
  const columns: GridColDef[] = [
    {
      headerName: "Tenant Name",
      field: "tenantName",
      minWidth: 265,
      flex: 1,
      sortable: true,
      renderCell: (params) => (
        <a
          style={{
            color: "rgb(0, 120, 212)",
            textDecoration: "none",
            fontSize: 12,
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
          href="#"
          onClick={() => {
            navigate("/customer?id=" + params.row.tenantId);
            // SetSelectedItem(params);
          }}
          title={params.row.tenantName}
        >
          {params.row.tenantName}
        </a>
      )
    },
    {
      headerName: "MoM Absolute",
      field: "moM",
      minWidth: 120,
      maxWidth: 200,
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        const val = Number(parseFloat(params.row.moM).toFixed(2)).toLocaleString("en", {
          minimumFractionDigits: 0
        });
        return (
          <span
            style={{
              color: String(val).includes("-") ? "red" : "green"
            }}
          >
            {val}
          </span>
        );
      }
    },
    {
      headerName: "MoM%",
      field: "mom_Percent",
      minWidth: 100,
      maxWidth: 200,
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        const val = parseInt(`${params.row.mom_Percent * 100}`);
        return (
          <span
            style={{
              color: String(val).includes("-") ? "red" : "green"
            }}
          >
            {val}%
          </span>
        );
      }
    },
    {
      headerName: "Current Value",
      field: "currentValue",
      minWidth: 120,
      maxWidth: 200,
      flex: 1,
      sortable: true,
      renderCell: (params) => (
        <>
          {Number(parseFloat(params.row.currentValue).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 0
          })}
        </>
      )
    }
  ];

  const showNoGraphData = () => {
    const rootStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "200px 0"
    };
    return <div style={rootStyle}>{`Table has no data to display by metric ${selectedMetric}`}</div>;
  };

  const renderCardHeader = (metricType: "Growing" | "Declining") =>
    isLoading ? (
      <Shimmer styles={PRODUCT_VIEW_STYLES.TAB.OPPORTUNITY_TENANTS.CSS_STYLES.cardHeaderShimmerStyles} />
    ) : (
      {
        title: data && data?.metricName && `Top ${metricType} Tenants for ${productName} by ${data?.metricName}`,
        tooltip:
          metricType === "Growing"
            ? PRODUCT_VIEW_DATA.TABS.TAB.OPPORTUNITY_TENANTS.CARD_HEADER.TOP_GROWING.tooltip
            : PRODUCT_VIEW_DATA.TABS.TAB.OPPORTUNITY_TENANTS.CARD_HEADER.TOP_DECLINING.tooltip
      }
    );

  const getExcelFormattedData = (excelData: any) => {
    const excelFormattedData = excelData.map((x: any) => {
      return {
        "Tenant Name": x.tenantName,
        "MoM Absolute": x.moM,
        "MoM %": Math.round(x.mom_Percent * 100) + "%",
        "Current Value": x.readableCurrentValue
      };
    });
    return excelFormattedData;
  };

  const topImprovingTenantsCardOverflowItems: ICommandBarItemProps[] = [
    {
      key: "exportToExcel",
      text: "Export to Excel",
      iconProps: { iconName: "Download" },
      onClick: () => {
        ExportToExcel(
          getExcelFormattedData(data?.topImprovingTenants),
          "Security360_" + productName + "_TopGrowingTenants_by_" + data?.metricName
        );
      }
    }
  ];

  const topDecliningTenantsCardOverflowItems: ICommandBarItemProps[] = [
    {
      key: "exportToExcel",
      text: "Export to Excel",
      iconProps: { iconName: "Download" },
      onClick: () => {
        ExportToExcel(
          getExcelFormattedData(data?.topDecliningTenants),
          "Security360_" + productName + "_TopDecliningTenants_by_" + data?.metricName
        );
      }
    }
  ];

  const handleSelectedMetrics = useCallback(({ optionText }: OptionOnSelectData) => {
    setSelectedMetric(optionText);
  }, []);

  const displayViewByMetricSelect = (isLoading && (
    <div style={{ flexBasis: "100%" }}>
      <Shimmer
        shimmerColors={{ shimmer: "var(--shimmer-color)" }}
        styles={{
          root: { paddingTop: 10, paddingBottom: 10, width: "25%" },
          shimmerWrapper: { ".ms-ShimmerGap-root": { background: "var(--colorBodyBackground1)" } }
        }}
        shimmerElements={[
          { type: ShimmerElementType.line, width: "15%" },
          { type: ShimmerElementType.gap, width: 10 },
          { type: ShimmerElementType.line, width: "15%" }
        ]}
      />
    </div>
  )) || (
    <CustomDropdown
      defaultOption={
        {
          optionText: data?.metricName,
          optionValue: data?.metricName,
          selectedOptions: data?.metricName ? [data?.metricName] : []
        } as OptionOnSelectData
      }
      onSelectedOption={handleSelectedMetrics}
      data={(overviewAndSummaryData?.overviewData || [])
        .filter(({ productKPIName }) => !productKPIName.includes("Tenants"))
        .map(({ productKPIName }) => ({ value: productKPIName, text: productKPIName }))}
      styles={{ root: { flexBasis: "100%", paddingBottom: 10 } }}
    />
  );

  useEffect(() => {
    selectedMetricRef.current = "";
  }, [productName]);

  useEffect(() => {
    selectedMetricRef.current = selectedMetric;
  }, [selectedMetric]);

  useEffect(() => {
    const apiBody = {
      hasPartner: hasPartner,
      IndustryName: industry,
      isCAT: isCAT,
      isFastTrackStrategic: isFastTrackStrategic,
      isSST: isSST,
      ProductKpiName: selectedMetricRef.current,
      ProductName: productName,
      RegionName: region,
      segmentGroup:segmentGroup,
      SegmentName: segment,
      SubSegmentName: subSegment,
      VerticalName: ""
    };
    getTopTenants(apiBody);
  }, [
    hasPartner,
    industry,
    isCAT,
    isFastTrackStrategic,
    isSST,
    productName,
    region,
    segmentGroup,
    segment,
    selectedMetric,
    subSegment
  ]);

  React.useEffect(() => {
    if (isSuccess) {
      logEvent("getTopTenants API call success", "Product View", "TopTenants");
    }
    if (isError) {
      logException("Product View", "TopTenants", "getTopTenants", error);
    }
  }, [isSuccess, isError]);

  return (
    <div className={PRODUCT_VIEW_STYLES.TAB.OPPORTUNITY_TENANTS.CLASS_NAMES.cardsContainer}>
      {displayViewByMetricSelect}
      <div ref={cardShimmRef} className={PRODUCT_VIEW_STYLES.TAB.OPPORTUNITY_TENANTS.CLASS_NAMES.cardsContainerItem}>
        <CustomCard
          cardHeaderTitle={renderCardHeader("Growing")}
          styles={PRODUCT_VIEW_STYLES.TAB.OPPORTUNITY_TENANTS.CSS_STYLES.cardStyles}
          overflowItems={topImprovingTenantsCardOverflowItems}
        >
          {isLoading ? (
            <CustomShimmer columnCount={columns.length} />
          ) : (
            (data && data?.topImprovingTenants?.length > 0 && (
              <DataGrid
                autoHeight
                rows={data?.topImprovingTenants}
                columns={columns}
                pageSizeOptions={pageSizeOptions}
                paginationModel={paginationModelForTopImproving}
                onPaginationModelChange={setPaginationModelForTopImproving}
                getRowId={(row) => row.tenantId}
                rowHeight={30}
                columnHeaderHeight={40}
                disableColumnMenu
                disableColumnFilter
                disableRowSelectionOnClick
                disableColumnSelector
              />
            )) ||
            (data && data?.topImprovingTenants?.length === 0 && showNoGraphData()) ||
            (isError && <DisplayAPIsException height={cardShimmRef.current?.offsetHeight} />)
          )}
        </CustomCard>
      </div>
      <div className={PRODUCT_VIEW_STYLES.TAB.OPPORTUNITY_TENANTS.CLASS_NAMES.cardsContainerItem}>
        <CustomCard
          cardHeaderTitle={renderCardHeader("Declining")}
          styles={PRODUCT_VIEW_STYLES.TAB.OPPORTUNITY_TENANTS.CSS_STYLES.cardStyles}
          overflowItems={topDecliningTenantsCardOverflowItems}
        >
          {isLoading ? (
            <CustomShimmer columnCount={columns.length} />
          ) : (
            (data && data?.topDecliningTenants?.length > 0 && (
              <DataGrid
                autoHeight
                rows={data?.topDecliningTenants}
                columns={columns}
                pageSizeOptions={pageSizeOptions}
                paginationModel={paginationModelForTopDeclining}
                onPaginationModelChange={setPaginationModelForTopDeclining}
                getRowId={(row) => row.tenantId}
                rowHeight={30}
                columnHeaderHeight={40}
                disableColumnMenu
                disableColumnFilter
                disableRowSelectionOnClick
                disableColumnSelector
              />
            )) ||
            (data && data?.topDecliningTenants?.length === 0 && showNoGraphData()) ||
            (isError && <DisplayAPIsException height={cardShimmRef.current?.offsetHeight} />)
          )}
        </CustomCard>
      </div>
    </div>
  );
};

export default TopTenants;
