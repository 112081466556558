import { PivotItem } from "@fluentui/react";
import { Paper } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ProductViewFiltersContext } from "../../../../pages/ProductView";
import { useGetFeatureProductDeatailsMutation } from "../../../../slices/FeatureProduct.slice";
import { PRODUCT_VIEW_DATA } from "../..//Common/ProductView.constants";
import { PRODUCT_VIEW_STYLES } from "../../Common/ProductView.styles";
import { TabsContainer } from "../../../../SharedComponents/TabsContainer";

export const FeatureTab = () => {
  const [enableTabs, setEnableTabs] = useState<boolean>(true);
  const {
    pivot: { ariaLabel },
    pivotItems
  } = PRODUCT_VIEW_DATA.TABS.TAB.FEATURE_VIEW.TABS_DATA;
  const renderTabs = pivotItems.map(({ headerText, itemKey, component }, index) => (
    <PivotItem headerText={headerText} itemKey={itemKey} key={`${itemKey + index}`}>
      {component()}
    </PivotItem>
  ));
  const filters = useContext(ProductViewFiltersContext);
  const [getData, { data, isLoading, isSuccess }] = useGetFeatureProductDeatailsMutation({
    fixedCacheKey: "getFeatures"
  });

  useEffect(() => {
    if (filters.productName) {
      getData({
        hasPartner: filters.hasPartner,
        industry: filters.industry,
        isCAT: filters.isCAT,
        isFastTrackStrategic: filters.isFastTrackStrategic,
        isSST: filters.isSST,
        productName: filters.productName,
        region: filters.region,
        segmentGroup:filters.segmentGroup,
        segment: filters.segment,
        subSegment: filters.subSegment
      });
    }
  }, [
    filters.hasPartner,
    filters.industry,
    filters.isCAT,
    filters.isFastTrackStrategic,
    filters.isSST,
    filters.productName,
    filters.region,
    filters.segmentGroup,
    filters.segment,
    filters.subSegment,
    getData
  ]);

  useEffect(() => {
    if (isSuccess) {
      setEnableTabs(Boolean(data?.featuresByName.length));
    }
  }, [isSuccess]);

  return (
    <Paper>
      {(isLoading || enableTabs) && <TabsContainer ariaLabel={ariaLabel}>{renderTabs}</TabsContainer>}
      {isSuccess && !enableTabs && <NoDataDisplayMessage productName={filters.productName} />}
    </Paper>
  );
};

const NoDataDisplayMessage = ({ productName }: { productName?: string }) => (
  <div className={PRODUCT_VIEW_STYLES.TAB.FEATURE_VIEW.CLASS_NAMES.noDataForProductDisplayMessage}>
    {productName} has no feature data available yet.
  </div>
);
