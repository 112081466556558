import { PowerBIEmbed } from "powerbi-client-react";
import { useAppSelector } from "../store/hooks";
const PowerBIReport = (props: any) => {
  let reportId = "";

  if (props.selectedItem?.row) {
    const reportLink = props.selectedItem.row.reportLink;
    const reportParser = reportLink?.split("/reports")[1];
    reportId = reportParser?.split("/")[1];
  }

  return (
    <PowerBIEmbed
      cssClassName="powerBiCSS"
      embedConfig={{
        type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
        id: reportId,
        embedUrl: `https://msit.powerbi.com/reportEmbed?reportId=${reportId}`,
        accessToken: useAppSelector((state) => state.msal.powerbiToken),
        tokenType: 0 // Use models.TokenType.Aad for SaaS embed
      }}
    />
  );
};

export default PowerBIReport;
