import { mergeStyles } from "@fluentui/react";
import { ProgressBarItemProps } from "../interfaces/ProgressBarItem.interfaces";
import { CUSTOM_PROGRESSBAR_STYLES } from "./Styles/ProgressBarItem.styles";

const ProgressBarItem = ({
  backgroundColor,
  displayValue,
  height,
  maxValue,
  minValue,
  progress
}: ProgressBarItemProps) => {
  const progressBarWidth = Math.abs((progress / (progress < 0 ? minValue || 1 : maxValue || 1)) * 100);

  return (
    <div className={CUSTOM_PROGRESSBAR_STYLES.progressBarContainer}>
      <div className={`${CUSTOM_PROGRESSBAR_STYLES.progressBarContainerContent} ${mergeStyles({ height: height })}`}>
        <div
          className={`${CUSTOM_PROGRESSBAR_STYLES.progressBar} ${mergeStyles({
            backgroundColor,
            width: `${progressBarWidth}%`
          })}`}
        ></div>
      </div>
      <div className={CUSTOM_PROGRESSBAR_STYLES.progressBarDisplayValue}>{`${displayValue}`}</div>
    </div>
  );
};

export default ProgressBarItem;
