import { Stack } from "@fluentui/react";
import {
  Combobox,
  ComboboxProps,
  FluentProvider,
  Label,
  Option,
  OptionOnSelectData,
  OptionProps,
  SelectionEvents,
  useId,
  webLightTheme
} from "@fluentui/react-components";
import * as React from "react";
import {
  COMBOBOX_STYLES,
  FONT_FAMILY_STYLE,
  SELECT_ALL_OPTION,
  exceptSelectAllPredicate
} from "../../ReportCatalog.common";
import { ProductSelectFilterProps } from "../../ReportCatalog.interfaces";

export default function ProductSelectFilter({
  data,
  onSelectedProducts,
  selectedProductOptions
}: Readonly<ProductSelectFilterProps>) {
  const productId = useId("product-combo-multi-select");
  const distinctProducts: string[] = Array.from(new Set(data.map((item: any) => item.product)));
  const [selectedValue, setSelectedValue] = React.useState(SELECT_ALL_OPTION.text);
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>(selectedProductOptions);
  const options: OptionProps[] = [
    SELECT_ALL_OPTION,
    ...distinctProducts.map((func) => ({
      value: func,
      text: func
    }))
  ];

  const selectableOptions = options.filter(({ value }) => exceptSelectAllPredicate(value));

  const handleSelectedOptions = React.useCallback(
    (selected: string[]) => {
      setSelectedOptions(selected);
      onSelectedProducts(selected.length ? selected : distinctProducts);
    },
    [distinctProducts, onSelectedProducts]
  );

  const onDefaultSelectedProducts = React.useCallback(() => {
    if (!selectedProductOptions.includes(SELECT_ALL_OPTION.value) && selectedProductOptions.length) {
      setSelectedValue(selectedProductOptions.join(","));
    }
    setSelectedOptions(selectedProductOptions);
  }, [selectedProductOptions]);

  const onSelect: ComboboxProps["onOptionSelect"] = React.useCallback(
    (event: SelectionEvents, data: OptionOnSelectData) => {
      const selected = data.selectedOptions;
      const currentSelectedOptionKeys = selected.filter(exceptSelectAllPredicate);
      const selectAllState = currentSelectedOptionKeys.length === selectableOptions.length;

      if (data.optionValue === SELECT_ALL_OPTION.value) {
        if (selectAllState) {
          handleSelectedOptions([]);
          setSelectedValue("");
        } else {
          handleSelectedOptions([SELECT_ALL_OPTION.value, ...selectableOptions.map((o) => o.value as string)]);
          setSelectedValue(SELECT_ALL_OPTION.text);
        }
      } else {
        const updatedKeys = [...selected.filter(exceptSelectAllPredicate)];
        if (updatedKeys.length === selectableOptions.length) {
          updatedKeys.push(SELECT_ALL_OPTION.value);
          setSelectedValue(SELECT_ALL_OPTION.text);
        } else {
          setSelectedValue(updatedKeys.join(", "));
        }
        handleSelectedOptions(updatedKeys);
      }
    },
    [handleSelectedOptions, selectableOptions]
  );

  React.useEffect(() => {
    onDefaultSelectedProducts();
  }, [onDefaultSelectedProducts]);

  return (
    <FluentProvider theme={webLightTheme}>
      <Stack>
        <Label id={productId} style={{ ...FONT_FAMILY_STYLE, fontWeight: 700 }}>
          Select Product
        </Label>
        <Combobox
          aria-labelledby={productId}
          multiselect={true}
          onOptionSelect={onSelect}
          placeholder="Select Product"
          selectedOptions={selectedOptions}
          style={COMBOBOX_STYLES}
          value={selectedValue}
        >
          {options.map((option) => (
            <Option key={option.text} text={option.text ?? ""} value={option.value}>
              {option.text}
            </Option>
          ))}
        </Combobox>
      </Stack>
    </FluentProvider>
  );
}
