import { Pivot, PivotItem } from "@fluentui/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { TabsContainer } from "../../SharedComponents/TabsContainer";
import { CustomerViewTabs } from "../../Utils/Enums/Customer.constants";
import CRMCustomerProductContainer from "./CRMCustomerProductContainer";
import CustomerEngagementContainer from "./CustomerEngagementContainer";
import CustomerProductInfo from "./CustomerProductInfo/CustomerProductInfo";
import FeatureRequestContainer from "./FeatureRequestContainer";
import ProgramsContainer from "./ProgramsContainer";
import SubscriptionContainer from "./SubscriptionContainer";
import SupportContainer from "./SupportContainer";
import { logEvent } from "../../helper/AppInsightsHelper";
import PartnerContainer from "./PartnerContainer";

export const CustomerPivot = (props: any) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultTab = "product";

  const [selectedTab, setSelectedTab] = React.useState<string>(searchParams.get("tab") || defaultTab);

  const TABS_DATA = {
    pivot: { ariaLabel: "customer-pivot-component-aria-label" },
    pivotItems: [
      {
        headerText: "Product",
        itemKey: CustomerViewTabs.Product,
        component: () => (
          <div style={{ marginTop: 20 }}>
            <CustomerProductInfo />
          </div>
        )
      },
      {
        headerText: "License and Subscription",
        itemKey: CustomerViewTabs.Subscription,
        component: () => (
          <div style={{ marginTop: 20 }}>
            <SubscriptionContainer tenantName={props.tenantName} />
          </div>
        )
      },
      {
        headerText: "Feature Request",
        itemKey: CustomerViewTabs.FeatureRequest,
        component: () => (
          <div style={{ marginTop: 20 }}>
            <FeatureRequestContainer tenantName={props.tenantName} />
          </div>
        )
      },
      // {
      //   headerText: "Request for Assistance",
      //   itemKey: CustomerViewTabs.RequestForAssistance,
      //   component: () => (
      //     <div style={{ marginTop: "20px", width: "100%" }}>
      //       <RequestForAssistanceContainer tenantName={props.tenantName} />
      //     </div>
      //   )
      // },
      {
        headerText: "Support",
        itemKey: CustomerViewTabs.Support,
        component: () => (
          <div style={{ width: "100%" }}>
            <SupportContainer tenantName={props.tenantName} />
          </div>
        )
      },
      {
        headerText: "Customer Engagements",
        itemKey: CustomerViewTabs.CustomerEngagements,
        component: () => (
          <div style={{ marginLeft: 8, marginRight: 8 }}>
            <TabsContainer ariaLabel={CUSTOMER_ENGAGEMENTS_TABS_DATA.pivot.ariaLabel}>
              {renderCustomerEngagementsTabs}
            </TabsContainer>
          </div>
        )
      },
      {
        headerText: "Partner",
        itemKey: CustomerViewTabs.Partner,
        component: () => (
          <div style={{ width: "100%" }}>
            <PartnerContainer tenantName={props.tenantName} />
          </div>
        )
      }
    ]
  };

  const CUSTOMER_ENGAGEMENTS_TABS_DATA = {
    pivot: { ariaLabel: "customer-egagements-pivot-component-aria-label" },
    pivotItems: [
      {
        headerText: "Customer Product",
        itemKey: CustomerViewTabs.CRMCustomerProduct,
        component: () => (
          <div style={{ marginBottom: 13 }}>
            <CRMCustomerProductContainer tenantName={props.tenantName} />
          </div>
        )
      },
      {
        headerText: "Engagements",
        itemKey: CustomerViewTabs.Engagements,
        component: () => (
          <div style={{ marginBottom: 13 }}>
            <CustomerEngagementContainer tenantName={props.tenantName} />
          </div>
        )
      },
      {
        headerText: "Programs",
        itemKey: CustomerViewTabs.Programs,
        component: () => (
          <div style={{ marginBottom: 13 }}>
            <ProgramsContainer tenantName={props.tenantName} />
          </div>
        )
      }
    ]
  };

  const renderCustomerEngagementsTabs = CUSTOMER_ENGAGEMENTS_TABS_DATA.pivotItems.map(
    ({ headerText, itemKey, component }, index) => (
      <PivotItem headerText={headerText} itemKey={itemKey} key={`${itemKey + index}`}>
        {component()}
      </PivotItem>
    )
  );

  const renderProductViewTabs = TABS_DATA.pivotItems.map(({ headerText, itemKey, component }, index) => (
    <PivotItem headerText={headerText} itemKey={itemKey} key={`${itemKey + index}`}>
      {component()}
    </PivotItem>
  ));

  const handleSelectedTab = (item?: PivotItem) => {
    if (item) {
      const newTab = item.props.itemKey as CustomerViewTabs; // Ensure newTab is string
      const currentId = searchParams.get("id"); // Get current 'id'
      setSearchParams({ id: currentId || "", tab: newTab });

      setSelectedTab(newTab || defaultTab);

      const customPropsForLogging = {
        "SelectedTab": newTab
      };
      logEvent("CustomerPage tab selected", "CustomerPage", "CustomerPivot", customPropsForLogging);
    }
  };

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab && Object.values(CustomerViewTabs).includes(tab as CustomerViewTabs)) {
      setSelectedTab(tab);
    } else {
      setSelectedTab(CustomerViewTabs.Product); // Default to Tabs.Product
    }
  }, [searchParams]);

  return (
    <Pivot
      aria-label={TABS_DATA.pivot.ariaLabel}
      selectedKey={selectedTab}
      onLinkClick={handleSelectedTab}
      styles={{ root: { width: "100%" } }}
    >
      {renderProductViewTabs}
    </Pivot>
  );
};
