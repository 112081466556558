import { IStyle, mergeStyleSets } from "@fluentui/react";
import { CSSProperties } from "react";

export const CUSTOM_FILTER_PILL_STYLES = {
  EMPTY_DATA_STYLES: {
    emptyDataTokens: { childrenGap: 10, padding: 10 } as CSSProperties,
    emptyDataContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      minHeight: 50
    } as CSSProperties
  },
  SEARCH_BOX: mergeStyleSets({
    searchBoxContainer: { borderBottom: "1px solid #ccc", padding: 10 } as IStyle,
    searchBoxLabel: { display: "inline-block", marginBottom: 5 } as IStyle
  }),
  ACTION_BUTTONS: {
    CLASS_NAMES: mergeStyleSets({
      actionButtonsContainer: { borderTop: "1px solid #ccc", padding: 10 } as IStyle
    }),
    CSS_STYLES: {
      actionButtonsContainerTokens: { childrenGap: 10 } as CSSProperties
    }
  }
};
