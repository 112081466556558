/* eslint-disable no-undef */
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { globalHistory } from "@reach/router";

const clickPluginInstance = new ClickAnalyticsPlugin();
// Click Analytics configuration
const clickPluginConfig = {
  autoCapture: true
};

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: window.location.origin.includes("localhost") ? "" : process.env.REACT_APP_INSTRUMENTAION_KEY,
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: globalHistory },
      [clickPluginInstance.identifier]: clickPluginConfig
    }
  }
});
ai.loadAppInsights();
const loginUserName = sessionStorage.getItem("loginUserName");
if (loginUserName) {
  ai.setAuthenticatedUserContext(loginUserName.replace(/\s/g, ""));
}

export default (Component) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;
