import { FontIcon, Link, Shimmer, Stack } from "@fluentui/react";
import { IChartDataPoint, IChartProps, getNextColor } from "@fluentui/react-charting";
import * as React from "react";
import FluentDonutChart from "../../ChartComponents/FluentDountChart";
import CustomCard from "../../SharedComponents/CustomCard";
import { getSumForNumberValues } from "../../Utils/js-common";
import { CUSTOMER_VIEW_STYLES } from "./CustomerView.styles";

const horizontalBarHeaderShimmerStyles = {
  root: { width: "50%" },
  shimmerWrapper: {
    backgroundColor: "var(--shimmer-color)",
    borderTopLeftRadius: 6,
    height: 20
  },
  shimmerGradient: {
    backgroundColor: "var(--shimmer-color)"
  }
};
const CRMCustomerProductCharts = (props: any) => {
  const [productName, setProductName] = React.useState<IChartProps>({});
  const [deploymentStatus, setDeploymentStatus] = React.useState<IChartProps>({});
  const [state, setState] = React.useState<IChartProps>({});
  const [isFilterApplied, setIsFilterApplied] = React.useState(false);
  const [highlightedCard, setHighlightedCard] = React.useState(-1);
  const customCardContainerRef = React.useRef<HTMLDivElement>(null);
  const width = React.useMemo(() => customCardContainerRef.current?.clientWidth, [customCardContainerRef.current]);
  const customCardPropsMemo = React.useMemo(
    () =>
      [
        {
          ...productName,
          key: "customer-view-customer-engagements-customer-product-chart-by-product-name",
          title: "Product Name"
        },
        {
          ...deploymentStatus,
          key: "customer-view-customer-engagements-customer-product-chart-by-deployment-status",
          title: "Deployment Status"
        },
        {
          ...state,
          key: "customer-view-customer-engagements-customer-product-chart-by-state",
          title: "State"
        }
      ]
        .filter(({ chartData }) => chartData === undefined || chartData?.length)
        .map(({ key, title, ...restData }) => {
          return {
            key,
            title,
            component: () => (
              <FluentDonutChart
                data={restData as IChartProps}
                isChartLoading={props.isChartLoading}
                styles={CUSTOMER_VIEW_STYLES.COMMON_STYLES.CSS_STYLES.donutChart}
                valueInsideDonut={getSumForNumberValues(
                  ((restData as IChartProps).chartData || []).map(({ data }) => data || 0)
                )}
                width={width}
              />
            )
          };
        }),
    [productName, deploymentStatus, state]
  );

  const getHighlightedStatus = (index: number) => isFilterApplied && highlightedCard === index;
  const horizontalBarHeader = (title: string, index: number, isLoading: boolean) =>
    isLoading ? (
      <Shimmer styles={horizontalBarHeaderShimmerStyles} />
    ) : (
      <Stack horizontal>
        <div>{title}</div>
        {getHighlightedStatus(index) && (
          <Link
            underline
            onClick={() => {
              setIsFilterApplied(false);
              setHighlightedCard(-1);
              props.setChartFilter({
                filterKey: "",
                value: ""
              });
            }}
            styles={{ root: { position: "absolute", right: 13 } }}
          >
            <FontIcon title="Clear Filter" iconName="ClearFilter" aria-label="Clear Filter"></FontIcon>
          </Link>
        )}
      </Stack>
    );

  React.useEffect(() => {
    if (props.chartData && props.keys) {
      props.keys.forEach((itemKey: string) => {
        switch (itemKey) {
          case "productName":
            {
              const tempData = props?.chartData?.productName.map((item: any, idx: number) => {
                const obj = {
                  legend: item.name,
                  data: item.count,
                  color: getNextColor(idx),
                  onClick: () => {
                    props.setChartFilter({
                      filterKey: itemKey,
                      value: item.name
                    });
                    setIsFilterApplied(true);
                  }
                };
                return obj;
              });
              setProductName({
                chartTitle: "Donut Chart",
                chartData: tempData
              });
            }
            break;

          case "deploymentStatus":
            {
              const tempData = props?.chartData?.deploymentStatus.map((item: any, idx: number) => {
                const obj = {
                  legend: item.name,
                  data: item.count,
                  color: getNextColor(idx),
                  onClick: () => {
                    props.setChartFilter({
                      filterKey: itemKey,
                      value: item.name
                    });
                    setIsFilterApplied(true);
                  }
                };
                return obj;
              });
              setDeploymentStatus({
                chartTitle: "Donut Chart",
                chartData: tempData
              });
            }
            break;

          case "state":
            {
              const tempData = props?.chartData?.state.map((item: any, idx: number) => {
                const obj: IChartDataPoint = {
                  legend: item.name,
                  data: item.count,
                  color: getNextColor(idx),
                  onClick: () => {
                    props.setChartFilter({
                      filterKey: itemKey,
                      value: item.name
                    });
                    setIsFilterApplied(true);
                  }
                };
                return obj;
              });
              setState({
                chartTitle: "Donut Chart",
                chartData: tempData
              });
            }
            break;

          default: {
            return { chartTitle: "Donut Chart", chartData: [] };
          }
        }
      });
    }
  }, [props?.chartData, props?.keys]);

  return (
    <div className={CUSTOMER_VIEW_STYLES.COMMON_STYLES.CLASS_NAMES.customCardContainer}>
      {customCardPropsMemo.map(({ component, key, title }, index) => (
        <div
          ref={customCardContainerRef}
          key={key}
          style={{ flex: 1 }}
          onMouseEnter={() => setHighlightedCard(index)}
          onMouseLeave={() => setHighlightedCard(-1)}
        >
          <CustomCard
            cardHeaderTitle={horizontalBarHeader(title, index, props.isChartLoading)}
            styles={{
              root: { flex: 1, height: "100%" },
              cardHeaderStart: { flex: 1 },
              cardHeaderTitleContainer: { flex: 1 }
            }}
          >
            {component()}
          </CustomCard>
        </div>
      ))}
    </div>
  );
};

export default CRMCustomerProductCharts;
