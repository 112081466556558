import axios from "axios";
import { IAzSearchInput } from "../interfaces/Customer.interfaces";
import { serviceApi } from "./ServiceConfig";

const controller = new AbortController();

axios.interceptors.request.use(
  (config: any) => {
    const token = sessionStorage.getItem("token");
    if(token){
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";
    return config;
  },
  (error) => Promise.reject(error)
);

export const getReports = async (data: IAzSearchInput) =>
  axios
    .post(`${serviceApi.security360}reportcatalog/getReports`, data, {
      signal: controller.signal,
    })
    .then((response) => response?.data)
    .catch((error) => error);
