import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger
} from "@fluentui/react-components";
import * as React from "react";
import {
  BUTTON_APPEARANCE_PRIMARY,
  BUTTON_APPEARANCE_SECONDARY,
  ButtonLoadingState,
  DISABLED_BUTTON_LOADING_STATE
} from "../../../SharedComponents/Styles/CustomCard.styles";
import { ONBOARD_REPORT_DIALOG_TITLE } from "../ReportCatalog.common";
import { CardsProps } from "../ReportCatalog.interfaces";
import { LoadingButton } from "./Form/LoadingButton";
import OnboardReportForm from "./Form/OnboardReportForm";

export const OnboardReport: React.FC<CardsProps> = (props: CardsProps) => {
  const [loadingState, setLoadingState] = React.useState<ButtonLoadingState>(DISABLED_BUTTON_LOADING_STATE);
  const formRef = React.useRef({
    onClick: () => {}
  });

  const handleClickFromParent = () => formRef.current.onClick();
  const handleLoadingState = React.useCallback((state: ButtonLoadingState) => setLoadingState(state), []);
  return (
    <Dialog>
      <DialogTrigger disableButtonEnhancement>
        <Button appearance={BUTTON_APPEARANCE_PRIMARY} disabled={props.loading}>
          {ONBOARD_REPORT_DIALOG_TITLE}
        </Button>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{ONBOARD_REPORT_DIALOG_TITLE}</DialogTitle>
          <DialogContent>
            <OnboardReportForm ref={formRef} onLoadingState={handleLoadingState} />
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance={BUTTON_APPEARANCE_SECONDARY}>Close</Button>
            </DialogTrigger>
            <LoadingButton loadingState={loadingState} onButtonClick={handleClickFromParent} />
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
