import { mergeStyles, Pivot, PivotItem, Shimmer, ShimmerElementType } from "@fluentui/react";
import * as React from "react";

import { IChartProps } from "@fluentui/react-charting";
import { OptionOnSelectData } from "@fluentui/react-components";
import { DataGrid, GridColDef, GridRowsProp, GridSortModel } from "@mui/x-data-grid";
import { useCallback, useContext, useEffect, useState } from "react";
import CustomHorizontalBarChart from "../../../BasicComponents/CustomHorizontalBarChart";
import DisplayAPIsException from "../../../BasicComponents/DisplayAPIsExceptionAlert";
import { logEvent, logException } from "../../../helper/AppInsightsHelper";
import { CustomShimmer } from "../../../helper/CustomShimmer";
import { ProductViewFiltersContext } from "../../../pages/ProductView";
import CustomCard from "../../../SharedComponents/CustomCard";
import ProgressBarItem from "../../../SharedComponents/ProgressBarItem";
import { useGetProductViewMetricsMutation } from "../../../slices/Product.slice";
import { OverviewAndSummaryMutationContext } from "../ProductView";
import CustomDropdown from "./CustomDropdown";
import { PRODUCT_VIEW_DATA } from "./ProductView.constants";
import { ProductTabMetricsProps } from "./ProductView.interfaces";
import { PRODUCT_VIEW_STYLES } from "./ProductView.styles";

let metricName1 = "";
const horizontalBarHeaderShimmerStyles = {
  root: { width: "50%" },
  shimmerWrapper: {
    backgroundColor: "#deecf9",
    borderTopLeftRadius: 6,
    height: 20
  },
  shimmerGradient: {
    backgroundColor: "#deecf9",
    backgroundImage: "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #c7e0f4 50%, rgba(255, 255, 255, 0) 100%)"
  }
};
const renderHorizontalBarHeaderData = ({ selectedMetric, selectedTab }: ProductTabMetricsProps) => ({
  title: `${selectedMetric || ""} by ${selectedTab}`,
  tooltip: PRODUCT_VIEW_DATA.TABS.TAB.COHORTS.CARD_HEADER_TOOLTIPS.METRICS_BY_CATEGORY
});
const renderMoMDetailsHeaderData = ({ selectedMetric, selectedTab }: ProductTabMetricsProps) => ({
  title: `MoM Details - ${selectedMetric || ""} by ${selectedTab}`,
  tooltip: PRODUCT_VIEW_DATA.TABS.TAB.COHORTS.CARD_HEADER_TOOLTIPS.METRICS_DETAILS
});
const conditionalMetricsData = (data: any[], isMultipleMetrics: boolean) => {
  const predicate = (metric: any) =>
    Object.keys(metric).map((key) => ({ [metric.metricName + key]: metric[key], name: metric.name }));
  const callback = (a: any, b: any) => {
    if (Object.keys(a).length) {
      return predicate(a).concat(predicate(b));
    }
    return predicate(b);
  };
  const mappedMetricsData = ({ metrics, name }: any) => metrics.map((m: any) => ({ ...m, name }));
  const mappdedReturnData = (data: any[]) => data.map((m) => m.reduce((a: any, b: any) => ({ ...a, ...b })));

  if (isMultipleMetrics) {
    return mappdedReturnData(data.map((m) => mappedMetricsData(m).reduce(callback)));
  }
  return mappdedReturnData(data.map((m) => mappedMetricsData(m).reduce(callback, {})));
};
const getColor = (value: string) =>
  String(value).includes("-") ? "var(--colorPaletteRedBackground3)" : "var(--colorPaletteLightGreenBackground3)";

const formatDataForChart = (data: { name: string; metrics: any[] }[]): IChartProps[] => {
  return data.map(({ name, metrics: [metric] }) => {
    return {
      chartTitle: name,
      chartData: [
        {
          legend: metric?.metricName || "",
          horizontalBarChartdata: {
            x: metric.currentValue,
            y: 10000 // Adjust the y value based on your logic
          },
          color: "#0078D4" // Updated color
        }
      ]
    };
  });
};

export default function TabWithProductTable() {
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [selectedTabMetric, setSelectedTabMetric] = useState<ProductTabMetricsProps>({
    selectedMetric: "",
    selectedTab: "Industry"
  });
  const metricRef = React.useRef<string | undefined>("");
  const [metricnames, setMetricNames] = useState<string[]>([]);
  const [maxMAUValue, setmaxMAUValue] = useState(0);
  const [minMAU, setMinMAU] = useState(0);
  const [maxMAUMOMValue, setmaxMAUMOM] = useState(0);
  const [minMAUMOM, setMinMAUMOM] = useState(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const filters = useContext(ProductViewFiltersContext);
  const { data: overviewAndSummaryData } = useContext(OverviewAndSummaryMutationContext);
  const [getProductViewMetrics, { data, error, isError, isLoading, isSuccess }] = useGetProductViewMetricsMutation();

  const handleAPIRequest = ({ productName, selectedMetric = "", selectedTab = "" }: ProductTabMetricsProps) => {
    const fullTabName = getTabFullName(selectedTab);
    const productKPIName = (productName ?? filters.productName) === filters.productName ? selectedMetric : "";
    const request = {
      hasPartner: filters.hasPartner,
      industry: filters.industry,
      isCAT: filters.isCAT,
      isFastTrackStrategic: filters.isFastTrackStrategic,
      isSST: filters.isSST,
      productKPIName: productKPIName,
      ProductName: filters.productName,
      Region: filters.region,
      segmentGroup: filters.segmentGroup,
      segment: filters.segment,
      SelectedTabName: fullTabName,
      subsegment: filters.subSegment,
      Vertical: ""
    };
    getProductViewMetrics(request);
  };

  useEffect(() => {
    if (isSuccess) {
      logEvent("getProductViewMetrics API success", "Product Page", "TabWithProductTable");
    }
    if (isError) {
      logException("Product Page", "TabWithProductTable", "getProductViewMetrics", error);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    handleAPIRequest(selectedTabMetric);
    metricRef.current = "";
  }, [
    filters.hasPartner,
    filters.industry,
    filters.isCAT,
    filters.isFastTrackStrategic,
    filters.isSST,
    filters.productName,
    filters.region,
    filters.segmentGroup,
    filters.segment,
    filters.subSegment,
    selectedTabMetric
  ]);

  useEffect(() => {
    if (data) {
      const metricsList: [] = data.productGridRows
        .map(({ metrics }: any) => metrics.map(({ metricName }: any) => metricName))
        .reduce((a: any, b: any) => Array.from(new Set(a.concat(b))), []);

      metricName1 = data?.productGridRows[0]?.metrics[0]?.metricName || "";
      if (metricName1) {
        metricRef.current = metricName1;
      }

      setmaxMAUValue(
        Math.max(
          ...data.productGridRows
            .map(({ metrics }: any) => metrics.filter(({ metricName }: any) => metricName === metricName1).at(0))
            .map(({ currentValue }: any) => currentValue)
        )
      );
      setMinMAU(
        Math.min(
          ...data.productGridRows
            .map(({ metrics }: any) => metrics.filter(({ metricName }: any) => metricName === metricName1).at(0))
            .map(({ currentValue }: any) => currentValue)
        )
      );
      setmaxMAUMOM(
        Math.max(
          ...data.productGridRows
            .map(({ metrics }: any) => metrics.filter(({ metricName }: any) => metricName === metricName1).at(0))
            .map(({ moM }: any) => moM)
        )
      );
      setMinMAUMOM(
        Math.min(
          ...data.productGridRows
            .map(({ metrics }: any) => metrics.filter(({ metricName }: any) => metricName === metricName1).at(0))
            .map(({ moM }: any) => moM)
        )
      );
      setMetricNames(metricsList);
      setRows(conditionalMetricsData(data?.productGridRows, metricsList.length > 1));
      setSortModel([
        {
          field: metricName1 + "currentValue",
          sort: "desc"
        }
      ]);

      setTimeout(() => {
        const elements = document.getElementsByClassName("MuiDataGrid-virtualScroller");
        Array.from(elements).forEach(function (element) {
          element.setAttribute("tabIndex", "0");
        });
        const grigrpElements = document.querySelectorAll("[role='grid']");
        grigrpElements.forEach(function (element) {
          element.setAttribute("aria-busy", "true");
        });
      }, 1000);
    }
  }, [data]);

  const showNoGraphData = (
    <div className={mergeStyles(PRODUCT_VIEW_STYLES.TAB.COHORTS.CLASS_NAMES.emptyDataMessageForLeftMetricDetails)}>
      Table has no data to display
    </div>
  );

  const getTabFullName = (selectedValue: string) => {
    switch (selectedValue) {
      case "Segment":
        return "segmentname";
      case "Vertical":
        return "verticalname";
      case "Tenant":
        return "tenantname";
      case "Product":
        return "productname";
      case "Sub Segment":
        return "subsegmentname";
      case "Region":
        return "regionname";
      default:
        return "industryname";
    }
  };

  const getColumns = (selectedValue: any): GridColDef[] => {
    const columnList: GridColDef[] = [
      {
        headerName: selectedValue,
        field: "name",
        minWidth: 200,
        flex: 1,
        sortable: true,

        renderCell: (params: any) => (
          <span
            style={{
              minWidth: 150,
              textAlign: "center",
              fontSize: 12,
              position: "relative",
              left: "5%",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
            className={selectedValue}
            title={params.row.name}
            tabIndex={params.tabIndex}
          >
            {params.row.name}
          </span>
        )
      }
    ];
    metricnames.map((x) => {
      columnList.push(
        ...[
          {
            headerName: x + " Current",
            field: x + "currentValue",
            minWidth: 135,
            maxWidth: 150,
            flex: 1,
            sortable: true,
            renderCell: ({ row }: any) => {
              return (
                <>
                  <span
                    style={{
                      alignItems: "center",
                      display: "flex",
                      fontSize: 12,
                      height: "100%",
                      textAlign: "right"
                    }}
                    title={row[x + "readableCurrentValue"]}
                  >
                    {row[x + "abbrCurrentValue"] ? (
                      <ProgressBarItem
                        backgroundColor="var(--colorPaletteLightGreenBackground3)"
                        height="70%"
                        progress={row[x + "currentValue"]}
                        displayValue={row[x + "abbrCurrentValue"]}
                        minValue={minMAU}
                        maxValue={maxMAUValue}
                      />
                    ) : (
                      "-"
                    )}
                  </span>{" "}
                </>
              );
            }
          },
          {
            headerName: x + " MoM",
            field: x + "moM",
            minWidth: 140,
            maxWidth: 200,
            flex: 1,
            sortable: true,
            renderCell: ({ row }: any) => {
              return (
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    fontSize: 12,
                    height: "100%",
                    textAlign: "right"
                  }}
                  title={row[x + "readableMom"]}
                >
                  {row[x + "abbrMom"] ? (
                    <ProgressBarItem
                      backgroundColor={getColor(row[x + "moM"])}
                      height="70%"
                      progress={row[x + "moM"]}
                      displayValue={row[x + "abbrMom"]}
                      minValue={minMAUMOM}
                      maxValue={maxMAUMOMValue}
                    />
                  ) : (
                    "-"
                  )}
                </div>
              );
            }
          },
          {
            headerName: x + " MoM%",
            field: x + "moMVariance",
            minWidth: 140,
            maxWidth: 200,
            flex: 1,
            sortable: true,
            renderCell: ({ row }: any) => {
              const formattedVariance = (postFix: string = "") =>
                row[x + "moMVariance"] ? Number(row[x + "moMVariance"]).toFixed(2) + postFix : "";

              return (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    minWidth: 65,
                    color: getColor(row[x + "moMVariance"])
                  }}
                  title={formattedVariance()}
                >
                  {formattedVariance("%") ?? "-"}
                </div>
              );
            }
          }
        ]
      );
    });

    return columnList;
  };

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedTabMetric((prev) => ({ ...prev, selectedTab: item.props.itemKey! }));
    }
  };

  const handleSelectedMetrics = useCallback(
    ({ optionText }: OptionOnSelectData) => {
      setSelectedTabMetric((prev) => ({ ...prev, productName: filters.productName, selectedMetric: optionText }));
      metricRef.current = optionText;
    },
    [filters.productName]
  );

  const displayAPIsException = isError && <DisplayAPIsException height={345} />;
  const displayBarChart = isSuccess && <CustomHorizontalBarChart data={formatDataForChart(data?.productGridRows)} />;
  const displayGrid = isSuccess && (
    <div className="CustomerProductfamilygrid">
      <div
        className="carddata"
        style={{
          height: "400px",
          overflow: rows?.length > 0 ? "auto" : ""
        }}
      >
        {rows?.length > 0 ? (
          <DataGrid
            rows={rows}
            columns={getColumns(selectedTabMetric.selectedTab)}
            getRowId={(row) => row.name}
            disableColumnMenu
            disableColumnFilter
            disableRowSelectionOnClick
            showCellVerticalBorder
            hideFooter={true}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            rowHeight={25}
            columnHeaderHeight={40}
            sx={{
              ".MuiDataGrid-columnHeader": {
                position: "relative"
              },
              ".MuiDataGrid-cell": {
                margin: "0px",
                padding: "0 2px 0 2px",
                fontSize: "10px !important"
              }
            }}
          />
        ) : (
          showNoGraphData
        )}
      </div>
    </div>
  );
  const renderMoMDetailsHeader = isLoading ? (
    <Shimmer styles={horizontalBarHeaderShimmerStyles} />
  ) : (
    renderMoMDetailsHeaderData({ selectedMetric: metricRef.current, selectedTab: selectedTabMetric.selectedTab })
  );
  const horizontalBarHeader = isLoading ? (
    <Shimmer styles={horizontalBarHeaderShimmerStyles} />
  ) : (
    renderHorizontalBarHeaderData({ selectedMetric: metricRef.current, selectedTab: selectedTabMetric.selectedTab })
  );
  const displayViewByMetricSelect = (isLoading && (
    <div style={{ flexBasis: "100%" }}>
      <Shimmer
        shimmerColors={{ shimmer: "var(--shimmer-color)" }}
        styles={{ root: { width: "25%" } }}
        shimmerElements={[
          { type: ShimmerElementType.line, width: "15%" },
          { type: ShimmerElementType.gap, width: 10 },
          { type: ShimmerElementType.line, width: "15%" }
        ]}
      />
    </div>
  )) || (
    <CustomDropdown
      defaultOption={
        {
          optionText: metricRef.current,
          optionValue: metricRef.current,
          selectedOptions: metricRef.current ? [metricRef.current] : []
        } as OptionOnSelectData
      }
      onSelectedOption={handleSelectedMetrics}
      data={(overviewAndSummaryData?.overviewData || [])
        .filter(({ productKPIName }) => !productKPIName.includes("Tenants"))
        .map(({ productKPIName }) => ({ value: productKPIName, text: productKPIName }))}
    />
  );

  return (
    <div>
      <Pivot
        aria-label="Pivot Selection"
        selectedKey={selectedTabMetric.selectedTab}
        onLinkClick={handleLinkClick}
        headersOnly={true}
        style={{ marginBottom: 10 }}
      >
        <PivotItem headerText="Industry" itemKey="Industry" />
        <PivotItem headerText="Segment" itemKey="Segment" />
        <PivotItem headerText="Sub Segment" itemKey="Sub Segment" />
        <PivotItem headerText="Region" itemKey="Region" />
      </Pivot>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px 20px", padding: 13, paddingTop: 0 }}>
        {displayViewByMetricSelect}
        <CustomCard
          cardHeaderTitle={renderMoMDetailsHeader}
          styles={{
            root: { flex: 1 },
            cardDetailsContent: { padding: 13 },
            cardHeaderStart: { flex: 1 },
            cardHeaderTitleContainer: { flex: 1 }
          }}
        >
          {isLoading ? (
            <div style={{ paddingBottom: "15px" }}>
              <CustomShimmer columnCount={4} />
            </div>
          ) : (
            displayGrid || displayAPIsException
          )}
        </CustomCard>
        <CustomCard
          cardHeaderTitle={horizontalBarHeader}
          styles={{
            root: { flex: 1 },
            cardDetailsContent: { padding: 13 },
            cardHeaderStart: { flex: 1 },
            cardHeaderTitleContainer: { flex: 1 }
          }}
        >
          {isLoading ? (
            <div style={{ paddingBottom: "15px" }}>
              <CustomShimmer columnCount={4} />
            </div>
          ) : (
            displayBarChart || displayAPIsException
          )}
        </CustomCard>
      </div>
    </div>
  );
}
