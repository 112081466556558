import { ApexOptions } from "apexcharts";
import React, { useState } from "react";
import Chart from "react-apexcharts";

interface chartprops {
  data: any[];
  width: any;
}
const FunnelChart: React.FunctionComponent<chartprops> = (props) => {
  const ChartData: ApexOptions = {
    series: [
      {
        name: "Funnel series",
        data: props.data.map((item) => item.y)
      }
    ],

    chart: {
      height: "auto",
      type: "bar"
    },
    plotOptions: {
      bar: {
        horizontal: true,
        isFunnel: true,
        borderRadius: 0,
        distributed: true
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return props.data.filter((value) => value.x == opt.w.globals.labels[opt.dataPointIndex])[0].abbValue;
      },
      dropShadow: {
        enabled: true
      }
    },
    legend: {
      show: true
    },
    xaxis: {
      type: "category",
      categories: props.data.map((item) => item.x)
    },
    colors: props.data.map((x) => x.color)
  };

  const [chartinfo] = useState<ApexOptions>(ChartData);

  const _showNoGraphData = (): React.JSX.Element => {
    const rootStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "inherit"
    };
    return <div style={rootStyle}>Graph has no data to display</div>;
  };

  return props.data?.length > 0 ? (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart options={chartinfo} series={chartinfo.series} type="bar" height="350" width={props.width} />
        </div>
      </div>
    </div>
  ) : (
    _showNoGraphData()
  );
};

export default FunnelChart;
