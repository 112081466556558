import { mergeStyles } from "@fluentui/react";
import { Dropdown, Option, OptionOnSelectData, SelectionEvents, useId } from "@fluentui/react-components";
import { useCallback, useEffect, useState } from "react";
import { CustomDropdownProps } from "./ProductView.interfaces";
import { PRODUCT_VIEW_STYLES } from "./ProductView.styles";

const CustomDropdown = ({ data, defaultOption, onSelectedOption, styles }: CustomDropdownProps) => {
  const dropdownId = useId("dropdown-default-select-option");
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedValue, setSelectedValue] = useState<string | undefined>("");

  const handleSelectedOption = useCallback((option?: OptionOnSelectData) => {
    if (option?.optionValue) {
      setSelectedOptions(option.selectedOptions);
    } else {
      setSelectedOptions([]);
    }
    setSelectedValue(option?.optionText);
  }, []);

  const onMetricSelect = (_event: SelectionEvents, data: OptionOnSelectData) => {
    handleSelectedOption(data);
    if (onSelectedOption) {
      onSelectedOption(data);
    }
  };

  useEffect(() => {
    handleSelectedOption(defaultOption);
  }, [defaultOption]);

  return (
    <div
      className={`${PRODUCT_VIEW_STYLES.TAB.COHORTS.CLASS_NAMES.viewByMetricSelectContainer} ${mergeStyles(styles?.root)}`}
    >
      <label htmlFor={`${dropdownId}-controlled`}>View By:</label>
      <Dropdown
        aria-labelledby={dropdownId}
        id={`${dropdownId}-controlled`}
        onOptionSelect={onMetricSelect}
        placeholder="--Select--"
        selectedOptions={selectedOptions}
        value={selectedValue}
      >
        {(data || []).map((option) => (
          <Option text={option.text} value={option.value} key={`${dropdownId}-${option.value}`}>
            {option.text || ""}
          </Option>
        ))}
      </Dropdown>
    </div>
  );
};

export default CustomDropdown;
