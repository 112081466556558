/* eslint-disable no-undef */
import { IShimmerProps, IStyle, Shimmer, ShimmerElementType, Stack, mergeStyleSets } from "@fluentui/react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Card, Divider, IconButton, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DisplayAPIsException from "../BasicComponents/DisplayAPIsExceptionAlert";
import CustomerBreadCrumb from "../MuiComponents/CustomerBreadCrumb";
import { ICustomer } from "../ServiceModels/CustomerService-Models";
import { getChatInsightCompletion } from "../Services/openAIServices";
import { copy } from "../Utils/js-common";
import CustomerDetails from "../components/CustomerView/CustmerDetails";
import CustomerContact from "../components/CustomerView/CustomerContact";
import CustomerInfo from "../components/CustomerView/CustomerInfo";
import { CustomerPivot } from "../components/CustomerView/CustomerPivot";
import { logPageView } from "../helper/AppInsightsHelper";
import {
  useGetFavorableCustomerByLoginQuery,
  useGetTenantDataQuery,
  useInsertUpdateFavCustomersMutation
} from "../slices/Customer.slice";

const bgColor = "#e6faff";
const customerInfoDetailsColor = "#1a1b19";
const shimmerColor = "rgb(222, 236, 249)";
const commonColor: string = "hsl(206, 100%, 42%)";
const styles = mergeStyleSets({
  customerInfoContainer: { margin: 10, minWidth: 600 } as IStyle,
  customerInfoIdentity: {
    marginLeft: 10,
    fontSize: 14,
    paddingBottom: 10,
    color: customerInfoDetailsColor
  } as IStyle,
  customerInfoDetails: {
    display: "flex",
    color: customerInfoDetailsColor
  } as IStyle,
  customerDetails: {
    border: "solid 0.7px #ececec",
    marginBottom: "10px",
    borderRadius: 4,
    boxShadow: "-1px 2px 1px -1.5px rgba(0,0,0,0.2)"
  } as IStyle,
  customerInfoIdentityInitialLetterStack: {
    fontSize: 44,
    color: commonColor,
    paddingTop: 8,
    border: "0.7px",
    borderRadius: 10
  } as IStyle,
  customerInfoIdentityInitialLetterStackLabel: {
    paddingLeft: 6,
    paddingRight: 6,
    background: bgColor
  } as IStyle,
  customerInfoLabel: {
    fontWeight: "600",
    fontSize: "16px"
  } as IStyle,
  infoSpace: {
    paddingBottom: "8px"
  } as IStyle
});

const customerInfoIdentityFirstLetterShimmer = {
  style: { marginTop: 10, width: 60 },
  shimmerElements: [{ type: ShimmerElementType.gap, height: 50 }]
};
const customerInfoIdentityDetailsShimmer = {
  style: {
    marginTop: 10,
    width: 300,
    paddingLeft: 4,
    paddingRight: 3
  },
  shimmerElements: [{ type: ShimmerElementType.line }]
};
const customerInfoIdentityTenantIdShimmer = {
  style: {
    marginTop: 10,
    width: 200,
    paddingLeft: 4,
    paddingRight: 3
  },
  shimmerElements: [{ type: ShimmerElementType.line, height: 10 }]
};
const customerInfoIdentityTopPIDShimmer = {
  style: {
    marginTop: 10,
    width: 100,
    paddingLeft: 4,
    paddingRight: 3
  },
  shimmerElements: [{ type: ShimmerElementType.line, height: 8 }]
};

export default function CustomerPage() {
  const [params] = useSearchParams();
  const tenantId = params.get("id");
  const [customer, setCustomer] = useState<ICustomer>({} as ICustomer);
  const userName = sessionStorage.getItem("loginName")?.toString();
  const userEmail = sessionStorage.getItem("loginUserName")?.toString();
  const enviroment = process.env.REACT_APP_ENVIRONMENT;
  const navigate = useNavigate();
  const [hideInfo, setHideInfo] = useState(false);
  const [displayText, setDisplayText] = useState("");
  const [customerInfoLoad, setCustomerInfoLoad] = useState(true);
  const customerDetailsRef = useRef<any>();
  const { data, error, isFetching } = useGetTenantDataQuery(
    { id: String(tenantId) },
    {
      refetchOnMountOrArgChange: true
    }
  );
  const { data: likeData } = useGetFavorableCustomerByLoginQuery({ email: String(userEmail), env: String(enviroment) });
  const [updateFavoriteCustomer] = useInsertUpdateFavCustomersMutation();

  useEffect(() => {
    if (!params.get("id")?.trim()) {
      navigate("/404");
      return;
    }

    if (data) {
      setCustomer(
        (prev) =>
          ({
            ...prev,
            data: data[0],
            name: data[0]?.tenantName,
            accountManager: data[0]?.accountManager,
            TopPId: data[0]?.tpid
          }) as ICustomer
      );
    }

    const customPropsForLogging = {
      CustomerName: customer?.name,
      TenantId: tenantId
    };

    logPageView("Customer Page", window.location.href, customPropsForLogging);
  }, [navigate, params, data]);

  useEffect(() => {
    if (likeData) {
      const isFavorite = Boolean(likeData.filter((f: any) => f.tenantId == tenantId).length);
      setCustomer((prev) => ({ ...prev, isFavorite: isFavorite }));
    }
  }, [enviroment, userEmail, userName, likeData, tenantId]);

  const updateFavIcon = (isFavorite: boolean) => {
    setCustomer((prev) => ({
      ...prev,
      data: { ...prev.data, IsFavorite: isFavorite },
      isFavorite: isFavorite
    }));
    updateFavoriteCustomer({
      loginName: userName,
      loginEmail: userEmail,
      tenantId: tenantId,
      isFaviourite: Number(isFavorite),
      enviroment
    });
  };
  const hideMe = (value: any) => {
    setHideInfo(value);
  };

  useEffect(() => {
    setCustomerInfoLoad(true);
    if (customer.name) {
      getChatInsightCompletion("tenantDescription", tenantId, customer.name).then((response) => {
        if (response !== "") {
          setDisplayText(response);
        } else {
          setDisplayText("No data found for given prompt");
        }
        setCustomerInfoLoad(false);
      });
    }
  }, [customer.name]);
  return (
    <>
      <HeaderSection name={customer.name} />
      <div className={styles.customerInfoContainer}>
        <div className={styles.customerInfoIdentity}>
          <CustomerInfoIdentity
            loading={isFetching}
            name={customer.name}
            favIcon={customer.isFavorite}
            updateFavIcon={updateFavIcon}
            tenantId={tenantId}
            TopPId={customer.TopPId}
            error={error}
          />
        </div>
        <br />
        <div className={styles.customerDetails} ref={customerDetailsRef}>
          <div className={styles.infoSpace}>
            {hideInfo ? (
              <KeyboardArrowDownIcon
                titleAccess="Click To Show Customer Information"
                sx={{
                  color: commonColor,
                  fontSize: "2rem"
                }}
                style={{
                  cursor: "pointer",
                  marginBottom: "-10px"
                }}
                onClick={() => hideMe(false)}
              />
            ) : (
              <KeyboardArrowUpIcon
                titleAccess="Click To Hide Customer Information"
                sx={{
                  color: commonColor,
                  fontSize: "2rem"
                }}
                style={{
                  cursor: "pointer",
                  marginBottom: "-10px"
                }}
                onClick={() => hideMe(true)}
              />
            )}
            <span className={styles.customerInfoLabel}> Customer Information</span>
          </div>

          {!hideInfo
            ? (!error && (
              <div className={styles.customerInfoDetails}>
                <CustomerInfo
                  name={customer.name}
                  custinfoload={customerInfoLoad}
                  loading={isFetching}
                  displayext={displayText}
                />
                <Divider orientation="vertical" style={{ marginBottom: "20px" }} flexItem />
                <CustomerDetails custDetails={customer.data} loading={isFetching} />
                <Divider orientation="vertical" flexItem style={{ marginBottom: "20px" }} />
                <CustomerContact
                  accountmgr={customer.accountManager}
                  loading={isFetching}
                  tenantName={customer.name}
                />
              </div>
            )) ||
            (!!error && <DisplayAPIsException height={Math.max(customerDetailsRef.current?.offsetHeight, 200)} />)
            : null}
        </div>
        <Card>
          <CustomerPivot tenantName={customer?.name} />
        </Card>
      </div>
    </>
  );
}

const HeaderSection = (props: { name: string }) => {
  const styles = mergeStyleSets({
    customerHeaderContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    } as IStyle,
    customerHeaderContainerItem: { flexGrow: 1 } as IStyle
  });
  return (
    <div className={styles.customerHeaderContainer}>
      <div className={styles.customerHeaderContainerItem}>
        <CustomerBreadCrumb name={props.name} />
      </div>
      <div>
        <WelcomeSection />
      </div>
    </div>
  );
};

const WelcomeSection = () => {
  const name: string | undefined = sessionStorage.getItem("loginName")?.toString().split(" ")[0];
  const wcStyle: any = { paddingRight: "30px" };

  return <div style={wcStyle}>Welcome {name} to Security360</div>;
};

const LoadShimmer = (props: { shimmerProps: IShimmerProps; loading: boolean }) => {
  return props.loading ? <Shimmer {...props.shimmerProps} shimmerColors={{ shimmer: shimmerColor }}></Shimmer> : "";
};

const CustomerInfoIdentity = (props: {
  loading: boolean;
  name: string;
  favIcon: boolean;
  updateFavIcon: (status: boolean) => void;
  tenantId: string | null;
  TopPId: string;
  error: any;
}) => {
  const custInfoIdentityRef = useRef<any>();
  const style = {
    display: props.error ? "block" : "flex"
  } as React.CSSProperties;

  return (
    <div ref={custInfoIdentityRef}>
      <Stack horizontal style={style}>
        {(!props.error && (
          <>
            <LoadShimmer shimmerProps={customerInfoIdentityFirstLetterShimmer} loading={props.loading} />
            <CustomerInfoIdentityInitialLetter loading={props.loading} name={props.name} />
            <CustomerInfoIdentityDetails
              loading={props.loading}
              name={props.name}
              favIcon={props.favIcon}
              updateFavIcon={props.updateFavIcon}
              tenantId={props.tenantId}
              TopPId={props.TopPId}
            />
          </>
        )) || <DisplayAPIsException height={custInfoIdentityRef.current?.offsetHeight} />}
      </Stack>
    </div>
  );
};

const CustomerInfoIdentityDetails = (props: {
  loading: boolean;
  name: string;
  favIcon: boolean;
  updateFavIcon: (status: boolean) => void;
  tenantId: string | null;
  TopPId: string;
}) => {
  const [title, setTitle] = useState("Copy Tenant Id");
  const [isEnabledCopyIcon, setIsEnabledCopyIcon] = useState(false);
  const styles = mergeStyleSets({
    CustomerInfoIdentityDetailsStack: {
      display: "initial",
      paddingLeft: 10
    } as IStyle,
    CustomerInfoIdentityDetailsStackLabel: {
      fontSize: 22,
      fontWeight: "25px",
      fontSizeAdjust: "initial"
    } as IStyle,
    CustomerInfoTenantIdFlexContainer: {
      display: "inline-flex",
      alignItems: "center",
      border: isEnabledCopyIcon ? "1px solid" : "1px solid transparent",
      padding: "2px 2px 2px 0px"
    } as IStyle,
    FontSize: {
      fontSize: 12
    },
    CustomerInfoTenantIdTooltipContainer: {
      visibility: isEnabledCopyIcon ? "visible" : "hidden",
      position: "sticky"
    },
    CustomerInfoTenantIdTooltipContainerBtn: { color: "#000 !important" }
  });
  const onMouseEnterTenantId = () => {
    setIsEnabledCopyIcon(true);
    setTitle("Copy Tenant Id");
  };
  const onMouseLeaveTenantId = () => setIsEnabledCopyIcon(false);
  const copyValue = async () => {
    await copy(props.tenantId as string).then(() => {
      setTitle("Copied");
    });
  };

  return (
    <Stack className={styles.CustomerInfoIdentityDetailsStack}>
      <LoadShimmer shimmerProps={customerInfoIdentityDetailsShimmer} loading={props.loading} />
      {!props.loading && (
        <>
          <label className={styles.CustomerInfoIdentityDetailsStackLabel}>{props.name}&nbsp;</label>
          <LoadFavoriteIcon {...props} />
        </>
      )}

      <LoadShimmer shimmerProps={customerInfoIdentityTenantIdShimmer} loading={props.loading} />
      {!props.loading && (
        <>
          <br />
          <div
            className={styles.CustomerInfoTenantIdFlexContainer}
            onMouseEnter={onMouseEnterTenantId}
            onMouseLeave={onMouseLeaveTenantId}
          >
            <label className={styles.FontSize}>Tenant ID : {props.tenantId}</label>
            <div className={styles.CustomerInfoTenantIdTooltipContainer}>
              <Tooltip title={title} leaveDelay={500} arrow>
                <IconButton
                  size="small"
                  onClick={copyValue}
                  onMouseEnter={onMouseEnterTenantId}
                  className={styles.CustomerInfoTenantIdTooltipContainerBtn}
                >
                  <ContentCopyIcon sx={{ fontSize: 12 }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </>
      )}

      <LoadShimmer shimmerProps={customerInfoIdentityTopPIDShimmer} loading={props.loading} />
      {!props.loading && (
        <>
          <br />
          <label style={{ fontSize: 12 }}>TPID : {props.TopPId}</label>
        </>
      )}
    </Stack>
  );
};

const LoadFavoriteIcon = (props: { favIcon: boolean; updateFavIcon: (status: boolean) => void }) => {
  return (
    <Stack style={{ display: "inline-flex" }}>
      {props.favIcon ? (
        <FavoriteIcon
          titleAccess="Click to Un-Favorite this customer"
          sx={{
            color: commonColor,
            fontSize: "1.2rem"
          }}
          style={{ cursor: "pointer" }}
          onClick={() => props.updateFavIcon(false)}
        />
      ) : (
        <FavoriteBorderIcon
          titleAccess="Click to Favorite this customer"
          sx={{
            color: commonColor,
            fontSize: "1.2rem"
          }}
          style={{ cursor: "pointer" }}
          onClick={() => props.updateFavIcon(true)}
        />
      )}
    </Stack>
  );
};

const CustomerInfoIdentityInitialLetter = (props: { name: string; loading: boolean }) => {
  return !props.loading ? (
    <Stack className={styles.customerInfoIdentityInitialLetterStack}>
      <label className={styles.customerInfoIdentityInitialLetterStackLabel}>
        <b>
          {props.name?.charAt(0)}
          {<CustomerNameInitial name={props.name} />}
        </b>
      </label>
    </Stack>
  ) : (
    ""
  );
};

const CustomerNameInitial = ({ name }: { name?: string }) => {
  const _name = name ?? "";
  const idx = _name.lastIndexOf(" ");
  const initial: string = _name.charAt(idx > 0 ? idx + 1 : idx);
  return initial.match(/^[a-z]/i) ? initial : "";
};
