import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store/store";
import { serviceApi } from "../Services/ServiceConfig";

export const ContactApi = createApi({
  reducerPath: "ContactApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${serviceApi.security360}customer/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).msal.token?.accessToken;
      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    }
  }),

  endpoints: (builder) => ({
    getCustomerContactList: builder.query({
      query: (id) => `getCustomerContactList?TenantId=${id}`
    }),
    getCustomerCxeContactList: builder.query({
      query: (id) => `getCustomerCxeContactList?TenantId=${id}`
    })
  })
});

export const { useGetCustomerContactListQuery, useGetCustomerCxeContactListQuery } = ContactApi;
