import { Divider } from "@mui/material";
import AnnouncementBreadCrumb from "../components/Announcement/AnnouncementBreadcrumb";
import { useEffect, useState } from "react";
import { useGetAnnouncementsQuery } from "../slices/Feedback.slice";
import { logPageView } from "../helper/AppInsightsHelper";
import { AnnouncementShimmer } from "../components/Announcement/AnnouncementShimmer";
import { ANNOUNCEMENT_STYLES } from "../components/Announcement/Announcement.styles";

export default function AnnouncmentPage() {
  const [annoucement, setAnnocement] = useState([]);
  const { data, isLoading } = useGetAnnouncementsQuery(null);

  useEffect(() => {
    if (data) {
      setAnnocement(data);
    }

    logPageView("Announcement Page", window.location.href);
  }, [data]);

  return (
    <>
      <AnnouncementBreadCrumb />
      <div className={ANNOUNCEMENT_STYLES.annoucementContainer}>
        <p className={ANNOUNCEMENT_STYLES.annoucementHeading}>Announcements</p>
        {!isLoading ? (
          annoucement.map((item: any) => (
            <>
              <div className={ANNOUNCEMENT_STYLES.announcementContent}>
                <div className={ANNOUNCEMENT_STYLES.announcementDateContainer}>
                  <label className={ANNOUNCEMENT_STYLES.monthYearFromDate}>
                    {new Date(item.date).toLocaleString("en-EN", {
                      month: "short"
                    })}{" "}
                    {new Date(item.date).toLocaleDateString("en", {
                      year: "2-digit"
                    })}
                  </label>
                  <label className={ANNOUNCEMENT_STYLES.dayFromDate}>{new Date(item.date).getDate()}</label>
                </div>
                <div>
                  <p className={ANNOUNCEMENT_STYLES.annoucementTitle}>{item.announcement_Header.toUpperCase()}</p>
                  <p className={ANNOUNCEMENT_STYLES.annoucementText}>{item.announcement_Text}</p>
                </div>
              </div>
              <br />
              <Divider orientation="horizontal" flexItem style={{ marginRight: "30px" }} />
            </>
          ))
        ) : (
          <>
            <AnnouncementShimmer />
          </>
        )}
        <br />
      </div>
    </>
  );
}
