import { FormValidationErrorMessageProps } from "../components/ReportCatalog/ReportCatalog.interfaces";
import { RED_COLOR } from "./Styles/CustomCard.styles";

export const FormValidationErrorMessage = ({
  message,
  styles,
  onMouseEnter,
  onMouseLeave
}: FormValidationErrorMessageProps) => (
  <div
    style={{
      color: RED_COLOR,
      ...styles
    }}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {message}
  </div>
);
