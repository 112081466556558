import { IStyle, Shimmer, ShimmerElementType, mergeStyleSets } from "@fluentui/react";
const styles = mergeStyleSets({
  shimmerDiv: {
    display: "flex",
    paddingBottom: 10
  } as IStyle,
  shimmer1: {
    marginTop: 15,
    width: 310,
    paddingLeft: 5,
    paddingBottom: 5
  } as IStyle,
  shimmer2: {
    marginTop: 10,
    width: 50,
    paddingLeft: 5,
    paddingRight: 5
  } as IStyle,
  shimmer3: {
    width: 250,
    paddingTop: 5,
    paddingLeft: 5,
    paddingBottom: 5
  } as IStyle,
  shimmer4: {
    width: 150,
    paddingLeft: 5,
    paddingTop: 5
  } as IStyle
});
export default function ContactListShimmer() {
  return (
    <>
      <Shimmer
        className={styles.shimmer1}
        shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]}
        shimmerColors={{ shimmer: "rgb(222, 236, 249)" }}
      ></Shimmer>
      <div className={styles.shimmerDiv}>
        <Shimmer
          className={styles.shimmer2}
          style={{
            marginTop: 10,
            width: 50,
            paddingLeft: 5,
            paddingRight: 5
          }}
          shimmerElements={[{ type: ShimmerElementType.circle, height: 50 }]}
          shimmerColors={{ shimmer: "rgb(222, 236, 249)" }}
        ></Shimmer>
        <div>
          <Shimmer
            className={styles.shimmer3}
            shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]}
            shimmerColors={{ shimmer: "rgb(222, 236, 249)" }}
          ></Shimmer>
          <Shimmer
            className={styles.shimmer4}
            shimmerElements={[{ type: ShimmerElementType.line, height: 8 }]}
            shimmerColors={{ shimmer: "rgb(222, 236, 249)" }}
          ></Shimmer>
        </div>
      </div>
    </>
  );
}
