import { useEffect } from "react";
import { Paper } from "@mui/material";
import HomeCardLinks from "../components/Home/HomeCardLinks";
import MediaCard from "../MuiComponents/MediaCard";
import HomeComponent from "../components/Home/HomeComponent";
import { PaperHeaderText } from "../helper/PaperHeaderText";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { logPageView } from "../helper/AppInsightsHelper";

export default function Home() {
  const subTitle =
    "Quick view of top metrics for security products. To explore product metrics in detail, click on the product hyperlink.";

  useEffect(() => {
    logPageView("Home Page", window.location.href);
  }, []);

  return (
    <>
      <div className="mainCard" style={{ margin: 10, minWidth: 600 }}>
        <Paper style={{}}>
          <div
            style={{
              color: "rgb(0, 120, 212)",
              padding: 10,
              fontSize: 18,
              fontWeight: 700,
              /*    FontFamily: "Roboto,sans-serif", */
              marginTop: 18,
              marginBottom:18,
              paddingBottom: 5
            }}
          >
            {" "}
            Welcome {sessionStorage.getItem("loginName")?.toString().split(" ")[0]} to Security360
            <p
              style={{
                color: "black",
                fontSize: 14,
                fontWeight: 500
              }}
            >
              Empowering MS Security CxE team to make data driven decision by understanding the adoption, usage and
              product stickiness of Security Portfolio
            </p>
          </div>
        </Paper>
        <FluentProvider theme={webLightTheme}>
          <HomeCardLinks />
        </FluentProvider>
        <Paper sx={{ paddingBottom: "5px" }}>
          <PaperHeaderText text="Top metrics at a glance" />
          <p
            style={{
              color: "black",
              fontWeight: 500,
              margin: 10
            }}
          >
            {subTitle}
          </p>
          <HomeComponent />
        </Paper>
        <br />

        <Paper>
          <PaperHeaderText text="Browse Reports by Products" />
          <MediaCard />
        </Paper>
      </div>
    </>
  );
}
