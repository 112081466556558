import { mergeStyleSets } from "@fluentui/react";

export const CUSTOM_PROGRESSBAR_STYLES = mergeStyleSets({
  progressBarContainer: { display: "flex", alignItems: "center", height: "100%", width: "100%" },
  progressBarContainerContent: {
    width: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 2,
    flex: 1
  },
  progressBar: {
    height: "100%"
  },
  progressBarDisplayValue: { flexBasis: "35%" }
});
