import {
  CommandBar,
  DocumentCard,
  DocumentCardDetails,
  IconButton,
  IIconProps,
  mergeStyles,
  mergeStyleSets,
  Stack,
  TooltipHost
} from "@fluentui/react";
import { useId } from "@fluentui/react-components";
import { PropsWithChildren } from "react";
import { CUSTOM_CARD } from "./Styles/CustomCard.styles";
import { CardHeaderTitleProps, CustomCardProps, CustomCommandbarProps } from "./Interfaces/CustomCard.interfaces";

const defaultStyles = CUSTOM_CARD.CUSTOM_CARD_DEFAULT_STYLES;
const defaultOverflowButtonProps = CUSTOM_CARD.OVERFLOW_BUTTON_PROPS;

const hasTooltip = (object: any): object is CardHeaderTitleProps => typeof object === "object" && "tooltip" in object;

const CardHeaderStyles = ({ cardHeaderTitleWithTooltipProps }: CustomCardProps) =>
  mergeStyleSets({
    root: mergeStyles(CUSTOM_CARD.HEADER_STYLES.root, cardHeaderTitleWithTooltipProps?.stackProps?.className),
    titleContainer: mergeStyles(cardHeaderTitleWithTooltipProps?.titleContainerStyle),
    tooltipContainer: mergeStyles(
      CUSTOM_CARD.HEADER_STYLES.toolTipStyles,
      cardHeaderTitleWithTooltipProps?.tooltipProps?.className
    )
  });

const RenderCardHeader = ({ cardHeaderTitle, cardHeaderTitleWithTooltipProps }: CustomCardProps) => {
  const tooltipId = useId("tooltip");
  const { root, titleContainer, tooltipContainer } = CardHeaderStyles({
    cardHeaderTitleWithTooltipProps
  });

  if (hasTooltip(cardHeaderTitle)) {
    const { title, tooltip } = cardHeaderTitle as CardHeaderTitleProps;
    const helpIcon: IIconProps = { iconName: "Unknown" };

    return (
      <Stack horizontal {...cardHeaderTitleWithTooltipProps?.stackProps} className={root}>
        <div className={titleContainer}>{title}</div>
        <TooltipHost
          {...cardHeaderTitleWithTooltipProps?.tooltipProps}
          className={tooltipContainer}
          content={tooltip}
          id={tooltipId}
        >
          <IconButton
            {...cardHeaderTitleWithTooltipProps?.tooltipIconProps}
            aria-describedby={tooltipId}
            ariaLabel={cardHeaderTitleWithTooltipProps?.tooltipIconProps?.ariaLabel ?? "Help"}
            iconProps={cardHeaderTitleWithTooltipProps?.tooltipIconProps?.iconProps ?? helpIcon}
          />
        </TooltipHost>
      </Stack>
    );
  }
  return cardHeaderTitle;
};

const CardHeaderStart = ({ cardHeaderTitle, cardHeaderTitleWithTooltipProps, styles }: CustomCardProps) => {
  return (
    <div className={defaultStyles.cardHeaderStart} style={styles?.cardHeaderStart}>
      <div className={defaultStyles.cardHeaderTitle} style={styles?.cardHeaderTitleContainer}>
        <RenderCardHeader
          cardHeaderTitle={cardHeaderTitle}
          cardHeaderTitleWithTooltipProps={cardHeaderTitleWithTooltipProps}
          styles={styles}
        />
      </div>
    </div>
  );
};

const CardHeaderEnd = ({ children, styles }: PropsWithChildren<CustomCardProps>) => (
  <div className={defaultStyles.cardHeaderEnd} style={styles?.cardHeaderEnd}>
    {children}
  </div>
);

const CustomCommandBar = ({ ariaLabel, overflowItems, overflowButtonProps }: CustomCommandbarProps) =>
  overflowItems &&
  overflowItems.length > 0 && (
    <CommandBar
      items={[]}
      styles={{ root: { padding: 0 } }}
      overflowItems={overflowItems}
      overflowButtonProps={overflowButtonProps}
      ariaLabel={ariaLabel ?? ""}
    />
  );

export default function CustomCard({
  ariaLabel,
  cardHeaderTitle,
  cardHeaderTitleWithTooltipProps,
  children,
  overflowItems,
  styles
}: PropsWithChildren<CustomCardProps>) {
  return (
    <DocumentCard
      className={defaultStyles.card}
      styles={{
        root: styles?.root
      }}
    >
      <DocumentCardDetails styles={{ root: styles?.cardDetails }}>
        <div className={defaultStyles.cardHeaderOuter} style={{ ...styles?.cardHeaderOuter }}>
          <div className={defaultStyles.cardHeaderInner} style={styles?.cardHeaderInner}>
            <CardHeaderStart
              cardHeaderTitle={cardHeaderTitle}
              cardHeaderTitleWithTooltipProps={cardHeaderTitleWithTooltipProps}
              styles={styles}
            />
            <CardHeaderEnd styles={styles}>
              <CustomCommandBar
                ariaLabel={ariaLabel}
                overflowButtonProps={{ ...defaultOverflowButtonProps, ...styles?.overflowButtonProps }}
                overflowItems={overflowItems}
              />
            </CardHeaderEnd>
          </div>
        </div>
        <div style={{ ...styles?.cardDetailsContent }}>{children}</div>
      </DocumentCardDetails>
    </DocumentCard>
  );
}
