import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  GridToolbar,
  GridTreeNodeWithRender
} from "@mui/x-data-grid";
import * as React from "react";

import { Link } from "@fluentui/react-components";
import PowerBIReportModal from "../../../MuiComponents/PowerBIReportModal";
import {
  ALLREPORTS_DATAGRID_STYLE,
  INITIAL_GRID_FILTER_MODEL,
  REPORT_CATALOG_COLUMNS,
  REPORT_LINK_STYLE
} from "../ReportCatalog.common";

export default function AllReportsDataGrid(props: any) {
  const [reportCatalogData, setReportCatalogData] = React.useState(props.rowData);
  const [filterModel, setFilterModel] = React.useState(INITIAL_GRID_FILTER_MODEL);
  const [isOpenPowerBIReportModel, setIsOpenPowerBIReportModel] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const columns: GridColDef[] = [
    {
      ...REPORT_CATALOG_COLUMNS.slice(0, 1)[0],
      renderCell: (params) => (
        <Link onClick={() => handleReportLinkClick(params)} style={REPORT_LINK_STYLE} title={params.row.report}>
          {params.row.report}
        </Link>
      )
    },
    ...REPORT_CATALOG_COLUMNS.slice(1)
  ];

  const handleReportLinkClick = React.useCallback(
    (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => {
      setIsOpenPowerBIReportModel(true);
      setSelectedRow(params);
    },
    []
  );

  const handleFilterModelChange = React.useCallback((newModel: GridFilterModel) => setFilterModel(newModel), []);

  const CloseModalReoprt = React.useCallback(() => {
    setIsOpenPowerBIReportModel(false);
  }, []);

  const handleGridRowsAndFilterModel = React.useCallback(
    (data: any[]) => {
      setReportCatalogData(data);
      setFilterModel((prev) => ({
        ...prev,
        quickFilterValues: [props.searchReportVal]
      }));
    },
    [props.searchReportVal]
  );

  React.useEffect(() => {
    const gridRows: any = [];
    props.selProds?.forEach((filterele: any) => {
      props.rowData?.forEach((element: any) => {
        const isProductIncludes = element.product.toLowerCase().includes(filterele.toLowerCase());

        if (isProductIncludes) {
          gridRows.push(element);
        }
      });
    });
    handleGridRowsAndFilterModel(gridRows);
  }, [handleGridRowsAndFilterModel, props]);

  return (
    <div style={{ minHeight: 200 }}>
      <DataGrid
        autoHeight
        columns={columns}
        disableDensitySelector
        disableRowSelectionOnClick
        filterModel={filterModel}
        getRowId={({ id }) => id}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 }
          }
        }}
        onFilterModelChange={handleFilterModelChange}
        pageSizeOptions={[5, 25, 100]}
        rows={reportCatalogData}
        slots={{ toolbar: GridToolbar }}
        sx={ALLREPORTS_DATAGRID_STYLE}
      />
      {isOpenPowerBIReportModel && (
        <PowerBIReportModal
          ismodelOpen={isOpenPowerBIReportModel}
          selectedItem={selectedRow}
          CloseModalReoprt={CloseModalReoprt}
        />
      )}
    </div>
  );
}
