import { IStyle, mergeStyleSets } from "@fluentui/react";

export const ANNOUNCEMENT_STYLES = mergeStyleSets({
  annoucementContainer: {
      border: "solid 0.7px rgb(225, 223, 221)",
      marginTop: 10,
      paddingLeft: 20
    } as IStyle,
    annoucementHeading: {
      fontWeight: 400,
      fontSize: 20,
      color: "gray",
      marginBlockEnd: "auto"
    } as IStyle,
    announcementContent: {
      display: "flex"
    } as IStyle,
    announcementDateContainer: {
      width: "5%",
      borderRadius: 4,
      marginTop: 10,
      paddingTop: 10,
      paddingLeft: 5,
      marginRight: 10,
      backgroundColor: "rgb(0, 120, 212)"
    } as IStyle,
    monthYearFromDate: {
      paddingLeft: 5,
      display: "flex",
      paddingTop: 2,
      paddingBottom: 5,
      color: "#fff"
    } as IStyle,
    dayFromDate: {
      alignItems: "center",
      color: "#fff",
      paddingLeft: 15,
      fontWeight: 800,
      fontSize: 20
    } as IStyle,
    annoucementTitle: {
      color: "rgb(0, 120, 212)",
      fontSize: 14,
      fontWeight: 600,
      textTransform: "capitalize"
    } as IStyle,
    annoucementText: {
      marginRight: 30
    } as IStyle,
    dividerStyle: {
      marginRight: 30
    } as IStyle,
    announcementShimmerConatiner: {
      display: "flex"
    } as IStyle,
    announcementShimmerForDate: {
      width: "5.5%",
      borderRadius: 4,
      marginTop: 10,
      marginRight: 10
    } as IStyle,
    shimmerForAnnouncementDetails: {
      marginBlockStart: "1em",
      marginBlockEnd: "1em"
    } as IStyle,
    shimmerWrapperForDate:{
      borderRadius: 4
    } as IStyle,
    shimmerTypeAsLine:{
      flex: 1
    }as IStyle
  });