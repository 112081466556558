import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ThemeProvider } from "@fluentui/react";
import { M365ActualLightTheme } from "@m365-admin/customizations";
import { Provider } from "react-redux";
import { msalConfig } from "./authConfig";
import Login from "./login";
import { store } from "./store/store";

const msalInstance = new PublicClientApplication(msalConfig);

export default (App) => (
  <MsalProvider instance={msalInstance}>
    <ThemeProvider theme={M365ActualLightTheme}>
      <Provider store={store}>
        <Login />
      </Provider>
    </ThemeProvider>
  </MsalProvider>
);
