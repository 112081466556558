// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UpsertReport } from "./ReportCatalog.interfaces";
import { serviceApi } from "../../Services/ServiceConfig";
import { RootState } from "../../store/store";

// Define a service using a base URL and expected endpoints
export const ReportCatalogApi = createApi({
  reducerPath: "reportCatalogApi",
  tagTypes: ["PostReport"],
  baseQuery: fetchBaseQuery({
    baseUrl: serviceApi.security360 + "ReportCatalog/",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).msal.token?.accessToken;
      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getReportCatalogData: builder.query({
      query: () => "getReportCatalogData",
      providesTags: ["PostReport"]
    }),
    upsertReport: builder.mutation<void, UpsertReport>({
      query(report) {
        return {
          url: "UpsertReportCatalogData",
          method: "POST",
          body: report
        };
      },
      invalidatesTags: ["PostReport"]
    })
  })
});

export const { useGetReportCatalogDataQuery, useUpsertReportMutation } = ReportCatalogApi;
