import { DocumentCard, PivotItem } from "@fluentui/react";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { Paper } from "@mui/material";
import { PRODUCT_VIEW_DATA } from "../Common/ProductView.constants";
import { PRODUCT_VIEW_STYLES } from "../Common/ProductView.styles";
import { TabsContainer } from "../../../SharedComponents/TabsContainer";

export const ProductViewTabsContainer = () => {
  const {
    pivot: { ariaLabel },
    pivotItems
  } = PRODUCT_VIEW_DATA.TABS.DATA;
  const renderTabs = pivotItems.map(({ headerText, itemKey, component }, index) => (
    <PivotItem headerText={headerText} itemKey={itemKey} key={`${itemKey + index}`}>
      {itemKey === "CohortsTab" ? <Paper>{component()}</Paper> : component()}
    </PivotItem>
  ));

  return (
    <DocumentCard styles={{ root: PRODUCT_VIEW_STYLES.OVERVIEW_SECTOIN.tabsCardRoot }}>
      <FluentProvider theme={webLightTheme}>
        <TabsContainer ariaLabel={ariaLabel} styles={PRODUCT_VIEW_STYLES.TABS_SECTION.pivotStyles}>
          {renderTabs}
        </TabsContainer>
      </FluentProvider>
    </DocumentCard>
  );
};
