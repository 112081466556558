import { type IStyleFunctionOrObject } from "@fluentui/react";
import {
  ICompositeListDetailsList,
  ICompositeListStyleProps,
  ICompositeListStyles,
  ICompositeCommandBarProps,
  CompositeList
} from "@m365-admin/composite-list";
import { EmptyStateImageSize } from "@m365-admin/empty-state";
import type { FunctionComponent } from "react";
import * as React from "react";

interface ICompositeListWithColumnedGroupHeaderProps {
  hideCommandBar: boolean;
  isCompactMode: boolean;
  isEmpty?: boolean;
  data: ICompositeListDetailsList;
  styles: IStyleFunctionOrObject<ICompositeListStyleProps, ICompositeListStyles>;
}

export const CustomCompositeList: FunctionComponent<ICompositeListWithColumnedGroupHeaderProps> = (
  props: any
) => {
  const [list, setList] = React.useState<ICompositeListDetailsList>(props.data);
  const [hideCommandBar, setCommandBar] = React.useState(false);
  const [isCompactMode, setCompactMode] = React.useState(false);
  const [showEmptyState, setShowEmptyState] = React.useState(false);
  const [styles, setStyles] = React.useState<IStyleFunctionOrObject<ICompositeListStyleProps, ICompositeListStyles>>(
    props.styles
  );

  const commandBarProps: ICompositeCommandBarProps = {
    priorityKeys: [],
    items: []
  };

  React.useEffect(() => {
    setShowEmptyState(!!props.isEmpty);
  }, [props.isEmpty]);

  React.useEffect(() => {
    setCommandBar(!!props.hideCommandBar);
  }, [props.hideCommandBar]);

  React.useEffect(() => {
    setCompactMode(!!props.isCompactMode);
  }, [props.isCompactMode]);

  React.useEffect(() => {
    setList(props.data);
    setTimeout(() => {
      const elements = document.querySelectorAll("[aria-rowindex]");
      elements.forEach(function (element) {
        element.removeAttribute("aria-rowindex");
      });
      const roleElements = document.querySelectorAll("[role=\"rowheader\"]");
      roleElements.forEach(function (element) {
        element.setAttribute("role", "none");
      });
      const gridCellElements = document.querySelectorAll("[role=\"gridcell\"]");
      gridCellElements.forEach(function (element) {
        element.setAttribute("role", "none");
        element.removeAttribute("aria-colindex");
        element.removeAttribute("aria-readonly");
        element.removeAttribute("aria-colspan");
      });

      // Setting aria-busy to true for all the elements with role=rowgroup, row, treegrid, menubar
      const rowGroupElements = document.querySelectorAll("[role=\"rowgroup\"]");
      rowGroupElements.forEach(function (element) {
        element.setAttribute("aria-busy", "true");
      });
      const rowElements = document.querySelectorAll("[role=\"row\"]");
      rowElements.forEach(function (element) {
        element.setAttribute("aria-busy", "true");
      });
      const treeGridElements = document.querySelectorAll("[role=\"treegrid\"]");
      treeGridElements.forEach(function (element) {
        element.setAttribute("aria-busy", "true");
      });
      const menubarElements = document.querySelectorAll("[role=\"menubar\"]");
      menubarElements.forEach(function (element) {
        element.setAttribute("aria-busy", "true");
      });
    }, 2000);
  }, [props.data]);

  React.useEffect(() => {
    setStyles(props.styles);
  }, [props.styles]);

  return (
    <CompositeList
      detailsListProps={list}
      commandBarProps={commandBarProps}
      emptyStateProps={{
        title: "No Results Found",
        body: "Please contact support",
        imageProps: {
          src: "https://admincontrolsdemoapps.blob.core.windows.net/images/EmptyState/CompositeListEmptyState.png",
          alt: "Empty State Graphic"
        },
        illustrationSize: EmptyStateImageSize.Large,
        linkProps: {
          href: "mailto:Security360Core@microsoft.com",
          text: "Security360Core@microsoft.com"
        }
      }}
      hideCommandBar={hideCommandBar}
      isCompactMode={isCompactMode}
      isEmpty={showEmptyState}
      styles={styles}
    />
  );
};
