import axios from "axios";
import { serviceApi } from "./ServiceConfig";

export async function getChatInsightCompletion(
  componentName: string,
  tenantId: string | null,
  tenantName: string | null
) {
  if (!tenantId)
  {
    return "Tenant ID is null. Insights cannot be generated.";
  }
  return axios({
    method: "GET",
    url: `${serviceApi.security360}Customer/getAIInsightData?componentName=${componentName}&tenantId=${tenantId}&tenantName=${tenantName}`,
  })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}
