import { navCollapsedWidth } from "@coherence-design-system/controls";
import { IStyle, ScrollablePane, mergeStyleSets } from "@fluentui/react";
import React, { PropsWithChildren, useCallback, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import useResizeObserver from "./Utils/resize-observer";
import AdminNav from "./components/AdminNav";
import HeaderComponent from "./components/HeaderComp";
import { ProductList } from "./components/ProductView/Common/ProductList";
import ReportView from "./components/Report/ReportView";
import Search from "./components/Search";
import AIPlaygroundContainer from "./components/Security360AIPlayground/AIPlaygroundContainer";
import AccessDenied from "./helper/AccessDenied";
import AnnouncmentPage from "./pages/AnnoucementPage";
import CustomerPage from "./pages/CustomerPage";
import CustomerSearch from "./pages/CustomerSearch";
import FavoriteCustomers from "./pages/FavoriteCustomers";
import Glossary from "./pages/Glossary";
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
import ProductPage from "./pages/ProductView";
import ReportCatalog from "./pages/ReportCatalog";
import { updateTarget } from "./slices/Resize.slice";
import { useAppDispatch, useAppSelector } from "./store/hooks";

export const scrollablePaneStyles = {
  position: "fixed",
  top: 48,
  bottom: 0,
  right: 0
};

const scrollBarWidth = 0;

export const calculcateScrollBarWidth = () => {
  return scrollBarWidth;
};

export const styles = mergeStyleSets({
  scrollablePaneCollapsed: {
    ...scrollablePaneStyles,
    left: navCollapsedWidth + calculcateScrollBarWidth() + 10
  } as IStyle,
  scrollablePaneExpand: {
    ...scrollablePaneStyles,
    left: 228 + calculcateScrollBarWidth() + 10
  } as IStyle,
  scrollablePaneBackgroundForProductPage: {
    backgroundColor: "#fff",
    inset: "48px 0px 0px 48px"
  } as IStyle,
  rootDiv: {
    paddingRight: "30px",
    paddingLeft: "10px",
    display: "flex",
    flexDirection: "row"
  } as IStyle,
  dividerLine: {
    width: "100%",
    height: "1px",
    backgroundColor: "black",
    marginBottom: "20px"
  } as IStyle,
  rowGap: {
    height: "30px"
  } as IStyle
});

const NavComponent = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const onMouseEnter = useCallback(() => {
    setIsNavCollapsed(false);
  }, []);
  const onMouseLeave = useCallback(() => {
    setIsNavCollapsed(true);
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      const rowGroupElements = document.querySelectorAll("[role='menubar']");
      rowGroupElements.forEach(function (element) {
        element.setAttribute("aria-busy", "true");
      });
    }, 1000);
  }, [isNavCollapsed]);
  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <AdminNav isNavCollapsed={isNavCollapsed}></AdminNav>
    </div>
  );
};

const MainContainer = () => {
  const { pathname } = useLocation();
  const isProductPage = ["products", "productview"].some((name) => pathname.includes(name));
  const renderClassNames = `${styles.scrollablePaneCollapsed} ${isProductPage ? styles.scrollablePaneBackgroundForProductPage : ""}`;

  return (
    <main id="main" tabIndex={-1}>
      <ScrollablePane
        className={renderClassNames}
        styles={{ contentContainer: isProductPage ? { paddingLeft: 8 } : {} }}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/reportcatalog/:id?" element={<ReportCatalog />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/productview" element={<ProductPage />} />
          <Route path="/customer/:id?" element={<CustomerPage />} />
          <Route path="/customersearch" element={<CustomerSearch />} />
          <Route path="/reportview/:reportid?" element={<ReportView />} />
          <Route path="/favoritecustomers" element={<FavoriteCustomers />} />
          <Route path="/search/:q?" element={<Search />} />
          <Route path="/404" Component={PageNotFound} />
          <Route path="*" Component={PageNotFound} />
          <Route path="/announcement" element={<AnnouncmentPage />} />
          <Route path="/glossary" element={<Glossary />} />
          <Route path="/aiplayground" element={<AIPlaygroundContainer />} />
        </Routes>
      </ScrollablePane>
    </main>
  );
};

const VerifyUserOrMainContainer = ({ children }: PropsWithChildren) => {
  const isAuthorizedUser = useAppSelector(({ msal: { token } }) => {
    const authorizedRoles = ["Admin", "User"];
    const roles = token?.account?.idTokenClaims?.roles ?? [];
    return authorizedRoles.some((role) => roles.includes(role));
  });

  return (
    <ScrollablePane className={styles.scrollablePaneCollapsed}>
      {isAuthorizedUser ? <>{children}</> : <AccessDenied />}
    </ScrollablePane>
  );
};

const MainComp = () => {
  const dispatch = useAppDispatch();
  const onResize = useCallback(
    (target: HTMLDivElement) => {
      dispatch(updateTarget(target));
    },
    [dispatch]
  );

  const ref = useResizeObserver(onResize);

  return (
    <div ref={ref}>
      <BrowserRouter>
        <HeaderComponent />
        <NavComponent />
        <VerifyUserOrMainContainer>
          <MainContainer />
        </VerifyUserOrMainContainer>
      </BrowserRouter>
    </div>
  );
};

export default MainComp;
