import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import PowerBIReport from "../SharedComponents/PowerBIEmbeded";
import { PrimaryButton } from "@fluentui/react";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  height: "80%",
  boxShadow: 24,
  p: 4
};

export default function PowerBIReportModal(props: any) {
  const [open, setOpen] = React.useState(props.ismodelOpen);

  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => {
    setOpen(false);
    props.CloseModalReoprt();
  }, [props]);

  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ marginBottom: "40px" }}>
            <PrimaryButton
              style={{
                position: "absolute",
                right: 130,
                marginRight: "10px"
              }}
              text="Open In New Window"
              type="a"
              href={props.selectedItem.row.reportLink}
              target="_blank"
            ></PrimaryButton>
            <PrimaryButton
              style={{ position: "absolute", right: 50 }}
              text="Close"
              onClick={() => {
                handleClose();
              }}
            />
          </div>
          <PowerBIReport selectedItem={props.selectedItem} />
        </Box>
      </Modal>
    </div>
  );
}
