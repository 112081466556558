import { IStyle, mergeStyleSets } from "@fluentui/react";
import { useId } from "@fluentui/react-components";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { Divider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { logEvent, logException } from "../../helper/AppInsightsHelper";
import { contactData } from "../../interfaces/customer-contact";
import { useGetCustomerContactListQuery } from "../../slices/Contact.slice";
import { arrSortAscByKeyLength, groupBy } from "../../Utils/js-common";
import ContactListShimmer from "./ContactListShimmer";

const styles = mergeStyleSets({
  iconCss: {
    fontSize: 14,
    height: 25,
    width: 25,
    margin: "2px 2px 2px 2px",
    color: "rgb(0, 120, 212)",
    cursor: "pointer"
  } as IStyle,
  contactName: {
    minWidth: "215px",
    maxWidth: "215px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflowX: "hidden"
  } as IStyle,
  contactJob: {
    fontSize: "10px",
    color: "gray",
    marginTop: "5px"
  } as IStyle,
  tableBorder: {
    border: "none"
  } as IStyle,
  proImg: {
    color: "rgb(0, 121, 214)",
    backgroundColor: "rgb(230, 250, 255)",
    borderRadius: "50%",
    display: "inline-block",
    padding: "10px",
    fontSize: "16px",
    minWidth: "25px",
    maxWidth: "25px"
  } as IStyle,
  imgLabel: {
    alignContent: "center",
    padding: "8px 8px 12px 8px"
  } as IStyle,
  contactDiv: {
    paddingLeft: "15px"
  } as IStyle,
  divTag: {
    paddingTop: "10px"
  } as IStyle,
  titleArea: {
    fontWeight: 600
  } as IStyle,
  contactInfo: {
    display: "flex",
    minWidth: 20,
    maxWidth: 20
  } as IStyle
});

const prepareData = (list: any[]) => {
  const family = groupBy(list, "title");
  const data = Object.keys(family).map((key: string) => ({
    title: key === "null" ? null : key,
    data: family[key]
  }));
  return arrSortAscByKeyLength({ key: "data", data });
};

export default function ContactList() {
  const [params] = useSearchParams();
  const tenantId = params.get("id");
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState<contactData[]>([]);
  const { data, error } = useGetCustomerContactListQuery(tenantId || "");
  const tableId = useId("automated-contact-list-container");

  const getNameInitials = (name: string) => {
    const idx = name.lastIndexOf(" ");
    const _name: string = name.charAt(idx > 0 ? idx + 1 : idx);
    return _name.match(/^[a-z]/i) ? _name : "";
  };
  const getJobTitle = (name: string) => name.split(" - ")[1];

  const handleLoading = useCallback((status: boolean) => {
    setLoading(status);
  }, []);

  const handleData = useCallback((data: any[]) => {
    setContacts(data);
  }, []);

  useEffect(() => {
    if (data) {
      handleLoading(false);
      handleData(prepareData(data));
      logEvent("useGetCustomerContactListQuery API success", "Customer Page", "ContactList");
    }
    if (error) {
      logException("Customer Page", "ContactList", "useGetCustomerContactListQuery", error);
    }
  }, [data, error, handleData, handleLoading]);

  return (
    <>
      <br />
      {loading && (
        <>
          <ContactListShimmer />
          <ContactListShimmer />
          <ContactListShimmer />
          <ContactListShimmer />
          <br />
        </>
      )}
      {!loading &&
        contacts?.map(({ title, data: d }) => (
          <>
            {title ? (
              <>
                <label className={styles.titleArea}>{title}</label>
                <Divider orientation="horizontal" />
                <div className={styles.divTag}></div>
              </>
            ) : (
              "No Results Found"
            )}

            {title
              ? d.map((contact, index) => (
                  <table key={`${tableId + (index + 1)}`} className={styles.tableBorder}>
                    <tr className={styles.tableBorder}>
                      <td className={styles.tableBorder}>
                        <div className={styles.proImg}>
                          <b>
                            {contact.fullName.charAt(0)}
                            {getNameInitials(contact.fullName)}
                          </b>
                        </div>
                      </td>
                      <td className={styles.tableBorder}>
                        <div className={styles.contactDiv}>
                          <div className={styles.contactName}>
                            <label title={contact.fullName}>{contact.fullName}</label>
                            <br />
                            <label className={styles.contactJob} title={getJobTitle(contact.nameRole)}>
                              {getJobTitle(contact.nameRole)}
                            </label>
                          </div>
                        </div>
                      </td>
                      <td className={styles.tableBorder}>
                        <div className={styles.contactInfo}>
                          <a
                            href={`https://teams.microsoft.com/l/chat/0/0?users=${contact.emailAddress}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FontIcon
                              aria-label="Teams"
                              title="Send Teams Message"
                              className={styles.iconCss}
                              iconName="TeamsLogo"
                            />
                          </a>
                          <a href={`mailto:${contact.emailAddress}`}>
                            <FontIcon
                              aria-label="Outlook"
                              title="Send An Email"
                              className={styles.iconCss}
                              iconName="Mail"
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </table>
                ))
              : ""}
            <br />
          </>
        ))}
    </>
  );
}
