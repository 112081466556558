import { CustomShimmer } from "../helper/CustomShimmer";
import DisplayAPIsException from "./DisplayAPIsExceptionAlert";
import React from "react";

interface ISlot {
  noRowsOverlay: (error: boolean) => React.JSXElementConstructor<any>;
  loadingOverlay: React.JSXElementConstructor<any>;
}
const DATAGRID_SLOTS = {
  noRowsOverlay: (error: boolean = false) => (error ? DisplayAPIsException : CustomNoRowsOverlay)
};

const styles = {
  container: {
    height: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  } as React.CSSProperties,
  item: {} as React.CSSProperties
};

const CustomNoRowsOverlay = () => (
  <div style={styles.container}>
    <div>No Rows Found</div>
  </div>
);

export const DATA_GRID_SLOTS: ISlot = {
  noRowsOverlay: (error: boolean = false) => DATAGRID_SLOTS.noRowsOverlay(error),
  loadingOverlay: CustomShimmer
};
