import { ISearchBoxStyles, SearchBox } from "@fluentui/react";
import { Paper } from "@mui/material";
import { ChangeEvent, CSSProperties, useEffect, useState } from "react";
import CustomerDataGrid from "../components/CustomerSearch/CustomerSearchGridComponent";
import { logPageView } from "../helper/AppInsightsHelper";
import { PaperHeaderText } from "../helper/PaperHeaderText";
import { debounce } from "../Utils/js-common";

const style = {
  searchBoxParent: {
    marginBottom: 18
  } as CSSProperties,
  seachBox: {
    root: {
      width: "60%",
      marginLeft: "20%",
      marginTop: 15,
      borderRadius: 4
    }
  } as Partial<ISearchBoxStyles>
};

export default function CustomerSearch() {
  const [searchText, setSearchText] = useState("");

  // Search function
  const performSearch = (query: string) => setSearchText(query);

  // Debounced search function
  const debouncedSearch = debounce(performSearch);

  const onChange = (event?: ChangeEvent<HTMLInputElement>) => debouncedSearch(event?.target.value || "");

  const onClear = (event?: any) => performSearch(event?.target.value || "");

  useEffect(() => logPageView("Customer Search Page", window.location.href), []);

  return (
    <div className="mainCard" style={{ margin: 10, marginBottom: 0, minWidth: 600 }}>
      <Paper>
        <PaperHeaderText text="Customer Search" />
        <div style={style.searchBoxParent}>
          <SearchBox
            disableAnimation
            onChange={onChange}
            onClear={onClear}
            onSearch={performSearch}
            placeholder="Search customer by Tenant Name, Tenant Id or TPID"
            styles={style.seachBox}
          />
        </div>
        <CustomerDataGrid searchText={searchText} />
      </Paper>
    </div>
  );
}
