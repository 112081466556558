import { Breadcrumb, IBreadcrumbProps } from "@fluentui/react";
import * as React from "react";

export const CustomBreadcrumb = ({
  items,
  dividerAs,
  focusZoneProps,
  maxDisplayedItems,
  onRenderOverflowIcon,
  overflowAriaLabel,
  ariaLabel,
  styles
}: IBreadcrumbProps) => (
  <Breadcrumb
    items={items}
    styles={styles}
    dividerAs={dividerAs}
    focusZoneProps={focusZoneProps}
    maxDisplayedItems={maxDisplayedItems}
    onRenderOverflowIcon={onRenderOverflowIcon}
    overflowAriaLabel={overflowAriaLabel ?? "More links"}
    ariaLabel={ariaLabel ?? "Breadcrumb with items rendered as buttons"}
  />
);
