import { Link } from "@mui/material";
import { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import { CUSTOM_LINK_NAVIGATE_STYLES } from "./Styles/CustomLinkNavigate.styles";

export function CustomLinkNavigate(props: {
  style?: CSSProperties;
  text: string;
  to: string;
  underline?: "none" | "hover" | "always";
}) {
  const navigate = useNavigate();
  const onLinkNavigate = () => navigate(props.to);

  return (
    <Link
      sx={{ ...CUSTOM_LINK_NAVIGATE_STYLES.linkStyle, ...props.style }}
      title={props.text}
      underline={props.underline || "hover"}
      onClick={onLinkNavigate}
    >
      {props.text}
    </Link>
  );
}
