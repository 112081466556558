/* eslint-disable no-undef */
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { AnyAction } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useSaveFeedbackMutation } from "../../slices/Feedback.slice";

export default function Compliment(props: any) {
  const [value, setValue] = useState(props.name);
  const [showSubmit, setShowsubmit] = useState(false);
  const [hide, setHide] = useState(false);
  const [saveFeedbackItem, { data }] = useSaveFeedbackMutation();
  const rootStyles = {
    container: { marginRight: 5, background: "#FFF" },
    paddingLft: { paddingLeft: "5px" }
  };
  useEffect(() => {
    if (showSubmit) {
      if (data && data.length > 0) {
        setShowsubmit(false);
        props.cancelClick();
        setValue("");
        alert("Saved Successfully");
      } else {
        setShowsubmit(false);
        props.cancelClick();
        setValue("");
        alert("Error:Something went wrong, please try again later");
      }
    }
  }, [data]);

  const handleChange = (event: AnyAction) => {
    setShowsubmit(true);
    setValue(event.target.value);
  };
  const cancelClick = () => {
    props.cancelClick();
    setValue("");
    setHide(true);
  };
  const loginEmail = sessionStorage.getItem("loginUserName")?.toString();
  const enviornment = process.env.REACT_APP_ENVIRONMENT;
  const saveComplient = () => {
    const obj = {
      feedBackType: "compliment",
      fieldType: "TextField",
      feedBackValue: value,
      environment: enviornment,
      createdBy: loginEmail
    };
    saveFeedbackItem(obj);
  };
  return (
    <>
      {!hide ? (
        <div style={rootStyles.container}>
          <div style={rootStyles.paddingLft}>
            {props.questions?.map((item: any) => (
              <>
                <span className="feedbackLabel">{item.question}</span>
                <br />
                <textarea
                  onChange={handleChange}
                  className="problemCSS"
                  placeholder="Give as much detail as you can, but do not include any private or sensitive information"
                ></textarea>
              </>
            ))}
            <br />
            <br />
            {showSubmit ? (
              <>
                <PrimaryButton text="Submit" onClick={saveComplient}></PrimaryButton>
                &nbsp;&nbsp;
                <DefaultButton onClick={cancelClick} text="Back" />
              </>
            ) : (
              <>
                <PrimaryButton disabled text="Submit" onClick={saveComplient}></PrimaryButton>
                &nbsp;&nbsp;
                <DefaultButton onClick={cancelClick} text="Cancel" />
              </>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}
