import { IStyle, mergeStyleSets } from "@fluentui/merge-styles";
import { Link } from "@fluentui/react";
import { LinkRegular } from "@fluentui/react-icons";
import { DataGrid, GridColDef, GridRowSelectionModel, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { formatDate } from "../../helper/DateFormat";
import { ICustomerFeatureProps } from "../../interfaces/customer-feature";
import { handleToggleDataGridRowSelection } from "../../Utils/js-common";

const styles = mergeStyleSets({
  featureTable: {
    height: 600,
    width: "100%"
  } as IStyle
});

export default function FeatutreTable(props: ICustomerFeatureProps) {
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const handleSelection = handleToggleDataGridRowSelection(selectionModel, setSelectionModel);
  useEffect(() => {
    setTimeout(() => {
      const elements = document.getElementsByClassName("MuiDataGrid-virtualScroller");
      Array.from(elements).forEach(function (element) {
        element.setAttribute("tabIndex", "0");
      });
    }, 1000);
  }, []);

  const columns: GridColDef[] = [
    {
      headerName: "CRM Link",
      field: "crmLink",
      width: 100,
      sortable: true,
      renderCell: (params) => (
        <Link
          style={{
            color: "rgb(0, 120, 212)",
            textDecoration: "none",
            fontSize: 26,
            cursor: "pointer"
          }}
          href={params.row.crmLink}
          title={params.row.crmLink}
          target="_blank"
          rel="noopener noreferrer"
          tabIndex={params.row.tabIndex}
        >
          <LinkRegular />
        </Link>
      )
    },
    {
      headerName: "ADO Link",
      field: "featureDevOpsURL",
      width: 100,
      sortable: true,
      renderCell: (params) =>
        params.row.featureDevOpsURL ? (
          <Link
            style={{
              color: "rgb(0, 120, 212)",
              textDecoration: "none",
              fontSize: 26,
              cursor: "pointer"
            }}
            href={params.row.featureDevOpsURL}
            title={params.row.featureDevOpsURL}
            target="_blank"
            rel="noopener noreferrer"
            tabIndex={params.row.tabIndex}
          >
            <LinkRegular />
          </Link>
        ) : (
          <></>
        )
    },
    {
      headerName: "Product Name",
      field: "crmProductName",
      width: 240,
      sortable: true
    },
    {
      headerName: "Feature Name",
      field: "featureName",
      width: 210,
      sortable: true
    },
    {
      headerName: "Feature Status",
      field: "featureRequestStatus",
      width: 120,
      sortable: true
    },
    {
      headerName: "Feature Request Notes",
      field: "featureRequestNotes",
      width: 220,
      sortable: true
    },
    {
      headerName: "Blocking Status",
      field: "featureEvidenceBlockingStatus",
      width: 130,
      sortable: true
    },
    {
      headerName: "Feature Age",
      field: "featureAgeInDays",
      width: 110,
      type: "number",
      sortable: true,
      align: "left",
      headerAlign: "left"
    },
    {
      headerName: "Feature PM",
      field: "featurePM",
      width: 110,
      sortable: true
    },
    {
      headerName: "Priority Rating",
      field: "featureRequestPriority",
      width: 120,
      sortable: true
    },

    {
      headerName: "Feature Description",
      field: "featureDescription",
      width: 220,
      sortable: true
    },
    {
      headerName: "Feature Created Date",
      field: "featureCreatedDate",
      type: "date",
      minWidth: 180,
      flex: 1,
      sortable: true,
      valueGetter: (value, row) => new Date(row.featureCreatedDate),
      renderCell: (params) => formatDate(params.row.featureCreatedDate)
    }
  ];

  return (
    <>
      <div className={styles.featureTable}>
        <DataGrid
          rows={props.tableData}
          columns={columns}
          getRowId={(row) => row.featureRequestCRMGuid}
          density="compact"
          disableDensitySelector
          disableRowSelectionOnClick
          showCellVerticalBorder
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true
            }
          }}
          onRowSelectionModelChange={handleSelection}
          rowSelectionModel={selectionModel}
        />
      </div>
    </>
  );
}
