import { IContextualMenuItem, mergeStyles } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { logEvent, logException } from "../../../helper/AppInsightsHelper";
import { AllFilters } from "../../../interfaces/allFilterData";
import { CustomFilterPill } from "../../../SharedComponents/CustomFilterPill";
import { useGetFiltersQuery } from "../../../slices/Customer.slice";
import { mapFilterPillMenuItemsPredicate } from "../../CustomerSearch/CustomerSearch.common";
import { CUSTOMER_SEARCH_STYLES } from "../../CustomerSearch/CustomerSearch.styles";

export const ProductFilterPill = (props: any) => {
  const navigate = useNavigate();
  const defaultValue = "All";

  const [regions, setRegions] = useState<IContextualMenuItem[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [segments, setSegments] = useState<IContextualMenuItem[]>([]);
  const [selectedSegments, setSelectedSegments] = useState<string[]>([]);
  const [segmentGroups, setSegmentGroups] = useState<IContextualMenuItem[]>([]);
  const [selectedSegmentGroups, setSelectedSegmentGroups] = useState<string[]>([]);
  const [subSegments, setSubSegments] = useState<IContextualMenuItem[]>([]);
  const [selectedSubSegments, setSelectedSubSegments] = useState<string[]>([]);
  const [industries, setIndustries] = useState<IContextualMenuItem[]>([]);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [hasPartners, setHasPartners] = useState<IContextualMenuItem[]>([]);
  const [selectedHasPartner, setSelectedHasPartner] = useState<string[]>([]);
  const [isFastTracks, setIsFastTracks] = useState<IContextualMenuItem[]>([]);
  const [selectedFastTrack, setSelectedFastTrack] = useState<string[]>([]);
  const [isCATs, setIsCATs] = useState<IContextualMenuItem[]>([]);
  const [selectedIsCAT, setSelectedIsCAT] = useState<string[]>([]);
  const [isSSTs, setIsSSTs] = useState<IContextualMenuItem[]>([]);
  const [selectedIsSST, setSelectedIsSST] = useState<string[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [allData, setAllData] = useState<AllFilters>();
  const { data, error } = useGetFiltersQuery(null);
  const filterPillsData = [
    { key: "productFilterPillForRegion", name: "Region", items: regions, setSelectedItems: setSelectedRegions },
    {
      key: "productFilterPillForSegmentGroup",
      name: "Segment Group",
      items: segmentGroups,
      setSelectedItems: setSelectedSegmentGroups
    },
    { key: "productFilterPillForSegment", name: "Segment", items: segments, setSelectedItems: setSelectedSegments },
    {
      key: "productFilterPillForSubSegment",
      name: "Sub Segment",
      items: subSegments,
      setSelectedItems: setSelectedSubSegments
    },
    {
      key: "productFilterPillForIndustry",
      name: "Industry",
      items: industries,
      setSelectedItems: setSelectedIndustries
    },
    {
      key: "productFilterPillForHasPartner",
      name: "HasPartner",
      items: hasPartners,
      setSelectedItems: setSelectedHasPartner
    },
    {
      key: "productFilterPillForIsFastTrack",
      name: "IsFastTrackStrategic",
      items: isFastTracks,
      setSelectedItems: setSelectedFastTrack
    },
    { key: "productFilterPillForIsCAR", name: "IsCAT ", items: isCATs, setSelectedItems: setSelectedIsCAT },
    { key: "productFilterPillForIsSST", name: "IsSST ", items: isSSTs, setSelectedItems: setSelectedIsSST }
  ];

  useEffect(() => {
    if (data) {
      setAllData(data);
      const productValue: any[] = [];
      if (products?.length === 0) {
        data?.productNames?.forEach((element: any) => {
          productValue.push({ label: element, value: element });
        });
        setProducts(productValue);
      }
      setRegions(((data?.regionNames as string[]) || []).map(mapFilterPillMenuItemsPredicate));
      const segmentValue: Set<string> = new Set();
      const subSegmentValue: Set<string> = new Set();
      const segmentGroupValue: Set<string> = new Set();
      data?.segmentGroups?.forEach((group: any) => {
        segmentGroupValue.add(group.segmentGroupName);
        group.segments.forEach((segment: any) => {
          segmentValue.add(segment.segmentName);
          segment.subSegments.forEach((subSegment: string) => {
            subSegmentValue.add(subSegment);
          });
        });
      });
      setSegments(Array.from(segmentValue).map(mapFilterPillMenuItemsPredicate));
      setSubSegments(Array.from(subSegmentValue).map(mapFilterPillMenuItemsPredicate));
      setSegmentGroups(Array.from(segmentGroupValue).map(mapFilterPillMenuItemsPredicate));
      setIndustries(data?.industryNames.map(mapFilterPillMenuItemsPredicate));
      setHasPartners(["Yes", "No"].map(mapFilterPillMenuItemsPredicate));
      setIsFastTracks(["Yes", "No"].map(mapFilterPillMenuItemsPredicate));
      setIsCATs(["Yes", "No"].map(mapFilterPillMenuItemsPredicate));
      setIsSSTs(["Yes", "No"].map(mapFilterPillMenuItemsPredicate));
      logEvent("useGetFiltersQuery API call success", "Product View", "FilterPillProduct");
    }
    if (error) {
      logException("Product View", "FilterPillProduct", "useGetFiltersQuery", error);
    }
  }, [data]);
  useEffect(() => {
    let newRegionValue: string = "";
    selectedRegions.forEach((element: string) => {
      newRegionValue = element + ";" + newRegionValue;
    });
    let newSegmentValue: string = "";
    selectedSegments.forEach((element: string) => {
      newSegmentValue = element + ";" + newSegmentValue;
    });
    let newSegmentGroupValue: string = "";
    selectedSegmentGroups.forEach((element: string) => {
      newSegmentGroupValue = element + ";" + newSegmentGroupValue;
    });
    if (
      (!selectedSegmentGroups || selectedSegmentGroups?.length === 0) &&
      (!selectedSegments || selectedSegments?.length === 0)
    ) {
      const segmentValue: Set<string> = new Set();
      const subSegmentValue: Set<string> = new Set();
      const segmentGroupValue: Set<string> = new Set();
      data?.segmentGroups?.forEach((group: any) => {
        segmentGroupValue.add(group.segmentGroupName);
        group.segments.forEach((segment: any) => {
          segmentValue.add(segment.segmentName);
          segment.subSegments.forEach((subSegment: string) => {
            subSegmentValue.add(subSegment);
          });
        });
      });
      setSegments(Array.from(segmentValue).map(mapFilterPillMenuItemsPredicate));
      setSubSegments(Array.from(subSegmentValue).map(mapFilterPillMenuItemsPredicate));
      setSegmentGroups(Array.from(segmentGroupValue).map(mapFilterPillMenuItemsPredicate));
    } else {
      const filteredSegments: Set<string> = new Set();
      const filteredSubSegments: Set<string> = new Set();

      if (selectedSegmentGroups && selectedSegmentGroups.length > 0) {
        allData?.segmentGroups.forEach((group: any) => {
          if (selectedSegmentGroups.includes(group.segmentGroupName)) {
            group.segments.forEach((seg: any) => {
              filteredSegments.add(seg.segmentName);

              if (selectedSegments && selectedSegments.length > 0) {
                if (selectedSegments.includes(seg.segmentName)) {
                  seg.subSegments.forEach((subSeg: string) => {
                    filteredSubSegments.add(subSeg);
                  });
                }
              } else {
                seg.subSegments.forEach((subSeg: string) => {
                  filteredSubSegments.add(subSeg);
                });
              }
            });
          }
        });
      }

      if (
        (!selectedSegmentGroups || selectedSegmentGroups.length === 0) &&
        selectedSegments &&
        selectedSegments.length > 0
      ) {
        allData?.segmentGroups.forEach((group: any) => {
          group.segments.forEach((seg: any) => {
            filteredSegments.add(seg.segmentName);
            if (selectedSegments.includes(seg.segmentName)) {
              seg.subSegments.forEach((subSeg: string) => {
                filteredSubSegments.add(subSeg);
              });
            }
          });
        });
      }

      setSegments(Array.from(filteredSegments).map(mapFilterPillMenuItemsPredicate));
      setSubSegments(Array.from(filteredSubSegments).map(mapFilterPillMenuItemsPredicate));
    }
    let newSubSegmentValue: string = "";
    selectedSubSegments.forEach((element: string) => {
      newSubSegmentValue = element + ";" + newSubSegmentValue;
    });
    let newIndustryValue: string = "";
    selectedIndustries.forEach((element: string) => {
      newIndustryValue = element + ";" + newIndustryValue;
    });
    let newPartnerValue: string = "";
    selectedHasPartner.forEach((element: string | string[]) => {
      newPartnerValue = selectedHasPartner.length > 1 ? "" : element.includes("Yes") ? "1" : "0";
    });
    let newIsSstValue: string = "";
    selectedIsSST.forEach((element: string | string[]) => {
      newIsSstValue = selectedIsSST.length > 1 ? "" : element.includes("Yes") ? "1" : "0";
    });
    let newIsCatValue: string = "";
    selectedIsCAT.forEach((element: string | string[]) => {
      newIsCatValue = selectedIsCAT.length > 1 ? "" : element.includes("Yes") ? "1" : "0";
    });
    let newFastTrackValue: string = "";
    selectedFastTrack?.forEach((element: string | string[]) => {
      newFastTrackValue = selectedFastTrack?.length > 1 ? "" : element.includes("Yes") ? "1" : "0";
    });
    props.onRegionChange(newRegionValue);
    props.onSegmentChange(newSegmentValue);
    props.onSubSegmentChange(newSubSegmentValue);
    props.onIndustryChange(newIndustryValue);
    props.onFastTrackChange(newFastTrackValue);
    props.onPartnerChange(newPartnerValue);
    props.onIsCatChange(newIsCatValue);
    props.onIsSstChange(newIsSstValue);
    props.onSegmentGroupChange(newSegmentGroupValue);
  }, [
    selectedRegions,
    selectedSegmentGroups,
    selectedSegments,
    selectedSubSegments,
    selectedIndustries,
    selectedHasPartner,
    selectedFastTrack,
    selectedIsCAT,
    selectedIsSST
  ]);
  const handleProductChange = (selectedOption: any) => {
    if (selectedOption.label !== undefined) {
      navigate("/productview?productname=" + encodeURIComponent(selectedOption.label));
    }
  };

  return (
    <div
      className={mergeStyles({
        alignItems: "flex-start",
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        padding: 12
      })}
    >
      <div style={{ display: "flex", flexWrap: "wrap", flex: 1 }}>
        {filterPillsData.map((pill) => (
          <CustomFilterPill
            defaultValue={defaultValue}
            isRemovable
            key={pill.key}
            name={pill.name}
            menuProps={{
              focusZoneProps: {
                /* Allow up and down arrows to move focus out of the SearchBox */
                shouldInputLoseFocusOnArrowKey: () => true
              },
              items: pill.items
            }}
            onApply={(selectedItems) => pill.setSelectedItems(selectedItems || [])}
            onCancel={(previouslyselectedItem) => pill.setSelectedItems(previouslyselectedItem || [])}
            onResetFilters={() => pill.setSelectedItems([])}
            styles={CUSTOMER_SEARCH_STYLES.CUSTOMER_SEARCH_FILTER_PILL.STYLES}
          />
        ))}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px"
        }}
      >
        <label>Product Name:</label>
        <div style={{ width: 300 }}>
          <Select
            defaultValue={{
              label: props.productName,
              value: props.productName
            }}
            value={{ label: props.productName, value: props.productName }}
            onChange={handleProductChange}
            options={products}
            isSearchable={true}
            menuPortalTarget={document.body}
            aria-label="Product Name Search"
          />
        </div>
      </div>
    </div>
  );
};
