import React from "react";
import { CountAnnotationBar, ICountAnnotationBarProps } from "@m365-admin/count-annotation";
import { groupBy } from "../../Utils/js-common";
const style = {
  maxWidth: "150px",
  minWidth: "150px"
};
const EngagementsAnnotations = (props: any) => {
  const [annotationData, setAnnotationData] = React.useState<any>();

  React.useEffect(() => {
    if (props.annotationData) {
      const programCount = Object.keys(groupBy(props.annotationData, "programName")).length;
      const productCount = Object.keys(groupBy(props.annotationData, "programProduct")).length;
      const countAnnotationProps: ICountAnnotationBarProps = {
        countAnnotationProps: [
          {
            annotationText: "# of Program",
            count: programCount,
            style: style
          },
          {
            annotationText: "# of Engagements",
            count: "NA",
            style: style
          },
          {
            annotationText: "Single Party Meetings",
            count: "NA",
            style: style
          },
          {
            annotationText: "multi-Party meetings",
            count: "NA",
            style: style
          },
          {
            annotationText: "# of products",
            count: productCount,
            style: style
          },
          {
            annotationText: "# of Completed meetings",
            count: "NA",
            style: style
          },
          {
            annotationText: "# of idle days",
            count: "NA",
            style: style
          }
        ]
      };
      setAnnotationData(countAnnotationProps);
    }
  }, [props.annotationData]);

  return (
    <div style={{ width: "30%", paddingLeft: 10 }}>{annotationData && <CountAnnotationBar {...annotationData} />}</div>
  );
};

export default EngagementsAnnotations;
