import { DefaultButton, Shimmer } from "@fluentui/react";
import { shimmerWithElementGapRow } from "../../helper/ShimmerElement";
import { useBoolean } from "@fluentui/react-hooks";
import ContactsPanel from "./ContactPanel";

export default function CustomerContact(props: any) {
  const [isContactOpen, { setTrue: openContactPanel, setFalse: dismissContactPanel }] = useBoolean(false);

  return (
    <>
      <div
        style={{
          background: "#FFF",
          marginLeft: 5,
          maxWidth: 230,
          minWidth: 230
        }}
      >
        <div style={{ paddingLeft: 7, marginTop: 0 }}>
          <label style={{ paddingBottom: 10 }}>
            <strong>Contact Information :</strong>
          </label>
          <br />
          <br />
          <div style={{ display: "flex" }}>
            {props.loading && (
              <Shimmer
                style={{ width: 120, paddingLeft: 5 }}
                shimmerElements={shimmerWithElementGapRow}
                shimmerColors={{
                  shimmer: "rgb(222, 236, 249)"
                }}
              ></Shimmer>
            )}
            {!props.loading && <label> Account Manager : </label>}

            {props.loading && (
              <Shimmer
                style={{
                  width: 120,
                  paddingLeft: 4,
                  paddingRight: 3
                }}
                shimmerElements={shimmerWithElementGapRow}
                shimmerColors={{
                  shimmer: "rgb(222, 236, 249)"
                }}
              >
                &nbsp;&nbsp;
              </Shimmer>
            )}
            {!props.loading && <div style={{ width: 250 }}> &nbsp;{props.accountmgr}</div>}
          </div>
          <br />
          <div>
            <DefaultButton
              text="Show Contacts"
              onClick={() => openContactPanel()}
              iconProps={{ iconName: "ContactInfo" }}
            />

            <ContactsPanel isOpen={isContactOpen} onDismiss={dismissContactPanel} tenantName={props?.tenantName} />
          </div>
          <br></br>
          <label>For more info visit -</label>
          <a
            href="https://aka.ms/scimcontacts"
            target="_blank"
            style={{ color: "hsl(206, 100%, 42%)" }}
            rel="noreferrer"
          >
            https://aka.ms/scimcontacts
          </a>
        </div>
      </div>
    </>
  );
}
