import { CSSProperties, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DisplayAPIsException from "../../BasicComponents/DisplayAPIsExceptionAlert";
import { CustomShimmer } from "../../helper/CustomShimmer";
import { groupBy } from "../../Utils/js-common";
import { logEvent, logException } from "../../helper/AppInsightsHelper";
import {
  blockingStatusType,
  featureRequestChartType,
  featureStatusType,
  productsType
} from "../../interfaces/customer-feature";
import { useGetCRMFeatureRequestDataQuery } from "../../slices/Feature.slice";
import FeatureChart from "./FeatureChart";
import FeatutreTable from "./FeatureTable";


export default function FeatureRequestContainer(props: any) {
  const [items, setitems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredChartData, setFilteredChartData] = useState<featureRequestChartType>();
  const [filterChData, setFilterChData] = useState<any>({
    blockingStatus: "",
    products: "",
    featureStatus: ""
  });
  const [searchParams] = useSearchParams();
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [chartKeys, setChartKeys] = useState<string[]>([]);
  const [chartFilter, setChartFilter] = useState({
    filterKey: "",
    value: ""
  });
  const tenantId = searchParams.get("id");
  const featureRequestData = useGetCRMFeatureRequestDataQuery(tenantId);
  const HandleAPIExcpetion = () => {
    const enableAPIException = featureRequestData.error;
    return (enableAPIException && <DisplayAPIsException height={250} />) || "";
  };

  useEffect(() => {
    logEvent("Customer Feature Request", "Customer Page", "FeatureRequestContainer");
  }, []);

  useEffect(() => {
    if (featureRequestData && featureRequestData?.data && featureRequestData?.data?.table) {
      setIsTableLoading(false);
      setitems(featureRequestData?.data?.table);
      setFilteredItems(featureRequestData?.data?.table);
    }
    if (featureRequestData && featureRequestData?.data && featureRequestData?.data?.charts) {
      setIsChartLoading(false);
      setFilterChData({
        blockingStatus: "",
        products: "",
        featureStatus: ""
      });
      setFilteredChartData(featureRequestData?.data?.charts);
      const keys: string[] = Object.keys(featureRequestData?.data?.charts);
      setChartKeys(keys);
    }
    if (featureRequestData.error) {
      setIsTableLoading(false);
      setIsChartLoading(false);
      logException("Product View", "FeatureRequestContainer", "useGetCRMFeatureRequestDataQuery", featureRequestData.error);
    }
  }, [featureRequestData?.data, featureRequestData.error, featureRequestData]);

  useEffect(() => {
    switch (chartFilter.filterKey) {
      case "blockingStatus":
        {
          let filteredData: any = [];
          filteredData = items.filter((item: any) => item.featureEvidenceBlockingStatus === chartFilter.value);
          setFilterChData({
            ...filterChData,
            blockingStatus: chartFilter.value
          });
          if (filterChData.products !== "") {
            filteredData = filteredData.filter((item: any) => item.crmProductName === filterChData.products);
          }
          if (filterChData.featureStatus !== "") {
            filteredData = filteredData.filter((item: any) => item.featureRequestStatus === filterChData.featureStatus);
          }
          setFilteredItems(filteredData);
          const product = groupBy(filteredData, "crmProductName");
          const filteredFamily: productsType[] = [];
          Object.keys(product).forEach((item) => {
            filteredFamily.push({
              crmProductName: item,
              productCount: product[item].length
            });
          });

          const featureStatus = groupBy(filteredData, "featureRequestStatus");
          const filteredStatus: featureStatusType[] = [];
          Object.keys(featureStatus).forEach((item) => {
            filteredStatus.push({
              featureRequestStatus: item,
              featureStatusCount: featureStatus[item].length
            });
          });
          if (filteredFamily && filteredStatus && filteredChartData?.blockingStatus) {
            setFilteredChartData({
              ...filteredChartData,
              products: filteredFamily,
              featureStatus: filteredStatus
            });
          }
        }
        break;
      case "products":
        {
          let filteredData: any = [];
          filteredData = items.filter((item: any) => item.crmProductName === chartFilter.value);
          setFilterChData({
            ...filterChData,
            products: chartFilter.value
          });

          if (filterChData.blockingStatus !== "") {
            filteredData = filteredData.filter(
              (item: any) => item.featureEvidenceBlockingStatus === filterChData.blockingStatus
            );
          }
          if (filterChData.featureStatus !== "") {
            filteredData = filteredData.filter((item: any) => item.featureRequestStatus === filterChData.featureStatus);
          }
          setFilteredItems(filteredData);
          const featureStatus = groupBy(filteredData, "featureRequestStatus");
          const assignedFiltered: featureStatusType[] = [];
          Object.keys(featureStatus).forEach((item) => {
            assignedFiltered.push({
              featureRequestStatus: item,
              featureStatusCount: featureStatus[item].length
            });
          });
          const blockStatus = groupBy(filteredData, "featureEvidenceBlockingStatus");
          const filteredStatus: blockingStatusType[] = [];
          Object.keys(blockStatus).forEach((item) => {
            filteredStatus.push({
              featureEvidenceBlockingStatus: item,
              blockingStatusCount: blockStatus[item].length
            });
          });
          if (assignedFiltered && filteredStatus && filteredChartData?.products) {
            setFilteredChartData({
              ...filteredChartData,
              featureStatus: assignedFiltered,
              blockingStatus: filteredStatus
            });
          }
        }
        break;
      case "featureStatus":
        {
          let filteredData: any = [];
          filteredData = items.filter((item: any) => item.featureRequestStatus === chartFilter.value);
          setFilterChData({
            ...filterChData,
            featureStatus: chartFilter.value
          });

          if (filterChData.blockingStatus !== "") {
            filteredData = filteredData.filter(
              (item: any) => item.featureEvidenceBlockingStatus === filterChData.blockingStatus
            );
          }
          if (filterChData.products !== "") {
            filteredData = filteredData.filter((item: any) => item.crmProductName === filterChData.products);
          }
          setFilteredItems(filteredData);
          const product = groupBy(filteredData, "crmProductName");
          const filteredFamily: productsType[] = [];
          Object.keys(product).forEach((item) => {
            filteredFamily.push({
              crmProductName: item,
              productCount: product[item].length
            });
          });
          const blockStatus = groupBy(filteredData, "featureEvidenceBlockingStatus");
          const blockFiltered: blockingStatusType[] = [];
          Object.keys(blockStatus).forEach((item) => {
            blockFiltered.push({
              featureEvidenceBlockingStatus: item,
              blockingStatusCount: blockStatus[item].length
            });
          });
          if (filteredFamily && blockFiltered && filteredChartData?.featureStatus) {
            setFilteredChartData({
              ...filteredChartData,
              blockingStatus: blockFiltered,
              products: filteredFamily
            });
          }
        }
        break;
      default: {
        setFilteredItems(featureRequestData?.data?.table);
        setFilteredChartData(featureRequestData?.data?.charts);
        setFilterChData({
          blockingStatus: "",
          products: "",
          featureStatus: ""
        });
      }
    }
  }, [chartFilter]);

  const showNoData = () => {
    const rootStyle: CSSProperties = {
      alignItems: "center",
      display: "flex",
      fontSize: 18,
      fontWeight: 600,
      justifyContent: "center",
      padding: 200
    };
    return <div style={rootStyle}>{`Feature request details are not available for ${props.tenantName}.`}</div>;
  };

  return (
    <>
      <FeatureChart
        chartData={filteredChartData}
        isChartLoading={isChartLoading}
        keys={chartKeys}
        tenantName={props.tenantName}
        setChartFilter={setChartFilter}
        tenantId={tenantId}
      />
      {isTableLoading ? (
        <CustomShimmer columnCount={11} />
      ) : (
        (items.length > 0 && (
          <div style={{ marginLeft: 8, marginRight: 8 }}>
            <FeatutreTable tableData={filteredItems} tenantName={props.tenantName} />
          </div>
        )) ||
        showNoData()
      )}
      <HandleAPIExcpetion />
    </>
  );
}
