import { IconButton, IStyle, mergeStyles } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";
import { CountAnnotationBar, ICountAnnotationBarProps } from "@m365-admin/count-annotation";

export default function CountAnnotation(props: any) {
  const defaultLinkProps = {
    role: "tab",
    "aria-selected": false,
    "aria-hidden": true
  };
  const tooltipId = useId("tooltip");
  const whiteColorStyle = mergeStyles({ color: "var(--white-color)" } as IStyle);

  const defaultCountAnnotationProps = (): ICountAnnotationBarProps => {
    const countAnnotation: ICountAnnotationBarProps = {
      countAnnotationProps: []
    };

    props.cardData?.map((key: any, index: number) => {
      countAnnotation.countAnnotationProps.push({
        annotationText: (
          <div
            key={`annotationText-${index}`}
            style={{
              backgroundColor: key.color,
              borderRadius: "3px",
              fontSize: 12,
              fontWeight: "bold",
              alignItems: "center",
              display: "flex",
              justifyContent: "space-evenly",
              paddingLeft: "10px",
              height: "25px"
            }}
          >
            <div className={whiteColorStyle}>{key.productKPIName} </div>
            <TooltipHost content={key.productKPIDescription} id={tooltipId}>
              <IconButton
                iconProps={{ iconName: "Info", className: whiteColorStyle }}
                styles={{
                  rootHovered: {
                    background: "transparent"
                  }
                }}
              />
            </TooltipHost>
          </div>
        ),
        count: (
          <div
            key={`count-${index}`}
            style={{
              fontSize: 15,
              color: key.color,
              fontWeight: "bold"
            }}
          >
            {" "}
            {key.abbrevatedValue}
          </div>
        ),
        annotationColor: key.color,
        color: key.color,
        isSelected: true,
        linkProps: {
          ...defaultLinkProps
        }
      });
    });
    return countAnnotation;
  };
  const countAnnotationProps = defaultCountAnnotationProps();

  return <CountAnnotationBar {...countAnnotationProps} />;
}
